import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Flight } from '@feathr/blackbox';
import { Alert, AlertType, Tab } from '@feathr/components';
import type { IPageProps } from '@feathr/extender/App/Page';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl } from '@feathr/extender/state';

import FlightActions from './FlightActions';

import styles from './FlightPage.css';

export interface IProps extends IPageProps {
  children: React.ReactNode;
  flight: Flight;
}

function FlightPage({
  actions,
  description,
  children,
  flight,
  title,
  ...props
}: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const flightUrl = localUrl(flight.getItemUrl());

  return (
    <Page
      {...props}
      actions={<FlightActions flight={flight} />}
      description={
        <>
          {flight.get('rerun') && (
            <Alert type={AlertType.info}>
              This Flight's Goals or Legs recently changed, so its conversions and conversion value
              are currently being recalculated. This process should take around 30 minutes.
            </Alert>
          )}
        </>
      }
      tabs={[
        <Tab exact={true} key={'overview'} link={flightUrl} title={'Overview'} />,
        <Tab key={'report'} link={`${flightUrl}/report`} title={'Report'} />,
      ]}
      title={flight.name}
      width={'wide'}
    >
      <h3>{title}</h3>
      {!!description && <div className={styles.description}>{description}</div>}
      {children}
    </Page>
  );
}

export default observer(FlightPage);
