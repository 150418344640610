import { faCodeMerge, faEdit, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { CustomField } from '@feathr/blackbox';
import { ArchiveModal, ContextMenu, MenuItem, Modal, toast } from '@feathr/components';
import MergeFieldDialog from '@feathr/extender/components/CustomFieldsTable/FieldColumns/MergeFieldDialog/MergeFieldDialog';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useRedirect, useToggle } from '@feathr/hooks';

interface IFieldRowOptionsProps {
  original: CustomField;
}

function FieldRowOptions({ original }: IFieldRowOptionsProps): JSX.Element {
  const { t } = useTranslation();

  const { CustomFields } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const [, setRedirect] = useRedirect();

  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isMergeModalOpen, toggleMergeModalOpen] = useToggle(false);

  const isReadOnly = original.get('is_read_only', false);

  async function handleConfirm(): Promise<void> {
    if (original.mergeId) {
      const mergeModel = CustomFields.get(original.mergeId);
      if (original.isValid()) {
        try {
          await original.merge();
          toast(
            t(
              'Issued request to merge "{{newFieldName}}" into "{{oldFieldName}}".\nThis process should complete in 20-30 minutes.',
              {
                newFieldName: mergeModel.get('u_key'),
                oldFieldName: original.get('u_key'),
              },
            ),
            { type: ToastType.SUCCESS },
          );
          toggleMergeModalOpen();

          // If err is instance of Error, it should be of type any.
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          toast(error.message, { type: ToastType.ERROR });
        }
      }
    } else {
      toast(t('Please select a field to merge into this one.'), { type: ToastType.ERROR });
    }
  }

  return (
    <>
      <ContextMenu>
        <MenuItem
          disabled={isReadOnly}
          link={setRedirect(localUrl(original.getItemUrl('edit')))}
          prefix={<FontAwesomeIcon icon={faEdit} />}
          tooltip={
            isReadOnly ? t('This field cannot be edited because it is read-only.') : undefined
          }
        >
          {t('Edit')}
        </MenuItem>
        <MenuItem
          disabled={isReadOnly}
          onClick={toggleMergeModalOpen}
          prefix={<FontAwesomeIcon icon={faCodeMerge} />}
          tooltip={
            isReadOnly ? t('This field cannot be merged because it is read-only.') : undefined
          }
        >
          {t('Merge')}
        </MenuItem>
        <MenuItem
          disabled={isReadOnly}
          onClick={toggleArchiveModalOpen}
          prefix={<FontAwesomeIcon icon={faTrashAlt} />}
          tooltip={
            isReadOnly ? t('This field cannot be archived because it is read-only.') : undefined
          }
        >
          {t('Archive')}
        </MenuItem>
      </ContextMenu>
      {isMergeModalOpen && (
        <Modal
          confirmButtonText={t('Merge')}
          confirmDisabled={!original.mergeId}
          controlled={true}
          onClose={toggleMergeModalOpen}
          onConfirm={handleConfirm}
          t={t}
          title={t('Merge Custom Fields')}
        >
          <MergeFieldDialog model={original} />
        </Modal>
      )}
      {isArchiveModalOpen && (
        <ArchiveModal
          model={original}
          onClose={toggleArchiveModalOpen}
          successMessage={t('Successfully archived "{{name}}".', { name: original.get('u_key') })}
          t={t}
        />
      )}
    </>
  );
}

export default observer(FieldRowOptions);
