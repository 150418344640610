import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { IObservableArray } from 'mobx';
import { autorun, runInAction, set, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { computedFn } from 'mobx-utils';
import numeral from 'numeral';
import type { JSX, ReactNode } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastType } from 'react-toastify';

import type {
  Account,
  Billable,
  Campaign,
  Event,
  IExposureSettings,
  IStripe,
  Segment,
  Targetable,
  Targeting,
  TSource,
  User,
} from '@feathr/blackbox';
import {
  CampaignClass,
  CampaignState,
  getMaxTargetValue,
  getMinDuration,
  getMinStartDate,
} from '@feathr/blackbox';
import {
  Alert,
  AlertType,
  Button,
  ButtonValid,
  CardV2 as Card,
  Collapse,
  DatePicker,
  Fieldset,
  Form,
  Label,
  NumberInput,
  Popover,
  toast,
  Tooltip,
} from '@feathr/components';
import PaymentMethodForm from '@feathr/extender/App/Settings/Billing/BillablePage/PaymentMethodForm';
import BillingSource from '@feathr/extender/components/BillingSource';
import { StoresContext, useFlags, useLocalUrl, useUser } from '@feathr/extender/state';
import { currencyFormatter } from '@feathr/extender/utils';
import {
  flattenErrors,
  moment,
  momentToDate,
  TimeFormat,
  timezoneAbbr,
  useToggle,
} from '@feathr/hooks';

import type { ICampaignValidationErrors } from '../../CampaignSummary';
import BaseBidSelect from './BaseBidSelect';
import { getTargetables, getTargetSegments } from './CampaignEditStepTwo';
import FreqCapSelect from './FreqCapSelect';
import FreqPeriodSelect from './FreqPeriodSelect';

import * as styles from './CampaignEditStepFive.css';

interface IProps {
  onNext: () => void;
  onPrev: () => void;
  account: Account;
  event: Event;
  billable?: Billable;
  campaign: Campaign;
  targetings: IObservableArray<Targeting>;
}

function setSource(billable: Billable, source: TSource): void {
  const stripe = billable.get('stripe');
  billable.set({ stripe: { ...stripe, source, token: source.id } });
}

const getAudienceSize: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  const cls: CampaignClass = campaign.get('_cls');
  if ([CampaignClass.Segment, CampaignClass.Facebook].includes(cls)) {
    return segments.map((s) => s.reachable).reduce((acc, c) => acc + c, 0);
  }
  if ([CampaignClass.EmailList, CampaignClass.EmailListFacebook].includes(cls)) {
    return targetables.reduce((acc, t) => {
      acc += t.get('num_unique_emails') || 0;
      return acc;
    }, 0);
  }

  // We use these numbers to scale the recommend budget by small, medium, and large expected audiences sizes
  switch (cls) {
    // Campaigns that target a specific audience are huge, so we limit the audience size to 100k to prevent an offensive recommended budget
    case CampaignClass.Lookalike:

    case CampaignClass.SeedSegment:

    case CampaignClass.Affinity: {
      return 100000;
    }

    // Search and Geo-like campaigns have smaller audiences on the order of 10s of thousands.
    case CampaignClass.Search:

    case CampaignClass.MobileGeoFencing:

    case CampaignClass.MobileGeoFenceRetargeting: {
      return 10000;
    }

    default: {
      // 1000 for default since first party groups tend to be pretty small
      return 1000;
    }
  }
});

export function getDays(campaign: Campaign): number {
  let days = 1;
  const dateStart = campaign.get('date_start');
  const dateEnd = campaign.get('date_end');
  if (!(dateStart && dateEnd)) {
    return days;
  }
  const startMoment = moment.utc(dateStart, moment.ISO_8601);
  const endMoment = moment.utc(dateEnd, moment.ISO_8601);
  if (startMoment.isBefore(endMoment)) {
    const startDay = startMoment.startOf('day');
    const endDay = endMoment.startOf('day');
    while (startDay.isBefore(endDay)) {
      startDay.add(1, 'day');
      days += 1;
    }
  }
  return days;
}

function getMinBudget(campaign: Campaign): number {
  const days = getDays(campaign);
  const isMonetization = campaign.get('parent_kind') === 'partner';
  if (isMonetization) {
    return days * 4000;
  }

  return days * 5;
}

const getRecommendedImpressions: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  const audienceSize = getAudienceSize(campaign, segments, targetables);
  const reach = 0.015;
  const freqCap = campaign.get('exposure_model').freq_cap;
  const days = getDays(campaign);
  return audienceSize * reach * freqCap * days;
});

const getRecommendedBudget: (
  campaign: Campaign,
  segments: Segment[],
  targetables: Targetable[],
) => number = computedFn((campaign: Campaign, segments: Segment[], targetables: Targetable[]) => {
  const audienceSize = getAudienceSize(campaign, segments, targetables);
  const reach = 0.015;
  const freqCap = campaign.get('exposure_model').freq_cap;
  const cpi = campaign.get('exposure_settings', {} as IExposureSettings).base_bid! / 1000;
  const days = getDays(campaign);
  const minBudget = getMinBudget(campaign);
  const result = Math.max(audienceSize * reach * freqCap * cpi * days, minBudget);
  // Limit to 2 decimals.
  return parseFloat(result.toFixed(2));
});

function getRecommendedBid(size: number): number {
  /*
   * This will be revisited at a later date. Intentionally leaving the multiple
   * if statements that evaluate to the same number.
   */
  if (size > 20000) {
    return 5;
  }
  if (size < 20000 && size > 5000) {
    return 5;
  }
  return 10;
}

export function validateStepFive(
  campaign: Campaign,
  event: Event,
  billable: Billable | undefined,
  account: Account,
  user?: User,
  noMaxBudget?: boolean,
): ICampaignValidationErrors {
  const monetization = campaign.get('parent_kind') === 'partner';

  const attributes = ['date_start', 'date_end', 'state', '_cls'];
  if (monetization) {
    attributes.push('monetization_value');
  }

  const errors = {
    budget: [] as string[],
    date_start: [] as string[],
    date_end: [] as string[],
    monetization_value: [] as string[],
    /*
     * We have to pass along state and _cls so they show up in the attributes provided
     * to the date_start validator
     */
    ...(toJS(campaign.validate(attributes, false, 'grouped').errors) as Record<string, string[]>),
  };

  const facebook = [CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(
    campaign.get('_cls'),
  );
  const endDate = campaign.get('date_end');
  const budget = campaign.get('exposure_settings').target_value || 0;
  const billing = event.get('billing');
  const balance = billing?.balance ?? 0;
  const isComplete = moment.utc(endDate).isBefore(moment.utc());
  const hasPaymentMethod =
    billable && !billable.isPending && billable.get('stripe')
      ? !!billable.get('stripe').source
      : false;
  const minBudget = facebook ? getMinBudget(campaign) : 1;
  const spend: number = campaign.get('total_stats')?.spend || 0;
  if (!billable) {
    errors.budget.push(
      'You need to add a billing configuration to this project in order to publish a campaign that incurs media spend.',
    );
  }

  //  Trigger errors only when user changed exposure settings (e.g. budget)
  const exposureSettingsHaveChanged = campaign.isAttributeDirty('exposure_settings');
  if (exposureSettingsHaveChanged && budget < minBudget && !monetization) {
    errors.budget.push(
      `Budget must be at least $${numeral(minBudget).format(
        '0,0.00',
      )} to fulfill this campaign for its duration.`,
    );
  }
  // Prevent user from setting a budget below campaign spend.
  if (exposureSettingsHaveChanged && budget < spend && !monetization) {
    errors.budget.push(
      `Budget must be at least $${numeral(spend).format(
        '0,0.00',
      )} to account for the amount already spent.`,
    );
  }

  /*
   *We can ignore max budget under the following circumstances:
   *- The noMaxBudget feature flag is set
   */
  if (!noMaxBudget) {
    const maxBudget = getMaxTargetValue(account.getSetting('campaigns_max_budget'), monetization);
    if (budget > maxBudget) {
      if (monetization) {
        errors.budget.push(
          `The number of impressions cannot exceed ${numeral(maxBudget).format('0,0')}.`,
        );
      } else {
        errors.budget.push(
          `The budget cannot exceed ${numeral(maxBudget).format('$0,0')} (account-wide setting).`,
        );
      }
    }
  }

  if (
    !isComplete &&
    !hasPaymentMethod &&
    budget - spend > balance &&
    account.get('media_validation') &&
    !monetization &&
    !facebook
  ) {
    const suffix =
      'Consider adding funds to your project or adding a payment method to your project billing configuration.';
    errors.budget.push(`
      Because you have no configured payment method, budget must be less than your project media credit balance
      and current campaign spend (${numeral(spend + balance).format('$0,0.00')}). ${suffix}`);
  } else if (
    !isComplete &&
    !hasPaymentMethod &&
    balance <= 0 &&
    account.get('media_validation') &&
    !!monetization
  ) {
    /*
     * TODO: Don't do this because negative balance isn't actually bad.
     * errors.budget.push(`
     *   You must configure a payment method or have a positive media credit balance in order to publish a
     *   monetization campaign.`);
     */
  }
  return errors;
}

const NextStepButton = observer(
  ({ campaign, event, billable, account, onNext }: Omit<IProps, 'onPrev' | 'targetings'>) => {
    const flags = useFlags();
    const user = useUser();

    const validationErrors = validateStepFive(
      campaign,
      event,
      billable,
      account,
      user,
      flags.noMaxBudget,
    );
    return (
      <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
        Next
      </ButtonValid>
    );
  },
);

function CampaignEditStepFive({
  account,
  campaign,
  event,
  billable,
  onNext,
  onPrev,
  targetings,
}: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { Segments, Targetables } = useContext(StoresContext);
  const flags = useFlags();
  const user = useUser();
  const { t } = useTranslation();

  const segments = getTargetSegments(targetings, Segments);
  const targetables = getTargetables(targetings, Targetables);
  const validationErrors = validateStepFive(
    campaign,
    event,
    billable,
    account,
    user,
    flags.noMaxBudget,
  );
  const monetization = campaign.get('parent_kind') === 'partner';
  const dateStart = moment.utc(campaign.get('date_start'), TimeFormat.isoDateTime);
  const dateEnd = moment.utc(campaign.get('date_end'), TimeFormat.isoDateTime);
  const datesSet = campaign.get('date_start') && campaign.get('date_end');
  const now = moment.utc();
  const [recBudget, setRecBudget] = React.useState(0);
  const [adding, toggleAdding] = useToggle(false);
  const isDraft = campaign.get('state') === CampaignState.Draft;
  const isFacebook = [CampaignClass.Facebook, CampaignClass.EmailListFacebook].includes(
    campaign.get('_cls'),
  );
  const isComplete = dateEnd.isBefore(now);
  const isStarted = dateStart.isBefore(now);
  React.useEffect(() => {
    return autorun(() => {
      const audienceSize = getAudienceSize(campaign, segments, targetables);
      const recommendedBudget = getRecommendedBudget(campaign, segments, targetables);
      const recommendedBid = getRecommendedBid(audienceSize);
      if (!monetization) {
        const exposureSettings = campaign.get('exposure_settings');
        if (exposureSettings && !exposureSettings.custom_target && isDraft) {
          runInAction((): void => {
            set(exposureSettings, { target_value: recommendedBudget });
          });
          campaign.setAttributeDirty('exposure_settings');
        }
        if (exposureSettings && !exposureSettings.custom_bid && isDraft) {
          runInAction((): void => {
            set(exposureSettings, {
              base_bid: recommendedBid,
              max_bid: recommendedBid * 2,
            });
          });
          campaign.setAttributeDirty('exposure_settings');
        }
      }
      setRecBudget(recommendedBudget);
    });
  }, [campaign, isDraft, monetization, segments, targetables]);

  function handleTargetValueChange(newValue?: number): void {
    const exposureSettings = campaign.get('exposure_settings');
    if (exposureSettings) {
      runInAction((): void => {
        set(exposureSettings, {
          target_value: newValue ?? 0,
          custom_target: true,
        });
      });
      campaign.setAttributeDirty('exposure_settings');
    }
  }

  function handleMonetizationValueChange(newValue?: number): void {
    campaign.set({ monetization_value: newValue ?? 0 });
  }

  const helpDeskLink = (): JSX.Element | null => {
    let prompt = '';
    let url = '';
    if (campaign.get('_cls') === CampaignClass.Segment) {
      prompt = 'how to set up Billing Configurations';
      url = 'https://help.feathr.co/hc/en-us/articles/360037323074-Feathr-Billing-Basics';
    } else {
      return null;
    }

    return (
      <p>
        <a href={url} target={'_blank'}>
          Check out our help desk to learn {prompt}.
        </a>
      </p>
    );
  };

  let description: ReactNode;
  if (isFacebook) {
    description = (
      <p>
        Here you will set the duration and budget for your campaign. Once you set the duration,
        Feathr will suggest a budget for you based on the duration, size of the audience and
        campaign type.
      </p>
    );
  } else if (!monetization) {
    description = (
      <>
        <p>
          Here you will set the duration and budget for your campaign. Once you set the duration,
          Feathr will suggest a budget for you based on the duration, size of the audience and
          campaign type.
        </p>
        <p>
          Feathr tries to spend enough each day to meet your campaign budget by the end of the
          campaign, but sometimes this is not possible due to limited audience availability.
        </p>
        {helpDeskLink()}
      </>
    );
  } else {
    description = (
      <>
        <p>Here you will set the duration and target impressions for your campaign.</p>
        <p>
          Feathr tries to bid enough each day to meet your campaign impression target by the end of
          the campaign, but sometimes this is not possible due to limited audience availability.
        </p>
        {helpDeskLink()}
      </>
    );
  }

  const billing = event.get('billing');
  const balance = billing?.balance || 0;
  const budget = campaign.get('exposure_settings').target_value || 0;
  const spend = campaign.get('total_stats')?.spend || 0;

  // Time stamps
  const startTimeStamp = campaign.get('date_start');
  const endTimeStamp = campaign.get('date_end');

  // Moment objects
  const startMoment = moment.utc(startTimeStamp).local();
  const endMoment = moment.utc(endTimeStamp).local();

  // Date objects
  const startDate = momentToDate(startMoment);
  const endDate = momentToDate(endMoment);

  // ISO formatted timestamps
  const isoStartTimestamp = moment.utc(startTimeStamp).format(TimeFormat.isoDateTime);
  const isoEndTimestamp = moment.utc(endTimeStamp).format(TimeFormat.isoDateTime);

  function handleOnChangeSendStart(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_start: newTimestamp,
    });
    if (newMoment.isAfter(moment.utc(campaign.get('date_end')))) {
      handleOnChangeDateEnd(newMoment.add(1, 'day').format(TimeFormat.isoDateTime));
    }
  }

  function handleOnChangeDateEnd(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_end: newTimestamp,
    });
    if (newMoment.isBefore(moment.utc(campaign.get('date_start')))) {
      let newStartMoment = newMoment.subtract(1, 'day');
      if (newStartMoment.isBefore()) {
        newStartMoment = moment.utc().startOf('minute');
      }
      handleOnChangeSendStart(newStartMoment.format(TimeFormat.isoDateTime));
    }
  }

  const overspendText = monetization
    ? t(
        'Due to standard bidding dynamics, campaigns may under or overreach their impression target by approximately 5%.',
      )
    : t(
        'Due to standard bidding dynamics, campaigns may underspend or overspend their budget by approximately 5%.',
      );

  const budgetLabel = (
    <span className={styles.budgetLabel}>
      {monetization ? t('Impressions') : t('Budget')}
      <Tooltip title={overspendText}>
        <FontAwesomeIcon className={styles.budgetTooltip} icon={faInfoCircle} />
      </Tooltip>
    </span>
  );

  const formattedTarget = numeral(
    getRecommendedImpressions(campaign, segments, targetables),
  ).format('0,0');
  const formattedBudget = numeral(recBudget).format('$0,0.00');

  const targetRecommendation = monetization
    ? t('Recommended target: {{target}}', { target: formattedTarget })
    : t('Recommended budget: {{budget}}', { budget: formattedBudget });

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          Previous
        </Button>,
        <NextStepButton
          account={account}
          billable={billable}
          campaign={campaign}
          event={event}
          key={'next'}
          onNext={onNext}
        />,
      ]}
      description={description}
      label={'Edit Campaign: Budget & Duration'}
    >
      <Fieldset>
        <DatePicker
          dateFormat={'MMM d, yyyy h:mm aa'}
          disabled={!isDraft && isStarted}
          label={'Start Date'}
          minDate={momentToDate(getMinStartDate(campaign.get('_cls')))}
          name={'date_start'}
          onDateStrChange={handleOnChangeSendStart}
          selected={startDate}
          showTimeSelect={true}
          suffix={timezoneAbbr(startMoment.toDate())}
          timeIntervals={5}
          validationError={validationErrors.date_start}
          value={isoStartTimestamp}
        />
        <DatePicker
          dateFormat={'MMM d, yyyy h:mm aa'}
          disabled={!isDraft && isComplete}
          label={'End Date'}
          minDate={startMoment ? momentToDate(startMoment.add(getMinDuration())) : undefined}
          name={'date_end'}
          onDateStrChange={handleOnChangeDateEnd}
          selected={endDate}
          showTimeSelect={true}
          suffix={timezoneAbbr(endMoment.toDate())}
          timeIntervals={5}
          validationError={validationErrors.date_end}
          value={isoEndTimestamp}
        />
      </Fieldset>
      <NumberInput
        className={styles.budgetInput}
        disabled={!datesSet}
        helpPlacement={'bottom'}
        helpText={targetRecommendation}
        label={budgetLabel}
        min={10}
        name={'budget'}
        onChange={handleTargetValueChange}
        prefix={monetization ? null : '$'}
        validationError={validationErrors.budget}
        value={campaign.get('exposure_settings').target_value}
        wrapperClassName={styles.budgetInput}
      />
      {monetization && (
        <Fieldset>
          <NumberInput
            className={styles.budgetInput}
            helpText={`How much is this campaign worth to you?
              Usually this is how much your partner paid to you to run the campaign.
              This value is only used to provide reporting context.`}
            label={'Sponsor package value'}
            min={0}
            name={'budget'}
            onChange={handleMonetizationValueChange}
            optional={true}
            prefix={'$'}
            required={true}
            validationError={validationErrors.monetization_value}
            value={campaign.get('monetization_value')}
            wrapperClassName={styles.budgetInput}
          />
        </Fieldset>
      )}
      {!isFacebook && (
        <Card>
          <Card.Header title={'Payment method'} />
          {!adding ? (
            <Card.Content>
              {balance > 0 && (
                <>
                  <Label>
                    Media Credit Balance
                    <Popover toggle={'onMouseOver'}>
                      <span>
                        <FontAwesomeIcon className={styles.info} icon={faInfoCircle} />
                      </span>
                      <div style={{ margin: 15 }}>
                        <p>
                          This number represents the amount of money you have already funded on this
                          project (in USD).
                        </p>
                      </div>
                    </Popover>
                  </Label>
                  <div
                    className={classNames({
                      [styles.balanceWithPayment]: budget - spend > balance,
                    })}
                  >
                    {currencyFormatter.format(Math.max(balance, 0))}
                  </div>
                </>
              )}
              {!billable ? (
                <Alert type={AlertType.danger}>
                  You will not be able to publish this campaign until you&nbsp;
                  <Link to={localUrl('/settings/billing/configurations')}>
                    set up a billing configuration
                  </Link>
                  &nbsp;and&nbsp;
                  <Link to={localUrl(event.getItemUrl('/settings/billing/edit'))}>
                    attach it to this project
                  </Link>
                  .
                </Alert>
              ) : !billable.isPending && billable.get('stripe', {} as IStripe).source ? (
                <BillingSource
                  billable={billable}
                  key={billable.get('stripe').source!.id}
                  source={billable.get('stripe').source!}
                />
              ) : (
                budget - spend > balance && (
                  <>
                    <p>Payment method has not been set.</p>
                    <Button onClick={toggleAdding}>Add payment method</Button>
                    <p className={styles.note}>
                      <strong>Note:</strong> Changing the payment method above will affect the
                      Billing Configuration for the whole Project.
                    </p>
                  </>
                )
              )}
            </Card.Content>
          ) : (
            !!billable &&
            !billable.isPending && (
              <PaymentMethodForm>
                {(onSave, element): JSX.Element => {
                  async function handleSave(): Promise<void> {
                    if (!billable || billable.isPending) {
                      return;
                    }

                    try {
                      const updatedSource = await onSave();
                      setSource(billable, updatedSource);
                      await billable.save();
                      toggleAdding();
                    } catch (error) {
                      const message =
                        error instanceof Error
                          ? error.message
                          : 'An error occurred while trying to add a payment method.';
                      toast(message, { type: ToastType.ERROR });
                    }
                  }

                  return (
                    <>
                      <Card.Content>{element}</Card.Content>
                      <Card.Actions>
                        <Button onClick={toggleAdding} type={'naked'}>
                          {t('Cancel')}
                        </Button>
                        <Button onClick={handleSave}>{t('Add')}</Button>
                      </Card.Actions>
                    </>
                  );
                }}
              </PaymentMethodForm>
            )
          )}
        </Card>
      )}
      {!isFacebook && (
        <Collapse title={'Advanced Options'}>
          <p>
            These settings are preset for optimal performance based on your audience size and the
            campaign type. There are some cases where you may want to adjust them, but typically the
            preset values should be used.
          </p>
          <Fieldset direction={'column'}>
            <FreqCapSelect campaign={campaign} />
            <BaseBidSelect campaign={campaign} />
          </Fieldset>
          <FreqPeriodSelect campaign={campaign} />
        </Collapse>
      )}
    </Form>
  );
}

export default observer(CampaignEditStepFive);
