import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { BlackbaudRaisersEdgeIntegration, FieldDataType } from '@feathr/blackbox';
import { EBlackbaudRaisersEdgeActivityDataTypes } from '@feathr/blackbox';
import {
  CardV2 as Card,
  EFontAwesomeKitIcon,
  FontAwesomeKitIcon,
  Input,
  Select,
  toast,
  Toggle,
} from '@feathr/components';
import { FieldOption, FieldSingleValue } from '@feathr/extender/components/SelectOptions';
import {
  BlackbaudRaisersEdgeActivityMappingFieldTranslationMap,
  BlackbaudRaisersEdgeActivityMappingToFeathrFieldTranslationMap,
} from '@feathr/extender/styles/blackbaud_raisers_edge';

import * as styles from './RaisersEdgeSyncActivity.css';

interface IProps {
  integration: BlackbaudRaisersEdgeIntegration;
}

interface ISelectField {
  id: string;
  name: string;
  data_type: FieldDataType;
}

function RaisersEdgeSyncActivity({ integration }: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const mappings = Object.entries(integration.get('downsync_activities'));

  function getOptionLabel({ name }: ISelectField): string {
    return name;
  }

  function getOptionValue({ id }: ISelectField): string {
    return id;
  }

  /*
   * There is currently only 1 option, but there may be more in the future.
   */
  const blackbaudFieldOptions = mappings.map(([key]) => ({
    id: key,
    name: BlackbaudRaisersEdgeActivityMappingFieldTranslationMap(t, key),
    data_type: EBlackbaudRaisersEdgeActivityDataTypes[key],
  }));

  const feathrFieldOptions = mappings.map(([key]) => ({
    id: key,
    name: BlackbaudRaisersEdgeActivityMappingToFeathrFieldTranslationMap(t, key),
    data_type: EBlackbaudRaisersEdgeActivityDataTypes[key],
  }));

  async function handleToggleSyncing(value?: boolean): Promise<void> {
    if (value === undefined) {
      return;
    }
    integration.set({ downsync_activities: { gift_events: value } });
    try {
      await integration.patchDirty();
      toast(
        t(`"{{field}}" {{action}}`, {
          field: 'Gifts',
          action: value ? 'enabled' : 'disabled',
        }),
        {
          type: ToastType.SUCCESS,
        },
      );
    } catch (error) {
      toast(t('Something went wrong while updating the mapping:\n{{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <>
      {mappings.map(([key, value]) => (
        <Card contentClassName={styles.card} key={key}>
          <Card.Content contentClassName={styles.content}>
            <div className={styles.actions}>
              <Input
                className={styles.input}
                disabled={true}
                prefix={<FontAwesomeKitIcon iconName={EFontAwesomeKitIcon.blackbaud} />}
                value={blackbaudFieldOptions.find(({ id }) => id === key)?.name}
              />
              <FontAwesomeIcon icon={faArrowRight} />
              <Select
                className={styles.select}
                components={{ Option: FieldOption, SingleValue: FieldSingleValue }}
                disabled={true}
                getOptionLabel={getOptionLabel}
                getOptionValue={getOptionValue}
                value={feathrFieldOptions.find(({ id }) => id === key)}
              />
            </div>
            <div className={styles.controls}>
              <Toggle label={t('Sync')} onChange={handleToggleSyncing} value={value} />
            </div>
          </Card.Content>
        </Card>
      ))}
    </>
  );
}

export default observer(RaisersEdgeSyncActivity);
