import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader, Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const DateCreatedColumn: IColumn<BaseCampaign> = {
  id: 'date_created',
  checkboxLabel: 'Created Date',
  Header: TableColumnHeader({
    sortType: 'numeric',
    title: 'Created',
  }),
  width: 110,
  headerClassName: tableStyles.sort,
  className: tableStyles.cell,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const value = original.get('date_created');
          return value ? <Time format={TimeFormat.shortDate} timestamp={value} /> : <>-</>;
        }}
      </Observer>
    );
  },
};

export default DateCreatedColumn;
