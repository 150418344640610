import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { UserRole } from '@feathr/blackbox';
import { Fieldset, Input, Modal, toast } from '@feathr/components';

import RoleSelect from './RoleSelect';

interface IArchiveRoleModalProps {
  onClose: () => void;
  role: UserRole;
}

function ArchiveRoleModal({ onClose, role }: Readonly<IArchiveRoleModalProps>): JSX.Element {
  const { t } = useTranslation();

  const [roleReassignment, setRoleReassignment] = useState<string | undefined>();
  const [confirmDelete, setConfirmDelete] = useState<string>();
  const roleName = role.name;

  function handleOnChangeRoleReassignment(newValue?: string): void {
    setRoleReassignment(newValue);
  }

  async function handleRoleDelete(): Promise<void> {
    if (!roleReassignment) {
      return;
    }
    try {
      await role.deleteRole(roleReassignment);
      onClose();
      toast(t('{{-roleName}} has been deleted.', { roleName }), { type: ToastType.SUCCESS });
    } catch (error) {
      toast(t('There was a problem deleting the role:\n{{- error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
  }

  function handleDeletionConfirmationChange(newValue?: string): void {
    setConfirmDelete(newValue ?? '');
  }

  function validateRoleDeletionConfirmation(): string | undefined {
    return confirmDelete !== t('{{-roleName}}', { roleName })
      ? t('Type "{{-roleName}}".', { roleName })
      : undefined;
  }

  return (
    <Modal
      confirmButtonText={t('Delete role')}
      confirmButtonType={'danger'}
      confirmDisabled={!(roleReassignment && confirmDelete === roleName)}
      controlled={true}
      description={t('Are you sure you want to delete this role? This action cannot be undone.')}
      onClose={onClose}
      onConfirm={handleRoleDelete}
      size={'sm'}
      t={t}
      title={t('Delete {{-roleName}} Role?', { roleName })}
    >
      <Fieldset>
        <RoleSelect
          excludeById={role.id}
          helpText={t(
            'Prior to deletion, choose the new role for all currently assigned users to be reassigned to.',
          )}
          label={t('Reassign users')}
          onChange={handleOnChangeRoleReassignment}
          value={roleReassignment}
        />
        <Input
          label={t('Type "{{-roleName}}" to confirm', { roleName })}
          name={'confirm-delete'}
          onChange={handleDeletionConfirmationChange}
          type={'text'}
          validationError={validateRoleDeletionConfirmation()}
          value={confirmDelete}
        />
      </Fieldset>
    </Modal>
  );
}

export default observer(ArchiveRoleModal);
