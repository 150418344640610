import React from 'react';

import type { IReportAttributes } from '@feathr/blackbox';
import type { ReportModel } from '@feathr/blackbox';
import { DatePicker, Fieldset, Select } from '@feathr/components';
import { moment, TimeFormat } from '@feathr/hooks';

import * as styles from './ReportDateRange.css';

interface IProps<IAttributes extends IReportAttributes> {
  reportEnd: string | undefined;
  reportStart: string | undefined;
  mode: 'live' | 'dateWindow';
  setReportStart: (start: string | undefined) => void;
  setReportEnd: (end: string | undefined) => void;
  setMode: (mode: 'live' | 'dateWindow') => void;
  model: ReportModel<IAttributes>;
}

interface IModeOption {
  id: 'live' | 'dateWindow';
  name: string;
}

function ReportDateRange<IAttributes extends IReportAttributes>({
  model,
  reportStart,
  setReportStart,
  reportEnd,
  setReportEnd,
  mode,
  setMode,
}: IProps<IAttributes>) {
  const start = reportStart || model.reportRangeStart;
  const end = reportEnd || model.reportRangeEnd || moment.utc().format();
  const startDate = new Date(start);
  const endDate = end === 'now' ? new Date() : new Date(end);
  const min = new Date(model.reportRangeStart);
  const modeOptions: IModeOption[] = [
    { id: 'live', name: 'Live' },
    { id: 'dateWindow', name: 'Date Window' },
  ];
  const selectedMode = modeOptions.find((opt) => opt.id === mode);
  return (
    <>
      <Fieldset direction={'column'}>
        <Select
          onSelectSingle={(option) => {
            setMode(option.id);
            setReportStart(model.reportRangeStart);
            if (option.id === 'live' && model.reportKey === 'e') {
              setReportEnd('now');
            } else {
              setReportEnd(model.reportRangeEnd);
            }
          }}
          options={modeOptions}
          placeholder={'Mode'}
          value={selectedMode}
          wrapperClassName={styles.modeSelect}
        />
      </Fieldset>
      {mode === 'dateWindow' && (
        <Fieldset className={styles.dateRange} direction={'column'}>
          <DatePicker
            endDate={end ? endDate : undefined}
            format={TimeFormat.isoDate}
            maxDate={end ? endDate : undefined}
            minDate={min}
            onDateStrChange={(date) => {
              setReportStart(date);
            }}
            placeholder={'Report Start'}
            selected={startDate}
            selectsStart={true}
            startDate={startDate}
          />
          <span className={styles.dateRangeTo}>to</span>
          <DatePicker
            endDate={end ? endDate : undefined}
            format={TimeFormat.isoDate}
            minDate={startDate}
            onDateStrChange={(date) => {
              setReportEnd(date);
            }}
            placeholder={'Report End'}
            selected={end ? endDate : undefined}
            selectsEnd={true}
            startDate={startDate}
          />
        </Fieldset>
      )}
    </>
  );
}

export default ReportDateRange;
