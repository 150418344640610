import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ITTDSubAdvertiser } from '@feathr/blackbox';
import { ArchiveModal, ContextMenu, MenuItem, toast } from '@feathr/components';
import { useAccount, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

interface IProps {
  original: ITTDSubAdvertiser;
}

function AdvertiserOptionsCell({ original }: IProps) {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const account = useAccount();
  const [showArchiveModal, toggleArchiveModal] = useToggle(false);

  async function deleteMessage() {
    if (!account) {
      return;
    }
    account.set({
      ttd: {
        ...account.get('ttd', {}),
        subadvertisers: (account.get('ttd', { subadvertisers: [] }).subadvertisers || []).filter(
          (subAdv) => subAdv.subadv_id !== original.subadv_id,
        ),
      },
    });
    await account.patchDirty();
    if (account.isErrored) {
      toast(
        t('Encountered an error while deleting advertiser:\n{{errorMessage}}', {
          errorMessage: account.error?.message,
        }),
        {
          type: 'error',
        },
      );
    }
    toast(t('Deleted advertiser.'), { type: 'success' });
    toggleArchiveModal();
  }

  return (
    <>
      <ContextMenu>
        <MenuItem link={localUrl(`settings/account/advertisers/${original.subadv_id}`)}>
          {t('Edit')}
        </MenuItem>
        <MenuItem onClick={toggleArchiveModal}>{t('Delete')}</MenuItem>
      </ContextMenu>
      {showArchiveModal && (
        <ArchiveModal
          message={t(
            'Are you sure you want to delete this advertiser? This action cannot be undone.',
          )}
          onClose={toggleArchiveModal}
          onConfirm={deleteMessage}
          t={t}
          title={t('Delete Message')}
        />
      )}
    </>
  );
}

export default observer(AdvertiserOptionsCell);
