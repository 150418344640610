import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Flight } from '@feathr/blackbox';
import { Modal, toast } from '@feathr/components';

import FlightPathSettings from './FlightPathSettings';

interface IProps {
  flight: Flight;
  toggleModal: () => void;
}

function onConfirm({ flight, t, toggleModal }: IProps & WithT) {
  return async () => {
    try {
      await flight.save();
      if (flight.collection) {
        flight.collection.refreshApiCache();
      }
      toast(t('Flight Path settings updated.'), { type: 'success' });
      toggleModal();
    } catch (reason) {
      toast(t('Something went wrong: {{reason}}', { reason }), { type: 'error' });
    }
  };
}

function MakeSharedFlightModal({ flight, toggleModal }: IProps) {
  const { t } = useTranslation();
  return (
    <Modal
      controlled={true}
      ephemeral={true}
      onClose={toggleModal}
      onConfirm={onConfirm({ flight, t, toggleModal })}
      t={t}
      title={t('Make Flight Path')}
    >
      <FlightPathSettings flight={flight} />
    </Modal>
  );
}

export default observer(MakeSharedFlightModal);
