import { set } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { IDynamicContent } from '@feathr/blackbox';
import { Select } from '@feathr/components';

interface IProps {
  dynamicContent: IDynamicContent;
}

function FontSelect({ dynamicContent }: IProps) {
  return (
    <Select
      label={'Font'}
      onSelectSingle={(option) => {
        set(dynamicContent, { font: option.id });
      }}
      options={[
        { id: 'Arial', name: 'Arial' },
        { id: 'Courier', name: 'Courier' },
        { id: 'Georgia', name: 'Georgia' },
        { id: 'Helvetica', name: 'Helvetica' },
        { id: 'Lato', name: 'Lato' },
        { id: 'Lucida Grande', name: 'Lucida Grande' },
        { id: 'Montserrat', name: 'Montserrat' },
        { id: 'Open Sans', name: 'Open Sans' },
        { id: 'Roboto', name: 'Roboto' },
        { id: 'Source Sans Pro', name: 'Source Sans Pro' },
        { id: 'Tahoma', name: 'Tahoma' },
        { id: 'Trebuchet', name: 'Trebuchet' },
        { id: 'Ubuntu', name: 'Ubuntu' },
        { id: 'Verdana', name: 'Verdana' },
      ]}
      value={{ id: dynamicContent.font!, name: dynamicContent.font! }}
    />
  );
}

export default observer(FontSelect);
