import { nanoid } from 'nanoid';
import { useState } from 'react';

interface IProps {
  ephemeral?: boolean;
}
export function useId({ ephemeral }: IProps = { ephemeral: false }): string {
  const uid = nanoid(12);
  const prefixedUid = `uid-${uid}`;
  const [value] = useState(prefixedUid);
  return ephemeral ? prefixedUid : value;
}
