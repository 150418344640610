import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { BlackbaudRaisersEdgeIntegration } from '@feathr/blackbox';
import { Section, Tab, Tabs } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import RaisersEdgeAddMapping from './RaisersEdgeAddMapping';
import RaisersEdgeSummaryCard from './RaisersEdgeSummaryCard';
import RaisersEdgeSyncActivity from './RaisersEdgeSyncActivity';
import RaisersEdgeSyncPerson from './RaisersEdgeSyncPerson';

import * as styles from './RaisersEdgeSyncWizard.css';
interface IProps {
  integration: BlackbaudRaisersEdgeIntegration;
}

type TSyncWizardTabKeys = 'persons-constituents' | 'activities-actions';

function RaisersEdgeSyncWizard({ integration }: IProps): JSX.Element {
  const { t } = useTranslation();
  const personTabKey: Extract<TSyncWizardTabKeys, 'persons-constituents'> = 'persons-constituents';
  const activityTabKey: Extract<TSyncWizardTabKeys, 'activities-actions'> = 'activities-actions';
  const [activeTab, setActiveTab] = useState<TSyncWizardTabKeys>(personTabKey);
  const [hasMappings, setHasMappings] = useState<boolean>(false);
  const localUrl = useLocalUrl();

  function handlePersonTabChange(): void {
    setActiveTab(personTabKey);
  }

  function handleActivityTabChange(): void {
    setActiveTab(activityTabKey);
  }

  const addMappingButton: JSX.Element = <RaisersEdgeAddMapping integration={integration} />;
  const description = (
    <Trans t={t}>
      <p className={styles.description}>
        This is a list of all mappings in your account. If a field is not mapped below, it won't be
        synced. If you don't have an option to map a field to, you will need to add one.{' '}
        <Link target={'_blank'} to={localUrl(`/data/custom-fields/add`)} type={'link'}>
          Add custom fields
        </Link>
      </p>
    </Trans>
  );

  return (
    <>
      <RaisersEdgeSummaryCard integration={integration} />

      <Section
        actions={hasMappings && activeTab === personTabKey && addMappingButton}
        description={description}
        layout={'vertical'}
        title={t('Mappings')}
      >
        <Tabs className={styles.tabs}>
          <Tab
            active={activeTab === personTabKey}
            key={personTabKey}
            onClick={handlePersonTabChange}
            title={t('Persons/Constituents')}
          />
          <Tab
            active={activeTab === activityTabKey}
            key={activityTabKey}
            onClick={handleActivityTabChange}
            title={t('Activities/Actions')}
          />
        </Tabs>
        {activeTab === personTabKey && (
          <RaisersEdgeSyncPerson
            addMappingButton={addMappingButton}
            integration={integration}
            setHasMappings={setHasMappings}
          />
        )}
        {activeTab === activityTabKey && <RaisersEdgeSyncActivity integration={integration} />}
      </Section>
    </>
  );
}

export default observer(RaisersEdgeSyncWizard);
