import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '@feathr/extender/App/Page';

import AllowList from './AllowList';

function AllowListPage(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Page title={t('Domain Allow List')}>
      <AllowList />
    </Page>
  );
}

export default observer(AllowListPage);
