import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { SaveButtonValid } from '@feathr/components';
import { useActionBar } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AddressData from './AddressData';
import GeneralInfo from './GeneralInfo';
import Location from './Location';
import Metadata from './Metadata';
import TrackedLocationData from './TrackedLocationData';

import * as styles from './Profile.css';

interface IProps {
  person: Person;
}

function validatePersonInfo(person: Person): TValidateGrouped {
  return person.validate(['email'], false, 'grouped').errors;
}

function Profile({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const validationErrors = validatePersonInfo(person);
  const { setRightActions } = useActionBar();

  useEffect(() => {
    setRightActions(
      <SaveButtonValid
        disabled={!person.isDirty}
        errors={flattenErrors(validationErrors)}
        method={'patch'}
        model={person}
      >
        {t('Save')}
      </SaveButtonValid>,
    );
  }, [person.isDirty]);

  return (
    <div className={styles.root}>
      <div>
        <GeneralInfo person={person} />
        <AddressData person={person} />
      </div>
      <div>
        <Location person={person} />
        <TrackedLocationData person={person} />
        <Metadata person={person} />
      </div>
    </div>
  );
}

export default observer(Profile);
