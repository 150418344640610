import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';

import type { ICampaignAttributes, IPartner, Template } from '@feathr/blackbox';
import { CampaignClass, RecipientType, TemplateClass } from '@feathr/blackbox';
import type { ISelectOption } from '@feathr/components';
import {
  AsyncSelect,
  Button,
  Card,
  Fieldset,
  Form,
  Input,
  PageHeader,
  Select,
  toast,
} from '@feathr/components';
import PersonSelect from '@feathr/extender/components/PersonSelect';
import { CampaignIconOption, PartnerOption } from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './TemplateEditor.css';

export const RecipientTypeOptions: ISelectOption[] = [
  { id: RecipientType.Person, name: 'Person' },
  { id: RecipientType.Partner, name: 'Partner' },
];

interface IProps {
  template: Template;
  onClose: () => void;
  recipientType?: RecipientType;
}

function SendPreview({ template, onClose, recipientType }: IProps) {
  const { eventId } = useParams<{ eventId: string }>();

  const [recipientTypeSelection, setRecipientTypeSelection] = useState<RecipientType | undefined>(
    recipientType,
  );
  const [invitesCampaignId, setInvitesCampaignId] = useState<string | undefined>();
  const [partnerId, setPartnerId] = useState<string | undefined>();
  const [personId, setPersonId] = useState<string | undefined>();
  const [fromEmail, setFromEmail] = useState<string | undefined>();
  const [fromName, setFromName] = useState<string | undefined>();
  const [toEmail, setToEmail] = useState<string | undefined>();

  const { Campaigns, Partners } = useContext(StoresContext);

  const isPinpointEmail = template.get('_cls') === TemplateClass.PinpointEmail;

  async function loadCampaignOptions(inputValue: string) {
    const campaigns = Campaigns.list({
      filters: {
        name__icontains: inputValue,
        event: template.get('event') || eventId,
        _cls__in: [CampaignClass.Referral],
      },
      pagination: {
        page: 0,
        page_size: 20,
      },
    });
    await when(() => !campaigns.isPending);
    return campaigns.models.map((cpn) => cpn.toJS());
  }

  async function loadPartnerOptions(inputValue: string) {
    const partners = Partners.list({
      filters: {
        name__icontains: inputValue,
        _parent: template.get('event') || eventId,
        participation: invitesCampaignId ?? undefined,
      },
      pagination: {
        page: 0,
        page_size: 20,
      },
    });
    await when(() => !partners.isPending);
    return partners.models.map((partner) => partner.toJS());
  }

  async function sendPreview() {
    if (isPinpointEmail) {
      await template.send(
        {
          from_: fromEmail ?? '',
          from_name: fromName ?? '',
          email: toEmail ?? '',
        },
        undefined,
        invitesCampaignId,
        partnerId,
        personId,
      );
    } else {
      await template.send(
        {
          from_: fromEmail ?? '',
          from_name: fromName ?? '',
          email: toEmail ?? '',
        },
        invitesCampaignId,
        undefined,
        partnerId,
        personId,
      );
    }
  }

  async function handleSend() {
    await sendPreview();
    toast(`A preview is on its way to ${toEmail}.`);
    onClose();
  }

  function handleSelectRecipientType(selection: ISelectOption) {
    setRecipientTypeSelection(selection.id as RecipientType);
    handleSelectPerson();
    handleClearPartner();
  }

  function handleClearRecipientType() {
    setRecipientTypeSelection(undefined);
    handleSelectPerson();
    handleClearPartner();
  }

  function handleSelectCampaign(option: ICampaignAttributes) {
    setInvitesCampaignId(option.id);
  }

  function handleClearCampaign() {
    setInvitesCampaignId(undefined);
  }

  function handleSelectPartner(option: IPartner) {
    setPartnerId(option.id);
  }

  function handleClearPartner() {
    setPartnerId(undefined);
  }

  function handleSelectPerson(newValue?: string) {
    setPersonId(newValue);
  }

  function handleChangeFromAddress(newValue?: string) {
    setFromEmail(newValue);
  }

  function handleChangeFromName(newValue?: string) {
    setFromName(newValue);
  }

  function handleChangeSubject(newValue?: string) {
    template.set({
      subject: newValue,
    });
  }

  function handleChangeToAddress(newValue?: string) {
    setToEmail(newValue);
  }

  return (
    <Card className={styles.dialogForm}>
      <Form
        actions={[
          <Button key={'cancel'} onClick={onClose}>
            Cancel
          </Button>,
          <Button key={'confirm'} onClick={handleSend} type={'primary'}>
            Send
          </Button>,
        ]}
        label={'Send Preview'}
      >
        <PageHeader className={styles.dialogHeader}>
          <h3>Send Preview</h3>
        </PageHeader>
        <Fieldset>
          {isPinpointEmail && !recipientType && (
            <Select
              defaultOptions={true}
              helpText={'The type of recipient to use for merge data.'}
              isClearable={true}
              label={'Merge Data Source'}
              onClear={handleClearRecipientType}
              onSelectSingle={handleSelectRecipientType}
              options={RecipientTypeOptions}
              placeholder={'Merge Data Source'}
              value={
                recipientTypeSelection ? RecipientTypeOptions[recipientTypeSelection] : undefined
              }
              wrapperClassName={styles.select}
            />
          )}
          {(!isPinpointEmail || recipientTypeSelection === RecipientType.Partner) && (
            <>
              <AsyncSelect
                components={{ Option: PartnerOption }}
                defaultOptions={true}
                helpText={
                  'This recipient is simply used to provide data to merge into the email template. The email will be sent to the Recipient Email Address you provide below.'
                }
                isClearable={true}
                label={'Recipient'}
                loadOptions={loadPartnerOptions}
                onClear={handleClearPartner}
                onSelectSingle={handleSelectPartner}
                placeholder={'Recipient'}
                value={partnerId ? Partners.get(partnerId).toJS() : undefined}
                wrapperClassName={styles.select}
              />
              <AsyncSelect
                components={{ Option: CampaignIconOption }}
                defaultOptions={true}
                helpText={'The campaign to use for merge data.'}
                isClearable={true}
                label={'Campaign'}
                loadOptions={loadCampaignOptions}
                onClear={handleClearCampaign}
                onSelectSingle={handleSelectCampaign}
                placeholder={'Campaign'}
                value={invitesCampaignId ? Campaigns.get(invitesCampaignId).toJS() : undefined}
                wrapperClassName={styles.select}
              />
            </>
          )}
          {recipientTypeSelection === RecipientType.Person && (
            <PersonSelect
              helpText={
                'This recipient is simply used to provide data to merge into the email template. The email will be sent to the Recipient Email Address you provide below.'
              }
              isClearable={true}
              label={'Recipient'}
              onChange={handleSelectPerson}
              placeholder={'Recipient'}
              value={personId}
            />
          )}
          <Input
            helpText={'Email address used as the reply-to for this message.'}
            label={'From Email Address'}
            onChange={handleChangeFromAddress}
            placeholder={'your@email.com'}
            required={true}
            type={'text'}
            value={fromEmail}
          />
          <Input
            helpText={
              'The "Friendly" component of the email sender. Shows as the name in the recipient\'s email address.'
            }
            label={'From Name'}
            onChange={handleChangeFromName}
            placeholder={'Your Name - Your Company'}
            required={true}
            type={'text'}
            value={fromName}
          />
          <Input
            helpText={'The email address to send the preview message to.'}
            label={'Recipient Email Address'}
            onChange={handleChangeToAddress}
            placeholder={'exhibitor@hotmail.com'}
            required={true}
            type={'text'}
            value={toEmail}
          />
          <Input
            helpText={'The subject of the email'}
            label={'Subject'}
            onChange={handleChangeSubject}
            placeholder={'Check it out!'}
            required={true}
            type={'text'}
            value={template.get('subject')}
          />
        </Fieldset>
      </Form>
    </Card>
  );
}

export default observer(SendPreview);
