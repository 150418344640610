import { faCheck, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Flight } from '@feathr/blackbox';
import { Button, Toolbar } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import MakeSharedFlightModal from './MakeSharedFlightModal';

interface IProps {
  flight: Flight;
}

function FlightActions({ flight }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <Toolbar>
        <Button
          link={localUrl(flight.getItemUrl('edit'))}
          prefix={<FontAwesomeIcon icon={faPencil} />}
        >
          {t('Edit')}
        </Button>
        <Button
          onClick={toggleIsModalOpen}
          prefix={flight.get('shared') ? <FontAwesomeIcon icon={faCheck} /> : undefined}
          type={'primary'}
        >
          {flight.get('shared') ? t('Flight Path') : t('Make Flight Path')}
        </Button>
      </Toolbar>
      {isModalOpen && <MakeSharedFlightModal flight={flight} toggleModal={toggleIsModalOpen} />}
    </>
  );
}

export default observer(FlightActions);
