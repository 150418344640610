import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router';

import type { ReferralCampaign } from '@feathr/blackbox';
import { Button, Tab, Tabs, Toolbar } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import CampaignPage from '../../CampaignPage/CampaignPage';

import * as styles from './ReferralCampaignPage.css';

export interface IProps {
  children: React.ReactNode;
  description?: React.ReactNode;
  title?: React.ReactNode;
}

function ReferralCampaignPage({ children, description, title }: IProps) {
  const localUrl = useLocalUrl();
  const { campaignId } = useParams<{ eventId?: string; campaignId: string }>();
  const { Campaigns } = React.useContext(StoresContext);
  const campaign = Campaigns.get(campaignId) as ReferralCampaign;

  const actions = (
    <Toolbar>
      <Button link={localUrl(campaign.getItemUrl('edit'))}>Edit</Button>
    </Toolbar>
  );

  return (
    <CampaignPage actions={actions} campaign={campaign} title={campaign.name}>
      <Tabs>
        <Tab exact={true} link={localUrl(campaign.getItemUrl())} title={'Report'} />
        <Tab link={localUrl(campaign.getItemUrl('templates'))} title={'Templates'} />
        <Tab link={localUrl(campaign.getItemUrl('partners'))} title={'Partners'} />
      </Tabs>
      <h3>{title}</h3>
      {!!description && <div className={styles.description}>{description}</div>}
      {children}
    </CampaignPage>
  );
}

export default observer(ReferralCampaignPage);
