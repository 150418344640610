import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { components } from 'react-select';

import type {
  Campaign,
  GeoFilter as GeoFilterModel,
  IGeoFilter,
  Targetable,
  Targeting,
} from '@feathr/blackbox';
import { TargetableClass } from '@feathr/blackbox';
import { AsyncSelect, Button, Card, Radios, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './GeoFilter.css';

interface IProps {
  campaign: Campaign;
  targeting: Targeting;
  targetings: IObservableArray<Targeting>;
  onRemove: (targeting: Targeting) => void;
}

function Option(props: any) {
  const { data } = props;
  return (
    <components.Option {...props}>
      <div className={styles.option}>
        <span>{data.name}</span>
        <dl>
          <dt>Kind</dt>
          <dd>{data.kind}</dd>
          {['Region', 'City'].includes(data.kind) && (
            <>
              <dt>Country</dt>
              <dd>{data.address.country}</dd>
            </>
          )}
          {['City'].includes(data.kind) && (
            <>
              <dt>Region</dt>
              <dd>{data.address.region}</dd>
            </>
          )}
        </dl>
      </div>
    </components.Option>
  );
}

function SingleValue(props: any) {
  const data = props.data as IGeoFilter;
  return (
    <components.SingleValue {...props}>
      <div className={styles.singleValue}>
        <span className={styles.singleValueLabel}>{data.name}</span>
        <dl className={styles.singleValueData}>
          <dt>Kind</dt>
          <dd>{data.kind}</dd>
          {['Region', 'City'].includes(data.kind) && (
            <>
              <dt>Country</dt>
              <dd>{data.address.country}</dd>
            </>
          )}
          {['City'].includes(data.kind) && (
            <>
              <dt>Region</dt>
              <dd>{data.address.region}</dd>
            </>
          )}
        </dl>
      </div>
    </components.SingleValue>
  );
}

function GeoFilter({ campaign, targeting, targetings, onRemove }: IProps) {
  const { GeoFilters, Targetables } = React.useContext(StoresContext);
  let targetable: Targetable | undefined;
  const targetableId = targeting.get('target_data');
  if (targetableId) {
    targetable = Targetables.get(targetableId);
  }
  const [, setFilterQuery] = React.useState('');
  let geofilter: GeoFilterModel | undefined;
  if (targetable && targetable.get('geo_filter')) {
    geofilter = GeoFilters.get(targetable.get('geo_filter')!);
  }

  function handleIncludedChange(newValue?: string) {
    targeting.set({ included: newValue === 'included' });
  }

  return (
    <>
      <Card
        actions={[
          <Tooltip key={'remove'} title={'Remove'}>
            <Button key={'remove_button'} onClick={() => onRemove(targeting)} type={'naked'}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </Tooltip>,
        ]}
      >
        <AsyncSelect<IGeoFilter>
          cacheOptions={true}
          components={{ Option, SingleValue }}
          defaultOptions={true}
          isLoading={geofilter ? geofilter.isPending : false}
          loadOptions={async (inputValue: string) => {
            const geofilters = GeoFilters.list({
              filters: {
                is_archived__ne: true,
                name__icontains: inputValue,
              },
              pagination: {
                page: 0,
                page_size: 50,
              },
            });
            await when(() => !geofilters.isPending);
            return [
              ...geofilters.models
                .filter(
                  (gf) =>
                    !targetings.find((t) => {
                      const data = t.get('target_data');
                      if (data) {
                        return data === gf.id;
                      }
                      return false;
                    }),
                )
                .map((gf) => gf.toJS()),
            ];
          }}
          onInputChange={(value: string) => setFilterQuery(value)}
          onSelectSingle={(option: IGeoFilter) => {
            const newGeoTargetable = Targetables.create({
              _cls: TargetableClass.geo,
              name: option.name,
              geo_filter: option.id,
              partner:
                campaign.get('parent_kind') === 'partner' ? campaign.get('parent') : undefined,
            });
            targeting.set({
              name: option.name,
              target_data: newGeoTargetable.get('id'),
            });
          }}
          placeholder={'Choose Filter'}
          value={geofilter ? (!geofilter.isPending ? geofilter.toJS() : undefined) : undefined}
        />
        <Radios
          className={styles.radios}
          onChange={handleIncludedChange}
          options={[
            { id: 'included', name: 'Included' },
            { id: 'excluded', name: 'Excluded' },
          ]}
          value={targeting.get('included') ? 'included' : 'excluded'}
        />
      </Card>
    </>
  );
}

export default observer(GeoFilter);
