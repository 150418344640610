import { ObjectId } from 'bson';
import { computed, makeObservable, runInAction, when } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, DisplayModel, wretch } from '@feathr/rachis';

import type { IAddress } from './address';
import { addressConstraints } from './address';
import { EPinpointRequestStatus } from './domains';
import { ECollectionClassName } from './model';

export type TEmailVerificationStatus = 'Unverified' | 'Pending' | 'Success';

export interface IEmailVerification extends IBaseAttributes {
  readonly account: string;
  address?: IAddress;
  readonly email: string;
  /** The human-friendly name that appears in email inboxes. "From: Joe Schmoe" */
  from_name?: string;
  /**
   * Starts as Unverified. Gets set to Pending when the User clicks "Verify Email".
   * Users will be sent a verification link to that email, which upon clicking will
   * update the status to Success.
   * If the email address belongs to a verified Email Domain (see domains.ts),
   * it will be automatically verified and the status will be set to Success.
   */
  readonly status: TEmailVerificationStatus;
}

// Use presenceUnless instead of presence.
const emailVerificationAddressConstraints = Object.keys(addressConstraints).reduce(
  (previousValue, currentValue) => {
    const presence = addressConstraints[currentValue as keyof IAddress]?.presence;
    if (presence) {
      previousValue[`address.${currentValue}`] = {
        presenceUnless: { ...presence, unless: (attributes) => attributes.status !== 'Success' },
      };
    }
    return previousValue;
  },
  {} as TConstraints<IEmailVerification>,
);

export class EmailVerification extends DisplayModel<IEmailVerification> {
  public readonly className = 'EmailVerification';

  public constraints: TConstraints<IEmailVerification> = {
    email: {
      presence: {
        allowEmpty: false,
        message: "^From email address can't be blank.",
      },
      email: true,
      async: {
        fn: async (value: string | undefined, model: EmailVerification) => {
          if (!value) {
            return;
          }
          if (model.get('status') === EPinpointRequestStatus.Success) {
            return;
          }
          if (!model.collection) {
            return [
              '^Model does not have a collection and therefore validity cannot be determined.',
            ];
          }
          // Check if the email address already exists
          const results = model.collection.list({
            filters: {
              __raw__: {
                $or: [
                  { email: { $regex: `^${value}$`, $options: 'i' } },
                  { id: new ObjectId(model.id).toHexString() },
                ],
              },
            },
            pagination: { page_size: 1 },
          });
          await when(() => !results.isPending);
          if (results.pagination.count > 0) {
            return ['^From email address already exists.'];
          }
          return undefined;
        },
      },
    },
    from_name: {
      presenceUnless: {
        allowEmpty: false,
        message: "^From name can't be blank.",
        unless: (attributes) => attributes.status !== 'Success',
      },
      format: {
        pattern: "[a-z0-9!#$%&*()\\-+=._?/ ']+",
        flags: 'i',
        message:
          "^From name can only contain alphanumeric characters, and the following symbols: ! # $ % & * ( ) - + = . _ ? / '.",
      },
    },
    status: {
      inclusion: {
        within: ['Unverified', 'Pending', 'Success'],
        message: '^Status can be one of Unverified, Pending, Success.',
      },
    },
    ...emailVerificationAddressConstraints,
  };

  constructor(attributes: Partial<EmailVerification> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<IEmailVerification> {
    return {
      status: 'Unverified',
    };
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/settings/account/emails/${this.id}`, pathSuffix);
  }

  @computed
  public get name(): string {
    return this.get('email', '').trim() || 'unnamed@somehwere.com';
  }

  /**
   * Resends the verification email in the event the user did not receive one.
   */
  public async resend(): Promise<IEmailVerification> {
    this.assertCollection(this.collection, ECollectionClassName.EmailVerification);
    this.assertId(this.id, ECollectionClassName.EmailVerification);

    this.isUpdating = true;
    const headers = this.collection.getHeaders();
    const response = await wretch<IEmailVerification>(`${this.collection.url()}${this.id}/resend`, {
      headers,
      method: 'POST',
    });
    if (isWretchError(response)) {
      runInAction(() => {
        this.isUpdating = false;
        this.isErrored = true;
        this.error = response.error;
      });
      throw response.error;
    } else {
      runInAction(() => {
        this.isUpdating = false;
        this.isErrored = false;
        this.error = null;
      });
    }
    return response.data;
  }
}

export class EmailVerifications extends Collection<EmailVerification> {
  public getModel(attributes: Partial<EmailVerification>): EmailVerification {
    return new EmailVerification(attributes);
  }

  public getClassName(): string {
    return 'email_verifications';
  }

  public url(): string {
    // Email Verifications are done through Pinpoint
    return `${this.getHostname()}pinpoint/email_verifications/`;
  }
}
