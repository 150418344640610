import type { MutableRefObject } from 'react';
import { useEffect, useRef } from 'react';

const canvasId = 'textWidthCanvas';

function addCanvas() {
  const canvas = document.getElementById(canvasId) as HTMLCanvasElement | null;
  if (canvas) {
    return canvas;
  }

  const newCanvas = document.createElement('canvas');
  newCanvas.setAttribute('id', canvasId);
  newCanvas.style.position = 'absolute';
  newCanvas.style.top = '-10000px';
  document.body.appendChild(newCanvas);
  return newCanvas;
}

function removeCanvas() {
  const canvas = document.getElementById(canvasId);
  if (canvas) {
    canvas.remove();
  }
}

function useTextwidth(): (text: string, fontName: string, fontSize: number) => number {
  const canvasRef = useRef<HTMLCanvasElement>(null) as MutableRefObject<HTMLCanvasElement>;
  if (!canvasRef.current) {
    canvasRef.current = addCanvas();
  }

  useEffect(() => {
    return () => {
      removeCanvas();
    };
  }, []);

  function measureText(text: string, fontName: string, fontSize: number): number {
    if (!canvasRef.current) {
      return 0;
    }

    const context = canvasRef.current.getContext('2d');
    if (!context) {
      return 0;
    }
    context.font = `${fontSize}px ${fontName}`;
    return context.measureText(text).width;
  }

  return measureText;
}

export default useTextwidth;
