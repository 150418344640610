import { faBullhorn, faUsdCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign } from '@feathr/blackbox';
import { CampaignLabelMap } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader } from '@feathr/components';
import { campaignColorMap, campaignIconMap } from '@feathr/extender/styles/campaign';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const TypeColumn: IColumn<BaseCampaign> = {
  id: '_cls',
  checkboxLabel: 'Type',
  Header: TableColumnHeader({
    title: 'Type',
  }),
  sortable: false,
  width: 150,
  className: tableStyles.cellLeft,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const type = original.get('_cls');

          return (
            <>
              <Chip
                prefix={<FontAwesomeIcon icon={campaignIconMap.get(type) ?? faBullhorn} />}
                theme={campaignColorMap.get(type)}
              >
                {CampaignLabelMap.get(type) ?? 'Campaign'}
              </Chip>
              {original.isMonetization && (
                // Extra div needed to push monetization chip to a new line, which makes it more clear and less visually awkward
                <div>
                  <Chip
                    className={tableStyles.spacingTop}
                    prefix={<FontAwesomeIcon icon={faUsdCircle} />}
                  >
                    Monetization
                  </Chip>
                </div>
              )}
            </>
          );
        }}
      </Observer>
    );
  },
};

export default TypeColumn;
