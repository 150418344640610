import { observer } from 'mobx-react-lite';
import React from 'react';

import type { LandingPageCampaign } from '@feathr/blackbox';

import CampaignReportSection from '../CampaignReportSection';
import LandingPageCampaignPage from './LandingPageCampaignPage';

interface IProps {
  campaign: LandingPageCampaign;
}

function DefaultLandingPageCampaignPage({ campaign }: IProps) {
  return (
    <LandingPageCampaignPage title={'Report'}>
      <CampaignReportSection campaign={campaign} />
    </LandingPageCampaignPage>
  );
}

export default observer(DefaultLandingPageCampaignPage);
