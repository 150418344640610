import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CampaignClass } from '@feathr/blackbox';
import { Section } from '@feathr/components';

import CampaignClassCard from '../CampaignClassCard';
import type { IType, TCampaignSection } from '../CampaignTypeSelect';
import { campaignSectionDescription, campaignSectionTitle } from '../CampaignTypeSelect';

import * as styles from './CampaignTypeSelectSection.css';

export interface IProps {
  disabled?: boolean;
  isClicked: boolean;
  onSelect: (type: CampaignClass) => void;
  section?: TCampaignSection;
  toggleClicked: () => void;
  types: IType[];
}

function CampaignTypeSelectSection({
  disabled,
  isClicked,
  onSelect,
  section,
  toggleClicked,
  types,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Section
      className={styles.section}
      contentClassName={styles.sectionContent}
      description={section ? campaignSectionDescription(section, t) : undefined}
      headerClassName={styles.sectionHeader}
      title={section ? campaignSectionTitle(section, t) : undefined}
    >
      {types.map((type) => (
        <CampaignClassCard
          cls={type.id}
          disabled={disabled}
          isClicked={isClicked}
          key={type.id}
          onClick={onSelect}
          toggleClicked={toggleClicked}
        />
      ))}
    </Section>
  );
}

export default CampaignTypeSelectSection;
