import { faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { Billable, ICreditCardSource, ILegacyAddress, IStripe } from '@feathr/blackbox';
import { isBankAccountSource, isCreditCardSource } from '@feathr/blackbox';
import { Address, Button, Chip, Collapse, Icon, Well } from '@feathr/components';
import { BankAccount, CreditCard } from '@feathr/extender/components/BillingSource';
import { useAccount, useLocalUrl } from '@feathr/extender/state';
import type { TCountryCodes } from '@feathr/hooks';
import { useToggle } from '@feathr/hooks';

import BankAccountVerify from './BankAccountVerify';
import BillableDeleteModal from './BillableDeleteModal';
import BillableProjectsAndInvoices from './BillableProjectsAndInvoices';

import * as styles from './BillableCard.css';

interface IProps {
  allowDelete?: boolean;
  allowEdit?: boolean;
  billable?: Billable;
  className?: string;
  isLoading?: boolean;
  redirect?: string;
}

/*
 * By using the type guard we can determine if the address is empty, we
 * simulate that it is undefined, which nets us the same result.
 */
function isLegacyAddressEmpty(address?: ILegacyAddress): address is undefined {
  if (!address) {
    return true;
  }
  return !Object.values(address).some((key) => key);
}

function BillableCard({
  billable,
  className,
  isLoading,
  redirect,
  allowEdit = true,
  allowDelete = false,
}: Readonly<IProps>): JSX.Element | null {
  const account = useAccount();
  const localUrl = useLocalUrl();
  const [isDeleteModalOpen, toggleIsDeleteModalOpen] = useToggle(false);
  const { t } = useTranslation();
  if (!billable) {
    return null;
  }

  const isReallyloading = isLoading || billable.isPending;
  const address = billable.get('address');
  const source = billable.get('stripe', {} as IStripe).source;
  const id = billable.id;

  const actions: JSX.Element[] = [];
  if (allowEdit) {
    const editUrl = localUrl(`/settings/billing/configurations/${id}/edit`);
    const editLink = redirect
      ? {
          pathname: editUrl,
          search: `?redirect=${encodeURIComponent(redirect)}`,
        }
      : editUrl;
    actions.push(
      <Button
        disabled={isReallyloading}
        key={'update'}
        link={editLink}
        prefix={<Icon icon={faPenToSquare} />}
      >
        {t('Update')}
      </Button>,
    );
  }
  if (allowDelete) {
    actions.push(
      <>
        {isReallyloading ? (
          <Button disabled={true} key={'delete'} prefix={<Icon icon={faTrash} />}>
            {t('Delete')}
          </Button>
        ) : (
          <>
            <Button onClick={toggleIsDeleteModalOpen} prefix={<Icon icon={faTrash} />}>
              {t('Delete')}
            </Button>
            {isDeleteModalOpen && (
              <BillableDeleteModal billable={billable} onClose={toggleIsDeleteModalOpen} />
            )}
          </>
        )}
      </>,
    );
  }

  const showBankAccountVerify =
    source && isBankAccountSource(source) && ['verified', 'errored'].includes(source.status);

  return (
    <Well
      className={className}
      data-id={id}
      data-model={'Billable'}
      layout={'vertical'}
      width={'wide'}
    >
      {/* Header */}
      <div className={styles.header}>
        <div className={styles.title}>
          <h4>{billable.get('description') ?? t('Untitled Billing Configuration')}</h4>
          <Link to={localUrl('/settings/billing/license')}>
            {account.get('license').billable === id && (
              <Chip theme={'blue'} tooltip={t("This is the Account's billing configuration.")}>
                {t('Account')}
              </Chip>
            )}
          </Link>
        </div>
        <div className={styles.actions}>{actions}</div>
      </div>
      <Well label={t('Billing info')} layout={'vertical'} theme={'white'}>
        {billable.get('name')}
        <br />
        {billable.get('email')}
      </Well>
      <div className={styles.sideBySide}>
        <Well label={t('Billing address')} layout={'vertical'} theme={'white'}>
          {/* If address is not set, or all fields are empty, show a placeholder */}

          {!isLegacyAddressEmpty(address) ? (
            <Address
              administrative_area_name={address.state}
              country_code={address.country as TCountryCodes}
              locality={address.city}
              postal_code={address.postal_code}
              premise1={address.line1}
              premise2={address.line2}
            />
          ) : (
            <p>{t('Address has not been set.')}</p>
          )}
        </Well>
        <Well label={t('Payment method')} layout={'vertical'} theme={'white'}>
          {!!source &&
            !!source.id &&
            (isBankAccountSource(source) ? (
              <BankAccount billable={billable} key={source.id} />
            ) : isCreditCardSource(source) ? (
              <CreditCard key={source.id} source={source as ICreditCardSource} />
            ) : null)}
          {!isReallyloading && (!source || !source.id) && (
            <p>{t('Payment method has not been set.')}</p>
          )}
        </Well>
      </div>
      {showBankAccountVerify && <BankAccountVerify billable={billable} />}
      <Collapse title={t('Projects and Invoices')}>
        <BillableProjectsAndInvoices billable={billable} />
      </Collapse>
    </Well>
  );
}

export default observer(BillableCard);
