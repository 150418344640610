import { action, computed, makeObservable, when } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IBaseAttributes, ListResponse, TConstraints, TRachisEmpty } from '@feathr/rachis';
import { Collection, DisplayModel, isWretchError, wretch } from '@feathr/rachis';

export type TPermissionMode = 'all' | 'some' | 'none';

export type TPermissionAllowList = string[];

export interface IPermissionSettings {
  /** ObjectIds of whatever documents the permission controls access to: segments, projects, etc */
  allow_list?: TPermissionAllowList;
  mode: TPermissionMode;
}

export interface IUserRole extends IBaseAttributes {
  /** The account attached to a custom role. Only applicable to custom roles and not defined for global roles. */
  account?: string;
  /** The user-facing name for this role. */
  name: string;
  hidden?: boolean;
  billing: IPermissionSettings;
  conversions: IPermissionSettings;
  imports: IPermissionSettings;
  domains: IPermissionSettings;
  segments: IPermissionSettings;
  integrations: IPermissionSettings;
  projects: IPermissionSettings;
  roles: IPermissionSettings;
  /**
   * If the user has sudo access. Avoid checking based on role name
   * because users could create custom roles with the same name.
   */
  sudo: IPermissionSettings;
  users: IPermissionSettings;
}

/** Account features that users can manage via user roles. */
export type TUserRolePermission = keyof Pick<
  IUserRole,
  | 'billing'
  | 'conversions'
  | 'imports'
  | 'domains'
  | 'segments'
  | 'integrations'
  | 'projects'
  | 'roles'
  | 'users'
>;

// We currently allow the following permissions to be edited:
export type TEditableUserRolePermission = Extract<
  TUserRolePermission,
  'projects' | 'segments' | 'billing' | 'imports' | 'domains' | 'integrations'
>;

// Account features that users can manage via user roles that should be contain an allow_list if mode is 'some'.
export type TComplexUserRolePermission = Extract<TUserRolePermission, 'projects' | 'segments'>;

/** Account features that are toggled on or off. */
export type TBinaryUserRolePermission = Extract<
  TUserRolePermission,
  'imports' | 'billing' | 'domains' | 'integrations' | 'conversions'
>;

/** Account features that are toggled on or off and can be modified in a custom role. */
export type TEditableBinaryUserRolePermission = Exclude<TBinaryUserRolePermission, 'conversions'>;

/**
 * SuperUser, CSM and Strategist roles share the same permissions as Admin with the addition
 * of sudo. These separate roles are used primarily for filtering users.
 */
export enum EUserRoleIDs {
  /**
   * Has same permissions as Admin with the addition of sudo.
   * For users with dev and support titles.
   */
  SuperUser = '000000000000000000000000',
  /**
   * Has same permissions as Admin with the addition of sudo.
   * For users with the CSM titles
   */
  CSM = '000000000000000000000001',
  /**
   * Has same permissions as Admin with the addition of sudo.
   * For users with the Strategist titles.
   */
  Strategist = '000000000000000000000002',
  /** Has full permissions for everything but sudo. */
  Admin = '000000000000000000000100',
  /**
   * Has no permissions for sudo, conversions, roles and user permissions.
   * Previously known as 'Normie'.
   */
  User = '000000000000000000000101',
}

export type TDefaultRoleId = Extract<EUserRoleIDs, EUserRoleIDs.Admin | EUserRoleIDs.User>;
export type TGlobalRoleId = Extract<
  EUserRoleIDs,
  EUserRoleIDs.SuperUser | EUserRoleIDs.CSM | EUserRoleIDs.Strategist
>;

const defaultRoles: TDefaultRoleId[] = [EUserRoleIDs.Admin, EUserRoleIDs.User];
const globalRoles: TGlobalRoleId[] = [
  EUserRoleIDs.SuperUser,
  EUserRoleIDs.CSM,
  EUserRoleIDs.Strategist,
];

export class UserRole extends DisplayModel<IUserRole> {
  public readonly className = 'UserRole';

  public constraints: TConstraints<IUserRole> = {
    name: {
      presence: {
        allowEmpty: false,
        message: '^Role name must not be blank.',
      },
      caseInsensitiveExclusion: {
        within: ['Admin', 'User'],
        message: '^Role name is reserved.',
      },
      async: {
        fn: async (value: string | undefined, model: UserRole): Promise<string[] | undefined> => {
          if (!value) {
            return;
          }
          if (!model.collection) {
            return [
              '^Model does not have a collection and therefore validity cannot be determined.',
            ];
          }
          const results: ListResponse<UserRole> = model.collection.list({
            filters: { name__iexact: value, id__ne: model.id },
            pagination: { page_size: 1 },
          });
          await when(() => !results.isPending);
          if (results.pagination.count > 0) {
            return ['^Role name already exists.'];
          }
          return undefined;
        },
      },
    },
    // TODO: figure out why when adding an async validator in name causes non-async constraints for nested objects to not work.
    'projects.allow_list': {
      async: {
        fn: async (
          allowList: TPermissionAllowList,
          model: UserRole,
        ): Promise<string[] | undefined> => {
          if (model.projects.mode === 'some' && allowList.length === 0) {
            return ['^You must select at least one project.'];
          }
          return undefined;
        },
      },
    },
    'segments.allow_list': {
      async: {
        fn: async (
          allowList: TPermissionAllowList,
          model: UserRole,
        ): Promise<string[] | undefined> => {
          if (model.segments.mode === 'some' && allowList.length === 0) {
            return ['^You must select at least one group.'];
          }
          return undefined;
        },
      },
    },
  };

  constructor(attributes: Partial<IUserRole> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getItemUrl(pathSuffix?: string): string {
    return concatPath(`/settings/account/users/roles/${this.id}`, pathSuffix);
  }

  /**
   * Returns the user-facing name of the role.
   */
  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Role';
  }

  @computed
  public get projects(): IPermissionSettings {
    return this.get('projects', { mode: 'none' });
  }

  @computed
  public get segments(): IPermissionSettings {
    return this.get('segments', { mode: 'none' });
  }

  @computed
  public get billing(): IPermissionSettings {
    return this.get('billing', { mode: 'none' });
  }

  @computed
  public get imports(): IPermissionSettings {
    return this.get('imports', { mode: 'none' });
  }

  @computed
  public get domains(): IPermissionSettings {
    return this.get('domains', { mode: 'none' });
  }

  @computed
  public get integrations(): IPermissionSettings {
    return this.get('integrations', { mode: 'none' });
  }

  /**
   * Returns whether or not the role is a global role (superuser, csm or strategist).
   *
   * If a global role is added to a user, the role will apply to the user across all accounts.
   * This is used to give Feathr staff access to all accounts. Global roles cannot be edited via the UI.
   */
  @computed
  public get isGlobal(): boolean {
    return globalRoles.includes(this.id as TGlobalRoleId);
  }

  /**
   * Returns whether or not the role is a default role (admin or user).
   *
   * Default roles are roles that are created by Feathr and cannot be edited or deleted.
   * They are used to provide a baseline set of permissions for all users.
   */
  @computed
  public get isDefault(): boolean {
    return defaultRoles.includes(this.id as TDefaultRoleId);
  }

  /**
   * Returns whether or not the role is a custom role.
   *
   * Custom roles are roles that are created by users and can be edited or deleted.
   * They are used to provide a flexible set of permissions for users.
   * A role is considered custom if it's neither a default role nor a global role.
   */
  @computed
  public get isCustom(): boolean {
    return !this.isDefault && !this.isGlobal;
  }

  /**
   * Returns whether or not the role is hidden.
   *
   * Roles are hidden to prevent being exposed to via the role endpoints.
   * << True >> for global roles.
   * << False >> for custom roles
   */
  @computed
  public get isHidden(): boolean {
    return this.get('hidden') ?? false;
  }

  /**
   * Method to delete a role.
   *
   * When deleting a role, a replacement role must be provided to assign to users who have the role being deleted.
   */
  @action
  public async deleteRole(replacementId: string): Promise<void> {
    this.assertCollection(this.collection, 'roles');

    const url = `${this.collection.url()}${this.id}`;
    const response = await wretch<TRachisEmpty>(url, {
      method: 'DELETE',
      headers: this.collection.getHeaders(),
      body: JSON.stringify({ replacement_id: replacementId }),
    });

    if (!isWretchError(response)) {
      this.collection.refreshApiCache();
    } else {
      throw response.error;
    }
  }
}

export class UserRoles extends Collection<UserRole> {
  public getModel(attributes: Partial<IUserRole>): UserRole {
    return new UserRole(attributes);
  }

  public getClassName(): string {
    return 'roles';
  }

  public url(): string {
    return super.url();
  }
}
