import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, Campaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getCTR(campaign: Campaign): number {
  const clicks = campaign.totalClicks;
  const views = campaign.totalViews;

  return views > 0 ? clicks / views : 0;
}

const CTRColumn: IColumn<BaseCampaign> = {
  id: 'ctr',
  checkboxLabel: 'CTR',
  Header: TableColumnHeader({
    title: 'CTR',
  }),
  sortable: false,
  width: 80,
  className: tableStyles.cellRight,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {(): JSX.Element => {
          const ctr = getCTR(original);
          return ctr ? <>{numeral(ctr).format('0.00%')}</> : <>-</>;
        }}
      </Observer>
    );
  },
};

export default CTRColumn;
