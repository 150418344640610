import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, TAttributionModel } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import type { ICampaignRow } from './CampaignsColumns';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function ConversionCell(model: TAttributionModel, property: 'num' | 'num_unique') {
  return ({ original }: ICampaignRow): JSX.Element => {
    let conversions = 0;
    const conversionStats = original.get('total_stats').conversions;
    if (conversionStats) {
      conversions = conversionStats[model]?.[property] || 0;
    }
    return <div>{conversions.toLocaleString()}</div>;
  };
}

export function getConversionsColumn(attributionModel: TAttributionModel): IColumn<BaseCampaign> {
  return {
    id: `stats__conversions__${attributionModel}__num`,
    checkboxLabel: 'Conversions',
    Header: TableColumnHeader({
      title: 'Conversions',
      sortType: 'numeric',
    }),
    headerClassName: tableStyles.sort,
    width: 140,
    className: tableStyles.cellRight,
    Cell: ConversionCell(attributionModel, 'num'),
    Footer({ data }): JSX.Element {
      const total = data.reduce((subtotal, currentValue) => {
        const stats = currentValue._original.get('total_stats');
        const conversions = stats.conversions || {};

        return subtotal + (conversions[attributionModel]?.num || 0);
      }, 0);
      return <>{total.toLocaleString()}</>;
    },
  };
}
