import { faCaretDown, faPaperPlane } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { Dispatch, JSX, SetStateAction } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { ToastType } from 'react-toastify';

import { CampaignClass } from '@feathr/blackbox';
import {
  Alert,
  AlertType,
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Modal,
  toast,
} from '@feathr/components';
import TagsField from '@feathr/extender/components/TagsField';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { logUserEvents, useToggle } from '@feathr/hooks';
import { isWretchError } from '@feathr/rachis';

import styles from './PartnersActions.css';

interface IProps {
  eventId: string;
  selected: string[];
  setSelected: Dispatch<SetStateAction<string[]>>;
}

function PartnersActions({ eventId, selected, setSelected }: IProps): JSX.Element {
  const account = useAccount();
  const { Campaigns, Partners } = useContext(StoresContext);
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [addTags, setAddTags] = useState<string[]>();
  const [isTagModalOpen, toggleTagModalOpen] = useToggle(false);
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);
  const { t } = useTranslation();

  const selectedMenu = (
    <Menu>
      <MenuItem onClick={toggleTagModalOpen}>{t('Tag selected')}</MenuItem>
      <MenuItem onClick={toggleArchiveModalOpen}>{t('Archive selected')}</MenuItem>
      <MenuItem onClick={toggleDeleteModalOpen}>{t('Delete selected')}</MenuItem>
    </Menu>
  );

  async function createMessage(partnerIds: string[] = []): Promise<void> {
    const message = Campaigns.create({
      _cls: CampaignClass.PinpointPartnerMessage,
      event: eventId,
      participation: {
        partner_ids: partnerIds,
        mode: partnerIds.length > 0 ? 'manual' : 'event',
      },
    });
    const response = await Campaigns.add(message, { validate: false });
    logUserEvents({
      'Created partner message': {
        account_id: account.id,
        campaign_id: response.id,
      },
    });
    history.push(localUrl(response.getItemUrl()));
  }

  async function handleOnClick(): Promise<void> {
    await createMessage(selected);
  }

  function handleOnClose(): void {
    setAddTags([]);
    toggleTagModalOpen();
  }

  async function handleOnConfirmAddTags(): Promise<void> {
    try {
      await Partners.bulk(selected, { tag_ids: addTags });
      toast(t('The tags were added to your selection.'), { type: 'success' });
      toggleTagModalOpen();
    } catch (e) {
      toast(t('There was a problem adding the tags to your selection.'), { type: 'error' });
    }
  }

  async function handleOnConfirmArchive(): Promise<void> {
    try {
      await Partners.bulk(selected, { is_archived: true });
      toast(t('Selected partners archived.'), { type: 'success' });
      Partners.refreshApiCache();
      setSelected([]);
      toggleArchiveModalOpen();
    } catch (e) {
      toast(t('There was a problem archiving selected partners.'), { type: 'error' });
    }
  }

  async function handleOnConfirmDelete(): Promise<void> {
    const result = await Partners.bulkDelete(selected);
    if (isWretchError(result)) {
      toast(t('There was a problem deleting selected partners.'), { type: ToastType.ERROR });
    } else {
      toast(t('Selected partners deleted.'), { type: ToastType.SUCCESS });
      toggleDeleteModalOpen();
      setSelected([]);
    }
  }

  function handleOnTagsChange(values: string[]): void {
    setAddTags(values);
  }

  return (
    <>
      {selected.length ? (
        <>
          <Button
            className={styles.btnGroupLeft}
            onClick={handleOnClick}
            prefix={<FontAwesomeIcon icon={faPaperPlane} />}
            type={'primary'}
          >
            {t('Message selected')}
          </Button>
          <Dropdown content={selectedMenu}>
            <Button className={styles.btnGroupRight} type={'primary'}>
              <FontAwesomeIcon icon={faCaretDown} />
            </Button>
          </Dropdown>
        </>
      ) : (
        <Button
          onClick={handleOnClick}
          prefix={<FontAwesomeIcon icon={faPaperPlane} />}
          type={'primary'}
        >
          {t('Message partners')}
        </Button>
      )}
      {isTagModalOpen && (
        <Modal
          confirmButtonText={t('Add tags')}
          controlled={true}
          onClose={handleOnClose}
          onConfirm={handleOnConfirmAddTags}
          t={t}
          title={t('Add Tags to Selected Partners')}
        >
          <TagsField context={'partner'} onChange={handleOnTagsChange} value={addTags} />
        </Modal>
      )}
      {isArchiveModalOpen && (
        <Modal
          confirmButtonText={t('Archive partners')}
          controlled={true}
          onClose={toggleArchiveModalOpen}
          onConfirm={handleOnConfirmArchive}
          size={'sm'}
          t={t}
          title={t('Archive Selected Partners')}
        >
          <Alert type={AlertType.warning}>
            {t('Archive the following partner?', { count: selected.length })}
          </Alert>
          <ol>
            {selected.slice(0, 20).map((partnerId) => {
              const partnerToArchive = Partners.get(partnerId);
              return (
                <li key={partnerId}>
                  {partnerToArchive.name}&lt;{partnerToArchive.get('email')}&gt;
                </li>
              );
            })}
            {selected.length > 20 && (
              <span>{t('...and {{count}} other partner.', { count: selected.length - 20 })}</span>
            )}
          </ol>
        </Modal>
      )}
      {isDeleteModalOpen && (
        <Modal
          confirmButtonText={t('Delete partners')}
          controlled={true}
          onClose={toggleDeleteModalOpen}
          onConfirm={handleOnConfirmDelete}
          size={'sm'}
          t={t}
          title={t('Delete Selected Partners')}
        >
          <Alert type={AlertType.danger}>
            {t('Delete the following partner?', { count: selected.length })}
          </Alert>
          <ol>
            {selected.slice(0, 20).map((partnerId) => {
              const partnerToDelete = Partners.get(partnerId);
              return (
                <li key={partnerId}>
                  {partnerToDelete.name}&lt;{partnerToDelete.get('email')}&gt;
                </li>
              );
            })}
          </ol>
          {selected.length > 20 && (
            <span>{t('...and {{count}} other partner.', { count: selected.length - 20 })}</span>
          )}
        </Modal>
      )}
    </>
  );
}

export default observer(PartnersActions);
