import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { BlackbaudRaisersEdgeIntegration, IBlackbaudContactMapping } from '@feathr/blackbox';
import {
  CardContent,
  CardV2 as Card,
  EFontAwesomeKitIcon,
  FontAwesomeKitIcon,
  Input,
} from '@feathr/components';
import { BlackbaudRaisersEdgeContactFieldsTranslationMap } from '@feathr/extender/styles/blackbaud_raisers_edge';

import DeleteMappingButton from '../../DeleteMappingButton';
import RaisersEdgeDateLastSynced from '../../RaisersEdgeDateLastSynced';
import SyncPersonSelect from '../SyncPersonSelect';

import * as styles from './SyncPersonCard.css';

interface ISyncPersonCardProps {
  integration: BlackbaudRaisersEdgeIntegration;
  mapping: IBlackbaudContactMapping;
  mappings: IBlackbaudContactMapping[];
}

function SyncPersonCard({ integration, mapping, mappings }: ISyncPersonCardProps): JSX.Element {
  const { t } = useTranslation();
  const { key, date_last_synced: dateLastSynced } = mapping;
  const blackbaudFieldName = BlackbaudRaisersEdgeContactFieldsTranslationMap(t, key);

  return (
    <Card contentClassName={styles.card}>
      <CardContent contentClassName={styles.content}>
        <div className={styles.actions}>
          <Input
            className={styles.input}
            disabled={true}
            prefix={<FontAwesomeKitIcon iconName={EFontAwesomeKitIcon.blackbaud} />}
            value={blackbaudFieldName}
          />
          <FontAwesomeIcon icon={faArrowRight} />
          <SyncPersonSelect
            className={styles.select}
            integration={integration}
            mapping={mapping}
            mappings={mappings}
          />
        </div>
        <div className={styles.controls}>
          <RaisersEdgeDateLastSynced
            className={styles.sync}
            dateLastSynced={dateLastSynced}
            showLastSyncedPrefix={false}
            showNeverSynced={true}
          />
          <DeleteMappingButton
            disabled={mapping.required}
            id={mapping.id}
            integration={integration}
          />
        </div>
      </CardContent>
    </Card>
  );
}

export default SyncPersonCard;
