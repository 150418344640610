import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { RowRenderProps } from 'react-table';

import type { TEmailVerificationStatus } from '@feathr/blackbox';
import type { EmailVerification } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Address, Chip, TableColumnHeader } from '@feathr/components';

import EmailOptions from './EmailOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

interface IRow extends RowRenderProps {
  original: EmailVerification;
}

const statusToColorMap = new Map<TEmailVerificationStatus, string>([
  ['Success', 'green'],
  ['Pending', 'yellow'],
  ['Unverified', 'red'],
]);

function StatusCell({ original }: IRow): JSX.Element {
  const { t } = useTranslation();
  const status = original.get('status');
  return (
    <Chip isLoading={original.isPending} theme={statusToColorMap.get(status)}>
      {status === 'Success' ? t('Verified') : status === 'Pending' ? t('Pending') : t('Unverified')}
    </Chip>
  );
}

const columns: Array<IColumn<EmailVerification>> = [
  {
    id: 'email',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'Email',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }): JSX.Element {
      return <Observer>{(): JSX.Element => <>{original.get('email')}</>}</Observer>;
    },
  },
  {
    id: 'status',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'Status',
    }),
    headerClassName: tableStyles.sort,
    width: 100,
    Cell({ original }): JSX.Element {
      return <StatusCell original={original} />;
    },
  },
  {
    id: 'from_name',
    Header: TableColumnHeader({
      sortType: 'alpha',
      title: 'Name',
    }),
    headerClassName: tableStyles.sort,
    className: tableStyles.cell,
    Cell({ original }): JSX.Element {
      return <Observer>{(): JSX.Element => <>{original.get('from_name')}</>}</Observer>;
    },
  },
  {
    id: 'address',
    Header: TableColumnHeader({
      title: 'Address',
    }),
    className: tableStyles.cell,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {(): JSX.Element => {
            const address = original.get('address');
            return address ? <Address {...address} /> : <>'-'</>;
          }}
        </Observer>
      );
    },
  },
  {
    id: 'options',
    Header: TableColumnHeader({
      title: 'Options',
    }),
    width: 80,
    sortable: false,
    className: tableStyles.cellCenter,
    Cell({ original }): JSX.Element {
      return <EmailOptions email={original} />;
    },
  },
];

export default columns;
