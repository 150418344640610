import type { IObservableArray } from 'mobx';
import React from 'react';

import type { Campaign, Goal, TrackedLinkCampaign } from '@feathr/blackbox';
import { Button, Toolbar } from '@feathr/components';
import CampaignPage from '@feathr/extender/App/EventsPage/CampaignPage';
import { useLocalUrl } from '@feathr/extender/state';

import CampaignReportSection from '../CampaignReportSection';
import TrackedLinkCampaignLinks from './TrackedLinkCampaignLinks';

interface IProps {
  goals: IObservableArray<Goal>;
  campaign: Campaign;
  eventId: string;
}

function DefaultCampaignPage({ campaign, goals }: IProps) {
  const localUrl = useLocalUrl();

  const actions = (
    <Toolbar>
      <Button link={localUrl(campaign.getItemUrl('edit'))}>Edit</Button>
    </Toolbar>
  );

  return (
    <CampaignPage actions={actions} campaign={campaign} title={campaign.name}>
      <h3>Links</h3>
      <TrackedLinkCampaignLinks campaign={campaign as TrackedLinkCampaign} />
      <h3>Report</h3>
      <CampaignReportSection campaign={campaign} goals={goals} />
    </CampaignPage>
  );
}

export default DefaultCampaignPage;
