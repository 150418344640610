import { faCog, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import type { Campaign, TAttributionModel, TCampaignGroup } from '@feathr/blackbox';
import {
  Button,
  Checkbox,
  DateRangeSelects,
  DebouncedInput,
  Fieldset,
  Input,
  Label,
  Popover,
  Tooltip,
} from '@feathr/components';
import { AttributionModel } from '@feathr/report_components';

import campaignsColumns from '../campaignsColumns';
import ExportCampaignsButton from '../ExportCampaignsButton';

import * as styles from './CampaignsFiltersSecond.css';

export interface IFiltersSecond {
  date_start?: string;
  date_end?: string;
  name?: string;
}

interface ISettings {
  attributionModel: TAttributionModel;
  columnIds: string[];
}

interface IProps {
  campaignGroup: TCampaignGroup;
  filters: IFiltersSecond;
  onChangeFilters: (filters: IFiltersSecond) => void;
  onChangeSettings: (settings: ISettings) => void;
  settings: ISettings;
  items: Campaign[];
}

function CampaignsFiltersSecond({
  campaignGroup,
  filters,
  onChangeFilters,
  onChangeSettings,
  settings,
  items,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const modalRef = useRef<HTMLDivElement>(null);

  const getColumnSetter = (columnId: string) => {
    return () => {
      const newColumnIds = [...settings.columnIds];
      const index = newColumnIds.indexOf(columnId);
      const columnVisible = index >= 0;
      if (columnVisible) {
        newColumnIds.splice(index, 1);
      } else {
        newColumnIds.push(columnId);
      }
      onChangeSettings({ ...settings, columnIds: newColumnIds });
    };
  };

  const includeProjectColumn = projectId === undefined;

  const columnOptionsMap: Record<TCampaignGroup, string[]> = {
    ads: ['project', 'cpm', 'cpc', 'ctr', 'cpa'],
    all: campaignsColumns(settings.attributionModel, includeProjectColumn).map(
      (column) => column.id!,
    ),
    email: [
      'project',
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      'options',
    ],
    'google-ads': ['project', 'options'],
    monetization: [
      'project',
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      'options',
    ],
    other: [
      'project',
      'stats__email_open_rate',
      'stats__email_click_through_rate',
      'stats__hard_bounce_rate',
      'options',
    ],
  };

  const columns = campaignsColumns(settings.attributionModel, includeProjectColumn).filter(
    ({ id }) => columnOptionsMap[campaignGroup].includes(id!),
  );

  function handleDebouncedSearchChange(newValue?: string): void {
    onChangeFilters({ ...filters, name: newValue ? newValue : undefined });
  }

  function handleChangeDateRange(newDates?: [string | undefined, string | undefined]): void {
    if (!newDates) {
      return;
    }
    onChangeFilters({ ...filters, date_start: newDates[0], date_end: newDates[1] });
  }

  function handleChangeAttributionModel(newAttributionModel: TAttributionModel): void {
    const newColumnIds = settings.columnIds.map((cid) => {
      if (cid.includes('roi')) {
        return `stats__conversions__${newAttributionModel}__roi`;
      } else if (cid.includes('conversions')) {
        return `stats__conversions__${newAttributionModel}__num`;
      }
      return cid;
    });
    onChangeSettings({
      attributionModel: newAttributionModel,
      columnIds: newColumnIds,
    });
  }

  return (
    <>
      <DateRangeSelects
        onChange={handleChangeDateRange}
        t={t}
        value={[filters.date_start, filters.date_end]}
      />
      <DebouncedInput<string> defaultValue={filters.name} onChange={handleDebouncedSearchChange}>
        {(liveValue, onChangeLiveValue): JSX.Element => (
          <Input
            isClearable={true}
            onChange={onChangeLiveValue}
            placeholder={t('Search by name...')}
            prefix={<FontAwesomeIcon icon={faSearch} />}
            type={'text'}
            value={liveValue}
          />
        )}
      </DebouncedInput>
      <Tooltip placement={'topRight'} title={t('Configure columns')}>
        <Popover refs={[modalRef]} toggleOnClick={false}>
          <Button type={'icon-outlined'}>
            <FontAwesomeIcon icon={faCog} />
          </Button>
          <div className={styles.content}>
            <section>
              <Label>{t('Columns')}</Label>
              <div className={styles.columns}>
                {columns.map((column) => {
                  return (
                    <Checkbox
                      id={`checkbox-${column.id}`}
                      key={column.id}
                      label={
                        typeof column.checkboxLabel === 'string'
                          ? column.checkboxLabel
                          : column.checkboxLabel?.(items)
                      }
                      name={column.id}
                      onChange={getColumnSetter(column.id!)}
                      value={settings.columnIds.includes(column.id!)}
                    />
                  );
                })}
              </div>
            </section>
            <section>
              <AttributionModel
                onChange={handleChangeAttributionModel}
                value={settings.attributionModel}
              />
            </section>
            <Fieldset>
              <ExportCampaignsButton
                isFullWidth={true}
                modalRef={modalRef}
                params={{
                  attribution_model: settings.attributionModel,
                  columns: settings.columnIds,
                  filters,
                }}
                theme={'primary'}
              />
            </Fieldset>
          </div>
        </Popover>
      </Tooltip>
    </>
  );
}

export default CampaignsFiltersSecond;
