import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import {
  Button,
  CardContent,
  CardHeader,
  CardV2,
  DatePicker,
  Fieldset,
  Form,
  Radios,
} from '@feathr/components';
import ConsentCapture from '@feathr/extender/components/ConsentCapture';
import { useUser } from '@feathr/extender/state';
import { flattenError, moment, TimeFormat, timezoneAbbr } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import PinpointEmailCampaignAudienceSummary from '../../PinpointEmailCampaignAudienceSummary';
import PinpointEmailCampaignSegments from '../../PinpointEmailCampaignSegments';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  onPrev: () => void;
  submitButton: ReactNode;
}

interface IErrors extends TValidateGrouped {
  segments?: string[];
  send_schedule?: string[];
  date_send_start?: string[];
  'consent.has_consent'?: string[];
}

export function validateStepScheduleSend(campaign: PinpointEmailBaseCampaign): IErrors {
  return campaign.validate(
    ['segments', 'send_schedule', 'date_send_start', 'consent.has_consent'],
    false,
    'grouped',
  ).errors;
}

function PinpointEmailCampaignStepScheduleSend({
  campaign,
  disabled,
  onPrev,
  submitButton,
}: IProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();
  const stats = campaign.get('total_stats');

  const sendSchedule = campaign.get('send_schedule');
  const sendTimestamp = moment.utc(campaign.get('date_send_start')).format(TimeFormat.isoDateTime);
  const userTimezone = user.get('timezone');
  const sendMoment = userTimezone
    ? moment.utc(sendTimestamp).tz(userTimezone)
    : moment.utc(sendTimestamp).local();

  let minTimeMoment = moment();

  if (moment.utc(sendTimestamp).startOf('day').isAfter(minTimeMoment)) {
    minTimeMoment = minTimeMoment.startOf('day');
  }

  function onSendtimeChange(newValue?: string): void {
    let dateSendStart = moment.utc();

    if (newValue === 'now') {
      dateSendStart = dateSendStart.startOf('day');
    }

    if (newValue === 'later' || newValue === 'now') {
      campaign.set({
        date_send_start: dateSendStart.format(TimeFormat.isoDateTime),
        date_start: dateSendStart.startOf('day').format(TimeFormat.isoDateTime),
        date_end: dateSendStart.add(30, 'days').startOf('day').format(TimeFormat.isoDateTime),
        send_schedule: newValue,
      });
    }
  }

  function onDateStrChange(sendStartTimestamp: string | undefined): void {
    const sendStartMoment = moment.utc(sendStartTimestamp);
    campaign.set({
      date_send_start: sendStartTimestamp,
      date_start: sendStartMoment.startOf('day').format(TimeFormat.isoDateTime),
      date_end: sendStartMoment.add(30, 'days').startOf('day').format(TimeFormat.isoDateTime),
    });
  }

  function handleChangeConsent(newValue = false): void {
    if (newValue) {
      campaign.set({
        consent: {
          user: user!.id,
          has_consent: true,
          date_consented: moment.utc().format(TimeFormat.isoDateTime),
        },
      });
    } else {
      campaign.set({
        consent: {
          has_consent: false,
        },
      });
    }
  }

  const validationErrors = validateStepScheduleSend(campaign);

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        submitButton,
      ]}
      description={t(
        'Choose or create a group of people that will receive this email. Anyone in this group will receive your email at the target send time, set below.',
      )}
      label={t('Edit Campaign: Audience')}
      width={'wide'}
    >
      <PinpointEmailCampaignSegments campaign={campaign} disabled={disabled} step={4} />
      <CardV2 width={'full'}>
        <CardHeader
          description={t("Please choose if you'd like to send your email now or at a later date.")}
          title={t('Schedule & Send')}
        />
        <CardContent>
          <Fieldset>
            <Radios
              dataName={'send_time'}
              disabled={disabled}
              label={t('Choose send time')}
              onChange={onSendtimeChange}
              options={[
                { id: 'now', name: t('Now') },
                { id: 'later', name: t('Later') },
              ]}
              // TODO: Fix pre-existing bug preventing the value from being intialized correctly - see #2766
              value={sendSchedule}
            />
            {sendSchedule === 'later' && (
              <DatePicker
                dateFormat={'MMM d, yyyy h:mm aa'}
                disabled={disabled}
                helpPlacement={'bottom'}
                helpText={
                  t('The date and time your email will be sent.') +
                  ' ' +
                  sendMoment.utc().format(TimeFormat.pickerDateTimeZone)
                }
                label={t('Send Date')}
                maxTime={sendMoment.endOf('day').toDate()}
                minDate={new Date()}
                minTime={minTimeMoment.toDate()}
                name={'date_send_start'}
                onDateStrChange={onDateStrChange}
                showTimeSelect={true}
                suffix={timezoneAbbr(sendMoment.toDate())}
                timeIntervals={5}
                timezone={userTimezone}
                validationError={flattenError(validationErrors.date_send_start)}
                // TODO: Fix pre-existing bug preventing the value from being intialized correctly - see #2766
                value={sendTimestamp}
              />
            )}
          </Fieldset>
        </CardContent>
      </CardV2>
      <CardV2 width={'full'}>
        <CardHeader title={t('Verify & Publish')} />
        <CardContent>
          <ConsentCapture
            alertDescription={t(
              'I understand that if my campaigns exhibit abnormally high bounce or complaint rates, it may result in my account being placed under temporary review or suspension.',
            )}
            alertTitle={t(
              'I attest that I am sending this email to recipients who have signed up and specifically requested to receive email about this topic from me or my organization.',
            )}
            disabled={disabled}
            label={t(
              'Please type "AGREE" in the box below to verify the above statement (case-sensitive).',
            )}
            onChange={handleChangeConsent}
            value={campaign.get('consent', { has_consent: false }).has_consent}
          />
        </CardContent>
      </CardV2>
      {!!stats.num_targeted &&
        [CampaignState.Published, CampaignState.Stopped].includes(campaign.get('state')) &&
        campaign.isAfterDateSendStart() && <PinpointEmailCampaignAudienceSummary stats={stats} />}
    </Form>
  );
}

export default observer(PinpointEmailCampaignStepScheduleSend);
