function duplicates(value: any, options: any) {
  if (!Array.isArray(value)) {
    return undefined;
  }
  const validatorOptions = {
    properties: [],
    message: undefined,
    maximum: 0,
    ...options,
  };
  const target = [...value];
  let count = 0;
  while (target.length > 0) {
    const item = target.shift();
    const duplicate = target.some((targetValue) => {
      if (validatorOptions.properties.length) {
        return validatorOptions.properties.some((key) => item[key] === targetValue[key]);
      }
      return item === targetValue;
    });
    if (duplicate) {
      count += 1;
    }
  }
  if (count > validatorOptions.maximum) {
    if (!validatorOptions.message) {
      validatorOptions.message = `must not have more than ${validatorOptions.maximum} duplicate values.`;
    }
    return validatorOptions.message;
  }
  return undefined;
}

export default duplicates;
