import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when } from 'mobx';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { ICampaignAttributes, IFlight } from '@feathr/blackbox';
import type { Flight as FlightModel } from '@feathr/blackbox';
import { ArchiveModal, Button, Card, Chip, toast, Tooltip } from '@feathr/components';
import FlightStats from '@feathr/extender/components/FlightStats';
import Legs from '@feathr/extender/components/Legs';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';
import type { Model } from '@feathr/rachis';

import * as styles from './Flight.css';

interface IProps {
  flight: FlightModel;
}

function Flight({ flight }: IProps): JSX.Element {
  const account = useAccount();
  const { Campaigns } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const [showArchiveModal, toggleArchiveModal] = useToggle(false);
  const { t } = useTranslation();

  function handleClickArchiveModal(event: MouseEvent): void {
    // This is needed to prevent navigation to the flight item URL
    event.preventDefault();
    toggleArchiveModal();
  }

  async function archive(): Promise<void> {
    const campaigns = Campaigns.list({
      filters: {
        flight: flight.id,
      },
    });
    await when(() => !campaigns.isPending);
    const promises: Array<Promise<Model<ICampaignAttributes> | Model<IFlight>>> =
      campaigns.models.map((cpn) => {
        cpn.set({ flight: undefined });
        return cpn.save();
      });
    flight.set({ legs: [], is_archived: true });
    promises.push(flight.save());
    await Promise.all(promises);

    flight.collection!.removeFromApiCache(flight.id);
    toast('Flight has been archived.');
    toggleArchiveModal();
  }

  return (
    <>
      <Link className={styles.link} to={localUrl(flight.getItemUrl())}>
        <Card
          actions={[
            <Tooltip key={'remove'} title={t('Remove')}>
              <Button onClick={handleClickArchiveModal} type={'naked'}>
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </Tooltip>,
          ]}
          className={styles.root}
          // Don't use link because it will seriously mess with Legs component's scrollbar.
          element={'article'}
          titleTruncated={true}
          type={'naked'}
        >
          <header className={styles.header}>
            <h3>{flight.attributes.name}</h3>
            {flight.get('shared') && <Chip theme={'teal'}>{t('Flight Path')}</Chip>}
          </header>
          <FlightStats
            attributionModel={(!!account && account.get('attribution_model')) || 'first_touch'}
            className={styles.stats}
            displayMode={'condensed'}
            flight={flight}
          />
          <Legs className={styles.legs} displayMode={'condensed'} flight={flight} />
        </Card>
      </Link>
      {showArchiveModal && (
        <ArchiveModal
          message={t(
            'Are you sure you want to archive this flight? Doing so may result in changes to conversions for the project.',
          )}
          model={flight}
          onClose={toggleArchiveModal}
          onConfirm={archive}
          t={t}
        />
      )}
    </>
  );
}

export default Flight;
