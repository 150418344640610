import type { IObservableArray } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';

import type { Campaign, Goal, IConfig } from '@feathr/blackbox';
import { CampaignLabelMap } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import ReportWrapper from '@feathr/extender/components/ReportWrapper';
import { useAccount } from '@feathr/extender/state';
import { logUserEvents } from '@feathr/hooks';
import { CampaignReport } from '@feathr/report_components';

interface IProps {
  campaign: Campaign;
  goals?: IObservableArray<Goal>;
}

export enum EActivityKeys {
  includeActivityBreakdown = 'includeActivityBreakdown',
  includeActivitySummary = 'includeActivitySummary',
  includeAdActivity = 'includeAdActivity',
  includeCampaignEngagement = 'includeCampaignEngagement',
  includeConversionsTable = 'includeConversionsTable',
  includeCreativesTable = 'includeCreativesTable',
  includeDailyStatsTable = 'includeDailyStatsTable',
  includeEmailActivity = 'includeEmailActivity',
  includeGoogleActivity = 'includeGoogleActivity',
  includeFormSubmissionsTable = 'includeFormSubmissionsTable',
  includeHeatmap = 'includeHeatmap',
  includeKeywordStatsTable = 'includeKeywordStatsTable',
  includeLeadsSummary = 'includeLeadsSummary',
  includeLinkClicksTable = 'includeLinkClicksTable',
  includeMonetizationROI = 'includeMonetizationROI',
  includePageActivity = 'includePageActivity',
  includePartnersStatsTable = 'includePartnersStatsTable',
  includeROI = 'includeROI',
  includeSpendPerformance = 'includeSpendPerformance',
  includeTrafficBreakdown = 'includeTrafficBreakdown',
}

const CampaignClassActivityKeyMap = new Map<CampaignClass, Array<keyof IConfig>>([
  [
    CampaignClass.LandingPage,
    [
      EActivityKeys.includePageActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeLeadsSummary,
      EActivityKeys.includeFormSubmissionsTable,
      EActivityKeys.includeDailyStatsTable,
    ],
  ],
  [
    CampaignClass.Referral,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeEmailActivity,
      EActivityKeys.includePageActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeLeadsSummary,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeFormSubmissionsTable,
      EActivityKeys.includePartnersStatsTable,
      EActivityKeys.includeDailyStatsTable,
    ],
  ],
  [
    CampaignClass.Segment,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.EmailList,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.Search,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.MobileGeoFencing,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.MobileGeoFenceRetargeting,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.TrackedLink,
    [
      EActivityKeys.includeTrafficBreakdown,
      EActivityKeys.includePageActivity,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
    ],
  ],
  [
    CampaignClass.Lookalike,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.SeedSegment,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.Affinity,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeHeatmap,
      EActivityKeys.includeActivityBreakdown,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.GoogleAdsSmart,
    [EActivityKeys.includeGoogleActivity, EActivityKeys.includeKeywordStatsTable],
  ],
  [
    CampaignClass.Facebook,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.EmailListFacebook,
    [
      EActivityKeys.includeAdActivity,
      EActivityKeys.includeDailyStatsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.PinpointEmail,
    [
      EActivityKeys.includeEmailActivity,
      EActivityKeys.includeROI,
      EActivityKeys.includeConversionsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.SmartPinpointEmail,
    [
      EActivityKeys.includeEmailActivity,
      EActivityKeys.includeROI,
      EActivityKeys.includeConversionsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
  [
    CampaignClass.AutoPinpointEmail,
    [
      EActivityKeys.includeEmailActivity,
      EActivityKeys.includeROI,
      EActivityKeys.includeConversionsTable,
      EActivityKeys.includeCampaignEngagement,
    ],
  ],
]);

const configDefault: IConfig = {
  attributionModel: 'full',
  [EActivityKeys.includeActivityBreakdown]: false,
  [EActivityKeys.includeActivitySummary]: true,
  [EActivityKeys.includeAdActivity]: false,
  [EActivityKeys.includeConversionsTable]: false,
  [EActivityKeys.includeCreativesTable]: true,
  [EActivityKeys.includeDailyStatsTable]: false,
  [EActivityKeys.includeEmailActivity]: false,
  [EActivityKeys.includeHeatmap]: false,
  [EActivityKeys.includeLeadsSummary]: false,
  [EActivityKeys.includeFormSubmissionsTable]: false,
  [EActivityKeys.includePageActivity]: false,
  [EActivityKeys.includeROI]: false,
  [EActivityKeys.includeSpendPerformance]: true,
  [EActivityKeys.includeTrafficBreakdown]: false,
  [EActivityKeys.includeMonetizationROI]: false,
  [EActivityKeys.includeLinkClicksTable]: true,
};

export function getCampaignReportConfig(campaign: Campaign): IConfig {
  const activityKeys: Array<keyof IConfig> = CampaignClassActivityKeyMap.get(campaign.get('_cls'))!;
  const config: IConfig = activityKeys
    ? activityKeys.reduce((newConfig, key) => {
        return { ...newConfig, [key]: true };
      }, configDefault)
    : configDefault;
  if (!campaign.isCreatedAfterScriptedUpdates) {
    /**
     * There are discrepancies in statistics for campaigns
     * that were running before and after scripted updates went live.
     * We do not want to show this component if that is the case.
     */
    delete config.includeCampaignEngagement;
  }
  return config;
}

function CampaignReportSection({ campaign }: IProps): JSX.Element {
  const account = useAccount();
  const config = getCampaignReportConfig(campaign);

  useEffect(() => {
    logUserEvents({
      [`Viewed ${CampaignLabelMap.get(campaign.get('_cls'))} report`]: {
        account_id: account.id,
        campaign_id: campaign.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ReportWrapper
      hideCustomDateRange={campaign.isEmail}
      initialConfig={config}
      model={campaign}
      Report={CampaignReport}
    />
  );
}

export default observer(CampaignReportSection);
