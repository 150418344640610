import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { Billable, Event } from '@feathr/blackbox';
import { Alert, AlertType, Modal } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

interface IProps {
  event: Event;
  billable: Billable;
  toggleShowAddAddressModal: () => void;
}

function AddAddress({ event, billable, toggleShowAddAddressModal }: IProps) {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Modal
      confirmButtonText={t('Edit Billing Configuration')}
      controlled={true}
      onClose={toggleShowAddAddressModal}
      onConfirm={async () => {
        history.push(localUrl(billable.getItemUrl('edit')));
      }}
      size={'sm'}
      t={t}
      title={t('Address Required')}
    >
      <Alert type={AlertType.warning}>
        <Trans t={t}>
          <p>
            To add pre-paid funds to this project please add an address to the billing
            configuration, or{' '}
            <a href={localUrl(event.getItemUrl('settings/billing/edit'))}>replace</a> this project's
            billing configuration with one that has an address.
          </p>
        </Trans>
      </Alert>
    </Modal>
  );
}

export default AddAddress;
