import type { JSX, ReactNode } from 'react';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import createStore, { ActionBarProvider, StoresContext } from '@feathr/extender/state';
import { i18n } from '@feathr/locales';

interface IProps {
  children: ReactNode;
}

function AppWrapper({ children }: IProps): JSX.Element {
  const store = createStore();

  return (
    <I18nextProvider i18n={i18n}>
      <StoresContext.Provider value={store}>
        <BrowserRouter>
          <ActionBarProvider>{children}</ActionBarProvider>
        </BrowserRouter>
      </StoresContext.Provider>
    </I18nextProvider>
  );
}

export default AppWrapper;
