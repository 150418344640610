import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Page from '@feathr/extender/App/Page';
import { StoresContext } from '@feathr/extender/state';

import RaisersEdgeIntegrationCard from './RaisersEdgeIntegrationCard';

import * as styles from './RaisersEdgeIntegrationPage.css';

function RaisersEdgeIntegrationPage(): JSX.Element {
  const { t } = useTranslation();
  const { BlackbaudRaisersEdgeIntegrations } = useContext(StoresContext);
  const blackbaudRaisersEdgeIntegrations = BlackbaudRaisersEdgeIntegrations.list();

  // There should only be one integration per account; so, if there is one, we have the basics of an integration
  const integration = blackbaudRaisersEdgeIntegrations.models.length
    ? blackbaudRaisersEdgeIntegrations.models[0]
    : undefined;

  const learnMoreLink =
    'https://help.feathr.co/hc/en-us/articles/21160663599255-How-to-Integrate-your-Blackbaud-Raiser-s-Edge-NXT-Account-with-Feathr';

  return (
    <Page
      description={
        <Trans t={t}>
          <div className={styles.description}>
            Sync all your data, breadcrumbs, and activities from Blackbaud Raiser’s Edge NXT to
            Feathr.{' '}
            <a href={learnMoreLink} target={'_blank'}>
              Learn more
            </a>
          </div>
        </Trans>
      }
      loading={blackbaudRaisersEdgeIntegrations.isPending}
      title={t("Blackbaud: Raiser's Edge NXT")}
    >
      <RaisersEdgeIntegrationCard integration={integration} />
    </Page>
  );
}

export default observer(RaisersEdgeIntegrationPage);
