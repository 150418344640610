import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { BlackbaudRaisersEdgeIntegration } from '@feathr/blackbox';
import { Button, toast } from '@feathr/components';

interface IProps {
  disabled?: boolean;
  id: string;
  integration: BlackbaudRaisersEdgeIntegration;
}

function DeleteMappingButton({ disabled = false, id, integration }: IProps): JSX.Element {
  const { t } = useTranslation();

  async function deleteMapping(): Promise<void> {
    try {
      // TODO: Refactor to use ContactMapping.delete() as part of #2650.
      await integration.deleteContactMapping(id);
      toast(t('Successfully deleted mapping.'), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necesary with the implementation of #2650.
      window.location.reload();
    } catch (error) {
      toast(t('Failed to delete mapping.'), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <Button
      disabled={disabled}
      name={'delete-contact-mapping'}
      onClick={deleteMapping}
      tooltip={disabled ? t('This contact mapping is required.') : null}
      tooltipPlacement={'topRight'}
      type={'icon'}
    >
      <FontAwesomeIcon icon={faTrash} />
    </Button>
  );
}

export default observer(DeleteMappingButton);
