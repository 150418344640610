import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPenToSquare, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Billable, ICreditCardSource, TSource } from '@feathr/blackbox';
import { isBankAccountSource, isCreditCardSource } from '@feathr/blackbox';
import { Alert, AlertType, Button, Modal, Well } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

import BankAccount from './BankAccount';
import CreditCard from './CreditCard';

import * as styles from './BillingSource.css';

interface IBillingSourceProps {
  className?: string;
  billable: Billable;
  deleteIcon?: IconProp;
  deleteLabel?: string;
  onDelete?: () => void;
  onUpdate?: () => void;
  readOnly?: boolean;
  source: TSource;
}

function BillingSource(props: Readonly<IBillingSourceProps>): JSX.Element {
  const { t } = useTranslation();

  const {
    billable,
    className,
    deleteIcon = faTrash,
    deleteLabel = t('Delete'),
    onDelete,
    onUpdate,
    readOnly = false,
    source,
  } = props;

  const [isDeleteModalOpen, toggleIsDeleteModalOpen] = useToggle(false);

  return (
    <Well>
      <div className={classNames(styles.root, className)}>
        {isBankAccountSource(source) && <BankAccount billable={billable} />}
        {isCreditCardSource(source) && <CreditCard source={source as ICreditCardSource} />}
        {(onUpdate || onDelete) && !readOnly && (
          <div>
            {onUpdate && (
              <Button onClick={onUpdate} prefix={<FontAwesomeIcon icon={faPenToSquare} />}>
                {t('Update')}
              </Button>
            )}
            {onDelete && (
              <Button
                onClick={toggleIsDeleteModalOpen}
                prefix={<FontAwesomeIcon icon={deleteIcon} />}
              >
                {deleteLabel}
              </Button>
            )}
          </div>
        )}
        {isDeleteModalOpen && (
          <Modal
            confirmButtonText={t('Yes, Delete')}
            controlled={true}
            onClose={toggleIsDeleteModalOpen}
            onConfirm={onDelete}
            t={t}
          >
            <Alert type={AlertType.danger}>
              {t('Are you sure you want to delete this payment method?')}
            </Alert>
          </Modal>
        )}
      </div>
    </Well>
  );
}

export default BillingSource;
