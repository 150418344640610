import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPause, faPlay } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { BlackbaudRaisersEdgeIntegration } from '@feathr/blackbox';
import { Button, Checkbox, ConfirmModal, toast } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

type TButtonType = 'disconnect' | 'pause' | 'resume';

interface IProps {
  readonly integration: BlackbaudRaisersEdgeIntegration;
  readonly type: TButtonType;
}

function getIconFromType(type: TButtonType): IconProp {
  const map = {
    // Disconnect does not have an icon.
    pause: faPause,
    resume: faPlay,
  };

  return map[type];
}

function RaisersEdgeControlButton({ integration, type }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [showModal, toggleShowModal] = useToggle(false);
  const [disconnectAcknowledged, toggleDisconnectAcknowledged] = useToggle(false);

  function getErrorAction(): string {
    const map = {
      disconnect: t('disconnect'),
      pause: t('pause'),
      resume: t('resume'),
    };

    return map[type];
  }

  function getSuccessAction(): string {
    const map = {
      disconnect: t('disconnected'),
      pause: t('paused'),
      resume: t('resumed'),
    };
    return map[type];
  }

  async function handleConfirm(): Promise<void> {
    try {
      switch (type) {
        case 'disconnect':
          await integration.disconnectIntegration();
          break;

        case 'pause':
          await integration.pauseIntegration();
          break;

        case 'resume':
          await integration.resumeIntegration();
          break;
      }

      toast(
        t('Your integration has been successfully {{action}}.', {
          action: getSuccessAction(),
        }),
        { type: ToastType.SUCCESS },
      );
      /*
       * TODO: This is a hack to get the page to refresh.
       * We could do this with processJSONResponse but there's a type issue with BaseIntegration. See publish in campaign.ts.
       */
      window.location.reload();
    } catch (error) {
      toast(
        t('Something went wrong while trying to {{action}} your integration: {{- error}}', {
          action: getErrorAction(),
          error,
        }),
        { type: ToastType.ERROR },
      );
    } finally {
      toggleShowModal();
    }
  }

  function handleClose(): void {
    if (showModal) {
      toggleShowModal();
    }
  }

  const buttonText = (function (): string {
    const map = { disconnect: t('Disconnect'), pause: t('Pause'), resume: t('Resume') };
    return map[type];
  })();

  const confirmButtonText = (function (): string {
    const map = {
      disconnect: t('Disconnect integration'),
      pause: t('Pause integration'),
      resume: t('Resume integration'),
    };
    return map[type];
  })();

  const title = (function (): string {
    const map = {
      disconnect: t('Disconnect integration?'),
      pause: t('Pause integration?'),
      resume: t('Resume integration?'),
    };
    return map[type];
  })();

  const description = (function (): string {
    const map = {
      disconnect: t(
        'Are you sure you want to disconnect this integration? Data will not sync while this integration is disconnected.',
      ),
      pause: t(
        'Are you sure you want to pause this integration? Data will not sync while this integration is paused.',
      ),
      resume: t(
        'Are you sure you want to resume this integration? Data will sync again once this integration is resumed.',
      ),
    };

    return map[type];
  })();

  const key = `${type}-raisers-edge-integration`;
  const isDisconnectButton = type === 'disconnect';

  return (
    <>
      <Button
        key={key}
        name={key}
        onClick={toggleShowModal}
        prefix={isDisconnectButton ? null : <FontAwesomeIcon icon={getIconFromType(type)} />}
      >
        {buttonText}
      </Button>
      {showModal && (
        <ConfirmModal
          cancelButtonText={t('Cancel')}
          confirmButtonText={confirmButtonText}
          confirmButtonType={isDisconnectButton ? 'danger' : undefined}
          confirmDisabled={isDisconnectButton ? !disconnectAcknowledged : undefined}
          description={description}
          onClose={handleClose}
          onConfirm={handleConfirm}
          t={t}
          title={title}
        >
          {isDisconnectButton && (
            <Checkbox
              label={t('Yes, I want to disconnect this integration')}
              onChange={toggleDisconnectAcknowledged}
            />
          )}
        </ConfirmModal>
      )}
    </>
  );
}

export default observer(RaisersEdgeControlButton);
