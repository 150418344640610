import { faCreditCard } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import * as styles from './BillableDefaultMissing.css';

function BillableDefaultMissing(): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <FontAwesomeIcon className={styles.icon} icon={faCreditCard} size={'3x'} />
      <h4>{t('No default billing option')}</h4>
      <p>{t('Get started by adding a new default billing option.')}</p>
      <Button
        className={styles.button}
        link={localUrl('/settings/billing/configurations/add')}
        prefix={<FontAwesomeIcon icon={faPlus} />}
      >
        {t('Default payment method')}
      </Button>
    </div>
  );
}

export default BillableDefaultMissing;
