import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { BannersnackCreative, Creative, DisplayCreative } from '@feathr/blackbox';
import { CreativeClass } from '@feathr/blackbox';
import { ArchiveModal, ContextMenu, MenuDivider, MenuItem, toast } from '@feathr/components';
import CloneCreativeModal from '@feathr/extender/components/CloneCreativeModal';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import DownloadBannersnackFileMenuItem from '../DownloadBannersnackFileMenuItem';

interface IProps {
  creative: Creative;
}

function EventCreativesOptions({ creative }: IProps) {
  const { Creatives } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isCloneModalOpen, toggleIsCloneModalOpen] = useToggle(false);
  const { t } = useTranslation();

  async function handleArchive() {
    try {
      await creative.archive();
      toast(t(`Creative {{name}} has been archived.`, { name: creative.get('name') }));
      toggleArchiveModalOpen();
    } catch (e) {
      toast(t('There was an issue archiving the creative. Please try again later.'), {
        type: 'error',
      });
    }
  }

  function handleClone() {
    Creatives.refreshApiCache();
  }

  const type = creative.get('_cls');
  const animated = CreativeClass.DisplayBannersnack
    ? (creative as BannersnackCreative).get('animated')
    : false;

  return (
    <>
      <ContextMenu>
        <MenuItem disabled={creative.isUpdating} link={localUrl(creative.getItemUrl('edit'))}>
          {t('Edit')}
        </MenuItem>
        <MenuItem disabled={creative.isUpdating} onClick={toggleIsCloneModalOpen}>
          {t('Clone')}
        </MenuItem>
        <MenuDivider />
        <MenuItem onClick={toggleArchiveModalOpen}>{t('Archive')}</MenuItem>
        {type === CreativeClass.DisplayBannersnack && (
          <>
            <MenuDivider />
            <DownloadBannersnackFileMenuItem
              creative={creative as BannersnackCreative}
              type={'jpg'}
            />
            <DownloadBannersnackFileMenuItem
              creative={creative as BannersnackCreative}
              type={'png'}
            />
            <DownloadBannersnackFileMenuItem
              creative={creative as BannersnackCreative}
              type={'pdf'}
            />
            {animated && (
              <>
                <DownloadBannersnackFileMenuItem
                  creative={creative as BannersnackCreative}
                  type={'animated-gif'}
                />
                <DownloadBannersnackFileMenuItem
                  creative={creative as BannersnackCreative}
                  type={'mp4'}
                />
              </>
            )}
          </>
        )}
      </ContextMenu>
      {isCloneModalOpen && (
        <CloneCreativeModal
          creative={creative as DisplayCreative}
          eventId={creative.get('event')}
          onClose={toggleIsCloneModalOpen}
          onConfirm={handleClone}
        />
      )}
      {isArchiveModalOpen && (
        <ArchiveModal
          model={creative}
          onClose={toggleArchiveModalOpen}
          onConfirm={handleArchive}
          t={t}
        />
      )}
    </>
  );
}

export default observer(EventCreativesOptions);
