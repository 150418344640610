import type { TFunction } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { CampaignClass, type IPredicate } from '@feathr/blackbox';
import { DatePicker, Input, NumberInput, Select } from '@feathr/components';

import type { ICollectionOption } from '../PredicateIdValueSelect';
import type { TCollection } from '../PredicateIdValueSelect/PredicateIdValueSelect';
import PredicateIdValueSelect from '../PredicateIdValueSelect/PredicateIdValueSelect';
import PredicateListValueSelect from '../PredicateListValueSelect';
import PredicateTextValueInput from '../PredicateTextValueInput';
import type { TOptionsGroup } from './utils';

import * as styles from './ValueInput.css';

interface IProps {
  disabled?: boolean;
  excludeIds?: string[];
  handleChangeValue: (value: any) => void;
  optionsGroup: TOptionsGroup;
  predicate: IPredicate;
}

interface IInputParams {
  predicate: IPredicate;
  excludeIds: string[];
  disabled: boolean;
  t: TFunction;
  optionsGroup: string;
  handleChangeValue: (value: any) => void;
}

export function getValueInput({
  predicate,
  excludeIds,
  disabled,
  t,
  optionsGroup,
  handleChangeValue,
}: IInputParams): JSX.Element | null {
  const { attr_against: targetAttribute, attr_type: type, comparison, kind } = predicate;

  function getPath(): string {
    const collectionMap = {
      activity: 'breadcrumbs',
      attribute: 'persons',
      update: 'persons',
    };
    return `${collectionMap[kind || 'activity']}/${targetAttribute}/values`;
  }

  function onSelect(selected: ICollectionOption | ICollectionOption[] | null): void {
    if (Array.isArray(selected)) {
      handleChangeValue(selected.map(({ id }) => id!));
    } else if (selected) {
      handleChangeValue(selected.id);
    } else {
      handleChangeValue(undefined);
    }
  }

  const {
    Conversation,
    LandingPage,
    EmailList,
    Lookalike,
    SeedSegment,
    Affinity,
    Segment,
    Search,
    MobileGeoFencing,
    MobileGeoFenceRetargeting,
    Facebook,
    TrackedLink,
    EmailListFacebook,
    Referral,
    PinpointEmail,
    SmartPinpointEmail,
  } = CampaignClass;

  // Construct allowed classes for 'campaign is' dropdown
  function getAllowedClasses(optionsGroup: string): CampaignClass[] {
    const allowedClasses: CampaignClass[] = [
      Conversation,
      LandingPage,
      EmailList,
      Lookalike,
      SeedSegment,
      Affinity,
      Segment,
      Search,
      MobileGeoFencing,
      MobileGeoFenceRetargeting,
      Facebook,
      TrackedLink,
      EmailListFacebook,
      Referral,
      PinpointEmail,
      SmartPinpointEmail,
    ];

    /**
     * Only allow autosend in dropdown for group builder and the targets
     * step of the campaign wizard. We want to exclude autosend campaigns
     * from being used in the trigger condition of other autosends.
     */
    if (['segment', 'campaign'].includes(optionsGroup)) {
      allowedClasses.push(CampaignClass.AutoPinpointEmail);
    }

    return allowedClasses;
  }

  // Construct optional filters for 'campaign is' dropdown
  function getOptionalFilters(excludeIds: string[]): Record<string, string[]> {
    return excludeIds.length ? { id__nin: excludeIds } : {};
  }

  function getSelectProps(collection, filters = {}, multi = false): Record<string, any> {
    const idSelectShared = {
      disabled,
      key: comparison,
      name: 'predicate_value',
      onSelect,
      placeholder: t('Select an option'),
      value: predicate.value as string | undefined,
    };
    return {
      ...idSelectShared,
      collection: collection as TCollection,
      filters,
      multi,
    };
  }

  const idSelectProps = {
    cpn_id: getSelectProps('Campaigns', {
      _cls__in: getAllowedClasses(optionsGroup),
      ...getOptionalFilters(excludeIds),
    }),
    e_id: getSelectProps('Events'),
    p_id: getSelectProps('Partners'),
    seg_id: getSelectProps('Segments', {
      context: 'breadcrumb',
      is_conversion_segment: true,
    }),
    tag_id: getSelectProps('Tags', {
      context: 'breadcrumb',
    }),
    tag_ids: getSelectProps(
      'Tags',
      {
        context: 'person',
      },
      true,
    ),
    'pp_opt_outs.events': getSelectProps('Events', {}, true),
    'pp_opt_outs.campaigns': getSelectProps(
      'Campaigns',
      {
        _cls__in: [
          CampaignClass.PinpointEmail,
          CampaignClass.SmartPinpointEmail,
          CampaignClass.AutoPinpointEmail,
        ],
      },
      true,
    ),
  };

  if (targetAttribute && Object.keys(idSelectProps).includes(targetAttribute)) {
    return <PredicateIdValueSelect {...idSelectProps[targetAttribute]} />;
  }

  const inputProps = {
    disabled,
    getPath,
    key: comparison,
    name: 'predicate_value',
    placeholder: t('Enter a value'),
  };

  switch (type) {
    case 'string':
      return (
        <PredicateTextValueInput
          {...inputProps}
          onChange={handleChangeValue}
          value={predicate.value as string}
        />
      );

    case 'list':
      return (
        <PredicateListValueSelect
          {...inputProps}
          onChange={handleChangeValue}
          value={predicate.value as string[]}
        />
      );

    case 'date':
      if (comparison?.includes('_r')) {
        const count = predicate.value !== undefined ? +predicate.value : undefined;
        return (
          <div className={styles.container}>
            <Trans count={count} t={t}>
              <NumberInput
                {...inputProps}
                className={styles.numberInput}
                onChange={handleChangeValue}
                value={count}
                wrapperClassName={styles.numberInput}
              />
              <span className={styles.time}>day ago</span>
            </Trans>
          </div>
        );
      }
      return (
        <DatePicker
          {...inputProps}
          autoComplete={'off'}
          onDateStrChange={handleChangeValue}
          placeholder={t('Select a date')}
          selected={predicate.value ? new Date(predicate.value as string) : null}
          showCalendarIcon={true}
        />
      );

    case 'integer':

    case 'float':
      return (
        <NumberInput
          {...inputProps}
          onChange={handleChangeValue}
          value={predicate.value as number | undefined}
          wrapperClassName={styles.numberInput}
        />
      );

    case 'boolean': {
      const booleanOptions = [
        { id: true, name: t('True') },
        { id: false, name: t('False') },
      ];
      return (
        <Select
          {...inputProps}
          onChange={handleChangeValue}
          options={booleanOptions}
          value={
            predicate.value !== undefined
              ? booleanOptions.find(({ id }) => predicate.value === id)
              : booleanOptions.find(({ id }) => false === id)
          }
        />
      );
    }

    default:
      return <Input {...inputProps} onChange={handleChangeValue} type={'text'} />;
  }
}

function ValueInput({
  disabled = false,
  excludeIds = [],
  handleChangeValue,
  optionsGroup,
  predicate: { comparison },
  predicate,
}: IProps): JSX.Element | null {
  const { t } = useTranslation();
  /*
   * We don't want to show value if there's no comparison,
   * or if we're just checking for existence.
   */
  if (!comparison || ['exists', 'nexists'].includes(comparison)) {
    return null;
  }

  return getValueInput({ predicate, excludeIds, disabled, t, optionsGroup, handleChangeValue });
}

export default observer(ValueInput);
