import { faClipboard } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import Prism from 'prismjs';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { Breadcrumb } from '@feathr/blackbox';
import { CopyToClipboardButton, Modal } from '@feathr/components';

import * as styles from './BreadcrumbRawModal.css';

interface IProps {
  breadcrumb: Breadcrumb;
  toggle: () => void;
}

function BreadcrumbRawModal({ breadcrumb, toggle }: IProps) {
  const { t } = useTranslation();

  const prismRef = useCallback((node) => {
    if (node !== null) {
      Prism.highlightElement(node);
    }
  }, []);

  const breadcrumbRaw = JSON.stringify(breadcrumb.toJS(), null, '  ');
  return (
    <Modal
      cancelButtonText={t('Close')}
      className={styles.container}
      controlled={true}
      footerControls={[
        <CopyToClipboardButton
          className={styles.button}
          key={'breadcrumbRawData'}
          prefix={<FontAwesomeIcon icon={faClipboard} />}
          t={t}
          text={breadcrumbRaw}
        >
          {t('Copy raw data')}
        </CopyToClipboardButton>,
      ]}
      onClose={toggle}
      size={'lg'}
      t={t}
      title={t('Raw Activity Data')}
    >
      <pre>
        <code className={'language-json'} ref={prismRef}>
          {breadcrumbRaw}
        </code>
      </pre>
    </Modal>
  );
}

export default observer(BreadcrumbRawModal);
