import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import React from 'react';
import type { ValueType } from 'react-select';

import type { Campaign } from '@feathr/blackbox';
import { CreatableSelect } from '@feathr/components';

import * as styles from './BaseBidSelect/BaseBidSelect.css';

interface IProps {
  campaign: Campaign;
}

interface IOption {
  value: string;
  label: string;
}

function compareOption(inputValue: string, option: IOption) {
  return inputValue === option.value;
}

function isValidNewOption(inputValue: string, _: ValueType<IOption>, options: readonly IOption[]) {
  const asNumber = Number(inputValue);
  return !(
    !inputValue ||
    !Number.isFinite(asNumber) ||
    !(asNumber > 0 && asNumber <= 15) ||
    options.some((option) => compareOption(inputValue, option))
  );
}

function createOption(inputValue: string) {
  const asNumeral = numeral(Number(inputValue));
  return { value: asNumeral.format('0'), label: `${asNumeral.format('0,0')} Imps.` };
}

function FreqCapSelect({ campaign }: IProps) {
  const cap = campaign.get('exposure_model').freq_cap;
  const onChangeFreqCap = (value: string | number) => {
    const newCap = Number(value);
    campaign.set({
      exposure_model: {
        ...campaign.get('exposure_model'),
        freq_cap: newCap,
        intensity: 'custom',
      },
    });
  };
  const period = String(campaign.get('exposure_model').freq_period);
  const lifetime = '2147483647';
  return (
    <CreatableSelect<IOption>
      createOption={createOption}
      defaultOptions={[
        { value: '4', label: '4 Imps.' },
        { value: '9', label: '9 Imps.' },
        { value: '16', label: '16 Imps.' },
      ]}
      formatCreateLabel={(inputValue: string) =>
        `Use custom value: ${numeral(Number(inputValue)).format('0,0')} Imps.`
      }
      helpText={`
          The maximum number of times Feathr may show ads from this campaign to the same user ${
            period === lifetime
              ? 'for the duration of the Campaign.'
              : `in a${period.includes('8') ? 'n' : ''} ${Math.ceil(
                  campaign.get('exposure_model').freq_period / 60,
                )} hour period.
          `
          }
        `}
      isValidNewOption={isValidNewOption}
      label={'Frequency Cap'}
      onSelectSingle={(option: { value: string; label: string }) => onChangeFreqCap(option.value)}
      value={{
        value: String(cap),
        label: `${numeral(cap).format('0,0')} Imps.`,
      }}
      wrapperClassName={styles.wrapper}
    />
  );
}

export default observer(FreqCapSelect);
