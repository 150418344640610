import { Observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { BaseCampaign, IStats, TAttributionModel } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

function getROI(stats: IStats, attributionModel: TAttributionModel): number {
  const conversion = stats.conversions;
  return (conversion && conversion[attributionModel]?.roi) || 0;
}

export function getROIColumn(attributionModel: TAttributionModel): IColumn<BaseCampaign> {
  return {
    id: `stats__conversions__${attributionModel}__roi`,
    checkboxLabel: 'Conversion Value',
    Header: TableColumnHeader({
      title: 'Conversion Value',
      sortType: 'numeric',
    }),
    headerClassName: tableStyles.sort,
    width: 170,
    className: tableStyles.cellRight,
    Cell({ original }): JSX.Element {
      return (
        <Observer>
          {(): JSX.Element => {
            const roi = getROI(original.get('total_stats'), attributionModel);
            return <>{numeral(roi).format('$0,0.00')}</>;
          }}
        </Observer>
      );
    },
    Footer({ data }): JSX.Element {
      return (
        <Observer>
          {(): JSX.Element => {
            const total = data.reduce((subtotal, currentValue) => {
              const cpm = getROI(currentValue._original.get('total_stats'), attributionModel);
              return subtotal + cpm;
            }, 0);
            return <>{numeral(total).format('$0,0.00')}</>;
          }}
        </Observer>
      );
    },
  };
}
