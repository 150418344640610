import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import React from 'react';
import type { ValueType } from 'react-select';

import type { IExposureModel } from '@feathr/blackbox';
import type { Campaign } from '@feathr/blackbox';
import { CreatableSelect } from '@feathr/components';

import * as styles from './BaseBidSelect/BaseBidSelect.css';

interface IProps {
  campaign: Campaign;
}

interface IOption {
  value: string;
  label: string;
}

function compareOption(inputValue: string, option: IOption) {
  return inputValue === option.value;
}

function isValidNewOption(inputValue: string, _: ValueType<IOption>, options: readonly IOption[]) {
  const period_max = 2147483647;
  const asNumber = Number(inputValue);
  return !(
    !inputValue ||
    !Number.isFinite(asNumber) ||
    !(asNumber > 0 && asNumber <= period_max) ||
    options.some((option) => compareOption(inputValue, option))
  );
}

function createOption(inputValue: string) {
  const asNumeral = numeral(Number(inputValue));
  return { value: asNumeral.format('0'), label: `${asNumeral.format('0,0')} Hours` };
}

function FreqPeriodSelect({ campaign }: IProps) {
  const lifetime = 2147483647;
  const period = campaign.get('exposure_model').freq_period;
  const onChangeFreqPeriod = (value: string | number) => {
    let newPeriod;
    if (value === 'lifetime') {
      newPeriod = lifetime;
    } else {
      newPeriod = Number(value) * 60;
    }
    campaign.set({
      exposure_model: {
        ...campaign.get('exposure_model'),
        freq_period: newPeriod,
        intensity: 'custom',
      } as IExposureModel,
    });
  };

  return (
    <CreatableSelect<IOption>
      createOption={createOption}
      defaultOptions={[
        { value: '1', label: '1 Hour' },
        { value: '8', label: '8 Hours' },
        { value: '24', label: '24 Hours' },
        { value: 'lifetime', label: 'Lifetime' },
      ]}
      formatCreateLabel={(inputValue: string) =>
        `Use custom value: ${numeral(Number(inputValue)).format('0,0')} Hours`
      }
      helpText={`The period of time after which a Person's Frequency Cap is reset.
          Choosing "Lifetime" means the Frequency Cap is the maximum number of impressions per Person.`}
      isValidNewOption={isValidNewOption}
      label={'Frequency Period'}
      onSelectSingle={(option: { value: string; label: string }) =>
        onChangeFreqPeriod(option.value)
      }
      value={{
        value: period === lifetime ? 'lifetime' : String(Math.ceil(period / 60)),
        label:
          period === lifetime
            ? 'Lifetime'
            : `${numeral(Math.ceil(period / 60)).format('0,0')} Hours`,
      }}
      wrapperClassName={styles.wrapper}
    />
  );
}

export default observer(FreqPeriodSelect);
