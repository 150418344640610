import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { ValueType } from 'react-select';

import type { Billable } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import BillingSource from '@feathr/extender/components/BillingSource';
import { StoresContext } from '@feathr/extender/state';

import * as styles from './BillableSelect.css';

interface IProps {
  label?: React.ReactNode;
  isLoading?: boolean;
  onChange?: (value: ValueType<Billable>) => void;
  value?: Billable;
}

function BillableSelect({ isLoading = false, label, onChange, value }: IProps): JSX.Element {
  const { Billables } = React.useContext(StoresContext);
  const billables = Billables.list();

  const stripeSource = value?.get('stripe').source;

  function handleChange(changedValue: ValueType<Billable>): void {
    if (Array.isArray(changedValue)) {
      return;
    }
    if (onChange) {
      onChange(changedValue);
    }
  }

  function handleOptionLabel(option): string {
    const description = option.get('description');
    if (description) {
      return description;
    }

    const name = option.get('name');
    const email = option.get('email', 'no email address');
    return `${name} (${email})`;
  }

  return (
    <div className={styles.root}>
      <Select<Billable>
        getOptionLabel={handleOptionLabel}
        isLoading={billables.isPending || isLoading}
        label={label}
        onChange={handleChange}
        // Shallow converting observables back to vanilla JavaScript.
        options={billables.models.slice()}
        value={value}
      />
      {!!value && !!stripeSource && <BillingSource billable={value} source={stripeSource} />}
    </div>
  );
}

export default observer(BillableSelect);
