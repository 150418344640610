import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import type { Font } from '@feathr/blackbox';
import { ArchiveModal, ContextMenu, MenuItem } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

interface IOptionsColumnProps {
  font: Font;
}

function FontOptions({ font }: IOptionsColumnProps) {
  const rootStore = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const { t } = useTranslation();

  return (
    <>
      <ContextMenu>
        <StoresContext.Provider value={rootStore}>
          <MenuItem link={localUrl(font.getItemUrl('edit'))}>{t('Edit')}</MenuItem>
          <MenuItem onClick={toggleArchiveModalOpen}>{t('Delete')}</MenuItem>
        </StoresContext.Provider>
      </ContextMenu>
      {isArchiveModalOpen && (
        <ArchiveModal
          message={t('Are you sure you want to delete this font?')}
          method={'delete'}
          model={font}
          onClose={toggleArchiveModalOpen}
          t={t}
          title={t('Delete Font')}
        />
      )}
    </>
  );
}

export default observer(FontOptions);
