import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';

import type { IPredicate, TComparison } from '@feathr/blackbox';
import { Select } from '@feathr/components';
import { cssVar } from '@feathr/hooks';

import type { IAttrOption, IComparisonOption } from './utils';
import { getComparisonOptions } from './utils';

import * as styles from './ComparisonSelect.css';

interface IProps {
  disabled?: boolean;
  handleChangeComparison: (comparison: TComparison) => void;
  predicate: IPredicate;
  rule?: IAttrOption;
}

export function ComparisonOption(props: any): JSX.Element {
  const { name } = props.data;
  const { Option } = components;
  return (
    <Option {...props}>
      <span className={styles.comparison}>{name}</span>
    </Option>
  );
}

function ComparisonSelect({
  disabled,
  handleChangeComparison,
  predicate,
  rule,
}: Readonly<IProps>): JSX.Element | null {
  const { t } = useTranslation();

  // We don't want to show comparison if a rule hasn't been selected.
  if (!rule) {
    return null;
  }

  function handleSelectComparison({ id }: IComparisonOption): void {
    runInAction(() => {
      predicate.comparison = id;
      handleChangeComparison(id);
      /*
       * Reset value to blank when changing comparison.
       */
      predicate.value = '';
    });
  }

  const options = rule ? getComparisonOptions(rule, t) : [];
  const comparison = options.find(({ id }) => id === predicate.comparison);
  const selectStyles = {
    /*
     * Margin-right here spaces the attr_against container from
     * the comparison operator container
     */
    container: (provided) => ({ ...provided, marginRight: cssVar('--spacing-1') }),
    menu: (provided) => ({ ...provided, width: 'auto' }),
    singleValue: (provided) => ({
      ...provided,
      transform: 'none',
      position: 'relative',
      top: 0,
      maxWidth: '100%',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      transform: 'none',
      position: 'relative',
      top: 0,
    }),
  };

  return (
    <Select
      className={styles.select}
      components={{ Option: ComparisonOption }}
      disabled={disabled}
      name={'predicate_comparison'}
      onSelectSingle={handleSelectComparison}
      options={options}
      styles={selectStyles}
      value={comparison}
    />
  );
}

export default observer(ComparisonSelect);
