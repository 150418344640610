import { faCloudSlash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { ImisIntegration } from '@feathr/blackbox';
import { Button, ConfirmModal, EAlertV2Type, toast } from '@feathr/components';
import { useToggle } from '@feathr/hooks';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface IiMISDisconnectControlProps {
  integration: ImisIntegration;
}

function ImisDisconnectControl({ integration }: IiMISDisconnectControlProps): JSX.Element | null {
  const { t } = useTranslation();
  const [showDisconnectModal, toggleDisconnectModal] = useToggle(false);

  function handleDisconnectModal(): void {
    toggleDisconnectModal();
  }

  function handleCancel(): void {
    if (showDisconnectModal) {
      toggleDisconnectModal();
    }
  }

  async function handleDisconnect(): Promise<void> {
    try {
      await integration.disconnectIntegration();
      toast(t('Your iMIS integration has been successfully disconnected'), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(
        t('Something went wrong while trying to disconnect your iMIS integration: {{error}}', {
          error,
        }),
        {
          type: ToastType.ERROR,
        },
      );
    } finally {
      toggleDisconnectModal();
      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    }
  }

  return (
    <>
      <Button
        key={'disconnect-imis-integration'}
        name={'disconnect-imis-integration'}
        onClick={handleDisconnectModal}
        prefix={<FontAwesomeIcon icon={faCloudSlash} />}
      >
        {t('Disconnect')}
      </Button>
      {showDisconnectModal && (
        <ConfirmModal
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Disconnect')}
          message={t('Are you sure you want to disconnect your integration?')}
          onClose={handleCancel}
          onConfirm={handleDisconnect}
          t={t}
          title={t('Disconnect your iMIS integration?')}
          type={EAlertV2Type.danger}
        >
          <p>{t('Data will stop syncing and all of your integration settings will be lost.')}</p>
        </ConfirmModal>
      )}
    </>
  );
}

export default observer(ImisDisconnectControl);
