import type { IObservableArray } from 'mobx';
import React from 'react';

import type { Campaign, Goal } from '@feathr/blackbox';

import CampaignReportSection from '../../CampaignReportSection';
import ReferralCampaignPage from '../ReferralCampaignPage';

interface IProps {
  campaign: Campaign;
  goals?: IObservableArray<Goal>;
}

function DefaultReferralCampaignPage({ campaign, goals }: IProps) {
  return (
    <ReferralCampaignPage title={'Report'}>
      <CampaignReportSection campaign={campaign} goals={goals} />
    </ReferralCampaignPage>
  );
}

export default DefaultReferralCampaignPage;
