import { faHistory } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import { FieldCollection } from '@feathr/blackbox';
import {
  ArchiveModal,
  Button,
  EAlertV2Type,
  Fieldset,
  Skeleton,
  Spinner,
  toast,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import BreadcrumbsTimeline from '@feathr/extender/components/BreadcrumbsTimeline';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useRedirect, useToggle } from '@feathr/hooks';
import { useInfiniteList } from '@feathr/rachis';

import ConversionDetails from './ConversionDetails';

import * as styles from './CampaignConversionPage.css';

function CampaignConversionPage(): JSX.Element {
  const { Breadcrumbs, Campaigns, Conversions, Persons } = useContext(StoresContext);
  const { campaignId, conversionId } = useParams<{
    campaignId: string;
    conversionId: string;
    eventId: string;
  }>();
  const [infiniteBreadcrumbsList] = useInfiniteList(Breadcrumbs);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);
  const campaign = Campaigns.get(campaignId);
  const localUrl = useLocalUrl();
  const { t } = useTranslation();
  const [redirect] = useRedirect();

  const conversion = Conversions.get(conversionId);
  const person = !conversion.isPending ? Persons.get(conversion.get('person')) : undefined;
  const breadcrumbs = !conversion.isPending
    ? infiniteBreadcrumbsList({
        predicates: [
          {
            kind: 'activity',
            comparison: 'eq',
            attr_against: 'f_id',
            attr_type: 'string',
            value: conversion.get('cookie'),
          },
          {
            kind: 'activity',
            comparison: 'lte',
            attr_against: 'd_c',
            attr_type: 'date',
            value: conversion.get('d_c'),
          },
        ],
        mode: 'match_all',
        lookback_mode: 'absolute',
        lookback_value: conversion.get('date_enter_funnel'),
        ordering: ['-d_c'],
        pagination: {
          page: 0,
          page_size: 10,
        },
      })
    : Breadcrumbs.newListResponse();

  async function handleDeleteConversion() {
    await conversion.delete();
    toast(t('Conversion successfully deleted.'), { type: ToastType.SUCCESS });
    /*
     * Takes user back to the report they came from (project, flight, campaign).
     * Uses campaign URL as fallback.
     */
    redirect(localUrl(campaign.getItemUrl()));
  }

  return (
    <Page
      actions={
        <Button onClick={toggleDeleteModalOpen} type={'danger'}>
          {t('Delete conversion')}
        </Button>
      }
      title={
        <>
          <Trans t={t}>
            Conversion for {{ person: !person || person.isPending ? undefined : person.name }}
            <Skeleton className={styles.placeholder} disabled={!person?.isPending} width={220} />
          </Trans>
        </>
      }
    >
      <div className={styles.columns}>
        <div>
          <ConversionDetails conversion={conversion} />
        </div>
        <Fieldset
          label={
            <>
              <FontAwesomeIcon icon={faHistory} />
              &nbsp;{t('Activity')}
            </>
          }
        >
          <InfiniteScroll
            className={styles.infinite}
            dataLength={breadcrumbs.models.length}
            hasMore={breadcrumbs.pagination.page < breadcrumbs.pagination.pages - 1}
            loader={<Spinner />}
            next={() => {
              breadcrumbs.fetchMore();
            }}
            scrollableTarget={'legacyScrollElement'}
          >
            <BreadcrumbsTimeline
              breadcrumbs={breadcrumbs.models}
              context={FieldCollection.Person}
              hasViewRawData={true}
              isLoading={conversion.isPending || breadcrumbs.isPending}
            />
          </InfiniteScroll>
        </Fieldset>
      </div>
      {isDeleteModalOpen && (
        <ArchiveModal
          message={
            'Are you sure you want to delete this conversion? Deleted conversions cannot be restored.'
          }
          method={'delete'}
          onClose={toggleDeleteModalOpen}
          onConfirm={handleDeleteConversion}
          t={t}
          title={t('Delete conversion')}
          type={EAlertV2Type.danger}
        />
      )}
    </Page>
  );
}

export default observer(CampaignConversionPage);
