import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { UserRole } from '@feathr/blackbox';
import { SaveButtonValid } from '@feathr/components';
import { flattenError } from '@feathr/hooks';

interface ISaveButtonProps {
  role: UserRole;
  onSave: () => void;
}

function SaveRoleButton({ role, onSave }: ISaveButtonProps): JSX.Element {
  const { t } = useTranslation();

  const validationErrors = role.validate(
    ['name', 'projects.allow_list', 'segments.allow_list'],
    true,
    'grouped',
  ).errors;

  return (
    <SaveButtonValid<UserRole>
      disabled={!role.isDirty}
      errors={flattenError(validationErrors)}
      method={role.isEphemeral ? 'add' : 'patch'}
      model={role}
      onSave={onSave}
      tooltipPlacement={'topLeft'}
    >
      {t('Save')}
    </SaveButtonValid>
  );
}

export default observer(SaveRoleButton);
