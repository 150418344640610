import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import type { ITemplate } from '@feathr/blackbox';
import type { Template } from '@feathr/blackbox';
import {
  ArchiveModal,
  ConfirmModal,
  ContextMenu,
  EAlertV2Type,
  MenuDivider,
  MenuItem,
} from '@feathr/components';
import TemplateSummary from '@feathr/extender/components/TemplateSummary';
import { StoresContext, useLocalUrl, useUser } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import { toastMessage } from '../templateUtils';

interface IProps {
  template: Template;
}

function EventTemplatesGlobalOptions({ template }: IProps): JSX.Element {
  const { Templates } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isCloneModalOpen, toggleCloneModalOpen] = useToggle(false);
  const [isDefaultModalOpen, toggleDefaultModalOpen] = useToggle(false);
  const [isAccountModalOpen, toggleAccountModalOpen] = useToggle(false);
  const { t } = useTranslation();
  const user = useUser();

  const parentKind = template.get('parent_kind');
  const isDefault = !!template.get('default');
  const isReadOnly = !!template.get('read_only');
  const isAccount = !isDefault && parentKind === 'account';
  const isEvent = !isDefault && parentKind === 'event';
  const isCampaign = !isDefault && parentKind === 'campaign';

  const isFeathrSuperuser = !!user?.isSuperuser;

  async function handleClone() {
    const patch = { default: false } as Partial<ITemplate>;

    if (isDefault || isAccount) {
      /*
       * Default and account templates should clone as project templates.
       * If default or account template, do not trust event parent data!
       */
      patch.event_id = eventId;
    } else {
      if (isEvent) {
        // template.event is not set for event-level templates.
        patch.event_id = template.get('parent');
      } else if (isCampaign) {
        patch.event_id = template.get('event');
      }
      if (isCampaign) {
        patch.campaign_id = template.get('parent');
      }
    }

    const updatedTemplate = await template.clone(patch);
    Templates.refreshApiCache();

    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been cloned.'),
      errorMessage: t('An error occurred while trying to clone your template:\n{{error}}', {
        error: updatedTemplate.error?.message || 'Unexpected error',
      }),
    });

    toggleCloneModalOpen();
  }

  async function handleMakeDefault() {
    const patch = {
      default: true,
      parent: undefined,
      parent_kind: undefined,
    } as Partial<ITemplate>;

    const updatedTemplate = await template.patch(patch);
    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been made a default template.'),
      errorMessage: t(
        'An error occurred while trying to make your template a default template:\n{{error}}',
        {
          error: updatedTemplate.error?.message || 'Unexpected error',
        },
      ),
    });
    toggleDefaultModalOpen();
  }

  async function handleMakeAccount() {
    const patch = {
      parent: template.get('account'),
      parent_kind: 'account',
    } as Partial<ITemplate>;

    if (template.get('event')) {
      patch.event = null;
    }

    const updatedTemplate = await template.patch(patch);
    toastMessage({
      updatedTemplate,
      successMessage: t('Your template has been made an account template.'),
      errorMessage: t(
        'An error occurred while trying to make your template and account template:\n{{error}}',
        {
          error: updatedTemplate.error?.message || 'Unexpected error',
        },
      ),
    });
    toggleAccountModalOpen();
  }

  return (
    <>
      <ContextMenu>
        <MenuItem disabled={template.isUpdating} link={localUrl(template.getItemUrl())}>
          {t('View')}
        </MenuItem>
        <MenuItem
          disabled={template.isUpdating || isDefault || isReadOnly}
          link={localUrl(template.getItemUrl('edit'))}
        >
          {t('Edit')}
        </MenuItem>
        <MenuDivider />
        <MenuItem disabled={template.isUpdating} onClick={toggleCloneModalOpen}>
          {t('Clone')}
        </MenuItem>
        {(isEvent || isAccount || isFeathrSuperuser) && (
          <>
            {isEvent && (
              <MenuItem disabled={template.isUpdating} onClick={toggleAccountModalOpen}>
                {t('Make account template')}
              </MenuItem>
            )}
            {!isDefault && isFeathrSuperuser && (
              <MenuItem disabled={template.isUpdating} onClick={toggleDefaultModalOpen}>
                {t('Make default template')}
              </MenuItem>
            )}
          </>
        )}
        <MenuDivider />
        <MenuItem disabled={template.isUpdating || isDefault} onClick={toggleArchiveModalOpen}>
          {t('Archive')}
        </MenuItem>
      </ContextMenu>
      {isCloneModalOpen && (
        <ConfirmModal
          message={t('Are you sure you want to clone this template?')}
          onClose={toggleCloneModalOpen}
          onConfirm={handleClone}
          t={t}
          title={t('Clone')}
        >
          <TemplateSummary template={template} />
        </ConfirmModal>
      )}
      {isDefaultModalOpen && (
        <ConfirmModal
          message={t(
            'Are you sure you want to make this a default template? This will make it available to all accounts!',
          )}
          onClose={toggleDefaultModalOpen}
          onConfirm={handleMakeDefault}
          t={t}
          title={t('Make Default Template')}
          type={EAlertV2Type.warning}
        >
          <TemplateSummary template={template} />
        </ConfirmModal>
      )}
      {isAccountModalOpen && (
        <ConfirmModal
          message={t(
            'Are you sure you want to make this an account template? This will make it available to all projects in your account.',
          )}
          onClose={toggleAccountModalOpen}
          onConfirm={handleMakeAccount}
          t={t}
          title={t('Make Account Template')}
        >
          <TemplateSummary template={template} />
        </ConfirmModal>
      )}
      {isArchiveModalOpen && (
        <ArchiveModal
          errorMessage={t('There was an issue archiving the template. Please try again later.')}
          model={template}
          onClose={toggleArchiveModalOpen}
          successMessage={t('Your template {{name}} has been archived.', { name: template.name })}
          t={t}
        >
          <TemplateSummary template={template} />
        </ArchiveModal>
      )}
    </>
  );
}

export default observer(EventTemplatesGlobalOptions);
