import { ObjectId } from 'bson';
import { computed, makeObservable } from 'mobx';
import type { Moment } from 'moment';
import type { WorkBook } from 'xlsx';
import { utils } from 'xlsx';

import { concatPath, moment } from '@feathr/hooks';
import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { isWretchError } from '@feathr/rachis';
import { Collection, DisplayModel, wretch } from '@feathr/rachis';

import type { CustomField, CustomFields, FieldDataType } from './custom_fields';
import type { IPerson } from './persons';

export type TImporterState = 'draft' | 'pending' | 'complete' | 'failed';

export interface IOverride {
  feathr_attr: string;
  attr_type: string;
  value: string | boolean | number;
}

export interface IPreviewRow extends Record<string, unknown> {
  id: string;
  input: Record<string, string>;
  result?: Partial<IPerson>;
  error?: string;
}

export interface IImporter extends IBaseAttributes {
  column_mappers: IImportColumn[];
  column_names: string[];
  created_by: string;
  data_key: string;
  data: string[];
  event: string;
  overrides?: IOverride[];
  file_name: string;
  kind: string;
  name?: string;
  segment: string;
  state: TImporterState;
  tag_ids: string[];
  errors_key?: string;
}

export interface IImportColumn {
  attr_type?: FieldDataType;
  column_name: string;
  feathr_attr?: string;
  u_key?: string;
}

export class Importer extends DisplayModel<IImporter> {
  public readonly className = 'Importer';

  public constraints: TConstraints<IImporter> = {
    name: {
      presence: {
        allowEmpty: false,
        message: '^File name cannot be blank.',
      },
      length: {
        maximum: 45,
        tooLong: '^Import name must be less than 45 characters in length.',
      },
    },
    data_key: {
      presence: {
        allowEmpty: false,
        message: '^Please upload a file.',
      },
    },
    event: {
      presence: {
        allowEmpty: false,
      },
    },
    column_mappers: {
      presence: {
        allowEmpty: false,
      },
      length: () => {
        return { minimum: 1, message: '^You must have at least one column.' };
      },
      duplicates: {
        maximum: 0,
        message: "^You can't map multiple columns to the same field.",
        properties: ['feathr_attr', 'column_name'],
      },
    },
  };

  constructor(attributes: Partial<IImporter> = {}) {
    super(attributes);

    makeObservable(this);
  }

  public getDefaults(): Partial<IImporter> {
    return {
      column_mappers: [],
      column_names: [],
    };
  }

  public xlsxToJson(workbook: WorkBook): string[][] {
    if (Object.keys(workbook.Sheets).length === 0) {
      return [];
    }
    return utils.sheet_to_json<string[]>(workbook.Sheets[workbook.SheetNames[0]], {
      header: 1,
      blankrows: false,
    });
  }

  public processWorkbook(workbook: WorkBook): void {
    const data = this.xlsxToJson(workbook);
    const sheetName = workbook.SheetNames[0];
    const columns: string[] = data.shift() ?? [];
    this.set({
      name: `${this.get('file_name') || sheetName}`,
      column_names: columns,
    });
    columns.forEach((columnName) => {
      this.get('column_mappers').push({ column_name: columnName });
    });
  }

  public getItemUrl(pathSuffix?: string): string {
    if (this.get('event') && this.get('kind') === 'partner') {
      return concatPath(`/projects/${this.get('event')}/partners/imports/${this.id}`, pathSuffix);
    }
    return concatPath(`/data/imports/${this.id}`, pathSuffix);
  }

  public getUsedCustomFields(customFields: CustomFields): CustomField[] {
    const defaults = [
      'name',
      'first_name',
      'last_name',
      'email',
      'external_id',
      'website',
      'description',
      'logo',
      'companies',
      'occupation',
      'pp_opt_outs.all',
    ];
    return this.get('column_mappers')
      .filter((m: IImportColumn) => !!m.feathr_attr && !defaults.includes(m.feathr_attr))
      .map((m: IImportColumn) => customFields.get(m.feathr_attr!));
  }

  public async preview(columnMappers: IImportColumn[]): Promise<IPreviewRow[]> {
    if (!this.collection) {
      return [];
    }

    const body = JSON.stringify({
      column_mappers: columnMappers,
      column_names: this.get('column_names'),
      data_key: this.get('data_key'),
      file_name: this.get('file_name'),
      name: this.get('name'),
      rows: this.get('rows'),
    });

    const response = await wretch<IPreviewRow[]>(`${this.collection.url()}${this.id}/preview`, {
      method: 'POST',
      body,
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data as IPreviewRow[];
  }

  public getEphemeralCustomFields(customFields: CustomFields): CustomField[] {
    return this.getUsedCustomFields(customFields).filter((cf) => cf.isEphemeral);
  }

  @computed
  public get dateCreated(): Moment {
    return moment.utc(new ObjectId(this.id).getTimestamp());
  }

  @computed
  public get downloadUrl(): string {
    if (this.collection) {
      return `${this.collection.url()}${this.id}/download`;
    }
    return '';
  }

  @computed
  public get downloadErrorUrl(): string {
    if (this.collection) {
      return `${this.collection.url()}${this.id}/download?file-type=error`;
    }
    return '';
  }

  @computed
  public get name(): string {
    return this.get('name', '').trim() || 'Unnamed Importer';
  }

  @computed
  public get completedColumnMappers(): IImportColumn[] {
    return this.get('column_mappers')
      .filter((m: IImportColumn) => m.feathr_attr)
      .filter((m: IImportColumn) => m?.u_key !== '')
      .sort();
  }

  @computed
  public get isOptOut(): boolean {
    return this.get('overrides', []).length > 0;
  }
}

export class Importers extends Collection<Importer> {
  public getClassName(): string {
    return 'importers';
  }

  public getModel(attributes: Partial<IImporter>): Importer {
    return new Importer(attributes);
  }
}
