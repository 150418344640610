import moment from 'moment';
import type { JSX } from 'react';
import React from 'react';

import type { IDailyStats } from '@feathr/blackbox';
import type { IAreaChartProps } from '@feathr/components';
import { AreaChart } from '@feathr/components';
import { getNestedValue, TimeFormat } from '@feathr/hooks';

type TAudienceData = IDailyStats & { date: string };

interface IProps extends IAreaChartProps<IDailyStats> {
  data: IDailyStats[];
  /**
   * Whether or not to format the date. If true, date will be formatted MMMM YYYY, e.g. `January 2020`. Defaults to `false`.
   * If `true`, the data must have a `metadata.date` property.
   */
  isFormatDate?: boolean;
}

function AudienceAreaChart({
  data,
  isFormatDate = true,
  xAxisKey,
  yAxisKey,
  ...props
}: Readonly<IProps>): JSX.Element {
  const determinedXAxisKey = isFormatDate ? 'date' : xAxisKey;

  // Format `metadata.date` to MMMM YYYY and add it to the data object as `date: 'June 2024'`
  function formatDate(data: IProps['data']): TAudienceData[] {
    if (!data) {
      return [];
    }
    return data.map((stat) => {
      const originalStat = stat;
      const formattedDate = moment
        .utc(originalStat.metadata.date, moment.ISO_8601)
        .format(TimeFormat.monthYear);
      return {
        // Spread the original stat object
        ...originalStat,
        // Override or add the date property with the formatted date
        date: formattedDate,
      };
    });
  }

  // Get the unique ticks for the X-Axis
  function getTicks(formattedStats?: TAudienceData[]): string[] {
    if (!formattedStats || formattedStats.length <= 0) {
      return [];
    }

    return [...new Set(formattedStats.map(({ date }) => date))];
  }

  function getChartAreaTitle(stats: IDailyStats[], attribute: string): string | undefined {
    if (!stats || stats.length <= 0) {
      return undefined;
    }
    if (stats.length > 0) {
      const lastStat = stats.slice(-1)[0];
      return getNestedValue(lastStat, attribute.split('.'));
    }
    return undefined;
  }

  if (isFormatDate) {
    data = formatDate(data);
  }

  const chartAreaTitle = getChartAreaTitle(data, yAxisKey);
  const ticks = isFormatDate ? getTicks(data as TAudienceData[]) : undefined;

  return (
    <AreaChart<IDailyStats>
      {...props}
      data={data}
      ticks={ticks}
      title={chartAreaTitle}
      xAxisKey={determinedXAxisKey}
      yAxisKey={yAxisKey}
    />
  );
}

export default AudienceAreaChart;
