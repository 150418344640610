import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { BlackbaudRaisersEdgeIntegration } from '@feathr/blackbox';
import { Toolbar } from '@feathr/components';

import RaisersEdgeControlButton from './RaisersEdgeControlButton';

import * as styles from './RaisersEdgeIntegrationControls.css';

interface IRaisersEdgeIntegrationsControls {
  integration?: BlackbaudRaisersEdgeIntegration;
}

function RaisersEdgeIntegrationControls({
  integration,
}: IRaisersEdgeIntegrationsControls): JSX.Element | null {
  if (!integration || integration.isDisconnected) {
    // If there is no integration, we don't need to show the controls
    return null;
  }

  return (
    <Toolbar align={'right'} className={styles.toolbar}>
      <RaisersEdgeControlButton integration={integration} type={'disconnect'} />
      {integration.isConnected && (
        <>
          {/* Show pause control */}
          <RaisersEdgeControlButton integration={integration} type={'pause'} />
        </>
      )}
      {integration.isPaused && (
        <>
          {/* Show resume control */}
          <RaisersEdgeControlButton integration={integration} type={'resume'} />
        </>
      )}
    </Toolbar>
  );
}

export default observer(RaisersEdgeIntegrationControls);
