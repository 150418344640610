import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { PinpointEmailBaseCampaign, Template } from '@feathr/blackbox';
import { TemplateClass } from '@feathr/blackbox';
import { Form, Spinner } from '@feathr/components';
import TemplateEditor from '@feathr/extender/App/TemplatePage/TemplateEditor';
import BannerTemplateEditor from '@feathr/extender/App/TemplatePage/TemplateEditor/BannerTemplateEditor';
import { TemplateSelectTable } from '@feathr/extender/components/TemplateSelect';
import { StoresContext, useUser } from '@feathr/extender/state';
import type { TValidateGrouped } from '@feathr/rachis';

import NextStepButton from './NextStepButton';

import * as styles from './PinpointEmailCampaignStepDesign.css';

export interface IPinpointEmailCampaignStepDesignBaseProps {
  campaign: PinpointEmailBaseCampaign;
  onNext: () => void;
  template?: Template;
}

interface IPinpointEmailCampaignStepDesignProps extends IPinpointEmailCampaignStepDesignBaseProps {
  disabled: boolean;
}

interface IErrors extends TValidateGrouped {
  template?: string[];
}

export function validateStepDesign(
  campaign: PinpointEmailBaseCampaign,
  template?: Template,
): IErrors {
  if (!template) {
    return { isSet: ['A template needs to be selected.'] };
  }

  const errors: IErrors = {};
  if (template.isEphemeral) {
    errors.isEphemeral = ['The template needs to be saved.'];
  }
  if (template.isDirty) {
    errors.isDirty = ['The template has unsaved changes and needs to be saved.'];
  }

  return errors;
}

function PinpointEmailCampaignStepDesign({
  campaign,
  onNext,
  template,
}: IPinpointEmailCampaignStepDesignProps): JSX.Element {
  const { Templates } = useContext(StoresContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  useUser();

  async function handleTemplateChange(templateId?: string): Promise<void> {
    if (!templateId) {
      return;
    }
    setLoading(true);

    await campaign.patchDirty();
    const clonedTemplate = await Templates.clone(templateId, {
      campaign_id: campaign.id,
      event_id: campaign.get('event')!,
      _cls: TemplateClass.PinpointEmail,
    });
    clonedTemplate.set({ name: campaign.get('name') });

    await clonedTemplate.patchDirty();
    campaign.addTemplate(clonedTemplate);

    setLoading(false);
  }

  const isBannersnackBannerTemplate =
    template &&
    [TemplateClass.Banner, TemplateClass.ReferralBanner].includes(template.get('_cls')) &&
    template.get('bannersnack_enabled');
  const EditorComponent = isBannersnackBannerTemplate ? BannerTemplateEditor : TemplateEditor;

  return (
    <Form
      actions={[
        <NextStepButton campaign={campaign} key={'next'} onNext={onNext} template={template} />,
      ]}
      className={classNames(styles.form, { [styles.withEditor]: template })}
      label={t('Edit Campaign: Design')}
    >
      {!template && !loading && (
        <TemplateSelectTable
          label={t('Pick your template')}
          onChange={handleTemplateChange}
          templateClass={TemplateClass.PinpointEmail}
          value={campaign.get('template_id')}
        />
      )}
      {!template && loading && (
        <div className={styles.loading}>
          <Spinner size={20} /> {t('Creating your email...')}
        </div>
      )}
      {!!template && (
        <EditorComponent
          className={styles.editor}
          disableSave={campaign.readOnly}
          disableSaveMessage={t(
            'This template cannot be edited because it has already been published.',
          )}
          isChangeable={!campaign.readOnly}
          isReadOnly={campaign.readOnly}
          template={template}
        />
      )}
    </Form>
  );
}

export default observer(PinpointEmailCampaignStepDesign);
