import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Flight } from '@feathr/blackbox';
import { FormElement, Modal, toast } from '@feathr/components';

import LegSelect from './LegSelect';

interface IProps {
  flight: Flight;
  legId: number;
  onClose: () => void;
}

function LegModalMoveCampaigns({ flight, legId, onClose }: IProps) {
  const [selectedLegId, setLegId] = useState<number | undefined>();
  const { t } = useTranslation();

  const legs = flight.get('legs');

  async function moveCampaigns(newLegId: number) {
    legs[newLegId].campaigns = [
      // Enforce unique campaignIds.
      ...new Set([...legs[newLegId].campaigns, ...legs[legId].campaigns]),
    ];
    legs[legId].campaigns = [];
    await flight.patch({
      legs,
    });
    onClose();

    toast(
      t('Successfully moved all campaigns from {{legName}} to leg {{newLegName}}.', {
        legName: legs[legId].name,
        newLegName: legs[newLegId].name,
      }),
      {
        type: 'success',
      },
    );
  }

  const helpText = (
    <Trans t={t}>
      Select a leg to move all the campaigns in leg <em>{legs[legId].name}</em> to.
    </Trans>
  );

  return (
    <Modal
      confirmDisabled={selectedLegId === undefined}
      controlled={true}
      onClose={onClose}
      onConfirm={() => moveCampaigns(selectedLegId!)}
      size={'sm'}
      t={t}
      title={t('Move Campaigns')}
    >
      <FormElement helpText={helpText}>
        <LegSelect
          exclude={[legId]}
          flightId={flight.id}
          onChange={setLegId}
          onClear={() => setLegId(undefined)}
          value={selectedLegId}
        />
      </FormElement>
    </Modal>
  );
}

export default observer(LegModalMoveCampaigns);
