import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Billable } from '@feathr/blackbox';
import { CardV2 as Card } from '@feathr/components';
import BillableCard from '@feathr/extender/components/BillableCard';

import BillableDefaultMissing from './BillableDefaultMissing';

function BillableDefault(): JSX.Element {
  const { t } = useTranslation();

  // This is a placeholder for the actual logic to determine if a default billing option exists
  const hasBillable = false;

  // Set up a fake billable object to pass to the BillableCard component
  const billable = new Billable({
    id: 'fake-id',
    name: 'John Smith',
    email: 'john.smith@emailaddress.com',
    stripe: {
      id: 'fake-stripe-id',
      token: 'fake-stripe-token',
      source: {
        id: 'fake-source',
        brand: 'Visa',
        exp_month: 12,
        exp_year: 2028,
        last4: '6834',
        status: 'chargeable',
      },
    },
  });

  return (
    <Card theme={'selected'} width={'wide'}>
      <Card.Header
        description={t(
          'This will be the payment method for your Feathr license and ad campaign media costs. Ad campaigns in projects with other billing methods will be charged to those alternatives instead of the default.',
        )}
        title={t('Default Billing Configuration')}
      />
      <Card.Content>
        {!hasBillable ? <BillableDefaultMissing /> : <BillableCard billable={billable} />}
      </Card.Content>
    </Card>
  );
}

export default BillableDefault;
