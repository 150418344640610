import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdvertisersPage from '@feathr/extender/App/Settings/Account/AdvertisersPage';
import AdvertiserPage from '@feathr/extender/App/Settings/Account/AdvertisersPage/AdvertiserPage';
import AllowListPage from '@feathr/extender/App/Settings/Account/AllowListPage';
import ConversionPage from '@feathr/extender/App/Settings/Account/ConversionsPage';
import DomainsPage from '@feathr/extender/App/Settings/Account/DomainsPage';
import EmailsPage from '@feathr/extender/App/Settings/Account/EmailsPage';
import EmailEditPage from '@feathr/extender/App/Settings/Account/EmailsPage/EmailEditPage';
import FontsPage from '@feathr/extender/App/Settings/Account/FontsPage';
import FontEditPage from '@feathr/extender/App/Settings/Account/FontsPage/FontEditPage';
import GoogleAdsCustomersPage from '@feathr/extender/App/Settings/Account/GoogleAdsCustomersPage';
import ImisIntegrationPage from '@feathr/extender/App/Settings/Account/ImisIntegrationPage';
import IPFilteringPage from '@feathr/extender/App/Settings/Account/IPFilteringPage';
import AccountProfilePage from '@feathr/extender/App/Settings/Account/ProfilePage';
import RaisersEdgeIntegrationPage from '@feathr/extender/App/Settings/Account/RaisersEdgeIntegrationPage';
import UsersAndRolesPage from '@feathr/extender/App/Settings/Account/UsersAndRolesPage';
import EditRolePage from '@feathr/extender/App/Settings/Account/UsersAndRolesPage/Roles/EditRolePage/EditRolePage';
import BillablePage from '@feathr/extender/App/Settings/Billing/BillablePage';
import BillableAddPage from '@feathr/extender/App/Settings/Billing/BillablePage/BillableAddPage';
import BillableEditPage from '@feathr/extender/App/Settings/Billing/BillablePage/BillableEditPage';
import InvoicesPage from '@feathr/extender/App/Settings/Billing/InvoicesPage';
import LicensePage from '@feathr/extender/App/Settings/Billing/LicensePage';
import LicenseEditPage from '@feathr/extender/App/Settings/Billing/LicensePage/LicenseEditPage';
import NotificationManagementPage from '@feathr/extender/App/Settings/User/NotificationManagementPage';
import UserProfilePage from '@feathr/extender/App/Settings/User/ProfilePage';
import ResetPasswordPage from '@feathr/extender/App/Settings/User/ResetPasswordPage';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { useAccount, useFlags, useRole } from '@feathr/extender/state';

import APIKeyEditPage from '../Settings/Account/APIKeysPage/APIKeyEditPage';
import APIKeysPage from '../Settings/Account/APIKeysPage/APIKeysPage';
import DomainDetailPage from '../Settings/Account/DomainDetailPage/DomainDetailPage';
import DomainEditPage from '../Settings/Account/DomainsPage/DomainEditPage';
import FacebookIntegrationPage from '../Settings/Account/FacebookIntegrationPage';
import IntegrationsDashboard from '../Settings/Account/IntegrationsDashboardPage/IntegrationsDashboardPage';
import SubscriptionsPage from '../Settings/Account/SubscriptionsPage';
import ProResourcesPage from '../Settings/ProResourcesPage';
import RedirectTo404 from './RedirectTo404';

function SettingsRoutes(): JSX.Element {
  const {
    hasBilling,
    hasConversions,
    hasDomains,
    hasIntegrations,
    isAdmin: isAdminUser,
    isGlobal,
    isSudoer,
    hasPermissionsLicenseOrFlag,
  } = useRole();
  const account = useAccount();
  const showAPIKeys = account.isFalcon;
  const { hasImis, hasBlackbaudRaisersEdge } = useFlags();
  const { isPro, isPlatformAdvanced } = account;

  const showImis = isPlatformAdvanced || hasImis;
  const showBlackbaudRaisersEdge = isPlatformAdvanced || hasBlackbaudRaisersEdge;
  const showGoogleAds = useGoogleAdsPermissions({ requireConnectedCustomer: false });

  const isAdmin = isSudoer || isAdminUser;
  const isAgencyOrAdmin = account.get('is_agency') || isSudoer;

  const activePackage = account.activePackage;
  if (!activePackage && !isSudoer) {
    return (
      <Switch>
        {hasBilling && (
          <Route exact={true} path={'/:accountId/settings/billing/configurations'}>
            <BillablePage />
          </Route>
        )}
        {hasBilling && (
          <Route exact={true} path={'/:accountId/settings/billing/configurations/add'}>
            <BillableAddPage />
          </Route>
        )}
        {hasBilling && (
          <Route exact={true} path={'/:accountId/settings/billing/configurations/:billingId/edit'}>
            <BillableEditPage />
          </Route>
        )}
        {hasBilling && (
          <Route exact={true} path={'/:accountId/settings/billing/invoices'}>
            <InvoicesPage />
          </Route>
        )}
        {hasBilling && (
          <Route exact={true} path={'/:accountId/settings/billing/license'}>
            <LicensePage />
          </Route>
        )}
        {hasBilling && (
          <Route exact={true} path={'/:accountId/settings/billing/license/edit'}>
            <LicenseEditPage />
          </Route>
        )}
      </Switch>
    );
  }

  return (
    <Switch>
      <Redirect
        exact={true}
        from={'/:accountId/settings'}
        to={'/:accountId/settings/user/profile'}
      />
      <Redirect
        exact={true}
        from={'/:accountId/settings/user'}
        to={'/:accountId/settings/user/profile'}
      />
      {isSudoer ? (
        <Redirect
          exact={true}
          from={'/:accountId/settings/account'}
          to={'/:accountId/settings/account/profile'}
        />
      ) : isAdmin ? (
        <Redirect
          exact={true}
          from={'/:accountId/settings/account'}
          to={'/:accountId/settings/account/users'}
        />
      ) : (
        <Redirect
          exact={true}
          from={'/:accountId/settings/account'}
          to={'/:accountId/settings/account/emails'}
        />
      )}
      <Redirect
        exact={true}
        from={'/:accountId/settings/billing'}
        to={'/:accountId/settings/billing/configurations'}
      />
      <Redirect
        exact={true}
        from={'/:accountId/settings/account/billing/:billingId/edit'}
        to={'/:accountId/settings/billing/configurations/:billingId/edit'}
      />

      {isAgencyOrAdmin && (
        <Route exact={true} path={'/:accountId/settings/account/advertisers'}>
          <AdvertisersPage />
        </Route>
      )}
      {isAgencyOrAdmin && (
        <Route exact={true} path={'/:accountId/settings/account/advertisers/:advertiserId(add)'}>
          <AdvertiserPage />
        </Route>
      )}
      {isAgencyOrAdmin && (
        <Route exact={true} path={'/:accountId/settings/account/advertisers/:advertiserId'}>
          <AdvertiserPage />
        </Route>
      )}
      {hasDomains && (
        <Route exact={true} path={'/:accountId/settings/account/allow-list'}>
          <AllowListPage />
        </Route>
      )}
      {hasConversions && (
        <Route exact={true} path={'/:accountId/settings/account/conversions'}>
          <ConversionPage />
        </Route>
      )}
      {hasDomains && (
        <Route exact={true} path={'/:accountId/settings/account/domains'}>
          <DomainsPage />
        </Route>
      )}
      {hasDomains && (
        <Route exact={true} path={'/:accountId/settings/account/domains/:domainId'}>
          <DomainDetailPage />
        </Route>
      )}
      {hasDomains && (
        <Route exact={true} path={'/:accountId/settings/account/domains/:domainId/edit'}>
          <DomainEditPage />
        </Route>
      )}
      <Route exact={true} path={'/:accountId/settings/account/emails'}>
        <EmailsPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/emails/:emailId(add)'}>
        <EmailEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/emails/:emailId/edit'}>
        <EmailEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/fonts'}>
        <FontsPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/fonts/:fontId(add)'}>
        <FontEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/fonts/:fontId/edit'}>
        <FontEditPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/account/subscriptions'}>
        <SubscriptionsPage />
      </Route>
      <Route
        component={IPFilteringPage}
        exact={true}
        path={'/:accountId/settings/account/ip-filtering'}
      />

      {hasBilling && (
        <Route exact={true} path={'/:accountId/settings/billing/configurations'}>
          <BillablePage />
        </Route>
      )}
      {hasBilling && (
        <Route exact={true} path={'/:accountId/settings/billing/configurations/add'}>
          <BillableAddPage />
        </Route>
      )}
      {hasBilling && (
        <Route exact={true} path={'/:accountId/settings/billing/configurations/:billingId/edit'}>
          <BillableEditPage />
        </Route>
      )}
      {hasBilling && (
        <Route exact={true} path={'/:accountId/settings/billing/invoices'}>
          <InvoicesPage />
        </Route>
      )}
      {hasBilling && (
        <Route exact={true} path={'/:accountId/settings/billing/license'}>
          <LicensePage />
        </Route>
      )}
      {hasBilling && (
        <Route exact={true} path={'/:accountId/settings/billing/license/edit'}>
          <LicenseEditPage />
        </Route>
      )}

      <Route exact={true} path={'/:accountId/settings/user/notifications'}>
        <NotificationManagementPage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/user/profile'}>
        <UserProfilePage />
      </Route>
      <Route exact={true} path={'/:accountId/settings/user/reset-password'}>
        <ResetPasswordPage />
      </Route>

      {hasIntegrations && (
        <Route exact={true} path={'/:accountId/settings/integrations'}>
          <IntegrationsDashboard />
        </Route>
      )}
      {hasIntegrations && (
        <Route exact={true} path={'/:accountId/settings/integrations/meta'}>
          <FacebookIntegrationPage />
        </Route>
      )}
      {hasIntegrations && showGoogleAds && (
        <Route exact={true} path={'/:accountId/settings/integrations/google-ads'}>
          <GoogleAdsCustomersPage />
        </Route>
      )}
      {hasIntegrations && showAPIKeys && (
        <Route exact={true} path={'/:accountId/settings/integrations/api-keys'}>
          <APIKeysPage />
        </Route>
      )}
      {hasIntegrations && showAPIKeys && (
        <Route exact={true} path={'/:accountId/settings/integrations/api-keys/:apiKeyId/add'}>
          <APIKeyEditPage />
        </Route>
      )}
      {hasIntegrations && showImis && (
        <Route exact={true} path={'/:accountId/settings/integrations/imis'}>
          <ImisIntegrationPage />
        </Route>
      )}
      {hasIntegrations && showBlackbaudRaisersEdge && (
        <Route exact={true} path={'/:accountId/settings/integrations/raisers-edge'}>
          <RaisersEdgeIntegrationPage />
        </Route>
      )}

      {isSudoer && (
        <Route path={'/:accountId/settings/account/profile'}>
          <AccountProfilePage />
        </Route>
      )}

      {/* Allow access to all user & roles routes with a license that has permissions access */}
      {(isGlobal || isAdmin) && (
        <Route exact={true} path={'/:accountId/settings/account/users/:tab(roles|authentication)?'}>
          <UsersAndRolesPage />
        </Route>
      )}

      {(isGlobal || isAdmin) && hasPermissionsLicenseOrFlag && (
        <Route
          exact={true}
          path={'/:accountId/settings/account/users/roles/:roleId/:action(create|edit)'}
        >
          <EditRolePage />
        </Route>
      )}

      {(isGlobal || isAdmin) && hasPermissionsLicenseOrFlag && (
        <Route exact={true} path={'/:accountId/settings/account/users/roles/:action(create|edit)'}>
          <EditRolePage />
        </Route>
      )}

      {isPro && (
        <Route exact={true} path={'/:accountId/settings/pro-resources'}>
          <ProResourcesPage />
        </Route>
      )}
      <RedirectTo404 />
    </Switch>
  );
}

export default observer(SettingsRoutes);
