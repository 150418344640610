import classNames from 'classnames';
import type { JSX, ReactElement, ReactNode } from 'react';
import React from 'react';

import type { ITabProps, Tab } from '@feathr/components';
import { ActionBar, Spinner, Tabs } from '@feathr/components';
import GlobalNotifications from '@feathr/extender/components/GlobalNotifications';
import UserErrorBoundary from '@feathr/extender/components/UserErrorBoundary';
import { useActionBar } from '@feathr/extender/state';
import { useDocumentTitle } from '@feathr/hooks';

import Breadcrumbs from './Breadcrumbs';
import PrimaryNav from './PrimaryNav';
import RouteAlert from './RouteAlert';

import * as styles from './Page.css';

export interface IPageProps {
  actions?: ReactNode;
  alerts?: ReactNode;
  children: ReactNode;
  className?: string;
  /** CSS className for main page content */
  contentClassName?: string;
  description?: ReactNode;
  loading?: boolean;
  nav?: ReactNode;
  /** Falls back to title if not set */
  pageTitle?: string;
  prefix?: ReactNode;
  secondaryText?: string | null;
  showHeader?: boolean;
  /**
   * Width of legacy main page content
   * @deprecated Child components should handle width
   */
  width?: 'narrow' | 'wide' | 'full';
  tabs?: Array<ReactElement<ITabProps, typeof Tab>>;
  title?: ReactNode;
}

function Page({
  actions,
  alerts,
  children,
  className,
  contentClassName,
  description,
  loading = false,
  nav,
  pageTitle,
  prefix,
  secondaryText,
  showHeader = true,
  width,
  tabs = [],
  title,
}: Readonly<IPageProps>): JSX.Element {
  const { leftActions, rightActions } = useActionBar();

  const titleSuffix = 'Feathr';
  const titleString = pageTitle ? pageTitle : title && typeof title === 'string' ? title : '';
  useDocumentTitle(titleString ? `${titleString} | ${titleSuffix}` : titleSuffix);

  const alertItems = React.Children.map(alerts as any, (alert: JSX.Element) => {
    // Live site uses type.displayName, jest uses type.name.
    if (alert.type.displayName === 'Alert' || alert.type.name === 'Alert') {
      return React.cloneElement(alert, {
        className: classNames(styles.pageAlerts, alert.props.className),
      });
    }
    return alert;
  });

  const widthClass = width ? styles[width] : undefined;

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.content} id={'legacyScrollElement'}>
        <UserErrorBoundary>
          {showHeader && (
            <header className={styles.mainHeader}>
              <Breadcrumbs className={styles.breadcrumbs} includeCurrentPage={false} />
              {!loading && prefix && <div className={styles.prefix}>{prefix}</div>}
              <div className={styles.titleAndDescription}>
                <div className={styles.titleBar}>
                  <div className={styles.titleWrapper}>
                    {title && <h1 className={styles.title}>{title}</h1>}
                    {secondaryText && <div className={styles.secondaryText}>{secondaryText}</div>}
                  </div>
                  <div className={styles.actions}>{actions}</div>
                </div>
                <RouteAlert className={styles.pageAlerts} />
                {alertItems}
                {!!description && (
                  <div
                    className={classNames(styles.descriptionBar, {
                      [styles.withTabs]: tabs.length > 0,
                    })}
                  >
                    {description}
                  </div>
                )}
              </div>
              {tabs.length > 0 && <Tabs className={styles.tabs}>{tabs}</Tabs>}
            </header>
          )}
          <main className={classNames(styles.main, widthClass, contentClassName)}>
            {width ? (
              loading ? (
                <div>
                  <Spinner className={styles.spinner} />
                </div>
              ) : (
                // Add wrapper div to ensure maximum compatibility for aligning page content
                <div>{children}</div>
              )
            ) : loading ? (
              <Spinner className={styles.spinner} />
            ) : (
              children
            )}
          </main>
        </UserErrorBoundary>
      </div>
      {(leftActions || rightActions) && <ActionBar left={leftActions} right={rightActions} />}
      <PrimaryNav className={styles.sidebar} secondaryNav={nav} />
      <header className={styles.header}>
        <GlobalNotifications />
      </header>
    </div>
  );
}

export default Page;
