import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useParams } from 'react-router';

import type { TAttributionModel } from '@feathr/blackbox';
import ReportWrapper from '@feathr/extender/components/ReportWrapper/ReportWrapper';
import { StoresContext } from '@feathr/extender/state';
import { FlightReport } from '@feathr/report_components';

import FlightPage from '../FlightPage';

interface IConfig {
  attributionModel: TAttributionModel;
  includeActivitySummary: boolean;
  includeAdActivity: boolean;
  includeAdPerformance: boolean;
  includeConversionsTable: boolean;
  includeEmailActivity: boolean;
  includeEmailPerformance: boolean;
  includeFlightStats: boolean;
  includePageActivity: boolean;
  includeROI: boolean;
  includeSpendPerformance: boolean;
}

function FlightReportPage() {
  const { flightId } = useParams<{ eventId: string; flightId: string }>();
  const { Flights } = useContext(StoresContext);
  const flight = Flights.get(flightId);

  const config: IConfig = {
    attributionModel: 'full',
    includeActivitySummary: true,
    includeAdActivity: true,
    includeAdPerformance: true,
    includeConversionsTable: false,
    includeEmailActivity: true,
    includeEmailPerformance: true,
    includeFlightStats: true,
    includePageActivity: true,
    includeROI: false,
    includeSpendPerformance: true,
  };

  return (
    <FlightPage flight={flight} title={'Report'}>
      <ReportWrapper initialConfig={config} model={flight} Report={FlightReport} />
    </FlightPage>
  );
}

export default observer(FlightReportPage);
