import { observer } from 'mobx-react-lite';
import React from 'react';
import { useParams } from 'react-router';

import Legs from '@feathr/extender/components/Legs';
import { StoresContext } from '@feathr/extender/state';

import FlightPage from '../FlightPage';

function FlightDetailPage() {
  const { Flights } = React.useContext(StoresContext);
  const { flightId } = useParams<{ flightId: string; eventId: string }>();

  const flight = Flights.get(flightId);

  return (
    <FlightPage flight={flight} title={'Overview'}>
      <Legs flight={flight} />
    </FlightPage>
  );
}

export default observer(FlightDetailPage);
