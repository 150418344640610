import { ObjectId } from 'bson';
import { set } from 'mobx';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { EUsageMetrics, type Segment } from '@feathr/blackbox';
import { Button, Fieldset, Input, Modal, Table, Toolbar } from '@feathr/components';
import { StoresContext, useUsageAndQuota } from '@feathr/extender/state';

import Page from '../Page';
import ConversionPixelsColumns from './ConversionPixelsColumns';
import ConversionPixelUsage from './ConversionPixelUsageTable';

interface IState {
  newSegment?: Segment;
  showModal: boolean;
}

function DataConversionPixelsPage(): JSX.Element {
  const state = useLocalObservable<IState>(() => ({
    newSegment: undefined,
    showModal: false,
  }));
  const { Goals, Segments } = useContext(StoresContext);
  const { t } = useTranslation();
  const { accountId } = useParams<{ accountId: string }>();
  const { getUsageText } = useUsageAndQuota(accountId, [EUsageMetrics.ConversionPixels]);

  async function addConversionPixel(): Promise<void> {
    const segmentId = new ObjectId().toHexString();
    const newSegment = Segments.create({
      id: segmentId,
      read_only: true,
      lookback_mode: 'unbounded',
      predicates: [
        {
          attr_type: 'string',
          attr_against: 'seg_id',
          comparison: 'eq',
          kind: 'activity',
          value: segmentId,
        },
      ],
      is_conversion_segment: true,
    });
    set(state, { newSegment, showModal: true });
  }

  return (
    <Page
      actions={
        <Toolbar>
          <Button onClick={addConversionPixel} type={'primary'}>
            {t('Add Conversion Pixel')}
          </Button>
        </Toolbar>
      }
      description={
        <Trans t={t}>
          <p>
            Create a conversion pixel for precise conversion tracking, including dynamic amount
            tracking. Share the code and{' '}
            <a href={'https://help.feathr.co/hc/en-us/articles/1500002693961'} target={'_blank'}>
              written guide
            </a>{' '}
            with your developer so that it is placed correctly. Then activate it in by using
            "Advanced" conversion tracking in campaigns. Reuse the same pixel for similar categories
            of conversions like donations, events, registrations, and more.
          </p>
        </Trans>
      }
      secondaryText={getUsageText()}
      title={t('Conversion Pixels')}
    >
      <Table<Segment>
        collection={Segments}
        columns={ConversionPixelsColumns({ Goals, t })}
        filters={{ is_conversion_segment: true, is_archived__ne: true }}
        noDataText={t('No conversion pixels yet.')}
        SubComponent={ConversionPixelUsage}
      />
      {state.showModal && (
        <Modal
          controlled={true}
          onClose={() => set(state, { newSegment: undefined, showModal: false })}
          onConfirm={async () => {
            if (state.newSegment) {
              await Segments.add(state.newSegment);
              Segments.refreshApiCache();
              set(state, { newSegment: undefined, showModal: false });
            }
          }}
          t={t}
          title={t('Add Conversion Pixel')}
        >
          <Fieldset>
            <Input
              attribute={'name'}
              label={t('Conversion Pixel Name')}
              model={state.newSegment!}
              type={'text'}
            />
          </Fieldset>
        </Modal>
      )}
    </Page>
  );
}

export default observer(DataConversionPixelsPage);
