import { observer } from 'mobx-react-lite';
import type { ReactNode } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ITemplate } from '@feathr/blackbox';
import type { TemplateClass } from '@feathr/blackbox';
import { Button, Modal } from '@feathr/components';

import TemplateSelect from '../TemplateSelect';

interface IProps {
  prefix?: ReactNode;
  label?: string;
  onSelect: (template: ITemplate) => void;
  templateClass: TemplateClass;
}

function TemplateSelectModal({ label, prefix, onSelect, templateClass }: IProps) {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [template, setTemplate] = useState<ITemplate | null>(null);
  const { t } = useTranslation();

  function confirmSelection() {
    if (template) {
      onSelect(template);
    }
    setShowModal(false);
  }

  function handleChange(selectedTemplate?: ITemplate) {
    setTemplate(selectedTemplate || null);
  }

  return (
    <>
      <Button onClick={() => setShowModal(true)} prefix={prefix}>
        {label ?? t('Add template')}
      </Button>
      {showModal && (
        <Modal
          confirmButtonText={t('Select this template')}
          controlled={true}
          onClose={() => setShowModal(false)}
          onConfirm={confirmSelection}
          t={t}
          title={label}
        >
          <TemplateSelect
            onChange={handleChange}
            templateClass={templateClass}
            value={template || undefined}
          />
        </Modal>
      )}
    </>
  );
}

export default observer(TemplateSelectModal);
