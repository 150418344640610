import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';

import type { BaseCampaign } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';

const NameColumn: IColumn<BaseCampaign> = {
  id: 'name',
  checkboxLabel: 'Name',
  Header: TableColumnHeader({
    sortType: 'alpha',
    title: 'Name',
  }),
  headerClassName: tableStyles.sort,
  className: tableStyles.cell,
  width: 300,
  Cell({ original }): JSX.Element {
    return (
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          const localUrl = useLocalUrl();

          const state = original.get('state');
          const to =
            state === 'draft'
              ? localUrl(original.getItemUrl('edit'))
              : localUrl(original.getItemUrl());
          return <Link to={to}>{original.name}</Link>;
        }}
      </Observer>
    );
  },
  Footer(): JSX.Element {
    return <strong>Page totals</strong>;
  },
};

export default NameColumn;
