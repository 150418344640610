import { action, computed } from 'mobx';

import { concatPath } from '@feathr/hooks';
import type { IAddOptions, TConstraints, TRachisEmpty } from '@feathr/rachis';
import { Collection, isWretchError, wretch } from '@feathr/rachis';

import type {
  IIntegrationAttributes,
  IIntegrationBaseMapping,
  TIntegrationPersonAttributes,
  TIntegrationSyncRule,
} from './integrations';
import { BaseIntegration } from './integrations';
import { ECollectionClassName } from './model';

export enum EiMISContactFieldMappingKeys {
  address = 'address',
  average_gift_value = 'average_gift_value',
  background_check_valid_until = 'background_check_valid_until',
  birth_date = 'birth_date',
  category = 'category',
  chapter = 'chapter',
  companies = 'companies',
  company_member_type = 'company_member_type',
  consecutive_years_giving = 'consecutive_years_giving',
  contact_rank = 'contact_rank',
  contact_status_code = 'contact_status_code',
  date_added = 'date_added',
  date_created = 'date_created',
  designation = 'designation',
  email = 'email',
  external_id = 'external_id',
  fax = 'fax',
  first_gift_amount = 'first_gift_amount',
  first_gift_appeal = 'first_gift_appeal',
  first_gift_date = 'first_gift_date',
  first_gift_product_code = 'first_gift_product_code',
  first_name = 'first_name',
  functional_title = 'functional_title',
  gender = 'gender',
  highest_gift_amount = 'highest_gift_amount',
  highest_trans_num_processed = 'highest_trans_num_processed',
  home_phone = 'home_phone',
  informal_name = 'informal_name',
  join_date = 'join_date',
  last_gift_amount = 'last_gift_amount',
  last_gift_appeal = 'last_gift_appeal',
  last_gift_date = 'last_gift_date',
  last_gift_product_code = 'last_gift_product_code',
  last_name = 'last_name',
  last_updated = 'last_updated',
  lifetime_gift_value = 'lifetime_gift_value',
  lowest_gift_amount = 'lowest_gift_amount',
  member_status = 'member_status',
  member_status_date = 'member_status_date',
  member_type = 'member_type',
  member_type_change_date = 'member_type_change_date',
  middle_name = 'middle_name',
  mobile_phone = 'mobile_phone',
  name = 'name',
  name_prefix = 'name_prefix',
  name_suffix = 'name_suffix',
  next_last_gift_amount = 'next_last_gift_amount',
  next_last_gift_appeal = 'next_last_gift_appeal',
  next_last_gift_date = 'next_last_gift_date',
  next_last_gift_product_code = 'next_last_gift_product_code',
  number_of_gifts = 'number_of_gifts',
  paid_through = 'paid_through',
  party_id = 'party_id',
  previous_member_type = 'previous_member_type',
  status = 'status',
  title = 'title',
  toll_free = 'toll_free',
  volunteer_age_group = 'volunteer_age_group',
  volunteer_available_beginning = 'volunteer_available_beginning',
  volunteer_background_check_status = 'volunteer_background_check_status',
  volunteer_completed_orientation = 'volunteer_completed_orientation',
  volunteer_emergency_contact = 'volunteer_emergency_contact',
  volunteer_emergency_contact_phone = 'volunteer_emergency_contact_phone',
  volunteer_government_id_number = 'volunteer_government_id_number',
  volunteer_is_volunteer = 'volunteer_is_volunteer',
  volunteer_orientation_valid_until = 'volunteer_orientation_valid_until',
  website = 'website',
  work_phone = 'work_phone',
}

export type TiMISContactMappingKey = keyof typeof EiMISContactFieldMappingKeys;

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IiMISContactMapping extends IIntegrationBaseMapping {
  key: keyof typeof EiMISContactFieldMappingKeys;
  integration: IiMISIntegrationAttributes['id'];
  default_field: TiMISContactMappingKey | null;
  type: 'ImisContactMapping';
}

// email is the only required field for now.
export type TiMISRequiredContactFieldMapping = {
  [key in Extract<EiMISContactFieldMappingKeys, 'email'>]: IiMISContactMapping;
};

export type TiMISOptionalContactFieldMapping = {
  [key in Exclude<EiMISContactFieldMappingKeys, 'email'>]?: IiMISContactMapping;
};

export type TiMISContactFieldMapping = TiMISRequiredContactFieldMapping &
  TiMISOptionalContactFieldMapping;

interface IUpdateContactMapping {
  customField?: string | null;
  defaultField?: TIntegrationPersonAttributes | null;
  syncRule?: TIntegrationSyncRule;
  mapping: IiMISContactMapping['id'];
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IiMISCommunicationPreference extends Record<string, unknown> {
  id: string;
  communication_id: string;
  // The label for the communication preference.
  communication_display: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IiMISServiceAccount extends Record<string, unknown> {
  /** The unique identifier for contacts across any business object in iMIS. */
  readonly contact_key: string;
  /** The email for the iMIS Service Account used to authorize requests to Imis. */
  readonly email: string;
  /** The password for the Feathr Service Account used to authorize requests to Imis. */
  readonly password: string;
  /** The username for the Service Account. Should always be "FEATHRSERVICEACCOUNT". */
  readonly username: string;
  /** The UserId/ Party id for the Service Account. Should always be "imis@feathr.co" */
  readonly user_id: string;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface IiMISIntegrationAttributes extends IIntegrationAttributes {
  communication_preference_id: IiMISCommunicationPreference['communication_id'];
  /*
   * Whether or not to map the customer's global unsubscribe setting from iMIS to Feathr.
   * Defaults to false.
   */
  map_global_unsubscribe: boolean;
  org_site: string;
  service_account: IiMISServiceAccount;
  type: 'ImisIntegration';
}

export type TiMISOrgSite = IiMISIntegrationAttributes['org_site'];

/**
 * This is a pseudo-Collection to be able to use Rachis caching.
 * There should only be one IMIS integration per account.
 */
export class ImisIntegration extends BaseIntegration<IiMISIntegrationAttributes> {
  public readonly className = 'ImisIntegration';
  public constraints: TConstraints<IiMISIntegrationAttributes> = {
    communication_preference_id: {
      presence: {
        allowEmpty: false,
        message: '^Communication Preference is required.',
      },
    },
    org_site: {
      presence: {
        allowEmpty: false,
        message: "^Organization can't be blank.",
      },
      format: {
        // Matches any URL that starts with "https://", does not contain "www." after the protocol, and has any path that does not end in a period or forward-slash.
        pattern: /https:\/\/(?!www\.)[^/.]*[^\s/]+\S*[^/.]/,
        flags: 'i',
        message: '^Please provide a valid iMIS domain, e.g. https://myorganization.imiscloud.com',
      },
    },
    state: {
      presence: {
        allowEmpty: false,
      },
    },
  };

  public getItemUrl(pathSuffix: string | undefined): string {
    return concatPath(`integrations/imis`, pathSuffix);
  }

  public get name(): string {
    return 'imis_integration';
  }

  // TODO: Make this HATEOAS compliant by using BaseIntegration pauseIntegration() method. It will require changes in Anhinga.
  public async pauseIntegration(): Promise<IiMISIntegrationAttributes> {
    const url = `${BLACKBOX_URL}integrations/imis/pause`;
    const response = await wretch<IiMISIntegrationAttributes>(url, {
      method: 'PATCH',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  // TODO: Make this HATEOAS compliant by using BaseIntegration disconnectIntegration() method. It will require changes in Anhinga.
  public async disconnectIntegration(): Promise<IiMISIntegrationAttributes> {
    const url = `${BLACKBOX_URL}integrations/imis/disconnect`;
    const response = await wretch<IiMISIntegrationAttributes>(url, {
      method: 'PATCH',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  // TODO: Make this HATEOAS compliant by using BaseIntegration resumeIntegration() method. It will require changes in Anhinga.
  public async resumeIntegration(): Promise<IiMISIntegrationAttributes> {
    const url = `${BLACKBOX_URL}integrations/imis/connect`;
    const response = await wretch<IiMISIntegrationAttributes>(url, {
      method: 'PATCH',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  // TODO: Make this HATEOAS compliant by using BaseIntegration resumeIntegration() method. It will require changes in Anhinga.
  public async syncIntegration(): Promise<IiMISIntegrationAttributes> {
    const url = `${BLACKBOX_URL}integrations/imis/sync`;
    const response = await wretch<IiMISIntegrationAttributes>(url, {
      method: 'POST',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  /*
   * iMIS Contact Mapping CRUD methods
   * These are a temporary measure. They will not be necessary once the work is done to
   * create an iMISContactMappings collection class in #2651 and #2722.
   */

  // GET
  @action
  public async getContactMappings(): Promise<IiMISContactMapping[]> {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);

    const url = `${this.collection.url()}${this.id}/contact_mappings`;
    const response = await wretch<IiMISContactMapping[]>(url, {
      headers: this.collection!.getHeaders(),
      method: 'GET',
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  private async addContactMapping(key: TiMISContactMappingKey): Promise<IiMISContactMapping> {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);

    const url = `${this.collection.url()}${this.id}/contact_mappings`;
    const response = await wretch<IiMISContactMapping>(url, {
      headers: this.collection!.getHeaders(),
      method: 'POST',
      body: JSON.stringify({ contact_mapping: key }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data as IiMISContactMapping;
  }

  // POST
  @action
  public async addContactMappings(
    mappings: TiMISContactMappingKey[],
  ): Promise<IiMISContactMapping[]> {
    const response = Promise.all(mappings.map((mapping) => this.addContactMapping(mapping)));
    return response;
  }

  // PATCH
  @action
  public async updateContactMapping({
    mapping,
    customField,
    defaultField,
    syncRule,
  }: IUpdateContactMapping): Promise<IiMISIntegrationAttributes> {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);

    const url = `${this.collection.url()}${this.id}/contact_mappings/${mapping}`;
    const response = await wretch<IiMISIntegrationAttributes>(url, {
      method: 'PATCH',
      headers: this.collection!.getHeaders(),
      body: JSON.stringify({
        // When patching a field you can either set the custom_field or the default_field, but not both.
        custom_field: customField ?? null,
        default_field: defaultField ?? null,
        sync_rule: syncRule,
      }),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }

  // DELETE
  @action
  public async deleteContactMapping(id: string): Promise<void> {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);

    const url = `${this.collection.url()}${this.id}/contact_mappings/${id}`;

    const response = await wretch<TRachisEmpty>(url, {
      headers: this.collection!.getHeaders(),
      method: 'DELETE',
    });

    if (isWretchError(response)) {
      throw new Error('Failed to delete contact mapping.');
    }
  }

  /** Download URL for the IQA file */
  @computed
  public get downloadIQAUrl(): string {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);

    // TODO: update this url once the endpoint is implemented
    return `${this.collection.url()}${this.id}/iqa/download`;
  }

  @action
  public async getServiceAccount(): Promise<IiMISServiceAccount> {
    const url = `${this.collection!.url()}${this.id}/service_account`;

    const response = await wretch<IiMISServiceAccount>(url, {
      method: 'GET',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    return response.data;
  }

  @action
  public async validateCredentials(): Promise<TRachisEmpty> {
    const url = `${this.collection!.url()}${this.id}/service_account/validate`;

    const response = await wretch<TRachisEmpty>(url, {
      method: 'GET',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw new Error('Unable to authenticate with iMIS using the expected credentials.');
    }
    return response.data;
  }

  @action
  public async checkForIQAFile(): Promise<TRachisEmpty> {
    // TODO: update this url once the endpoint is implemented
    const url = `${this.collection!.url()}${this.id}/iqa/validate`;

    const response = await wretch<TRachisEmpty>(url, {
      method: 'GET',
      headers: this.collection!.getHeaders(),
    });
    if (isWretchError(response)) {
      throw new Error('Unable to verify that the IQA file is present.');
    }
    return response.data;
  }

  /*
   * Methods to retrieve and validate the iMIS Business Object file.
   * This file must be uploaded to iMIS before the IQA file can be
   * properly generated.
   */

  public get downloadBusinessObjectFileUrl(): string {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);
    return `${this.collection.url()}${this.id}/business_object/download`;
  }

  @action
  public async validateBusinessObjectFile(): Promise<void> {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);

    const url = `${this.collection.url()}${this.id}/business_object/validate`;

    const response = await wretch<TRachisEmpty>(url, {
      method: 'GET',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw new Error('Unable to verify that the business object file is present.');
    }
  }

  @action
  public async getCommunicationPreferenceOptions(): Promise<IiMISCommunicationPreference[]> {
    this.assertCollection(this.collection, ECollectionClassName.ImisIntegrations);

    const url = `${this.collection.url()}${this.id}/communication_preferences`;
    const response = await wretch<IiMISCommunicationPreference[]>(url, {
      method: 'GET',
      headers: this.collection.getHeaders(),
    });

    if (isWretchError(response)) {
      throw response.error;
    }

    return response.data;
  }
}

export class ImisIntegrations extends Collection<ImisIntegration> {
  public getModel(attributes: IiMISIntegrationAttributes): ImisIntegration {
    return new ImisIntegration(attributes);
  }

  public getClassName(): string {
    return 'imis_integration';
  }

  public url(): string {
    return `${this.getHostname()}integrations/imis/`;
  }

  public async add(
    model: ImisIntegration,
    options: Partial<IAddOptions> = {},
  ): Promise<ImisIntegration> {
    const { validate = true, refreshApiCache = true } = options;
    if (validate && !model.isValid()) {
      throw new Error('Model is invalid');
    }
    const url = `${BLACKBOX_URL}integrations/imis/`;

    const response = await wretch<IiMISIntegrationAttributes>(url, {
      headers: this.getHeaders(),
      method: 'POST',
    });

    if (isWretchError(response)) {
      this.processErrorResponse(model, response.error);
      return model;
    }

    if (refreshApiCache) {
      this.refreshApiCache();
    }

    return this.processJSONResponse(response);
  }

  public async finishIntegration(integration: ImisIntegration): Promise<ImisIntegration> {
    const url = `${BLACKBOX_URL}integrations/imis/${integration.id}`;
    const response = await wretch(url, {
      method: 'PATCH',
      body: JSON.stringify({
        state: 'paused',
        communication_preference_id: integration.get('communication_preference_id'),
      }),
      headers: this.getHeaders(),
    });
    if (isWretchError(response)) {
      throw response;
    }
    return this.processJSONResponse(response);
  }
}
