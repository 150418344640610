import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ToastType } from 'react-toastify';

import type { IPackage, TEmailHealth } from '@feathr/blackbox';
import { CAMPAIGN_MAX_BUDGET, CAMPAIGN_MIN_BUDGET } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  ButtonValid,
  EAlertV2Type as AlertType,
  Form,
  Input,
  NumberInput,
  toast,
  Value,
} from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import UserSelect from '@feathr/extender/components/UserSelect';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';
import { flattenError, flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import GracePeriodSelect from '../../GracePeriodSelect';
import EmailHealthSelect from './EmailHealthSelect';

import * as styles from './ProfilePage.css';

interface IErrors extends TValidateGrouped {
  campaigns_max_budget?: string;
  csm?: string[];
  name?: string[];
  logo?: string[];
  email_health?: string[];
}

function ProfilePage(): JSX.Element {
  const { Users } = useContext(StoresContext);
  const account = useAccount();
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const [selectedPackage, setSelectedPackage] = useState<IPackage | undefined>();
  const hasInvalidActivePackage = account.activePackage && !account.hasValidLicensePackageName;
  const campaignsMaxBudget = account.getSetting('campaigns_max_budget');

  function handleEmailHealthChange(value: TEmailHealth = 'healthy'): void {
    account.set({ email_health: value });
  }

  async function handleSave(): Promise<void> {
    try {
      await account.patchDirty();

      if (selectedPackage) {
        await account.updateLicense(selectedPackage);
      }
      toast(t('Your settings were saved.'), { type: ToastType.SUCCESS });
    } catch (error) {
      toast(t('There was an error saving your settings.'), { type: ToastType.ERROR });
    }
  }

  function handleCSMChange(value?: string): void {
    account.set({ csm: value });
  }

  function handleCampaignsMaxBudgetChange(newValue?: number): void {
    if (newValue) {
      account.setSetting('campaigns_max_budget', newValue);
    }
  }

  const errors: IErrors = account.validate<IErrors>(
    ['settings.campaigns_max_budget', 'csm', 'name', 'logo', 'email_health'],
    false,
    'grouped',
  ).errors;

  return (
    <Page title={t('Profile')}>
      <Form
        actions={
          <ButtonValid errors={flattenErrors(errors)} onClick={handleSave}>
            {t('Save')}
          </ButtonValid>
        }
        description={t('Change account profile information, CSM, statuses, etc.')}
        label={t('Account profile')}
      >
        <Input
          attribute={'name'}
          label={t('Name')}
          model={account}
          type={'text'}
          validationError={flattenError(errors.name)}
        />
        {hasInvalidActivePackage && (
          <Alert
            description={t(
              'An invalid license was found on this account. This could cause issues with accessing some features in the app.',
            )}
            title={t('Invalid license')}
            type={AlertType.warning}
          />
        )}
        <Value
          className={styles.license}
          helpPlacement={'bottom'}
          helpText={
            <Link target={'_blank'} to={localUrl('settings/billing/license')}>
              {t('See license details')}
            </Link>
          }
          label={t('License')}
          value={account.activePackage?.name || t('None')}
        />
        <Input
          attribute={'logo'}
          label={t('Logo')}
          model={account}
          type={'text'}
          validationError={flattenError(errors.logo)}
        />
        <UserSelect
          filters={{ role: 'csm', is_archived__ne: true, skip_acl: true }}
          isClearable={true}
          label={t('CSM')}
          onChange={handleCSMChange}
          onClear={handleCSMChange}
          placeholder={t('Select CSM...')}
          queryOptions={{ fetchOptions: { headers: Users.getHeaders(false) } }}
          value={account.get('csm')}
        />
        <EmailHealthSelect
          label={t('Email health status')}
          onChange={handleEmailHealthChange}
          onClear={handleEmailHealthChange}
          value={account.get('email_health')}
        />
        <GracePeriodSelect licensePackage={selectedPackage} onChange={setSelectedPackage} />
        <NumberInput
          className={styles.maxBudget}
          helpText={t('The maximum budget allowed for a campaign.')}
          label={t('Campaigns max budget')}
          max={CAMPAIGN_MAX_BUDGET}
          min={CAMPAIGN_MIN_BUDGET}
          onChange={handleCampaignsMaxBudgetChange}
          validationError={flattenError(errors['settings.campaigns_max_budget'])}
          value={campaignsMaxBudget}
        />
      </Form>
    </Page>
  );
}

export default observer(ProfilePage);
