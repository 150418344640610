import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, IFlavors } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatBox, StatsCard } from '@feathr/components';

interface IProps {
  campaign: Campaign;
}

const ConversationStats = ({ campaign }: IProps): JSX.Element => {
  const { t } = useTranslation();

  const flavors: IFlavors = campaign.get('stats').flavors;
  const convOpened = flavors.conversation_opened || 0;
  const convPopped = flavors.conversation_popped || 0;
  const convResponse = flavors.conversation_response || 0;
  const responseRate = convResponse / convPopped;

  return (
    <StatsCard title={reportModuleLabels.includeActivitySummary}>
      <StatBox
        label={t('Shown')}
        primary={`${convPopped}`}
        tooltip={t(
          'The number of times this conversation campaign opened automatically on a page.',
        )}
      />
      <StatBox
        label={t('Responses')}
        primary={`${convResponse}`}
        secondaryLabel={t('{{responseRate}} response rate', {
          responseRate: numeral(responseRate).format('0.00%'),
        })}
      />
      <StatBox
        label={t('Manually Opened')}
        primary={`${convOpened}`}
        tooltip={t(
          'The number of times this conversation campaign was opened by someone clicking on it',
        )}
      />
    </StatsCard>
  );
};

export default observer(ConversationStats);
