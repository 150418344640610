import type { TFunction } from 'i18next';

import type { ISegment } from '@feathr/blackbox';

export type TPresetId =
  | 'all'
  | 'active'
  | 'new'
  | 'leads'
  | 'ads'
  | 'pages'
  | 'known'
  | 'anonymous';

function getSegmentPresets(t: TFunction): Record<TPresetId, Partial<ISegment>> {
  return {
    all: {
      name: t('All People'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [],
    },
    active: {
      name: t('Active People'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [
        {
          kind: 'attribute',
          attr_against: 'date_last_seen',
          attr_type: 'date',
          comparison: 'gt_r',
          value: 1,
          unit: 'days',
          group: [],
        },
      ],
    },
    new: {
      name: t('New People'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [
        {
          kind: 'attribute',
          attr_against: 'date_created',
          attr_type: 'date',
          comparison: 'gt_r',
          value: 1,
          unit: 'days',
          group: [],
        },
      ],
    },
    leads: {
      name: t('Leads'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [
        {
          kind: 'attribute',
          attr_against: 'name',
          attr_type: 'string',
          comparison: 'exists',
          group: [],
        },
        {
          kind: 'attribute',
          attr_against: 'email',
          attr_type: 'string',
          comparison: 'exists',
          group: [],
        },
      ],
    },
    ads: {
      name: t('Ads'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [
        {
          group: [
            {
              kind: 'activity',
              attr_against: 'cpn_id',
              attr_type: 'string',
              comparison: 'exists',
            },
            {
              kind: 'activity',
              attr_against: 'flvr',
              attr_type: 'string',
              comparison: 'starts_with',
              value: 'ad_',
            },
          ],
        },
      ],
    },
    pages: {
      name: t('Pages'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [
        {
          kind: 'activity',
          attr_against: 'flvr',
          attr_type: 'string',
          comparison: 'starts_with',
          value: 'page_',
        },
      ],
    },
    known: {
      name: t('Known'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [
        {
          kind: 'attribute',
          attr_against: 'email',
          attr_type: 'string',
          comparison: 'exists',
          group: [],
        },
      ],
    },
    anonymous: {
      name: t('Anonymous'),
      mode: 'match_any',
      lookback_mode: 'unbounded',
      lookback_value: 0,
      predicates: [
        {
          kind: 'attribute',
          attr_against: 'email',
          attr_type: 'string',
          comparison: 'nexists',
          group: [],
        },
      ],
    },
  };
}

export default getSegmentPresets;
