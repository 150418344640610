import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast, ToastType } from 'react-toastify';

import type { IPredicate, ISegmentUsage, Segment } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  CardV2 as Card,
  EAlertV2Type,
  Spinner,
} from '@feathr/components';
import SaveAsNewButton from '@feathr/extender/App/DataSegmentsPage/DataSegmentPage/SaveAsNewButton';
import { StoresContext } from '@feathr/extender/state';
import { generateKeys, objectId, removeElementAtIndex, useToggle } from '@feathr/hooks';
import { RerunModal } from '@feathr/report_components';

import GroupModeControls from './GroupModeControls';
import Predicates from './Predicates/Predicates';
import SegmentGauge from './SegmentGauge';

interface IUniquePredicate extends IPredicate {
  key: string;
}

import styles from './EditFilters.css';
interface IProps {
  filterContext?:
    | 'campaign'
    | 'flight'
    | 'segment'
    | 'campaignGoal'
    | 'flightGoal'
    | 'facebookCampaign';
  className?: string;
  hideAlert?: boolean;
  hideGauge?: boolean;
  mode?: 'count' | 'crumbs' | 'reachable' | 'emails';
  segment: Segment;
  disabled?: boolean;
}

function EditFilters({
  className,
  disabled = false,
  filterContext = 'segment',
  hideAlert = false,
  hideGauge = false,
  mode = 'count',
  segment,
}: IProps): JSX.Element {
  const { Conversions } = useContext(StoresContext);
  const { campaignId } = useParams<{ campaignId?: string }>();
  const { t } = useTranslation();
  const [usage, setUsage] = useState<ISegmentUsage>();
  const [isRerunModalOpen, toggleRerunModalOpen] = useToggle(false);
  // Predicates do not have a unique identifier, so we need to add one.
  const [localPredicates, setLocalPredicates] = useState(
    observable.array(generateKeys(segment.get('predicates') || [])),
  );

  const { canEdit, isReadOnly } = segment.permissions;
  const isDisabled = isReadOnly || canEdit === false || disabled;
  const predicates = segment.get('predicates') || observable([]);

  const showNoPermissionsAlert = !hideAlert && !segment.get('parent_segment') && canEdit === false;

  const getUsage = useCallback(async () => {
    const segmentUsage = await segment.getUsage();
    setUsage(segmentUsage);
  }, [segment]);

  useEffect(() => {
    getUsage();
  }, [getUsage]);

  useEffect(() => {
    runInAction(() => {
      setLocalPredicates(observable.array(generateKeys(segment.get('predicates') || [])));
    });
  }, [segment]);

  useEffect(() => {
    if (segment.isEphemeral && predicates.length > 0) {
      runInAction(() => {
        setLocalPredicates(observable.array(generateKeys(segment.get('predicates') || [])));
      });
    }
  }, [predicates]);

  if (!usage) {
    return <Spinner />;
  }

  const affectedGoals = usage.goals.length;
  const hasFacebook = usage.active_campaigns.some((cpn) => cpn._cls === CampaignClass.Facebook);

  const handleAddFilterV2 = action((): void => {
    const newPredicate: IUniquePredicate = {
      /*
       * Use key on predicate to give them a unique identifier
       * and prevent re-renders when indices change.
       */
      key: objectId(),
      kind: 'activity',
      attr_against: 'loc_url',
      attr_type: 'string',
      comparison: 'eq',
      value: '',
      unit: 'days',
      group: [],
    };

    const newPredicates = [...localPredicates, newPredicate];
    setLocalPredicates(observable.array(newPredicates));
    /*
     * We don't want to patch the predicates with keys as it will cause a re render
     * since they are not returned with keys.
     */
    segment.set({
      predicates: newPredicates,
    });
  });

  const handleRemoveFilter = action((index: number): void => {
    const newPredicates = removeElementAtIndex(localPredicates, index);
    setLocalPredicates(observable.array(newPredicates));
    /*
     * We don't want to patch the predicates with keys as it will cause a re render
     * since they are not returned with keys.
     */
    segment.set({ predicates: newPredicates });
  });

  const warnings: string[] = [
    t(
      'Recalculate conversions to see which campaigns, projects, and/or flights use this group as a goal.',
    ),
    t('{{count}} goal uses this group.', {
      count: affectedGoals,
    }),
  ];

  async function handleConfirmRerun(): Promise<void> {
    const params = new URLSearchParams({
      segment: segment.id,
    });
    try {
      await Conversions.rerun(params);
      segment.set({ rerun: true });
    } catch (e) {
      toast(t('Something went wrong when trying to recalculate conversions.'), {
        type: ToastType.ERROR,
      });
      throw e;
    }
  }

  const permissionsAlertDescription = (
    <>
      <SaveAsNewButton group={segment} prefix={''} type={'link'}>
        {t('Save as new')}
      </SaveAsNewButton>{' '}
      {t('in order to add additional filters.')}
    </>
  );

  const noPermissionsAlert = showNoPermissionsAlert && (
    <Alert
      description={permissionsAlertDescription}
      title={t("You don't have permissions to edit this group.")}
      type={EAlertV2Type.info}
    />
  );

  const AddFilterButton = (
    <Button
      disabled={isDisabled}
      name={'add_filter'}
      onClick={handleAddFilterV2}
      prefix={<FontAwesomeIcon icon={faPlus} />}
    >
      {t('Add filter set')}
    </Button>
  );

  return (
    <div className={className}>
      {noPermissionsAlert}
      {!!affectedGoals && !isDisabled && (
        <Alert
          description={warnings}
          title={t(
            'Editing this group can affect the conversions of campaigns and flights that use it.',
          )}
          type={EAlertV2Type.warning}
        >
          <button onClick={toggleRerunModalOpen} type={'button'}>
            {t('Recalculate conversions')}
          </button>
        </Alert>
      )}

      {isRerunModalOpen && (
        <RerunModal
          context={'Group'}
          id={segment.id}
          name={segment.name}
          onClose={toggleRerunModalOpen}
          onConfirm={handleConfirmRerun}
        />
      )}

      {hasFacebook && (
        <Alert
          title={t(
            'Targets being used by active Meta campaigns currently only support the "URL" filter.',
          )}
          type={EAlertV2Type.warning}
        />
      )}

      <Card theme={isDisabled ? 'disabled' : 'default'} width={'full'}>
        <Card.Content padding={'compact'}>
          <GroupModeControls disabled={isDisabled} group={segment} />
        </Card.Content>
        {localPredicates.length > 0 && (
          <Card.Content
            contentClassName={classNames(styles.content, className)}
            padding={'compact'}
          >
            {localPredicates.map((localPredicate, index) => {
              function handleRemove(): void {
                handleRemoveFilter(index);
              }

              return (
                <Predicates
                  disabled={isDisabled}
                  excludeIds={campaignId ? [campaignId] : undefined}
                  key={localPredicate.key}
                  model={segment}
                  onClickRemove={handleRemove}
                  optionsGroup={filterContext}
                  predicate={localPredicate}
                  predicates={localPredicates}
                />
              );
            })}
          </Card.Content>
        )}

        {!isDisabled && <Card.Actions>{AddFilterButton}</Card.Actions>}
      </Card>

      {!hideGauge && <SegmentGauge mode={mode} segment={segment} />}
    </div>
  );
}

export default observer(EditFilters);
