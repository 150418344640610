import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl } from '@feathr/extender/state';

import BillableDefault from './BillableDefault';
import BillableList from './BillableList';

export const BILLABLE_V2 = false;

function BillablePage(): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const actions = (
    <Toolbar>
      <Button
        link={localUrl('/settings/billing/configurations/add')}
        prefix={<FontAwesomeIcon icon={faPlus} />}
        type={'primary'}
      >
        {t('Billing configuration')}
      </Button>
    </Toolbar>
  );

  return (
    <Page actions={actions} title={t('Billing Configurations')}>
      {BILLABLE_V2 && <BillableDefault />}
      <BillableList />
    </Page>
  );
}

export default BillablePage;
