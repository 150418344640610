import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { Segment as Group } from '@feathr/blackbox';
import type { IButtonProps, TModalActionEvent } from '@feathr/components';
import { Button } from '@feathr/components';

import SaveAsNewGroupModal from './SaveAsNewGroupModal';

interface IProps extends IButtonProps {
  group: Group;
}

function SaveAsNewButton({ group, prefix, type, ...props }: Readonly<IProps>): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  function handleOpenModal(): void {
    setIsModalOpen(true);
  }

  function handleCloseModal(event: TModalActionEvent): void {
    setIsModalOpen(false);
    // Prevent event from bubbling up to Popover click listener
    event.stopPropagation();
  }

  return (
    <>
      <Button
        {...props}
        onClick={handleOpenModal}
        prefix={prefix ?? <FontAwesomeIcon icon={faCopy} />}
        type={type ?? 'primary'}
      >
        {t('Save as new')}
      </Button>
      {isModalOpen && <SaveAsNewGroupModal group={group} onClose={handleCloseModal} />}
    </>
  );
}

export default SaveAsNewButton;
