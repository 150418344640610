import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Person } from '@feathr/blackbox';
import { CardV2 as Card, Fieldset, Input, Toggle, Tooltip } from '@feathr/components';
import TagsField from '@feathr/extender/components/TagsField';
import { useToggle } from '@feathr/hooks';

import type { IPersonCompaniesSelectOption } from './PersonCompaniesSelect';
import PersonCompaniesSelect from './PersonCompaniesSelect';

interface IProps {
  readonly person: Person;
}

function GeneralInfo({ person }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [hideInternalTags, toggleHideInternalTags] = useToggle(true);
  const localStore = useLocalObservable(() => ({
    companies: [] as IPersonCompaniesSelectOption[],
    setCompanies(companies: IPersonCompaniesSelectOption[]): void {
      localStore.companies = companies;
    },
  }));

  function handleChangeTags(tags: string[]): void {
    person.set({ tag_ids: tags });
  }

  return (
    <Card>
      <Card.Header title={t('General info')} />
      <Card.Content>
        <Fieldset>
          <Input
            attribute={'external_id'}
            key={'external_id'}
            label={
              <>
                {t('External ID')}{' '}
                <Tooltip
                  title={t(
                    'An External ID is a unique identifier for a person record. This ID will be used to find the person for updates and deduplication.',
                  )}
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              </>
            }
            model={person}
            name={'external-id-input'}
            type={'text'}
          />

          <Input
            attribute={'name'}
            key={'name'}
            label={t('Name')}
            model={person}
            name={'name-input'}
            type={'text'}
          />

          <Input
            attribute={'first_name'}
            key={'first_name'}
            label={t('First name')}
            model={person}
            name={'first-name-input'}
            type={'text'}
          />

          <Input
            attribute={'last_name'}
            key={'last_name'}
            label={t('Last name')}
            model={person}
            name={'last-name-input'}
            type={'text'}
          />
          <Input
            attribute={'email'}
            key={'email'}
            label={t('Email address')}
            model={person}
            name={'email-address-input'}
            type={'text'}
          />
          <Input
            attribute={'phone'}
            key={'phone'}
            label={t('Phone number')}
            model={person}
            name={'phone-number-input'}
            type={'text'}
          />
          <Input
            attribute={'occupation'}
            key={'occupation'}
            label={t('Title')}
            model={person}
            name={'occupation-input'}
            type={'text'}
          />
          <PersonCompaniesSelect
            defaultOptions={localStore.companies}
            person={person}
            setCompanies={localStore.setCompanies}
          />
          <TagsField
            context={'person'}
            hideInternalTags={hideInternalTags}
            label={t('Tags')}
            onChange={handleChangeTags}
            value={person.get('tag_ids', [])}
          />
          <Toggle
            label={t('Hide system generated tags')}
            layout={'well'}
            onChange={toggleHideInternalTags}
            value={hideInternalTags}
          />
        </Fieldset>
      </Card.Content>
    </Card>
  );
}

export default observer(GeneralInfo);
