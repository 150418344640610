import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CardV2 as Card } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import AudienceAreaChart from '@feathr/extender/components/AudienceAreaChart';
import { StoresContext } from '@feathr/extender/state';

import DashboardStats from './DashboardStats';

import * as styles from './DashboardPage.css';

// TODO: remove this temporary data once the API is ready
import temporaryDashboardData from './temporaryDashboardData.json';

function DashboardPage(): JSX.Element {
  const { t } = useTranslation();
  const { Stats } = useContext(StoresContext);

  const todayObject = new Date();
  const today = todayObject.toISOString().split('T')[0];
  const threeMonthsAgo = new Date(todayObject.setMonth(todayObject.getMonth() - 2))
    .toISOString()
    .split('T')[0];

  return (
    // TODO: Replace the title with the user's name
    <Page title={<> 👋 Good morning, Abhay!</>}>
      <Card contentClassName={styles.root} width={'full'}>
        <Card.Header title={t('Audience Engagement')} />
        <Card.Content contentClassName={styles.content} padding={'relaxed'}>
          <DashboardStats />
          <div>
            <Observer>
              {(): JSX.Element => {
                const stats = Stats.list({
                  model: 'account',
                  filters: {
                    metadata__date__lte: today,
                    metadata__date__gte: threeMonthsAgo,
                  },
                });
                const audienceExpansion = stats.models.map((s) => s.toJS());
                // eslint-disable-next-line no-console
                console.log(audienceExpansion);

                return (
                  <AudienceAreaChart
                    data={temporaryDashboardData}
                    height={364}
                    label={t('Persons created')}
                    theme={'purple'}
                    tooltip={t(
                      'The total number of people who have been added to your account through any source.',
                    )}
                    xAxisKey={'metadata.date'}
                    yAxisKey={'num_persons.daily'}
                  />
                );
              }}
            </Observer>
            <Observer>
              {(): JSX.Element => {
                return (
                  <AudienceAreaChart
                    data={temporaryDashboardData}
                    label={t('By pixel')}
                    theme={'emerald'}
                    tooltip={t(
                      'The number of people who have been added to your account through our tracking pixel.',
                    )}
                    xAxisKey={'metadata.date'}
                    yAxisKey={'num_persons_pixel.daily'}
                  />
                );
              }}
            </Observer>
          </div>
        </Card.Content>
      </Card>
    </Page>
  );
}

export default DashboardPage;
