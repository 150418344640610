import { observer } from 'mobx-react-lite';
import numeral from 'numeral';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { AggregatedStat } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatBox, StatsCard } from '@feathr/components';

interface ICardProps {
  aggregatedStats: AggregatedStat;
  reportType: 'Flight' | 'Project';
}

function EmailPerformanceCard({ aggregatedStats, reportType }: ICardProps) {
  const { t } = useTranslation();

  const flavors = aggregatedStats.get('flavors', {});

  const clicks = flavors.pinpoint_tracked_email_click || 0;
  const deliveries = flavors.pinpoint_tracked_email_delivered || 0;
  const deliveryRate = aggregatedStats.get('successful_delivery_rate') || 0;
  const opens = flavors.pinpoint_tracked_email_open || 0;
  const opensPerPerson = aggregatedStats.get('opens_per_person') || 0;
  const sends = flavors.pinpoint_tracked_email_send || 0;
  const suppressions = flavors.pinpoint_tracked_email_suppression || 0;

  function formatNumber(value: number) {
    return numeral(value).format('0,0');
  }

  function formatPercent(value: number, suffix?: string) {
    return numeral(value).format('0.00%') + (suffix ? ' ' + suffix : '');
  }

  return (
    <StatsCard title={reportModuleLabels.includeEmailPerformance}>
      <StatBox
        label={t('Sends')}
        primary={formatNumber(sends - suppressions)}
        tooltip={t('Total sends across all email campaigns in this {{type}}.', {
          type: reportType,
        })}
      />
      <StatBox
        label={t('Deliveries')}
        primary={formatNumber(deliveries)}
        secondary={formatPercent(deliveryRate)}
        secondaryLabel={t('Delivery rate')}
        tooltip={t('Total successful deliveries across all email campaigns in this {{type}}.', {
          type: reportType,
        })}
      />
      <StatBox
        label={t('Opens')}
        primary={formatNumber(opens)}
        secondary={formatNumber(opensPerPerson)}
        secondaryLabel={t('Opens per person')}
        tooltip={t('Total opens across all email campaigns in this {{type}}.', {
          type: reportType,
        })}
      />
      <StatBox
        label={t('Total clicks')}
        primary={formatNumber(clicks)}
        tooltip={t('Total clicks across all email campaigns in this  {{type}}.', {
          type: reportType,
        })}
      />
    </StatsCard>
  );
}

export default observer(EmailPerformanceCard);
