import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Alert, AlertType, Button, Fieldset } from '@feathr/components';
import PartnerSelect from '@feathr/extender/components/PartnerSelect/PartnerSelect';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import * as styles from './AddCampaignModalPartnerSelect.css';

export interface IAddCampaignModalPartnerSelectProps {
  onChange: (newValue?: string) => void;
  eventId?: string;
}

function AddCampaignModalPartnerSelect({
  eventId,
  onChange,
}: IAddCampaignModalPartnerSelectProps): JSX.Element {
  const { Partners } = useContext(StoresContext);
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const [value, setValue] = useState<string | undefined>(undefined);

  const partners = Partners.list({
    filters: {
      _parent: eventId,
      // Rough query to try to only get partners that have valid logo and website
      logo__icontains: 'http',
      website__icontains: 'http',
    },
    only: ['id'],
    pagination: { page_size: 1 },
  });
  const partnersCount = !partners.isPending ? partners.pagination.count : 0;

  function handleChangeValue(id?: string): void {
    setValue(id);
    onChange(id);
  }

  function handleClearValue(): void {
    setValue(undefined);
    onChange(undefined);
  }

  return (
    <>
      {!partners.isPending && partnersCount === 0 && (
        <Alert className={styles.alert} type={AlertType.info}>
          <Trans t={t}>
            No eligible partners in this project.{' '}
            <Button link={localUrl(`/projects/${eventId}/partners`)} type={'link'}>
              Add a partner
            </Button>{' '}
            to create a new monetization campaign.
            <br />
            Partners need a logo and website to be eligible.
          </Trans>
        </Alert>
      )}

      <Fieldset className={styles.partnerSelect}>
        <PartnerSelect
          disabled={!eventId || (!partners.isPending && partnersCount === 0)}
          filters={{
            logo__icontains: 'http',
            website__icontains: 'http',
          }}
          helpPlacement={'bottom'}
          helpText={t(
            'Only partners with a valid logo and website URL can have a monetization campaign.',
          )}
          isLoading={partners.isPending}
          isMulti={false}
          label={t('Partner')}
          onChange={handleChangeValue}
          onClear={handleClearValue}
          placeholder={t('Select partner...')}
          projectIds={eventId ? [eventId] : []}
          value={value}
        />
      </Fieldset>
    </>
  );
}

export default observer(AddCampaignModalPartnerSelect);
