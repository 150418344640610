import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CampaignClass, CampaignState, TCampaignGroup } from '@feathr/blackbox';
import PartnerSelect from '@feathr/extender/components/PartnerSelect';
import ProjectSelect from '@feathr/extender/components/ProjectSelect';
import { useAccount } from '@feathr/extender/state';

import CampaignStateSelect from './CampaignStateSelect';
import CampaignTypeSelect from './CampaignTypeSelect';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-table/react-table.css';

export interface IFiltersFirst {
  types?: Array<CampaignClass | 'monetization'>;
  participants?: string[];
  partner?: string;
  state__in?: CampaignState[];
  event__in?: string[];
}

interface IProps {
  campaignGroup: TCampaignGroup;
  context: 'global' | 'project';
  filters: IFiltersFirst;
  onChange: (filters: IFiltersFirst) => void;
}

function CampaignsFiltersFirst({ campaignGroup, context, filters, onChange }: IProps): JSX.Element {
  const { t } = useTranslation();
  const account = useAccount();

  function setPartner(partner?: string[] | string): void {
    onChange({
      ...filters,
      partner: partner && !Array.isArray(partner) ? partner : undefined,
    });
  }

  function setParticipants(participants?: string[] | string): void {
    onChange({
      ...filters,
      participants: participants && Array.isArray(participants) ? participants : undefined,
    });
  }

  function setProjectIds(projects?: string[]): void {
    onChange({
      ...filters,
      event__in: projects,
    });
  }

  function setStates(states?: CampaignState[]): void {
    onChange({
      ...filters,
      state__in: states,
    });
  }

  function setTypes(types?: Array<CampaignClass | 'monetization'>): void {
    onChange({
      ...filters,
      types,
    });
  }

  const showPartnerSelects =
    account && account.isFalcon && ['all', 'monetization'].includes(campaignGroup);
  const showTypeSelect = campaignGroup !== 'google-ads';
  const showProjectSelect = context === 'global';

  return (
    <>
      {showProjectSelect && (
        <ProjectSelect onChangeMulti={setProjectIds} values={filters.event__in} />
      )}
      <CampaignStateSelect onChange={setStates} value={filters.state__in} />
      {showTypeSelect && (
        <CampaignTypeSelect
          campaignGroup={campaignGroup}
          onChange={setTypes}
          value={filters.types}
        />
      )}
      {showPartnerSelects && (
        <>
          <PartnerSelect
            isMulti={true}
            label={t('Participants')}
            onChange={setParticipants}
            onClear={setParticipants}
            placeholder={t('All participants')}
            projectIds={filters.event__in}
            value={filters.participants ? filters.participants : []}
          />
          <PartnerSelect
            label={t('Partners')}
            onChange={setPartner}
            onClear={setPartner}
            placeholder={t('All partners')}
            projectIds={filters.event__in}
            value={filters.partner}
          />
        </>
      )}
    </>
  );
}

export default observer(CampaignsFiltersFirst);
