import type { IBaseAttributes, TConstraints } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

export interface IFacebookAdAccount extends IBaseAttributes {
  name: string;
  business?: IBusiness;
  funding_source_details?: IFundingSourceDetails;
  tos_accepted?: IToSAccepted;
}

export interface IFundingSourceDetails {
  id: string;
  display_string?: string;
  type: FundingSourceType;
}

export interface IToSAccepted {
  web_custom_audience_tos: boolean;
  custom_audience_tos: boolean;
}

export interface IBusiness {
  id: string;
  name: string;
}

export enum FundingSourceType {
  UNSET = 0,
  CREDIT_CARD,
  FACEBOOK_WALLET,
  FACEBOOK_PAID_CREDIT,
  FACEBOOK_EXTENDED_CREDIT,
  ORDER,
  INVOICE,
  FACEBOOK_TOKEN,
  EXTERNAL_FUNDING,
  FEE,
  FX,
  DISCOUNT,
  PAYPAL_TOKEN,
  PAYPAL_BILLING_AGREEMENT,
  FS_NULL,
  EXTERNAL_DEPOSIT,
  TAX,
  DIRECT_DEBIT,
  DUMMY,
  ALTPAY,
  STORED_BALANCE,
}

export type TFeathrError =
  | 'account_integration_not_found'
  | 'token_expired'
  | 'ad_account_not_found'
  | 'account_integration_not_ready';

export type TFacebookError =
  | 'payment_method_not_found'
  | 'business_tools_terms_not_accepted'
  | 'customer_list_terms_not_accepted'
  | 'ad_account_not_in_business';

export interface IFacebookError {
  message: string;
  link: string;
}

export const feathrFacebookErrorMap: Record<TFeathrError, IFacebookError> = {
  ['account_integration_not_found']: {
    message: 'Facebook is not integrated with this account.',
    link: '/settings/integrations/meta',
  },
  ['token_expired']: {
    message: 'Facebook authorization token is expired.',
    link: '/settings/integrations/meta',
  },
  ['ad_account_not_found']: {
    message: 'Facebook Ad Account is not selected.',
    link: '/settings/integrations/meta',
  },
  ['account_integration_not_ready']: {
    message: 'Facebook account integration is still pending.',
    link: '/settings/integrations/meta',
  },
};

export class FacebookAdAccount extends Model<IFacebookAdAccount> {
  public readonly className = 'FacebookAdAccount';

  public constraints: TConstraints<IFacebookAdAccount> = {
    id: {
      presence: {
        allowEmpty: false,
      },
    },
    name: {
      presence: {
        allowEmpty: false,
      },
    },
  };

  public facebookErrorMap: Record<TFacebookError, IFacebookError> = {
    ['payment_method_not_found']: {
      message: 'Payment method for the selected Facebook Ad Account is missing.',
      link: `https://www.facebook.com/ads/manager/account_settings/account_billing?act=${
        this.get('id').split('act_')[1]
      }`,
    },
    ['business_tools_terms_not_accepted']: {
      message:
        'Facebook Business Tools Terms of service for the selected Ad Account have not been accepted.',
      link: `https://www.facebook.com/customaudiences/app/tos?act=${
        this.get('id').split('act_')[1]
      }`,
    },
    ['customer_list_terms_not_accepted']: {
      message:
        'Facebook Customer List Custom Audiences Terms of service for the selected Ad Account have not been accepted.',
      link: `https://business.facebook.com/ads/manage/customaudiences/tos?act=${
        this.get('id').split('act_')[1]
      }`,
    },
    ['ad_account_not_in_business']: {
      message:
        'Ad account must be added to a business to be able to use email list custom audiences.',
      link: 'https://www.facebook.com/business/help/910137316041095?id=420299598837059',
    },
  };

  public get tosAccepted(): boolean {
    const adAccountTosAccepted = this.get('tos_accepted');
    return !!adAccountTosAccepted && adAccountTosAccepted.web_custom_audience_tos;
  }
}

export class FacebookAdAccounts extends Collection<FacebookAdAccount> {
  public getModel(attributes: Partial<IFacebookAdAccount>): FacebookAdAccount {
    return new FacebookAdAccount(attributes);
  }

  public getClassName(): string {
    return 'facebook_ad_accounts';
  }

  public url(): string {
    return `${this.getHostname()}integrations/facebook/adaccounts`;
  }
}
