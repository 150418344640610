import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { IPinpointTrigger, PinpointEmailBaseCampaign, Segment } from '@feathr/blackbox';
import { CampaignState, transformTriggersToPredicates } from '@feathr/blackbox';
import {
  AlertV2 as Alert,
  Button,
  CardContent,
  CardHeader,
  CardV2 as Card,
  DatePicker,
  EAlertV2Type as AlertType,
  Fieldset,
  Form,
} from '@feathr/components';
import ConsentCapture from '@feathr/extender/components/ConsentCapture';
import { StoresContext, useUser } from '@feathr/extender/state';
import { flattenError, moment, TimeFormat, timezoneAbbr } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import PinpointEmailCampaignAudienceSummary from '../../PinpointEmailCampaignAudienceSummary';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  disabled: boolean;
  onPrev: () => void;
  submitButton: ReactNode;
  setCurrentStep: (index: number) => void;
}

interface IErrors extends TValidateGrouped {
  date_send_start?: string[];
  date_send_end?: string[];
  'consent.has_consent'?: string[];
}

export function validateStepScheduleSend(campaign: PinpointEmailBaseCampaign): IErrors {
  return campaign.validate(
    ['date_send_start', 'date_send_end', 'consent.has_consent'],
    false,
    'grouped',
  ).errors;
}

function AutoPinpointEmailCampaignStepScheduleSend({
  campaign,
  disabled,
  onPrev,
  submitButton,
  setCurrentStep,
}: Readonly<IProps>): JSX.Element {
  const { t } = useTranslation();
  const { Segments } = useContext(StoresContext);

  const user = useUser();
  const stats = campaign.get('total_stats');

  const userTimezone = user.get('timezone');
  const sendStartTimestamp = campaign.get('date_send_start');
  const sendEndTimestamp = campaign.get('date_send_end');
  const bulkSend = campaign.get('send_all');
  const sendStartMoment = moment.utc(sendStartTimestamp).local();
  const sendEndMoment = moment.utc(sendEndTimestamp).local();
  const isoSendStartTimestamp = moment.utc(sendStartTimestamp).format(TimeFormat.isoDateTime);
  const isoSendEndTimestamp = moment.utc(sendEndTimestamp).format(TimeFormat.isoDateTime);
  const triggers = campaign.get('actions');

  const minStartTimeMoment = moment.utc(sendStartTimestamp).startOf('day').isAfter(moment())
    ? moment().startOf('day')
    : moment();
  const minEndTimeMoment = moment.utc(sendEndTimestamp).startOf('day').isAfter(moment())
    ? moment().startOf('day')
    : moment();

  function onChangeSendStart(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_send_start: newTimestamp,
      date_start: newMoment.clone().startOf('day').format(TimeFormat.isoDateTime),
    });
    if (newMoment.isAfter(moment.utc(campaign.get('date_send_end')))) {
      onChangeDateEnd(newMoment.add(1, 'day').format(TimeFormat.isoDateTime));
    }
  }

  function onChangeDateEnd(newTimestamp?: string): void {
    const newMoment = moment.utc(newTimestamp);
    campaign.set({
      date_send_end: newTimestamp,
      /*
       * adding 30 days to the date_end is necessary to correctly aggregate stats
       * in certain attribution models
       */
      date_end: newMoment.clone().add(30, 'days').startOf('day').format(TimeFormat.isoDateTime),
    });
    if (newMoment.isBefore(moment.utc(campaign.get('date_send_start')))) {
      let newStartMoment = newMoment.subtract(1, 'day');
      if (newStartMoment.isBefore()) {
        newStartMoment = moment.utc().startOf('minute');
      }
      onChangeSendStart(newStartMoment.format(TimeFormat.isoDateTime));
    }
  }

  function handleChangeConsent(newValue = false): void {
    if (newValue) {
      campaign.set({
        consent: {
          user: user!.id,
          has_consent: true,
          date_consented: moment.utc().format(TimeFormat.isoDateTime),
        },
      });
    } else {
      campaign.set({
        consent: {
          has_consent: false,
        },
      });
    }
  }

  const validationErrors = validateStepScheduleSend(campaign);

  // Ephemeral segment to get the count of people who will receive the email.
  const [segment] = useState<Segment>(() =>
    Segments.create({
      account: campaign.get('account'),
      is_conversion_segment: false,
      is_custom: false,
      lookback_mode: 'unbounded',
      mode: 'match_all',
      predicates: [
        {
          kind: 'attribute',
          attr_against: 'email',
          attr_type: 'string',
          comparison: 'exists',
        },
        {
          group: transformTriggersToPredicates(campaign.get('actions')),
          group_mode: campaign.get('mode') ?? 'match_any',
          kind: triggers.some((trigger: IPinpointTrigger) => trigger.kind === 'update')
            ? 'attribute'
            : 'activity',
        },
      ],
    }),
  );

  const peopleCount = segment.count;

  const handleSetStep = useCallback(
    (index: number) => {
      return function (): void {
        setCurrentStep(index);
      };
    },
    [setCurrentStep],
  );

  return (
    <Form
      actions={[
        <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
          {t('Previous')}
        </Button>,
        submitButton,
      ]}
      description={t(
        'Choose or create a group of people that will receive this email. Anyone in this group will receive your email at the target send time, set below.',
      )}
      label={t('Edit Campaign: Schedule')}
      width={'wide'}
    >
      <Card width={'full'}>
        <CardHeader
          description={t(
            'People who meet the triggers of this campaign during the specified timeframe will receive your message. The maximum time window is one year.',
          )}
          title={t('Send Window')}
        />
        <CardContent>
          <Fieldset>
            <DatePicker
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={disabled}
              helpPlacement={'bottom'}
              helpText={t(
                'Choose the time that Feathr should start sending emails to contacts who perform the trigger. {{dateTimeUtc}}',
                { dateTimeUtc: sendStartMoment.utc().format(TimeFormat.pickerDateTimeZone) },
              )}
              label={t('Start date/time')}
              maxTime={sendStartMoment.local().endOf('day').toDate()}
              minDate={new Date()}
              minTime={minStartTimeMoment.toDate()}
              name={'date_send_start'}
              onDateStrChange={onChangeSendStart}
              showTimeSelect={true}
              suffix={timezoneAbbr(sendStartMoment.toDate())}
              timeIntervals={5}
              timezone={userTimezone}
              validationError={flattenError(validationErrors.date_send_start)}
              // TODO: Fix pre-existing bug preventing the value from being initialized correctly - see #2766 }
              value={isoSendStartTimestamp}
            />
            <DatePicker
              dateFormat={'MMM d, yyyy h:mm aa'}
              disabled={disabled}
              helpPlacement={'bottom'}
              helpText={t(
                'Choose the time that Feathr should stop sending emails to contacts who perform the trigger. {{dateTimeUtc}}',
                { dateTimeUtc: sendEndMoment.utc().format(TimeFormat.pickerDateTimeZone) },
              )}
              label={t('End date/time')}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
              maxTime={sendEndMoment.local().endOf('day').toDate()}
              minDate={new Date()}
              minTime={minEndTimeMoment.toDate()}
              name={'date_send_end'}
              onDateStrChange={onChangeDateEnd}
              showTimeSelect={true}
              suffix={timezoneAbbr(sendStartMoment.toDate())}
              timeIntervals={5}
              timezone={userTimezone}
              validationError={flattenError(validationErrors.date_send_end)}
              // TODO: Fix pre-existing bug preventing the value from being initialized correctly - see #2766 }
              value={isoSendEndTimestamp}
            />
          </Fieldset>
        </CardContent>
      </Card>
      <Card width={'full'}>
        <CardHeader title={t('Verify & Publish')} />
        <CardContent>
          {bulkSend && peopleCount > 0 && (
            <Alert
              description={t(
                'A separate single send campaign will also be sent alongside this Auto Send Campaign to {{ count, number }} person who has previously met your specified criteria.',
                { count: peopleCount },
              )}
              title={t('Single Send Campaign created')}
              type={AlertType.warning}
            >
              <button name={'change_bulk_send'} onClick={handleSetStep(2)}>
                {t('Change this setting')}
              </button>
            </Alert>
          )}
          <ConsentCapture
            alertDescription={t(
              'I understand that if my campaigns exhibit abnormally high bounce or complaint rates, it may result in my account being placed under temporary review or suspension.',
            )}
            alertTitle={t(
              'I attest that I am sending this email to recipients who have signed up and specifically requested to receive email about this topic from me or my organization.',
            )}
            disabled={disabled}
            label={t(
              'Please type "AGREE" in the box below to verify the above statement (case-sensitive).',
            )}
            onChange={handleChangeConsent}
            value={campaign.get('consent', { has_consent: false }).has_consent}
          />
        </CardContent>
      </Card>
      {!!stats.num_targeted &&
        [CampaignState.Published, CampaignState.Stopped].includes(campaign.get('state')) &&
        campaign.isAfterDateSendStart() && <PinpointEmailCampaignAudienceSummary stats={stats} />}
    </Form>
  );
}

export default observer(AutoPinpointEmailCampaignStepScheduleSend);
