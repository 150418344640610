import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import type { ValueType } from 'react-select';

import type { Billable, IEvent, IEventBilling, ReportModel } from '@feathr/blackbox';
import {
  Button,
  CardV2 as Card,
  Fieldset,
  ImagePicker,
  Input,
  SaveButtonValid,
} from '@feathr/components';
import BillableSelect from '@feathr/extender/components/BillableSelect';
import { StoresContext, useActionBar, useLocalUrl } from '@feathr/extender/state';
import { flattenErrors, logUserEvents } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './EventAdd.css';

function EventAdd(): JSX.Element {
  const { Billables, Events } = useContext(StoresContext);
  const { t } = useTranslation();
  const eventRef = useRef<ReportModel<IEvent> | undefined>(undefined);
  // Initializing this way to avoid re-creating the ref contents - see https://react.dev/reference/react/useRef#avoiding-recreating-the-ref-contents
  if (eventRef.current === undefined) {
    eventRef.current = Events.create();
  }
  const event = eventRef.current;
  const { setRightActions } = useActionBar();
  const history = useHistory();
  const localUrl = useLocalUrl();

  const billing = event.get('billing', {} as IEventBilling);
  const billable = billing.billable_id ? Billables.get(billing.billable_id) : undefined;

  function handleBillingChange(selectedBillable?: ValueType<Billable>): void {
    if (!selectedBillable || Array.isArray(selectedBillable)) {
      return;
    }
    event.set({
      billing: {
        ...billing,
        billable_id: (selectedBillable as Billable).get('id'),
      } as IEventBilling,
    });
  }

  function handleRefIdChange(newValue?: string): void {
    event.set({ billing: { ...billing, reference_id: newValue } as IEventBilling });
    event.setAttributeDirty('billing');
  }

  function handleCancel(): void {
    history.push(localUrl('/projects'));
  }

  function handleSaveEvent(savedEvent: ReportModel<IEvent>): void {
    logUserEvents({ 'Created project': { eventId: savedEvent.id } });
    history.push(localUrl(event.getItemUrl()));
  }

  interface IErrors extends TValidateGrouped {
    name?: string;
  }

  function validateEvent(event: ReportModel<IEvent>): IErrors {
    return event.validate(['name'], false, 'grouped').errors;
  }

  const validationErrors = validateEvent(event);

  useEffect(() => {
    setRightActions([
      <Button key={'cancel'} onClick={handleCancel}>
        {t('Cancel')}
      </Button>,
      <SaveButtonValid
        disabled={!event.isDirty}
        errorMessage={t('Something went wrong while adding your Project. Please try again later.')}
        errors={flattenErrors(validationErrors)}
        key={'save_project'}
        method={'add'}
        model={event}
        onSave={handleSaveEvent}
        successMessage={t('Successfully added new Project.')}
      >
        {t('Save')}
      </SaveButtonValid>,
    ]);
  }, [event.isValid()]);

  const BILLING_LEARN_MORE_LINK =
    'https://help.feathr.co/hc/en-us/articles/360037323074-Feathr-Billing-Basics';
  const billingDescription = (
    <>
      <p>
        {t(
          'Select a billing configuration for this project. You have two options to pay for media for ad campaigns in this project: pay as you go or pre-pay.',
        )}
      </p>
      <a href={BILLING_LEARN_MORE_LINK} rel={'noreferrer'} target={'_blank'}>
        {t('Learn more about billing')}
      </a>
    </>
  );

  return (
    <>
      <Card>
        <Card.Header title={t('Project information')} />
        <Card.Content>
          <Fieldset>
            <Input
              attribute={'name'}
              label={t('Project name')}
              model={event}
              required={true}
              type={'text'}
            />
            <ImagePicker
              align={'left'}
              attribute={'logo'}
              buttonText={t('Upload profile picture')}
              helpText={t(
                'Upload an image to represent this project. This makes it easier to find this project in your projects list and customizes the look of its reports.',
              )}
              imgClassName={styles.image}
              label={t('Project logo')}
              model={event}
              required={false}
              value={event.get('logo')}
            />
          </Fieldset>
        </Card.Content>
      </Card>
      <Card>
        <Card.Header description={billingDescription} title={t('Billing')} />
        <Card.Content>
          <Fieldset>
            <BillableSelect
              label={t('Billing configuration')}
              onChange={handleBillingChange}
              value={billable}
            />
            <Input
              helpText={t(
                'Optional reference identification field (such as a PO number) that will be included on media invoices for this project.',
              )}
              label={'Reference ID'}
              onChange={handleRefIdChange}
              optional={true}
              type={'text'}
              value={billing.reference_id}
            />
          </Fieldset>
        </Card.Content>
      </Card>
    </>
  );
}

export default observer(EventAdd);
