import type { BaseCampaign, TCampaignGroup } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';

import BudgetColumn from './CampaignsColumns/BudgetColumn';
import ClicksColumn from './CampaignsColumns/ClicksColumn';
import CPCColumn from './CampaignsColumns/CPCColumn';
import CPMColumn from './CampaignsColumns/CPMColumn';
import CTRColumn from './CampaignsColumns/CTRColumn';
import DateCreatedColumn from './CampaignsColumns/DateCreatedColumn';
import DateEndColumn from './CampaignsColumns/DateEndColumn';
import DateStartColumn from './CampaignsColumns/DateStartColumn';
import DurationColumn from './CampaignsColumns/DurationColumn';
import EmailCTRColumn from './CampaignsColumns/EmailCTRColumn';
import EmailOpenRateColumn from './CampaignsColumns/EmailOpenRateColumn';
import EmailSendsColumn from './CampaignsColumns/EmailSendsColumn';
import FlightColumn from './CampaignsColumns/FlightColumn';
import HardBounceRateColumn from './CampaignsColumns/HardBounceRateColumn';
import MonetizationROIColumn from './CampaignsColumns/MonetizationROIColumn';
import MonetizationValueColumn from './CampaignsColumns/MonetizationValueColumn';
import NameColumn from './CampaignsColumns/NameColumn';
import OptionsColumn from './CampaignsColumns/OptionsColumn';
import ParticipantsColumn from './CampaignsColumns/ParticipantsColumn';
import PartnerColumn from './CampaignsColumns/PartnerColumn';
import ProgressColumn from './CampaignsColumns/ProgressColumn';
import ProjectColumn from './CampaignsColumns/ProjectColumn';
import ResponsesColumn from './CampaignsColumns/ResponsesColumn';
import SeenColumn from './CampaignsColumns/SeenColumn';
import SpendColumn from './CampaignsColumns/SpendColumn';
import StateColumn from './CampaignsColumns/StateColumn';
import TargetImpressionsColumn from './CampaignsColumns/TargetImpressionsColumn';
import TypeColumn from './CampaignsColumns/TypeColumn';
import ViewsColumn from './CampaignsColumns/ViewsColumn';

const getDefaultColumns = (includeProjectColumn?: boolean): Array<IColumn<BaseCampaign>> => {
  const columns = [
    PartnerColumn,
    NameColumn,
    TypeColumn,
    StateColumn,
    DateCreatedColumn,
    DateStartColumn,
    DateEndColumn,
    DurationColumn,
    SpendColumn,
    MonetizationValueColumn,
    MonetizationROIColumn,
    CPMColumn,
    CPCColumn,
    CTRColumn,
    { id: 'cpa', checkboxLabel: 'CPA', Header: 'CPA' },
    BudgetColumn,
    TargetImpressionsColumn,
    ProgressColumn,
    SeenColumn,
    ResponsesColumn,
    EmailSendsColumn,
    ViewsColumn,
    EmailOpenRateColumn,
    ClicksColumn,
    EmailCTRColumn,
    { id: 'conversions', checkboxLabel: 'Conversions', Header: 'Conversions' },
    { id: 'roi', checkboxLabel: 'Conversion Value', Header: 'Conversion Value' },
    ParticipantsColumn,
    HardBounceRateColumn,
    FlightColumn,
    OptionsColumn,
  ];

  if (includeProjectColumn) {
    // We want to insert the project column after the name column.
    columns.splice(2, 0, ProjectColumn);
  }

  return columns;
};

const defaultColumnIds = (showProject?: boolean): string[] => {
  const ids = [
    'name',
    '_cls',
    'state',
    'date_created',
    'date_start',
    'date_end',
    'views',
    'clicks',
    'options',
  ];

  if (showProject) {
    // We want to insert the project column after the name column.
    ids.splice(1, 0, 'project');
  }

  return ids;
};

const baseColumnIds = (showProject?: boolean): string[] => {
  const ids = [
    'name',
    '_cls',
    'state',
    'date_created',
    'date_start',
    'date_end',
    'views',
    'clicks',
    'conversions',
    'roi',
    'options',
  ];

  if (showProject) {
    // We want to insert the project column after the name column.
    ids.splice(1, 0, 'project');
  }

  return ids;
};

function defaultColumnIdsMap(showProject?: boolean): Record<TCampaignGroup, string[]> {
  return {
    ads: [...baseColumnIds(showProject), 'budget', 'stats__spend'],
    all: defaultColumnIds(showProject),
    email: [...baseColumnIds(showProject), 'email_sends'],
    'google-ads': [...defaultColumnIds(showProject), 'conversions', 'roi'],
    monetization: [...baseColumnIds(showProject)],
    other: [...baseColumnIds(showProject), 'email_sends'],
  };
}

export { defaultColumnIdsMap };
export default getDefaultColumns;
