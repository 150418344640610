import { faDollarSign } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { IEvent, IEventBilling } from '@feathr/blackbox';
import type { Event } from '@feathr/blackbox';
import { Label, Modal, NumberInput, Select, toast } from '@feathr/components';
import { EventOption, EventSingleValue } from '@feathr/extender/components/SelectOptions';
import { StoresContext } from '@feathr/extender/state';
import { currencyFormatter } from '@feathr/extender/utils';
import type { IRachisMessage } from '@feathr/rachis';
import { Collection, isWretchError, wretch } from '@feathr/rachis';

import * as styles from './TransferFunds.css';

interface IProps {
  event: Event;
  toggleShowTransferFundsModal: () => void;
}

function TransferFunds({ event, toggleShowTransferFundsModal }: IProps): JSX.Element {
  const billing = event.get('billing', {} as IEventBilling);
  const { media_credit_balance = 0, max_transfer = 0 } = billing;

  const { Events } = React.useContext(StoresContext);
  const [targetEvent, setTargetEvent] = React.useState<IEvent>();
  const [transferAmount, setTransferAmount] = React.useState<number>(max_transfer);
  const { t } = useTranslation();

  const events = Events.list({
    filters: {
      id__ne: event.id,
    },
    only: ['id', 'billing', 'logo', 'name'],
    pagination: { page_size: 1000 },
    ordering: ['-_id'],
  });

  async function transferFunds(): Promise<void> {
    const response = await wretch<IRachisMessage>(`${BLACKBOX_URL}events/transfer-funds`, {
      method: 'POST',
      headers: Collection.prototype.getHeaders(),
      body: JSON.stringify({
        to_event_id: targetEvent!.id,
        from_event_id: event.id,
        amount: transferAmount,
      }),
    });
    if (isWretchError(response)) {
      throw response.error;
    }
    toast(response.data.message.detail, { type: ToastType.SUCCESS });
    toggleShowTransferFundsModal();
    window.location.reload();
  }

  function handleTransferAmountChange(newValue?: number): void {
    setTransferAmount(newValue ?? 0);
  }

  return (
    <Modal
      confirmDisabled={!targetEvent || transferAmount <= 0 || transferAmount > max_transfer}
      controlled={true}
      onClose={toggleShowTransferFundsModal}
      onConfirm={transferFunds}
      t={t}
      title={t('Transfer Funds')}
    >
      <>
        <Trans t={t}>
          <p>
            You may transfer funds that are not already budgeted. The maximum available to transfer
            is {{ amount: max_transfer }}. To transfer more, decrease the budgets of currently
            running campaigns or future campaigns. Funds will be removed from this project and added
            to the destination project.
          </p>
        </Trans>
        <Select<IEvent>
          components={{ Option: EventOption, SingleValue: EventSingleValue }}
          isLoading={events.isPending}
          label={t('Pick a project you want to transfer funds to')}
          onSelectSingle={setTargetEvent}
          options={events.models.filter((e) => e.id !== event.id).map((e) => e.toJS())}
          styles={{
            control: (base) => ({
              ...base,
              padding: 'var(--spacing-2) 0',
            }),
          }}
          value={targetEvent}
          wrapperClassName={styles.event}
        />
        <NumberInput
          label={t('Transfer Amount')}
          max={max_transfer}
          min={0}
          onChange={handleTransferAmountChange}
          prefix={<FontAwesomeIcon icon={faDollarSign} />}
          value={transferAmount}
          wrapperClassName={styles.numberInput}
        />
        {!!targetEvent && !!targetEvent.billing && (
          <>
            <Label>{t('New balance for {{name}}', { name: event.name })}</Label>
            <div>
              {currencyFormatter.format(Math.max(media_credit_balance - transferAmount, 0))}
            </div>
            <Label>{t('New balance for {{name}}', { name: targetEvent.name })} </Label>
            {/* targetEvent.billing.balance requires Events stats */}
            <div>
              {currencyFormatter.format(
                Math.max(targetEvent.billing.media_credit_balance + transferAmount, 0),
              )}
            </div>
          </>
        )}
      </>
    </Modal>
  );
}

export default observer(TransferFunds);
