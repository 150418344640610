import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Billable, Event } from '@feathr/blackbox';
import { Session } from '@feathr/blackbox';
import {
  Alert,
  AlertType,
  Checkbox,
  Fieldset,
  Form,
  Input,
  Label,
  Modal,
  NumberInput,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import * as styles from './AddFunds.css';

interface IProps {
  event: Event;
  billable: Billable;
  toggleShowAddFundsModal: () => void;
}

function AddFunds({ billable, event, toggleShowAddFundsModal }: IProps): JSX.Element {
  const { Bills } = useContext(StoresContext);
  const [confirmDisabled, setConfirmDisabled] = useState(true);
  const [amount, setAmount] = useState(0);
  const [acknowledged, toggleAcknowledged] = useToggle(false);
  const [poNumber, setPONumber] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setConfirmDisabled(!amount || amount < 0 || !acknowledged);
  }, [amount, acknowledged]);

  function handlePONumberChange(newValue?: string): void {
    setPONumber(newValue ?? '');
  }

  function handleAmountChange(newValue?: number): void {
    setAmount(newValue ?? 0);
  }

  async function handleConfirm(): Promise<void> {
    const bill = await Bills.prepay(amount, event.id, Session.userId, poNumber);
    window.open(bill.get('stripe')!.hosted_invoice_url);
    Bills.refreshApiCache();
    toggleShowAddFundsModal();
  }

  return (
    <Modal
      confirmButtonText={t('Generate invoice')}
      confirmDisabled={confirmDisabled}
      controlled={true}
      onClose={toggleShowAddFundsModal}
      onConfirm={handleConfirm}
      size={'sm'}
      t={t}
      title={'Add funds'}
    >
      <>
        <Alert type={AlertType.warning}>
          <Trans t={t}>
            <strong>
              An invoice will be generated and funds will be credited upon receipt of payment.
            </strong>
            <p>
              Adding funds to your media balance for this project is subject to the same rules and
              terms of the account license as defined in our{' '}
              <a href={'https://feathr.co/terms'} target={'_blank'}>
                Terms &amp; Conditions
              </a>
              .
            </p>
          </Trans>
        </Alert>
        <Form label={'Add funds'}>
          <Label>{t('Project')}</Label>
          <p>{event.get('name')}</p>

          <Label>{t('Billing contact')}</Label>
          <p>{billable.get('email')}</p>
          <Fieldset>
            <Input
              helpText={
                'If you need a PO number to be attached to the invoice, please add it here.'
              }
              label={t('PO number')}
              onChange={handlePONumberChange}
              optional={true}
              type={'text'}
              value={poNumber}
            />
            <NumberInput
              helpText={t('This is the amount in USD to add to your balance for this project.')}
              label={t('Amount')}
              min={0}
              name={'amount'}
              onChange={handleAmountChange}
              prefix={'$'}
              required={true}
              value={amount}
              wrapperClassName={styles.amount}
            />
          </Fieldset>
          <Checkbox
            label={
              <Trans t={t}>
                <strong>
                  I understand that clicking "Generate and Pay Invoice" will create an invoice to
                  add pre-paid funds this project and that funds will only be added to this project
                  upon receipt of payment.
                </strong>{' '}
                I acknowledge that I am authorized to add funds to this project.
              </Trans>
            }
            name={'acknowledged'}
            onChange={toggleAcknowledged}
            value={acknowledged}
          />
        </Form>
      </>
    </Modal>
  );
}

export default AddFunds;
