import type { WithT } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { Campaign } from '@feathr/blackbox';
import { CampaignClass, CampaignLabelMap, CampaignState, Targetable } from '@feathr/blackbox';
import type { ButtonType } from '@feathr/components';
import { ButtonValid, toast } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';
import { flattenError, logUserEvents } from '@feathr/hooks';
import type { IBaseAttributes, IValidation, Model } from '@feathr/rachis';

import type { ICampaignValidationErrors } from '../../CampaignSummary';

interface ISaveProps extends WithT {
  accountId: string;
  campaign: Campaign;
  childModels: Array<Model<IBaseAttributes>>;
  grandchildModels?: Array<Model<IBaseAttributes>>;
  name?: string;
  preSave?: (campaign: Campaign) => Promise<void> | void;
  postSave?: (campaign: Campaign, stateChanged?: boolean) => Promise<void> | void;
  shouldChangeState?: boolean;
}

interface ISaveButtonProps extends Omit<ISaveProps, 't' | 'accountId'> {
  disabled?: boolean;
  validate?: () => ICampaignValidationErrors | IValidation<ICampaignValidationErrors>;
}

async function save({
  campaign,
  childModels,
  grandchildModels,
  preSave,
  postSave,
  shouldChangeState,
  t,
  accountId,
}: ISaveProps): Promise<void> {
  /*
   * If draft => save as draft + (if valid) publish
   * if published => save changes + stop campaign
   */
  try {
    const state = campaign.get('state', CampaignState.Draft);

    // TODO: Move setting campaign.is_enabled on publish to backend
    if (shouldChangeState && [CampaignState.Draft, CampaignState.Stopped].includes(state)) {
      campaign.set({ is_enabled: true });
    }
    if (preSave) {
      await preSave(campaign);
    }
    await campaign.patchDirty();

    /*
     * Skip validation for targetables to let them be saved as
     * incomplete while campaign is in draft, but will be validated
     * within the targetables step and when publishing
     */
    const shouldSkipValidation =
      campaign.get('state') === CampaignState.Draft && !shouldChangeState;

    const saveModel = async (model: Model<IBaseAttributes>): Promise<void> => {
      if (model.isEphemeral || !model.id) {
        await model.collection!.add(model, {
          validate: model instanceof Targetable && shouldSkipValidation ? false : true,
        });
      } else if (model.isDirty) {
        await model.save();
      }
      if (model.isErrored) {
        throw model.error;
      }
    };

    await (grandchildModels
      ? Promise.all(grandchildModels.map((childModel) => saveModel(childModel)))
      : Promise.resolve());
    await Promise.all(childModels.map((childModel) => saveModel(childModel)));

    if (shouldChangeState) {
      if ([CampaignState.Draft, CampaignState.Stopped].includes(state)) {
        logUserEvents({ 'Published campaign': { campaign_id: campaign.id } });
        await campaign.publish();
      } else {
        await campaign.stop();
      }
      if (campaign.isErrored) {
        throw campaign.error;
      }
    } else if (
      !campaign.isPinpointCampaign &&
      [CampaignState.Published, CampaignState.Publishing].includes(state)
    ) {
      await campaign.publish();
      logUserEvents({ 'Published campaign': { campaign_id: campaign.id } });
    }

    function getMessage(currentState: CampaignState): string {
      if (!shouldChangeState) {
        return t('Campaign updated');
      }
      if ([CampaignState.Draft, CampaignState.Stopped].includes(currentState)) {
        return t('Campaign published');
      }
      return t('Campaign stopped');
    }

    // Concatenates to campaign type + campaign published/stopped/updated
    logUserEvents({
      [`${CampaignLabelMap.get(campaign.get('_cls'))} ${getMessage(state)}`]: {
        account_id: accountId,
        campaign_id: campaign.id,
      },
    });

    if (postSave) {
      await postSave(campaign, shouldChangeState);
    }

    toast(getMessage(state), {
      type: shouldChangeState ? ToastType.SUCCESS : ToastType.INFO,
    });

    // If err is instance of Error, it should be of type any.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    logUserEvents({
      [`Failed to publish ${CampaignLabelMap.get(campaign.get('_cls'))}`]: {
        account_id: accountId,
        campaign_id: campaign.id,
      },
    });
    if (e.errors) {
      e.errors.map((error) => {
        toast(error.message, { type: ToastType.ERROR });
      });
    } else {
      toast(e.message || t('An unknown error occurred!'), { type: ToastType.ERROR });
    }
  }
}

function SaveCampaignButton({
  campaign,
  childModels,
  grandchildModels = [],
  disabled,
  name,
  preSave,
  postSave,
  shouldChangeState = false,
  validate,
}: ISaveButtonProps): JSX.Element {
  const account = useAccount();
  const { t } = useTranslation();
  const state = campaign.get('state', CampaignState.Draft);

  function getLabel(): string {
    if (state === CampaignState.Draft) {
      return shouldChangeState ? t('Publish') : t('Save as draft');
    } else if (
      [CampaignState.Published, CampaignState.Publishing, CampaignState.Erroring].includes(state)
    ) {
      return shouldChangeState ? t('Stop campaign') : t('Save changes');
    }
    return shouldChangeState ? t('Publish') : t('Save changes');
  }

  function handleSave(): Promise<void> {
    return save({
      campaign,
      childModels,
      grandchildModels,
      preSave,
      postSave,
      shouldChangeState,
      t,
      accountId: account.id,
    });
  }

  /*
   * shouldChangeState = true
   *   when draft or stopped => publish
   *   when published or publishing (what about erroring?) => stop campaign
   *
   * shouldChangeState = false
   *   when draft => save draft
   *   when stopped => save changes
   *   when published or publishing (what about erroring?) => save changes
   *
   * Stop button will be disabled for all campaigns past their end date.
   * Save should be disabled: on draft, stopped, or published - when no changes
   * from previous campaign state are present.
   */

  const isPublishing = state === CampaignState.Publishing;
  const isPublishedOrPublishing =
    [CampaignState.Published, CampaignState.Erroring].includes(state) || isPublishing;
  const isErroring = state === CampaignState.Erroring;

  let type: ButtonType = 'secondary';
  if (shouldChangeState) {
    type = isPublishedOrPublishing || isErroring ? 'primary' : 'success';
  }

  const childModelsDirty = [...childModels, ...grandchildModels].some(({ isDirty }) => isDirty);
  // If no changes, disable save button.
  const isSaveButtonNoChanges = !shouldChangeState && !campaign.isDirty && !childModelsDirty;
  // If published single send campaign is past start date, disable stop button.
  const isSingleSendPastStartDate =
    isPublishedOrPublishing &&
    shouldChangeState &&
    campaign.get('_cls') === CampaignClass.PinpointEmail &&
    campaign.isPastStartDate;
  // If campaign is past end date, disable stop/publish button.
  const isPublishOrStopButtonPastEndDate = shouldChangeState && campaign.isPastEndDate;

  // Allow force disabled, else let validation deal with it.
  let isDisabled: boolean | undefined = disabled;
  if (
    isSaveButtonNoChanges ||
    isPublishing ||
    isPublishOrStopButtonPastEndDate ||
    isSingleSendPastStartDate
  ) {
    isDisabled = true;
  }

  /**
   * Type guard for IValidation
   *
   * When using async validation we need access to additional properties, such
   * as isPending. In that case the validate function passed in should return a
   * IValidation object.
   */
  function isValidation(
    obj?: ICampaignValidationErrors | IValidation<ICampaignValidationErrors>,
  ): obj is IValidation<ICampaignValidationErrors> {
    return !!obj && 'errors' in obj;
  }

  const validation = validate?.();
  const errors = isValidation(validation) ? validation?.errors : validation;
  const isLoading = isValidation(validation) && validation.isPending;

  return (
    <ButtonValid
      disabled={isDisabled}
      errors={flattenError(errors)}
      isLoading={isLoading}
      name={name}
      onClick={handleSave}
      tooltip={
        isPublishOrStopButtonPastEndDate
          ? t(
              'This campaign can no longer be published or stopped because it is past the end date.',
            )
          : undefined
      }
      type={type}
    >
      {getLabel()}
    </ButtonValid>
  );
}

export default observer(SaveCampaignButton);
