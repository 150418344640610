import type { TFunction } from 'i18next';
import numeral from 'numeral';
import type { JSX } from 'react';
import React from 'react';

import type { Campaign, IGoogleAdsKeyword, IKeywordStats } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader } from '@feathr/components';

import KeywordStatsOptions from './KeywordStatsOptions';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './KeywordStatsColumns.css';

export interface IRow {
  original: IKeywordStats;
}

function getClickThroughRate(stats: IKeywordStats): string {
  return numeral(stats.num_clicks / stats.num_views).format('0.00%');
}

function getCostPerClick(stats: IKeywordStats): string {
  return numeral(stats.spend / stats.num_clicks).format('$0.00');
}

function KeywordStatsColumns(campaign: Campaign, t: TFunction): Array<IColumn<IKeywordStats>> {
  const keywords: IGoogleAdsKeyword[] = campaign.get('keyword_themes');

  function isNegativeKeyword(term: string): boolean {
    return keywords.some((keyword) => keyword.display_name === term && keyword.negative);
  }

  return [
    {
      Header: TableColumnHeader({ title: t('Keyword') }),
      id: 'keywords',
      Cell({ original }: IRow): JSX.Element {
        return (
          <>
            {original.term}{' '}
            {isNegativeKeyword(original.term) && (
              <Chip className={styles.negativeChip} theme={'red'}>
                Negative
              </Chip>
            )}
          </>
        );
      },
      width: 325,
      sortable: false,
    },
    {
      Header: TableColumnHeader({ title: t('Views') }),
      id: 'views',
      Cell({ original }: IRow): JSX.Element {
        return <>{numeral(original.num_views).format('0,0')}</>;
      },
      sortable: false,
    },
    {
      Header: TableColumnHeader({ title: t('Clicks') }),
      id: 'clicks',
      Cell({ original }: IRow): JSX.Element {
        return <>{numeral(original.num_clicks).format('0,0')}</>;
      },
      sortable: false,
    },
    {
      Header: TableColumnHeader({ title: t('Click Through Rate') }),
      id: 'clickThroughRate',
      Cell({ original }: IRow): JSX.Element {
        return <>{getClickThroughRate(original)}</>;
      },
      sortable: false,
    },
    {
      Header: TableColumnHeader({ title: t('Cost Per Click') }),
      id: 'costPerClick',
      Cell({ original }: IRow): JSX.Element {
        return <>{getCostPerClick(original)}</>;
      },
      sortable: false,
    },
    {
      id: 'options',
      Header: TableColumnHeader({
        title: t('Options'),
      }),
      width: 80,
      sortable: false,
      className: tableStyles.cellCenter,
      Cell({ original }: IRow): JSX.Element {
        return (
          <KeywordStatsOptions
            campaign={campaign}
            isNegative={isNegativeKeyword(original.term)}
            term={original.term}
          />
        );
      },
    },
  ];
}

export default KeywordStatsColumns;
