import type { JSX } from 'react';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import DataFieldsPage from '@feathr/extender/App/DataPage/DataFieldsPage';
import PersonPage from '@feathr/extender/App/DataPage/PersonPage';
import DataRequestsPage from '@feathr/extender/App/DataRequestsPage';
import DataRequestPage from '@feathr/extender/App/DataRequestsPage/DataRequestPage';
import DataSegmentsPage from '@feathr/extender/App/DataSegmentsPage';
import DataSegmentAnalyticsPage from '@feathr/extender/App/DataSegmentsPage/DataSegmentAnalyticsPage';
import DataSegmentEditPage from '@feathr/extender/App/DataSegmentsPage/DataSegmentEditPage';
import DataSegmentPage from '@feathr/extender/App/DataSegmentsPage/DataSegmentPage';
import SuperPixelPage from '@feathr/extender/App/SuperPixelPage';
import { useAccount, useRole } from '@feathr/extender/state';

import DataConversionPixelsPage from '../DataConversionPixelsPage';
import DataExplorePage from '../DataExplorePage';
import DataImportsPage from '../DataImportsPage';
import DataImportPage from '../DataImportsPage/DataImportPage';
import DataFieldEditPage from '../DataPage/DataFieldsPage/DataFieldEditPage';
import DataSegmentSettingsPage from '../DataSegmentSettingsPage';
import RedirectTo404 from './RedirectTo404';

interface ILocationState {
  search: string;
}

function DataRoutes(): JSX.Element {
  const { search } = useLocation<ILocationState>();
  const account = useAccount();
  const { hasImports, hasSegments, hasSegmentsSome, isSudoer } = useRole();

  return (
    <Switch>
      <Redirect
        exact={true}
        from={'/:accountId/data'}
        to={{ pathname: '/:accountId/data/explore/people', search }}
      />
      <Redirect
        exact={true}
        from={'/:accountId/data/explore'}
        to={{ pathname: '/:accountId/data/explore/all/people', search }}
      />
      <Redirect
        exact={true}
        from={'/:accountId/data/explore/:view(all|known|anonymous)'}
        to={{ pathname: '/:accountId/data/explore/:view(all|known|anonymous)/people', search }}
      />
      <Redirect
        exact={true}
        from={'/:accountId/data/segments/:segmentId'}
        to={{ pathname: '/:accountId/data/segments/:segmentId/people', search }}
      />

      {/* Redirects from legacy urls */}
      <Redirect
        exact={true}
        from={'/:accountId/data/people'}
        to={{ pathname: '/:accountId/data/explore/people', search }}
      />
      {hasSegmentsSome && (
        <Redirect
          exact={true}
          from={'/:accountId/data/explore/:tab(people|activity)'}
          to={{
            pathname: '/:accountId/data/segments',
            search,
          }}
        />
      )}
      <Redirect
        exact={true}
        from={'/:accountId/data/explore/:tab(people|activity)'}
        to={{
          pathname: '/:accountId/data/explore/all/:tab(people|activity)',
          search,
        }}
      />

      <Redirect
        exact={true}
        from={'/:accountId/data/people/:personId'}
        to={'/:accountId/data/people/:personId/activity'}
      />

      {hasSegments && (
        <Route path={'/:accountId/data/explore/:view(all|known|anonymous)/:tab(people|activity)'}>
          <DataExplorePage />
        </Route>
      )}

      <Route
        component={PersonPage}
        exact={true}
        path={
          '/:accountId/data/people/:personId/:tab(activity|profile|custom-fields|subscriptions)'
        }
      />

      {hasSegments && account.isFalcon && (
        <Route exact={true} path={'/:accountId/data/custom-fields'}>
          <DataFieldsPage />
        </Route>
      )}
      {hasSegments && account.isFalcon && (
        <Route exact={true} path={'/:accountId/data/custom-fields/add'}>
          <DataFieldEditPage />
        </Route>
      )}
      {hasSegments && account.isFalcon && (
        <Route path={'/:accountId/data/custom-fields/:customFieldId/edit'}>
          <DataFieldEditPage />
        </Route>
      )}

      <Route exact={true} path={'/:accountId/data/pixel'}>
        <SuperPixelPage />
      </Route>
      {(account.hasPixelImplementation || isSudoer) && (
        <Route exact={true} path={'/:accountId/data/pixel/implementations'}>
          <DataRequestsPage />
        </Route>
      )}
      {(account.hasPixelImplementation || isSudoer) && (
        <Route exact={true} path={'/:accountId/data/pixel/implementations/:dataRequestId'}>
          <DataRequestPage />
        </Route>
      )}

      <Route exact={true} path={'/:accountId/data/pixel/conversions'}>
        <DataConversionPixelsPage />
      </Route>

      <Route exact={true} path={'/:accountId/data/segments'}>
        <DataSegmentsPage />
      </Route>

      <Route exact={true} path={'/:accountId/data/segments/:segmentId/:view(people|activity)'}>
        <DataSegmentPage />
      </Route>

      <Route exact={true} path={'/:accountId/data/segments/:segmentId/analytics'}>
        <DataSegmentAnalyticsPage />
      </Route>

      <Route exact={true} path={'/:accountId/data/segments/:segmentId/edit'}>
        <DataSegmentEditPage />
      </Route>

      <Route exact={true} path={'/:accountId/data/segments/:segmentId/settings'}>
        <DataSegmentSettingsPage />
      </Route>

      {hasImports && (
        <Route exact={true} path={'/:accountId/data/imports'}>
          <DataImportsPage />
        </Route>
      )}
      {hasImports && (
        <Route exact={true} path={'/:accountId/data/imports/:importerId'}>
          <DataImportPage />
        </Route>
      )}

      <RedirectTo404 />
    </Switch>
  );
}

export default DataRoutes;
