import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import type { Billable } from '@feathr/blackbox';
import { Button, Form, SaveButtonValid } from '@feathr/components';
import { useActionBar, useLocalUrl } from '@feathr/extender/state';
import { flattenErrors } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import BillingAddressCard from './BillingAddressCard';
import BillingInfoCard from './BillingInfoCard';
import PaymentMethodCard from './PaymentMethodCard';

interface IProps {
  billable: Billable;
  isDefault?: boolean;
  /** Accommodate legacy page component */
  onRedirect?: () => void;
}

function validate(billable: Billable): TValidateGrouped {
  return billable.validate(
    [
      'name',
      'email',
      // TODO: Enable validation of the following fields in a future billing PR
      /*
       * 'address.line1',
       * 'address.city',
       * 'address.state',
       * 'address.postal_code',
       * 'address.country',
       * 'stripe.source',
       * 'stripe.source.status',
       */
    ],
    false,
    'grouped',
  ).errors;
}

export function getLabel(isEphemeral: boolean, isDefault: boolean): string {
  // Add default billing
  if (isEphemeral && isDefault) {
    return t('Add Default Billing');
  }
  // Add additional billing
  if (isEphemeral) {
    return t('Add Additional Billing');
  }
  // Update default billing
  if (isDefault) {
    return t('Update Default Billing');
  }
  // Update additional billing
  return t('Update Additional Billing');
}

function BillableForm({ billable, isDefault, onRedirect }: Readonly<IProps>): JSX.Element {
  const { setRightActions } = useActionBar();
  const history = useHistory();
  const localUrl = useLocalUrl();

  // Get data used in validation
  const name = billable.get('name');
  const email = billable.get('email');
  const description = billable.get('description');
  const address = JSON.stringify(billable.get('address'));
  const stripe = JSON.stringify(billable.get('stripe'));

  useEffect(() => {
    function handleRedirect(): void {
      const search = new URLSearchParams(window.location.search);
      const redirect = search.get('redirect');
      history.push(redirect ?? localUrl('/settings/billing/configurations'));
    }

    const validationErrors = validate(billable);

    setRightActions(
      <>
        <Button onClick={handleRedirect}>{t('Cancel')}</Button>
        <SaveButtonValid<Billable>
          errors={flattenErrors(validationErrors)}
          key={'save'}
          method={billable.isEphemeral ? 'add' : 'patch'}
          model={billable}
          onSave={onRedirect ?? handleRedirect}
          successMessage={t('Billing information saved.')}
          tooltipPlacement={'topRight'}
        >
          {t('Save')}
        </SaveButtonValid>
      </>,
    );
  }, [
    address,
    billable,
    billable.isEphemeral,
    description,
    email,
    history,
    localUrl,
    name,
    onRedirect,
    setRightActions,
    stripe,
  ]);

  return (
    <Form
      data-id={billable.get('id')}
      data-model={'Billable'}
      label={getLabel(billable.isEphemeral, !!isDefault)}
    >
      <BillingInfoCard billable={billable} isDefault={isDefault} />
      <BillingAddressCard billable={billable} />
      <PaymentMethodCard billable={billable} />
    </Form>
  );
}

export default observer(BillableForm);
