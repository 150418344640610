import { observer } from 'mobx-react-lite';
import React from 'react';

import { Button } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useLocalUrl } from '@feathr/extender/state';

import Advertisers from './Advertisers';

function AdvertisersPage() {
  const localUrl = useLocalUrl();

  return (
    <Page
      actions={[
        <Button key={'addAdvertiser'} link={localUrl('/settings/account/advertisers/add')}>
          Add advertiser
        </Button>,
      ]}
      description={
        <>
          <p>
            Advertisers are the organizations you use Feathr to advertise on behalf of. To help
            ensure accountability and a high standard of quality in the ad exchanges we integrate
            with, we ask that you provide a business name, logo and website URL for each
            organization that you advertise on behalf of.
          </p>
          <p>
            If you only run campaigns on the behalf of your own organization, you don't need to add
            any Advertisers or use them in your campaigns.
          </p>
        </>
      }
      title={'Advertisers'}
    >
      <Advertisers />
    </Page>
  );
}

export default observer(AdvertisersPage);
