import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Flight } from '@feathr/blackbox';
import { CampaignClass } from '@feathr/blackbox';
import { AlertV2, EAlertV2Type, Modal, toast } from '@feathr/components';
import CampaignSelect from '@feathr/extender/components/CampaignSelect/CampaignSelect';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';

import * as styles from './LegModalAddExistingCampaign.css';

interface IProps {
  flight: Flight;
  legId: number;
  onClose: () => void;
}

function LegModalAddExistingCampaign({ flight, legId, onClose }: IProps): JSX.Element {
  const { Campaigns } = React.useContext(StoresContext);
  const [selectedCampaigns, setSelectedCampaigns] = React.useState<Campaign[]>();
  const { t } = useTranslation();
  const localUrl = useLocalUrl();

  const eventId = flight.get('event_id');
  const flightId = flight.id;

  async function addCampaignsToFlight(campaigns: Campaign[]) {
    const legs = flight.get('legs');
    campaigns.forEach((campaign) => legs[legId].campaigns.push(campaign.id));
    flight.setAttributeDirty('legs');
    /*
     * Await campaign first so that when flight updates we know that the campaign already
     * has the flight id set
     */
    await Promise.all(campaigns.map((campaign) => campaign.patch({ flight: flightId })));
    await flight.patchDirty();
    onClose();
    Campaigns.refreshApiCache();

    toast(
      t('{{count}} campaign successfully added to {{legName}}.', {
        count: campaigns.length,
        legName: legs[legId].name,
      }),
      {
        type: 'success',
      },
    );
  }

  return (
    <Modal
      controlled={true}
      description={t(
        'Choose one or more of your existing campaigns that are not already part of a flight to add to this flight.',
      )}
      onClose={onClose}
      onConfirm={() => addCampaignsToFlight(selectedCampaigns!)}
      size={'sm'}
      t={t}
      title={t('Add Existing Campaigns')}
    >
      <>
        <AlertV2
          description={t(
            'To recalculate conversions to include the newly added campaign, select "Recalculate conversions" from the conversions table on this flight\'s report.',
          )}
          title={t(
            "Adding a campaign to this flight will make this flight's reported conversions inaccurate.",
          )}
          type={EAlertV2Type.warning}
        >
          <a href={localUrl(`${flight.getItemUrl()}/report`)} target={'_blank'}>
            {t('View flight report')}
          </a>
        </AlertV2>
        <CampaignSelect
          className={styles.select}
          eventId={eventId}
          filters={{ flight__exists: false }}
          isMulti={true}
          onChange={(campaignIds: string[]) => {
            setSelectedCampaigns(
              campaignIds
                .filter((campaign) => campaign !== undefined)
                .map((campaign) => Campaigns.get(campaign)),
            );
          }}
          onClear={() => setSelectedCampaigns(undefined)}
          type={[
            CampaignClass.Segment,
            CampaignClass.Search,
            CampaignClass.EmailList,
            CampaignClass.Referral,
            CampaignClass.Conversation,
            CampaignClass.LandingPage,
            CampaignClass.MobileGeoFencing,
            CampaignClass.MobileGeoFenceRetargeting,
            CampaignClass.Lookalike,
            CampaignClass.SeedSegment,
            CampaignClass.Affinity,
            CampaignClass.TrackedLink,
            CampaignClass.Facebook,
            CampaignClass.EmailListFacebook,
            CampaignClass.PinpointEmail,
            CampaignClass.SmartPinpointEmail,
            CampaignClass.AutoPinpointEmail,
          ]}
          value={selectedCampaigns ? selectedCampaigns.map((c) => c.id) : undefined}
        />
      </>
    </Modal>
  );
}

export default observer(LegModalAddExistingCampaign);
