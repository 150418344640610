import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Input, Modal, toast } from '@feathr/components';
import { validate } from '@feathr/rachis';

interface IProps {
  title: string;
  onClose: () => void;
  onConfirm: (emailAddress: string) => Promise<unknown | Error>;
}

function ExportModal({ onClose, onConfirm, title }: IProps) {
  const { t } = useTranslation();
  const [isDirty, setDirty] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');

  function validateEmailAddress() {
    if (!isDirty) {
      return;
    }
    const error: Array<string | undefined> = validate.single(emailAddress, {
      presence: { allowEmpty: false },
      email: true,
    });
    return error ? error[0] : undefined;
  }

  function handleChange(newValue?: string) {
    setEmailAddress(newValue ?? '');
    setDirty(true);
  }

  async function handleConfirm() {
    const result = await onConfirm(emailAddress);
    if (result instanceof Error) {
      toast(t('Something went wrong while preparing your report. Please try again later.'), {
        type: 'error',
      });
    } else {
      toast(t('Your export is on the way! Check your email inbox in a few minutes.'), {
        type: 'success',
      });
    }
    onClose();
  }

  return (
    <Modal
      confirmButtonText={t('Export')}
      confirmDisabled={!!validateEmailAddress()}
      controlled={true}
      ephemeral={true}
      onClose={onClose}
      onConfirm={handleConfirm}
      t={t}
      title={title}
    >
      <div>
        <Trans t={t}>
          <p>
            Input an email address below and click export.
            <br />
            Afterward you should receive an email at the provided address with the requested data in{' '}
            <code>.csv</code> format.
          </p>
        </Trans>
        <Input
          onChange={handleChange}
          placeholder={t('Email address')}
          type={'text'}
          validationError={validateEmailAddress()}
          value={emailAddress}
        />
      </div>
    </Modal>
  );
}

export default ExportModal;
