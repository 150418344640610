import { observer } from 'mobx-react-lite';
import React from 'react';

import type { AggregatedStat, Campaign, FacebookCampaignInsight } from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatsCard } from '@feathr/components';

import { ClicksStat, ViewsStat } from '../Stats';

interface ICardProps {
  aggregatedStats: AggregatedStat;
  campaign?: Campaign;
  clicksLabel?: string;
  clicksTooltip?: string;
  conversions?: number;
  facebookCampaignStats?: FacebookCampaignInsight;
  isMonetization?: boolean;
  reachLabel?: string;
  reachTooltipText?: string;
  targetImpressions?: number;
  totalUsers?: number;
  uniqueClicksLabel?: string;
  uniqueClicksTooltipText?: string;
  viewsLabel?: string;
  viewsTooltip?: string;
}

function AdPerformanceCard({
  campaign,
  clicksLabel,
  clicksTooltip,
  conversions,
  aggregatedStats,
  facebookCampaignStats,
  isMonetization,
  targetImpressions,
  viewsLabel,
  viewsTooltip,
}: ICardProps) {
  const flavors = aggregatedStats.get('flavors', {});

  const clicks =
    (flavors.ad_click || 0) + (flavors.page_link_click || 0) + (flavors.email_link_click || 0);
  const views = (flavors.ad_view || 0) + (flavors.page_view || 0) + (flavors.email_view || 0);

  const users =
    campaign?.isFacebook && facebookCampaignStats
      ? facebookCampaignStats.get('reach')
      : aggregatedStats.get('num_users_new', 0);

  return (
    <StatsCard title={reportModuleLabels.includeAdPerformance}>
      <>
        <ViewsStat
          conversions={conversions}
          isMonetization={isMonetization}
          label={viewsLabel}
          targetImpressions={targetImpressions}
          tooltip={viewsTooltip}
          users={users}
          value={views}
        />
        <ClicksStat
          label={clicksLabel}
          tooltip={clicksTooltip}
          type={campaign?.get('_cls')}
          value={clicks}
          views={views}
        />
      </>
    </StatsCard>
  );
}

export default observer(AdPerformanceCard);
