import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type {
  GoogleAdsSmartCampaign,
  IConfig as IBaseConfig,
  IReportAttributes,
  PinpointEmailBaseCampaign,
  ReportModel,
  TReportClass,
} from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import {
  Button,
  CopyToClipboardButton,
  Spinner,
  toast,
  Toolbar,
  Tooltip,
} from '@feathr/components';
import { StoresContext, useAccount, useLocalUrl, useUser } from '@feathr/extender/state';
import { moment, TimeFormat, useReactionEffect, useToggle } from '@feathr/hooks';
import type { IRachisMessage } from '@feathr/rachis';
import { wretch } from '@feathr/rachis';
import type { IReportProps } from '@feathr/report_components';
import {
  ConfigModal,
  ExportModal,
  ReportDateRange,
  ShareableLinksModal,
} from '@feathr/report_components';

import * as styles from './ReportWrapper.css';

interface IReportPageProps<IAttributes extends IReportAttributes, IConfig> {
  hideCustomDateRange?: boolean;
  initialConfig: IConfig;
  model: ReportModel<IAttributes>;
  Report: React.FunctionComponent<IReportProps>;
}

function ReportWrapper<IAttributes extends IReportAttributes, IConfig extends IBaseConfig>({
  hideCustomDateRange = false,
  initialConfig,
  model,
  Report,
}: IReportPageProps<IAttributes, IConfig>): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const account = useAccount();

        const store = useContext(StoresContext);
        const localUrl = useLocalUrl();

        const { eventId, campaignId, flightId } = useParams<{
          eventId: string;
          campaignId?: string;
          flightId?: string;
        }>();

        const user = useUser();

        // Load in user's reports_config setting if it exists
        const reportClass: TReportClass =
          model.className === 'campaign' ? model.get('_cls') : (model.className as TReportClass);
        const userReportsConfig = user.getReportsConfigSetting(reportClass, initialConfig);

        const [config, setConfig] = useState<IConfig>({
          ...initialConfig,
          ...userReportsConfig[reportClass],
        });

        const [reportStart, setReportStart] = useState<string | undefined>(model.reportRangeStart);
        const [reportEnd, setReportEnd] = useState<string | undefined>(model.reportRangeEnd);
        const [mode, setMode] = useState<'live' | 'dateWindow'>('live');
        const [isModalOpen, toggleModalOpen] = useToggle(false);
        const [isExportModalOpen, toggleExportModalOpen] = useToggle(false);
        const [isLinksModalOpen, toggleLinksModalOpen] = useToggle(false);
        const { t } = useTranslation();

        let goals = store.Goals.newListResponse();
        if (campaignId || flightId) {
          goals = store.Goals.list({
            filters: {
              _parent: campaignId ?? flightId,
              is_archived__ne: true,
            },
          });
        } else if (eventId) {
          const campaigns = store.Campaigns.list({
            filters: {
              _parent: eventId,
              is_archived__ne: true,
              state__ne: CampaignState.Draft,
              _cls__ne: CampaignClass.PinpointPartnerMessage,
            },
          });
          const flights = store.Flights.list({
            filters: {
              event_id: eventId,
              is_archived__ne: true,
            },
          });
          const flightlessCampaigns = campaigns.models.filter((cpn) => !cpn.get('flight'));
          goals = store.Goals.list({
            filters: {
              _parent__in: flights.models
                .map((f) => f.id)
                .concat(flightlessCampaigns.map((cpn) => cpn.id)),
              is_archived__ne: true,
            },
          });
        }

        useReactionEffect(
          () => !!account && !account.isPending,
          () => {
            if (!account || account.isPending) {
              return;
            }
            setConfig((newConfig) => ({
              ...newConfig,
              attributionModel:
                sessionStorage.getItem(`${model.id}_attributionModel`) ??
                account.get('attribution_model', 'full'),
            }));
          },
        );

        useReactionEffect(
          () => !model.isPending,
          () => {
            if (model.isPending) {
              return;
            }
            setReportStart(model.reportRangeStart);
            setReportEnd(model.reportRangeEnd);
          },
        );

        useEffect(() => {
          if (goals.models.length > 0) {
            setConfig((newConfig) => ({
              ...newConfig,
              includeROI: true,
              includeConversionsTable: true,
            }));
          }
        }, [goals.isPending, goals.models.length]);

        if (goals.isPending) {
          return <Spinner />;
        }

        const startMoment = moment.utc(reportStart);
        const endMoment = moment.utc(reportEnd === 'now' ? undefined : reportEnd);
        const startFormatted = startMoment.format(TimeFormat.isoDate);
        const endFormatted = endMoment.format(TimeFormat.isoDate);
        const hasGoals = goals.models.length > 0;
        const configString = btoa(JSON.stringify(config));
        const queryString = `?s=${startFormatted}&e=${
          mode === 'live' ? 'now' : endFormatted
        }&c=${configString}&m=${mode === 'live' ? 'l' : 'dw'}`;

        function isEmailCampaign(
          model: ReportModel<IAttributes> | PinpointEmailBaseCampaign,
        ): model is PinpointEmailBaseCampaign {
          return [
            CampaignClass.PinpointEmail,
            CampaignClass.SmartPinpointEmail,
            CampaignClass.AutoPinpointEmail,
          ].includes((model as PinpointEmailBaseCampaign).get('_cls'));
        }

        function isGoogleCampaign(
          model: ReportModel<IAttributes> | PinpointEmailBaseCampaign | GoogleAdsSmartCampaign,
        ): model is ReportModel<IAttributes> {
          return (model as GoogleAdsSmartCampaign).get('_cls') === CampaignClass.GoogleAdsSmart;
        }

        async function saveReportsConfig() {
          if (user?.isAttributeDirty('settings')) {
            await user.patch({ settings: user.attributes.settings });
            if (user.isErrored) {
              toast(t('Your report settings could not be saved.'), { type: ToastType.ERROR });
            } else {
              toast(t('Your report settings have been saved.', { type: ToastType.SUCCESS }));
            }
          }
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        function handleConfigChange(key: keyof IConfig, value: any) {
          const updatedConfig = {
            ...config,
            [key]: value,
          };

          // Update user's reports_config setting with latest changes
          userReportsConfig[reportClass] = updatedConfig;
          user?.setReportsConfigSetting(userReportsConfig);

          setConfig(updatedConfig);
          if (key === 'attributionModel') {
            sessionStorage.setItem(`${model.id}_attributionModel`, value);
          }
        }

        async function handleOnClose() {
          await saveReportsConfig();
          toggleModalOpen();
        }

        async function handleExport(email) {
          const exportParams = new URLSearchParams({
            attribution_model: config.attributionModel,
            start: startFormatted,
            end: endFormatted,
            email,
          });
          // TODO: Add error handling.
          await wretch<IRachisMessage>(
            `${model.collection!.url()}${model.id}/export?${exportParams.toString()}`,
            {
              method: 'GET',
              headers: model.collection!.getHeaders(),
            },
          );
        }

        return (
          <section>
            <div className={styles.toolbar}>
              {!hideCustomDateRange ? (
                <ReportDateRange<IAttributes>
                  mode={mode}
                  model={model}
                  reportEnd={reportEnd}
                  reportStart={reportStart}
                  setMode={setMode}
                  setReportEnd={setReportEnd}
                  setReportStart={setReportStart}
                />
              ) : (
                <span className={styles.dateRangePlaceholder}>
                  Live{' '}
                  <Tooltip title={t('Email campaign reports are live / cumulative only.')}>
                    <FontAwesomeIcon icon={faQuestionCircle} />
                  </Tooltip>
                </span>
              )}
              <Toolbar>
                <Button onClick={toggleModalOpen}>Configure report</Button>
                <Button onClick={toggleLinksModalOpen}>Get short link</Button>
                <CopyToClipboardButton
                  successMessage={'Copied to clipboard'}
                  t={t}
                  text={`${BLACKBOX_SHORT_URL}reports/${model.reportKey}/${model.id}${queryString}`}
                >
                  Copy shareable link
                </CopyToClipboardButton>
                <Button onClick={toggleExportModalOpen}>Export to CSV</Button>
              </Toolbar>
            </div>
            <Report
              config={config}
              end={endFormatted}
              hasGoals={hasGoals}
              localUrl={localUrl}
              modelId={model.id}
              start={startFormatted}
              store={store}
            />
            {isModalOpen && (
              <ConfigModal<IConfig>
                config={config}
                hasGoals={hasGoals}
                isEmailCampaign={isEmailCampaign(model)}
                isGoogleCampaign={isGoogleCampaign(model)}
                onChange={handleConfigChange}
                onClose={handleOnClose}
              />
            )}
            {isLinksModalOpen && (
              <ShareableLinksModal
                config={config}
                endDate={endFormatted}
                mode={mode}
                model={model}
                onClose={toggleLinksModalOpen}
                startDate={startFormatted}
              />
            )}
            {isExportModalOpen && (
              <ExportModal
                onClose={toggleExportModalOpen}
                onConfirm={handleExport}
                title={'Export Report Data'}
              />
            )}
          </section>
        );
      }}
    </Observer>
  );
}

export default ReportWrapper;
