import type { Account, BaseCampaign, TAttributionModel } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { useAccount } from '@feathr/extender/state';

import { getConversionsColumn } from './CampaignsColumns/ConversionsColumn';
import { getCPAColumn } from './CampaignsColumns/CPAColumn';
import { getROIColumn } from './CampaignsColumns/ROIColumn';
import getDefaultColumns from './defaultColumns';

interface IPermissionColumn {
  shouldShow?: (account: Account) => boolean;
}

function getColumns(
  attributionModel: TAttributionModel,
  columns: Array<IColumn<BaseCampaign>>,
): Array<IColumn<BaseCampaign>> {
  return columns.map((column) => {
    if (column.id === 'roi') {
      return getROIColumn(attributionModel);
    }
    if (column.id === 'conversions') {
      return getConversionsColumn(attributionModel);
    }
    if (column.id === 'cpa') {
      return getCPAColumn(attributionModel);
    }
    return column;
  });
}

export function getColumnIds(attributionModel: TAttributionModel, columnIds: string[]): string[] {
  return columnIds.map((columnId) => {
    if (columnId === 'roi') {
      return getROIColumn(attributionModel).id!;
    }
    if (columnId === 'conversions') {
      return getConversionsColumn(attributionModel).id!;
    }
    if (columnId === 'cpa') {
      return getCPAColumn(attributionModel).id!;
    }
    return columnId;
  });
}

function CampaignsColumns(
  attributionModel: TAttributionModel,
  includeProjectColumn: boolean,
): Array<IPermissionColumn & IColumn<BaseCampaign>> {
  const account = useAccount();
  const columns = getDefaultColumns(includeProjectColumn);
  return getColumns(attributionModel, columns).filter(
    (col: IPermissionColumn & IColumn<BaseCampaign>) =>
      col.shouldShow ? !!account && col.shouldShow(account) : true,
  );
}

export default CampaignsColumns;
