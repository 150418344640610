import type { TFunction } from 'i18next';
import { Observer, observer, useLocalObservable } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useRouteMatch } from 'react-router';

import type { Campaign, TAttributionModel, TCampaignGroup } from '@feathr/blackbox';
import { CampaignClass, CampaignGroupMap, CampaignState } from '@feathr/blackbox';
import type { ISorted } from '@feathr/components';
import { Table, Toolbar } from '@feathr/components';
import Page from '@feathr/extender/App/Page';
import { useGoogleAdsPermissions } from '@feathr/extender/hooks';
import { StoresContext, useAccount, useUser } from '@feathr/extender/state';
import { removeEmpty } from '@feathr/hooks';
import type { IPagination } from '@feathr/rachis';

import AddCampaignButton from './AddCampaignButton';
import campaignsColumns, { getColumnIds } from './campaignsColumns';
import CampaignsColumns from './CampaignsColumns/CampaignsColumns';
import CampaignsFiltersFirst from './CampaignsFiltersFirst';
import type { IFiltersFirst } from './CampaignsFiltersFirst/CampaignsFiltersFirst';
import CampaignsFiltersSecond from './CampaignsFiltersSecond';
import type { IFiltersSecond } from './CampaignsFiltersSecond/CampaignsFiltersSecond';
import { defaultColumnIdsMap } from './defaultColumns';
import ExportCampaignsButton from './ExportCampaignsButton';

import * as styles from './CampaignsPage.css';

interface IFilters extends IFiltersFirst, IFiltersSecond {}

interface ISettings {
  attributionModel: TAttributionModel;
  columnIds: string[];
}

export interface ICampaignsListFilters {
  _cls__in?: CampaignClass[];
  _cls__nin?: CampaignClass[];
  _cls__ne?: CampaignClass;
  date_end__gte?: string;
  date_end__lte?: string;
  date_start__gte?: string;
  date_start__lte?: string;
  event__in: string[];
  'exposure_settings.target_type'?: 'fixed_impressions';
  is_archived__ne: boolean;
  name__icontains?: string;
  _parent?: string;
  parent_kind?: 'partner' | 'event';
  participants?: string[];
  state__in?: CampaignState[];
  state__ne: CampaignState;
}

function parseFilters(
  filters: IFilters,
  projectIds: string[],
  campaignGroup: TCampaignGroup,
  showGoogleAds: boolean,
  reset = false,
): Partial<ICampaignsListFilters> {
  /**
   * Only show monetization campaigns if it is the monetization or all campaigns page.
   */
  const nonMonetization = campaignGroup === 'all' ? undefined : 'event';
  const parentKind = campaignGroup === 'monetization' ? 'partner' : nonMonetization;

  const realFilters: Partial<ICampaignsListFilters> = {
    is_archived__ne: true,
    // Filter out google ads if the user is not connected to a google ads account.
    _cls__in: showGoogleAds
      ? CampaignGroupMap[campaignGroup]
      : CampaignGroupMap[campaignGroup].filter(
          (campaign) => campaign !== CampaignClass.GoogleAdsSmart,
        ),
    state__ne: CampaignState.Archived,
    parent_kind: parentKind,
  };

  const monetization = filters.types?.find((type) => type === 'monetization');
  if (monetization) {
    realFilters.parent_kind = 'partner';
    realFilters['exposure_settings.target_type'] = 'fixed_impressions';
  }

  const otherTypes: CampaignClass[] = filters.types?.filter(
    (type) => type !== 'monetization',
  ) as CampaignClass[];
  if (otherTypes && otherTypes.length) {
    realFilters._cls__in = otherTypes;
  }

  if (reset) {
    // Return early to provide only necessary filters.
    return realFilters;
  }

  Object.assign(
    realFilters,
    removeEmpty({
      event__in: projectIds,
      name__icontains: filters.name,
      _parent: filters.partner,
      participants: filters.participants,
      date_start__gte: filters.date_start,
      date_end__lte: filters.date_end,
      state__in: filters.state__in,
    }),
  );

  return realFilters;
}

const initialSort: ISorted[] = [{ id: 'date_created', desc: true }];

interface ICampaignsPageProps {
  campaignGroup: TCampaignGroup;
}

interface ITableSettings {
  campaignGroup: TCampaignGroup;
  filters: IFilters;
  pagination: IPagination;
  sort: ISorted[];
}

function getTitle(t: TFunction, campaignGroup: TCampaignGroup): string {
  const titleMap: Record<TCampaignGroup, string> = {
    ads: t('Ads'),
    all: t('All Marketing'),
    email: t('Email Marketing'),
    monetization: t('Monetization Campaigns'),
    'google-ads': t('Google Ads'),
    other: t('Other Marketing'),
  };
  return titleMap[campaignGroup];
}

function CampaignsPage({ campaignGroup }: ICampaignsPageProps): JSX.Element | null {
  const { eventId: projectId } = useParams<{ eventId?: string }>();
  const account = useAccount();
  const user = useUser();
  const { Campaigns } = useContext(StoresContext);
  const [attributionModel, setAttributionModel] = useState<TAttributionModel>('full');
  const [columnIds, setColumnIds] = useState<string[]>([]);
  const { t } = useTranslation();
  const match = useRouteMatch<{ primary: string; secondary: string }>({
    path: '/:accountId/:primary/:secondary',
  });
  const isGlobal = projectId === undefined;
  const includeProjectColumn = isGlobal;

  const showGoogleAds = useGoogleAdsPermissions();

  /*
   * If we're at the account level there will be no project id, so we want to save the user
   * settings based on where we are, ie. "marketing/all" or "marketing/ads"
   */
  const sessionFiltersKey = projectId ?? `${match!.params.primary}/${match!.params.secondary}`;

  const state = useLocalObservable(() => ({
    campaigns: Campaigns.newListResponse(),
    setCampaigns: (campaigns): void => {
      state.campaigns = campaigns;
    },
    filters: {
      name: undefined,
      event__in: projectId ? [projectId] : [],
      active: undefined,
      types: [],
      partner: undefined,
      participants: undefined,
      date_start: undefined,
      date_end: undefined,
      state__in: [],
    },
    setFilters: (filters): void => {
      state.filters = filters;
    },
    pagination: {
      page: 0,
      page_size: 20,
    },
    setPagination: (pagination): void => {
      state.pagination = pagination;
    },
    sort: initialSort,
    setSort: (sort): void => {
      state.sort = sort;
    },
  }));

  function updateColumnIds(newColumnIds: string[]): void {
    const setting = isGlobal ? 'displayed_campaign_columns_global' : 'displayed_campaign_columns';
    const displayedCampaignColumns = user.getSetting(setting);
    if (displayedCampaignColumns) {
      // Only delete if there is an existing object.
      delete displayedCampaignColumns[campaignGroup];
    }
    const newSettings = { ...displayedCampaignColumns, [campaignGroup]: newColumnIds } as Record<
      TCampaignGroup,
      string[]
    >;
    user.setSetting(setting, newSettings);

    setColumnIds(newColumnIds);
    user.patchDirty();
  }

  function onPageSizeChange(newPageSize: number, newPage: number): void {
    state.setPagination({ page: newPage, page_size: newPageSize });
  }

  function onPageChange(newPage: number): void {
    state.setPagination({ page: newPage, page_size: state.pagination.page_size });
  }

  function onChangeFilters(newFilters: IFiltersFirst | IFiltersSecond): void {
    const filter: IFilters = { ...state.filters, ...newFilters };
    // If we are filtering by seed group, we also want to filter by lookalike
    if (filter.types?.includes(CampaignClass.SeedSegment)) {
      filter.types.push(CampaignClass.Lookalike);
    }
    state.setFilters(filter);
    state.setPagination({ page: 0, page_size: state.pagination.page_size });
  }

  function onChangeSettings(settings: ISettings): void {
    setAttributionModel(settings.attributionModel);
    updateColumnIds(settings.columnIds);
  }

  function onSortChange(newSort: ISorted[]): void {
    state.setSort(newSort);
  }

  const getCampaigns = useCallback(async () => {
    const filters = parseFilters(
      state.filters,
      projectId ? [projectId] : state.filters.event__in,
      campaignGroup,
      showGoogleAds,
    );
    if (!filters._cls__ne) {
      filters._cls__ne = CampaignClass.PinpointPartnerMessage;
    }
    const campaigns = await Campaigns.list({
      filters,
      pagination: state.pagination,
      ordering: [`${state.sort[0].desc ? '-' : ''}${state.sort[0].id}`],
    });
    state.setCampaigns(campaigns);
  }, [Campaigns, campaignGroup, projectId, state, showGoogleAds]);

  useEffect(() => {
    const tableSettings = sessionStorage.getItem(sessionFiltersKey);
    if (tableSettings) {
      const {
        campaignGroup: parsedCampaignGroup,
        filters,
        pagination: parsedPagination,
        sort,
      } = JSON.parse(tableSettings) as ITableSettings;
      const { pagination, setFilters, setPagination, setSort } = state;
      if (parsedCampaignGroup !== campaignGroup) {
        state.setFilters({
          name: undefined,
          active: undefined,
          types: [],
          partner: undefined,
          participants: undefined,
          event__in: projectId ? [projectId] : [],
        });
        setPagination({ page: 0, page_size: pagination.page_size });
        setSort(initialSort);
      } else {
        setFilters(filters);
        setPagination(parsedPagination);
        setSort(sort);
      }
    }
    getCampaigns();
  }, [projectId, campaignGroup, getCampaigns, state, sessionFiltersKey]);

  useEffect(() => {
    if (!account.isPending) {
      setAttributionModel(account.get('attribution_model', 'full') as TAttributionModel);
    }
  }, [account.isPending]);

  useEffect(() => {
    if (!user.isPending) {
      const setting = isGlobal ? 'displayed_campaign_columns_global' : 'displayed_campaign_columns';

      const displayedCampaignColumns = user.getSetting(setting);
      const ids = displayedCampaignColumns?.[campaignGroup]?.length
        ? displayedCampaignColumns[campaignGroup]
        : defaultColumnIdsMap(includeProjectColumn)[campaignGroup];

      setColumnIds(ids);
    }
  }, [user.isPending, campaignGroup, projectId, includeProjectColumn, isGlobal]);

  useEffect(() => {
    const noFilters = sessionStorage.getItem(sessionFiltersKey) === null;
    const newTableSettings = {
      campaignGroup: campaignGroup,
      filters: state.filters,
      pagination: state.pagination,
      sort: state.sort,
    };

    if (noFilters) {
      Object.assign(newTableSettings, {
        filters: parseFilters(
          state.filters,
          projectId ? [projectId] : [],
          campaignGroup,
          showGoogleAds,
          true,
        ),
        pagination: { page: 0, page_size: 20 },
        sort: initialSort,
      });
      state.setFilters(newTableSettings.filters);
    }

    sessionStorage.setItem(sessionFiltersKey, JSON.stringify(newTableSettings));
    getCampaigns();
  }, [
    campaignGroup,
    projectId,
    getCampaigns,
    state.filters,
    state,
    state.pagination,
    state.sort,
    sessionFiltersKey,
    showGoogleAds,
  ]);

  const columns = campaignsColumns(attributionModel, includeProjectColumn);
  const updatedColumnIds = getColumnIds(attributionModel, columnIds);

  const filterElements = [
    <CampaignsFiltersFirst
      campaignGroup={campaignGroup}
      context={projectId ? 'project' : 'global'}
      filters={state.filters}
      key={'first'}
      onChange={onChangeFilters}
    />,
    <CampaignsFiltersSecond
      campaignGroup={campaignGroup}
      filters={state.filters}
      // This is needed to determine label based on if there are any PinpointEmailBaseCampaign campaigns.
      items={state.campaigns.models}
      key={'second'}
      onChangeFilters={onChangeFilters}
      onChangeSettings={onChangeSettings}
      settings={{ attributionModel, columnIds }}
    />,
  ];

  const actions = (
    <Toolbar>
      <Observer>
        {function useAnonymousFunction(): JSX.Element {
          return (
            <ExportCampaignsButton
              params={{
                attribution_model: attributionModel,
                columns: updatedColumnIds,
                filters: parseFilters(
                  state.filters,
                  projectId ? [projectId] : state.filters.event__in,
                  campaignGroup,
                  showGoogleAds,
                ),
                sort: state.sort.map((s) => `${s.desc ? '-' : ''}${s.id}`),
              }}
            />
          );
        }}
      </Observer>
      <AddCampaignButton campaignGroup={campaignGroup} />
    </Toolbar>
  );

  return (
    <Page actions={actions} title={getTitle(t, campaignGroup)}>
      <Table<Campaign>
        columns={CampaignsColumns(columns, updatedColumnIds)}
        filterElements={filterElements}
        filterLabel={<></>}
        filtersClassName={styles.filterElements}
        idKey={'id'}
        isLoading={state.campaigns.isPending}
        isPaginated={true}
        items={state.campaigns.models}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        onSortChange={onSortChange}
        page={state.pagination.page}
        pages={state.campaigns.pagination.pages}
        pageSize={state.pagination.page_size}
        sort={state.sort}
      />
    </Page>
  );
}

export default observer(CampaignsPage);
