import { when } from 'mobx';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import type { ValueType } from 'react-select';

import type { Campaign, ICampaignAttributes } from '@feathr/blackbox';
import { CampaignClass, CampaignState } from '@feathr/blackbox';
import {
  ArchiveModal,
  ConfirmModal,
  ContextMenu,
  EAlertV2Type,
  MenuItem,
  toast,
  Tooltip,
} from '@feathr/components';
import CampaignSummary from '@feathr/extender/App/EventsPage/CampaignSummary';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import EventSelect from '../EventSelect';

interface ICampaignOptions {
  campaign: Campaign;
  showFlightOptions?: boolean;
}

export interface IEventProps {
  project_id: string;
  name: string;
}

function CampaignOptions({ campaign, showFlightOptions = false }: ICampaignOptions): JSX.Element {
  const rootStore = React.useContext(StoresContext);
  const { Events } = rootStore;
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isRemoveFromFlightModalOpen, toggleRemoveFromFlightModalOpen] = useToggle(false);
  const [isCloneModalOpen, toggleCloneModalOpen] = useToggle(false);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);
  const [selectedEvent, setSelectedEvent] = useState<ValueType<IEventProps>>();
  const { t } = useTranslation();

  const eventId = campaign.get('event');
  const eventName = eventId ? Events.get(eventId).name : '';
  const state = campaign.get('state');
  const type = campaign.get('_cls');

  const isStoppedSmartPinpointCampaign =
    (type === CampaignClass.AutoPinpointEmail || type === CampaignClass.SmartPinpointEmail) &&
    state === CampaignState.Stopped;

  useEffect(() => {
    if (!selectedEvent) {
      setSelectedEvent({ project_id: eventId, name: eventName });
    }
  }, []);

  async function getFlight() {
    const { Flights } = rootStore;
    const flight = Flights.get(campaign.get('flight')!);
    await when(() => !flight.isPending);
    return Flights.get(flight.id);
  }

  async function toggleEnabled() {
    if (isStoppedSmartPinpointCampaign) {
      /*
       * Auto Send Campaigns that are re-enabled send to the full audience due to a bug in Pinpoint
       * TODO: remove this conditional when resolved in pp
       */
      toast(
        t(
          'Auto Send and Smart campaigns cannot be re-enabled. Please clone this campaign instead.',
        ),
        {
          type: 'error',
        },
      );
      return;
    }
    const newState =
      state === CampaignState.Stopped ? CampaignState.Published : CampaignState.Stopped;
    campaign.set({
      is_enabled: newState !== CampaignState.Stopped,
      is_archived: false,
      state: newState,
    });
    await campaign.save();
    toast(
      t('Campaign {{name}} state has been updated to {{state}}.', {
        name: campaign.name,
        state: newState,
      }),
    );
  }

  async function clone() {
    const { Campaigns, Flights } = rootStore;
    const clonedCampaign: Campaign = await campaign.clone(
      selectedEvent as Partial<ICampaignAttributes>,
    );
    const flightId = campaign.get('flight');
    if (flightId) {
      await Flights.reload(flightId, ['legs']);
    }
    Campaigns.clearApiCache();
    history.push(localUrl(clonedCampaign.getItemUrl()));
  }

  async function archive() {
    campaign.set({
      is_archived: true,
      state: CampaignState.Archived,
    });
    if (campaign.get('flight')) {
      const flight = await getFlight();
      flight.removeCampaign(campaign.id);
      campaign.set({ flight: undefined });
      await Promise.all([campaign.save(), flight.save()]);
    } else {
      await campaign.save();
    }
    campaign.collection!.remove(campaign.id);
    toast(t('Campaign {{name}} has been archived.', { name: campaign.name }));
    toggleArchiveModalOpen();
  }

  async function destroy() {
    if (campaign.get('flight')) {
      const flight = await getFlight();
      flight.removeCampaign(campaign.id);
      await flight.save();
    }
    const name = campaign.name;
    campaign.set({
      is_archived: true,
      state: CampaignState.Archived,
    });
    await campaign.delete();
    toast(t('Campaign {{name}} has been deleted.', { name }));
    toggleDeleteModalOpen();
  }

  async function removeFromFlight() {
    const { Campaigns } = rootStore;
    if (campaign.get('flight')) {
      const flight = await getFlight();
      flight.removeCampaign(campaign.id);
      await flight.save();
    }
    campaign.set({ flight: undefined });
    await campaign.save();
    Campaigns.removeFromApiCache(campaign.id);
    toast(t('Campaign {{name}} was removed from flight.', { name: campaign.name }));
  }

  const stateChangeMenuItem = (
    <MenuItem disabled={isStoppedSmartPinpointCampaign} onClick={toggleEnabled}>
      {state === CampaignState.Stopped ? t('Enable') : t('Disable')}
    </MenuItem>
  );

  const flightOrProject = campaign.get('flight') ? t('flight') : t('project');
  const flightOrProjectURL = campaign.get('flight')
    ? localUrl(campaign.getItemUrl())
    : localUrl(`/projects/${campaign.get('parent')}/report`);

  const alertPageLink = (
    <a href={flightOrProjectURL} target={'_blank'}>
      {t('View {{flightOrProject}} report', { flightOrProject })}
    </a>
  );

  function handleChangeEvent(event: ValueType<IEventProps>): void {
    setSelectedEvent(event);
  }

  function handleClearEvent(): void {
    setSelectedEvent(undefined);
  }

  return (
    <>
      <ContextMenu>
        <StoresContext.Provider value={rootStore}>
          <MenuItem link={localUrl(campaign.getItemUrl('edit'))}>Edit</MenuItem>
          {campaign.get('_cls') === CampaignClass.Lookalike ? (
            <Tooltip
              title={t('This lookalike campaign type is outdated and can no longer be cloned.')}
            >
              <MenuItem disabled={true}>Clone</MenuItem>
            </Tooltip>
          ) : (
            <MenuItem onClick={toggleCloneModalOpen}>{t('Clone')}</MenuItem>
          )}
          {state !== CampaignState.Draft && !isStoppedSmartPinpointCampaign && stateChangeMenuItem}
          {state !== CampaignState.Draft && isStoppedSmartPinpointCampaign && (
            <Tooltip
              title={t(
                'Auto Send and Smart campaigns cannot be re-enabled. Please clone this campaign instead.',
              )}
            >
              {stateChangeMenuItem}
            </Tooltip>
          )}
          {showFlightOptions && (
            <MenuItem onClick={toggleRemoveFromFlightModalOpen}>{t('Remove from flight')}</MenuItem>
          )}
          <MenuItem onClick={toggleArchiveModalOpen}>{t('Archive')}</MenuItem>
          {state === CampaignState.Draft && (
            <MenuItem onClick={toggleDeleteModalOpen}>{t('Delete')}</MenuItem>
          )}
        </StoresContext.Provider>
      </ContextMenu>

      {isRemoveFromFlightModalOpen && (
        <ConfirmModal
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Remove')}
          confirmButtonType={'danger'}
          message={t('Are you sure you want to remove this campaign from this flight?')}
          messageDescription={t(
            'Removing this campaign from this flight will make this flight\'s reported conversions inaccurate. To recalculate conversions to exclude the removed campaign, select "Recalculate conversions" from the conversions table on this flight\'s report.',
            { flightOrProject },
          )}
          messageLinks={alertPageLink}
          onClose={toggleRemoveFromFlightModalOpen}
          onConfirm={removeFromFlight}
          t={t}
          title={t('Remove From Flight')}
          type={EAlertV2Type.warning}
        />
      )}
      {isCloneModalOpen && (
        <ConfirmModal
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Clone')}
          message={t('Are you sure you want to clone this campaign?')}
          messageDescription={t(
            'To recalculate conversions to include the newly cloned campaign, select "Recalculate conversions" from the conversions table on this flight\'s report.',
            { flightOrProject },
          )}
          messageLinks={alertPageLink}
          onClose={toggleCloneModalOpen}
          onConfirm={clone}
          t={t}
          title={t('Clone Campaign')}
          type={EAlertV2Type.warning}
        >
          <CampaignSummary campaign={campaign} />

          {type === CampaignClass.PinpointEmail && (
            <Observer>
              {(): JSX.Element => (
                <EventSelect
                  helpText={t('This campaign will be cloned to the selected project.')}
                  id={eventId}
                  label={t('Project')}
                  onChange={handleChangeEvent}
                  onClear={handleClearEvent}
                  placeholder={t('Select a project...')}
                  value={selectedEvent}
                />
              )}
            </Observer>
          )}
        </ConfirmModal>
      )}
      {isArchiveModalOpen && (
        <ArchiveModal
          message={t('Are you sure you want to archive this campaign?')}
          messageDescription={t(
            'Archiving this campaign will make this {{flightOrProject}}\'s reported conversions inaccurate. To recalculate conversions to exclude the archived campaign, select "Recalculate conversions" from the conversions table on this {{flightOrProject}}\'s report.',
            { flightOrProject },
          )}
          messageLinks={alertPageLink}
          model={campaign}
          onClose={toggleArchiveModalOpen}
          onConfirm={archive}
          t={t}
        />
      )}
      {isDeleteModalOpen && (
        <ArchiveModal
          confirmButtonText={t('Delete')}
          confirmButtonType={'danger'}
          message={t(
            'Are you sure you want to delete this campaign? This action cannot be undone.',
          )}
          messageDescription={t(
            'Deleting this campaign will make this {{flightOrProject}}\'s reported conversions inaccurate. To recalculate conversions to exclude the deleted campaign, select "Recalculate conversions" from the conversions table on this {{flightOrProject}}\'s report.',
            { flightOrProject },
          )}
          messageLinks={alertPageLink}
          method={'delete'}
          onClose={toggleDeleteModalOpen}
          onConfirm={destroy}
          t={t}
          title={t('Delete Campaign')}
        />
      )}
    </>
  );
}

export default CampaignOptions;
