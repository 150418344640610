import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TFunction } from 'i18next';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Template } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, PlaceholderImage, TableColumnHeader, Time } from '@feathr/components';
import {
  templateGroupLabel,
  templateGroupTheme,
  templateTypeIcon,
  templateTypeTheme,
} from '@feathr/extender/styles/template';
import { TimeFormat } from '@feathr/hooks';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './templateSelectColumns.css';

import noImg from 'images/no-img.png';

function templateSelectColumns(t: TFunction): Array<IColumn<Template>> {
  return [
    {
      id: 'thumbnail',
      Header: TableColumnHeader({
        title: 'Thumbnail',
      }),
      sortable: false,
      className: styles.thumbnail,
      width: 220,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const url = original.get('thumbnail_url', noImg);
              if (original.isBanner() && original.shouldUsePlaceholder()) {
                return (
                  <PlaceholderImage
                    height={original.get('height')!}
                    width={original.get('width')!}
                  />
                );
              }
              return <img alt={''} src={url} />;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'name',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: 'Name',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return <>{original.name}</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: '_cls',
      Header: TableColumnHeader({
        title: 'Type',
      }),
      sortable: false,
      className: tableStyles.cellCenter,
      width: 150,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const cls = original.get('_cls');
              const typeIcon = templateTypeIcon(cls);
              return (
                <Chip
                  prefix={typeIcon ? <FontAwesomeIcon icon={typeIcon} /> : undefined}
                  theme={templateTypeTheme(cls)}
                >
                  {original.getTypeLabel()}
                </Chip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'group',
      Header: TableColumnHeader({
        title: 'Group',
      }),
      sortable: false,
      className: tableStyles.cellCenter,
      width: 150,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              const isDefault = !!original.get('default');
              const isAccount = original.get('parent_kind') === 'account';
              const isEvent = original.get('parent_kind') === 'event';
              const isCampaign = original.get('parent_kind') === 'campaign';

              if (isDefault) {
                return (
                  <Chip theme={templateGroupTheme('default')}>
                    {templateGroupLabel('default', t)}
                  </Chip>
                );
              } else if (isAccount) {
                return (
                  <Chip theme={templateGroupTheme('account')}>
                    {templateGroupLabel('account', t)}
                  </Chip>
                );
              } else if (isEvent) {
                return (
                  <Chip theme={templateGroupTheme('project')}>
                    {templateGroupLabel('project', t)}
                  </Chip>
                );
              } else if (isCampaign) {
                return (
                  <Chip theme={templateGroupTheme('campaign')}>
                    {templateGroupLabel('campaign', t)}
                  </Chip>
                );
              }
              return <>&nbsp;</>;
            }}
          </Observer>
        );
      },
    },
    {
      id: 'date_last_modified',
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: 'Last Updated',
      }),
      headerClassName: tableStyles.sort,
      className: tableStyles.cell,
      width: 150,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return (
                <Time
                  format={TimeFormat.shortDateTime}
                  timestamp={original.get('date_last_modified')}
                />
              );
            }}
          </Observer>
        );
      },
    },
  ];
}

export default templateSelectColumns;
