import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign, Goal } from '@feathr/blackbox';
import { Button, Toolbar } from '@feathr/components';
import CampaignPage from '@feathr/extender/App/EventsPage/CampaignPage';
import { useLocalUrl } from '@feathr/extender/state';

import CampaignReportSection from '../CampaignReportSection';

interface IProps {
  goals: IObservableArray<Goal>;
  campaign: Campaign;
  eventId: string;
}

function DefaultCampaignPage({ campaign, goals }: IProps): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const actions = (
    <Toolbar>
      <Button
        link={localUrl(`${campaign.getItemUrl()}/edit`)}
        prefix={<FontAwesomeIcon icon={faEdit} />}
      >
        {t('Edit')}
      </Button>
    </Toolbar>
  );

  return (
    <CampaignPage actions={actions} campaign={campaign} title={campaign.name}>
      <h3>{t('Report')}</h3>
      <CampaignReportSection campaign={campaign} goals={goals} />
    </CampaignPage>
  );
}

export default DefaultCampaignPage;
