import { faBullhorn } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { type Campaign, CampaignLabelMap } from '@feathr/blackbox';
import { Button, Chip, TLDR } from '@feathr/components';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { campaignColorMap, campaignIconMap } from '@feathr/extender/styles/campaign';

import * as styles from './CampaignTLDR.css';

interface IProps {
  readonly campaign?: Campaign;
}

function CampaignTLDR({ campaign }: IProps): JSX.Element {
  const { t } = useTranslation();
  const localUrl = useLocalUrl();
  const { Creatives } = useContext(StoresContext);
  const creatives = campaign?.isCreativesCampaign
    ? Creatives.list({ filters: { _parent: campaign.id }, pagination: { page_size: 1 } })
    : undefined;

  if (campaign === undefined || campaign.isPending || creatives?.isPending) {
    return <TLDR isLoading={true} />;
  }

  const type = campaign.get('_cls');
  const chip = (
    <Chip
      className={styles.chip}
      prefix={<FontAwesomeIcon icon={campaignIconMap.get(type) ?? faBullhorn} />}
      theme={campaignColorMap.get(type)}
    >
      {CampaignLabelMap.get(type) ?? t('Campaign')}
    </Chip>
  );

  return (
    <TLDR
      action={<Button href={localUrl(campaign.getItemUrl())}>{t('View report')}</Button>}
      description={chip}
      isLoading={false}
      title={campaign.name}
    />
  );
}

export default observer(CampaignTLDR);
