import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type {
  AggregatedStat,
  Campaign,
  FacebookCampaignInsight,
  PinpointEmailBaseCampaign,
} from '@feathr/blackbox';
import { reportModuleLabels } from '@feathr/blackbox';
import { StatsCard } from '@feathr/components';

import {
  ClicksStat,
  ConversionsStat,
  NewUsersStat,
  ReachableNewAudienceStat,
  ReachStat,
  UniqueClicksStat,
  ViewsStat,
} from '../Stats';
import EmailStats from './EmailStats';

interface ICardProps {
  aggregatedStats: AggregatedStat;
  campaign?: Campaign;
  clicksLabel?: string;
  clicksTooltip?: string;
  conversions?: number;
  facebookCampaignStats?: FacebookCampaignInsight;
  isMonetization?: boolean;
  reachLabel?: string;
  reachTooltipText?: string;
  targetImpressions?: number;
  totalUsers?: number;
  uniqueClicksLabel?: string;
  uniqueClicksTooltipText?: string;
  viewsLabel?: string;
  viewsTooltip?: string;
  isEventOrFlight?: boolean;
}

function ActivitySummaryCard({
  campaign,
  clicksLabel,
  clicksTooltip,
  conversions,
  aggregatedStats,
  facebookCampaignStats,
  isMonetization,
  reachLabel,
  reachTooltipText,
  targetImpressions,
  totalUsers,
  uniqueClicksLabel,
  uniqueClicksTooltipText,
  viewsLabel,
  viewsTooltip,
  isEventOrFlight,
}: ICardProps): JSX.Element {
  const flavors = aggregatedStats.get('flavors', {});
  const clicks = () => {
    const {
      ad_click: adClick = 0,
      page_link_click: pageLinkClick = 0,
      email_link_click: emailLinkClick = 0,
    } = flavors;
    return campaign?.isGoogle
      ? aggregatedStats.get('num_clicks', 0)
      : adClick + pageLinkClick + emailLinkClick;
  };
  const newUsers = aggregatedStats.get('num_new_users_new') || 0;
  const views = campaign?.isGoogle
    ? aggregatedStats.get('num_views', 0)
    : (flavors.ad_view || 0) + (flavors.page_view || 0) + (flavors.email_view || 0);
  let users;
  let uniqueClicks;
  if (campaign?.isFacebook && facebookCampaignStats) {
    users = facebookCampaignStats.get('reach');
    uniqueClicks = facebookCampaignStats.get('unique_clicks');
  } else {
    users = aggregatedStats.get('num_users_new', 0);
    uniqueClicks = aggregatedStats.get('num_clicks_new', 0);
  }

  const reachableNewAudience = aggregatedStats.get('num_new_users_new_reachable', 0);

  return (
    <StatsCard title={reportModuleLabels.includeActivitySummary}>
      {campaign?.isEmail ? (
        <EmailStats
          aggregatedStats={aggregatedStats}
          campaign={campaign as PinpointEmailBaseCampaign}
        />
      ) : (
        <>
          {!campaign?.isGoogle && (
            <ReachStat
              isMonetization={isMonetization}
              label={reachLabel}
              tooltip={reachTooltipText}
              totalUsers={totalUsers}
              value={users}
            />
          )}
          {!isEventOrFlight && (
            <ViewsStat
              conversions={conversions}
              isMonetization={isMonetization}
              label={viewsLabel}
              targetImpressions={targetImpressions}
              tooltip={viewsTooltip}
              users={users}
              value={views}
            />
          )}
          {!campaign?.isGoogle && (
            <UniqueClicksStat
              label={uniqueClicksLabel}
              tooltip={uniqueClicksTooltipText}
              value={uniqueClicks}
            />
          )}
          {!isEventOrFlight && (
            <ClicksStat
              label={clicksLabel}
              tooltip={clicksTooltip}
              type={campaign?.get('_cls')}
              value={clicks()}
              views={views}
            />
          )}
          {campaign?.isGoogle && (
            <ConversionsStat goals={campaign.get('goals', [])} value={conversions ?? 0} />
          )}
          {newUsers > 0 && (
            <NewUsersStat
              tooltip={'The combined audience from all audience expansion campaigns in the Flight.'}
              value={newUsers}
            />
          )}
          {campaign?.isAwarenessCampaign && (
            <ReachableNewAudienceStat
              isMonetization={isMonetization}
              totalUsers={totalUsers}
              value={reachableNewAudience}
            />
          )}
        </>
      )}
    </StatsCard>
  );
}

export default observer(ActivitySummaryCard);
