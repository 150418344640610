import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import type { TAttributionModel } from '@feathr/blackbox';
import Page from '@feathr/extender/App/Page';
import ReportWrapper from '@feathr/extender/components/ReportWrapper';
import { StoresContext } from '@feathr/extender/state';
import { EventReport } from '@feathr/report_components';

interface IConfig {
  attributionModel: TAttributionModel;
  includeActivitySummary: boolean;
  includeAdActivity: boolean;
  includeAdPerformance: boolean;
  includeConversionsTable: boolean;
  includeEmailActivity: boolean;
  includeEmailPerformance: boolean;
  includeGoogleActivity: boolean;
  includePageActivity: boolean;
  includeROI: boolean;
  includeSpendPerformance: boolean;
}

function EventReportPage(): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();
  const { Events } = useContext(StoresContext);
  const event = Events.get(eventId);

  const config: IConfig = {
    attributionModel: 'full',
    includeActivitySummary: true,
    includeAdActivity: true,
    includeAdPerformance: true,
    includeConversionsTable: false,
    includeEmailActivity: true,
    includeGoogleActivity: true,
    includeEmailPerformance: true,
    includePageActivity: true,
    includeROI: false,
    includeSpendPerformance: true,
  };

  return (
    <Page loading={event.isPending} title={'Report'}>
      <ReportWrapper initialConfig={config} model={event} Report={EventReport} />
    </Page>
  );
}

export default observer(EventReportPage);
