import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StatBox } from '@feathr/components';

import * as styles from './DashboardStats.css';

export function DashboardStats(): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <StatBox
        align={'center'}
        border={'right'}
        label={t('Community')}
        primary={'888.88m'}
        secondaryLabel={t('People')}
        statSize={'large'}
        theme={'purple'}
        tooltip={t(
          'This metric evaluates your success in acquiring new audience members and expanding your reach.',
        )}
        tooltipIcon={faInfoCircle}
      />
      <StatBox
        align={'center'}
        border={'none'}
        label={t('Connections')}
        primary={'888.88m'}
        secondaryLabel={t('People')}
        statSize={'large'}
        theme={'cyan'}
        tooltip={t(
          'This metric measures your success in terms of deepening your engagement with existing audiences',
        )}
        tooltipIcon={faInfoCircle}
      />
      <StatBox
        align={'center'}
        border={'left'}
        label={t('Conversions')}
        primary={'888.88k'}
        secondaryLabel={t('People')}
        statSize={'large'}
        theme={'green'}
        tooltip={t(
          'This metric assesses your impact in terms of driving the actions and conversions that advance your organization’s mission.',
        )}
        tooltipIcon={faInfoCircle}
      />
    </div>
  );
}

export default DashboardStats;
