import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import { Session } from '@feathr/blackbox';
import { Menu, MenuItem, toast } from '@feathr/components';

import styles from './UserNav.css';

function UserNav(): JSX.Element {
  const { t } = useTranslation();

  async function handleLogOut(): Promise<void> {
    try {
      await Session.logOut(() => {
        window.location.assign(
          `${Session.logInUrl}?from=${encodeURIComponent(window.location.href)}`,
        );
      });
    } catch (error) {
      toast(t('There was an error logging out.'), {
        type: ToastType.ERROR,
      });
    }
  }

  return (
    <Menu className={styles.root}>
      <MenuItem onClick={handleLogOut} prefix={<FontAwesomeIcon icon={faSignOut} />}>
        Log out
      </MenuItem>
    </Menu>
  );
}

export default observer(UserNav);
