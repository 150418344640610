import type { SetupWorkerApi } from 'msw';

function initMsw(): void {
  if (process.env.NODE_ENV === 'development' && process.env.MSW) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const { worker } = require('./browser.ts') as { worker: SetupWorkerApi };
    worker.start({
      onUnhandledRequest: 'bypass',
    });
    // console.log(worker.listHandlers());
  }
}

export default initMsw;
