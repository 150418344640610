/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ValidateOption } from 'validate.js';
import validate from 'validate.js';

import { moment, TimeFormat } from '@feathr/hooks';

import type { Model } from '../model';
import array from './array';
import async from './async';
import caseInsensitiveExclusion from './caseInsensitiveExclusion';
import domain from './domain';
import duplicates from './duplicates';
import list from './list';
import presenceUnless from './presenceUnless';
import search from './search';
import urlWithoutProtocol from './urlWithoutProtocol';

export interface IValidateResult {
  attribute: string;
  value: string;
  validator: keyof IValidateConstraints;
  globalOptions: ValidateOption;
  attributes: any;
  options: any;
  error: string;
}

export interface IValidateConstraints {
  array: any;
  async: any;
  date: any;
  datetime: any;
  domain: any;
  email: any;
  equality: any;
  exclusion: any;
  format: any;
  inclusion: any;
  length: any;
  list: any;
  numericality: any;
  presence: any;
  presenceUnless: any;
  url: any;
  urlWithoutProtocol: any;
}

export interface IValidateGlobalOptions {
  format?: 'detailed' | 'flat' | 'grouped';
  onlyCheckDirty?: boolean;
  model?: Model;
}

validate.validators = {
  ...validate.validators,
  array,
  async,
  caseInsensitiveExclusion,
  domain,
  duplicates,
  presenceUnless,
  search,
  list,
  urlWithoutProtocol,
};

validate.extend(validate.validators.datetime, {
  // Return unix timestamp from a non-null, non-undefined value
  parse(value: string) {
    return +moment.utc(value);
  },

  // Return formatted string from unix timestamp
  format(value: number, options: { dateOnly: boolean }) {
    const format = options.dateOnly ? TimeFormat.isoDate : TimeFormat.intlDateTime;
    return moment.utc(value).format(format);
  },
});

/*
 * This is needed because default grouped formatter when used with the array validator
 * dedupes the error messages making the index of the message no longer map to the correct
 * element of the array
 */
const defaultGroupedFormatter = validate.formatters.grouped;
validate.formatters.grouped = (errors: IValidateResult[]): any => {
  const nonArrayErrors = defaultGroupedFormatter(
    errors.filter((error) => error.validator !== 'array'),
  );
  const arrayErrors = errors.reduce(
    (acc, error) => {
      const list = acc[error.attribute] as string[];
      if (list) {
        list.push(error.error);
      } else {
        acc[error.attribute] = [error.error];
      }
      return acc;
    },
    {} as Record<string, string[]>,
  );
  return {
    ...nonArrayErrors,
    ...arrayErrors,
  };
};

export type { IValidateAsyncConstraint } from './async';

export default validate;
