import type { IBaseAttributes } from '@feathr/rachis';
import { Collection, Model } from '@feathr/rachis';

import { EIntegrationTypes } from './integrations';

export interface ITargeting extends IBaseAttributes {
  included: boolean;
  is_enabled: boolean;
  kind: string;
  name: string;
  parent: string;
  target_data?: string;
  integrations?: EIntegrationTypes[];
}

export class Targeting extends Model<ITargeting> {
  public static defaults: Partial<ITargeting> = {
    integrations: [EIntegrationTypes.Tradedesk],
  };
  public readonly className = 'Targeting';
  public constraints = {
    kind: {
      inclusion: [
        'email_list',
        'geo_audience',
        'geo',
        'geofence',
        'lookalike',
        'search',
        'segment',
      ],
    },
    target_data: {
      presence(...args: any[]) {
        const attributes = args[1];
        const { kind } = attributes;
        const config: Record<string, boolean | string> = { allowEmpty: false };
        switch (kind) {
          case 'segment':
            config.message =
              '^One of your targets does not have a group. Please choose or create a group.';
            break;

          case 'geo':
            config.message = '^One of your filters does not have a geographic area to filter on.';
            break;

          default:
            config.message = '^One of your targets is not fully configured.';
        }
        return config;
      },
    },
  };

  public constructor(...args: any[]) {
    super(...args);
  }

  public getDefaults(): Partial<ITargeting> {
    return Targeting.defaults;
  }
}

export class Targetings extends Collection<Targeting> {
  public getClassName() {
    return 'targetings';
  }

  public getModel(attributes: Partial<ITargeting>) {
    return new Targeting(attributes);
  }
}
