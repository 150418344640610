import {
  faBullhorn,
  faCircleMinus,
  faCirclePlus,
  faFeatherAlt,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash.capitalize';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
import type { RowRenderProps } from 'react-table';

import type {
  Campaign,
  CampaignClass,
  Campaigns as CampaignsCollection,
  Flight,
  Flights as FlightsCollection,
} from '@feathr/blackbox';
import { CampaignLabelMap } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { Chip, TableColumnHeader } from '@feathr/components';
import {
  campaignColorMap,
  campaignIconMap,
  campaignStateColorMap,
} from '@feathr/extender/styles/campaign';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './SegmentInformaticsColumns.css';

interface IProps {
  localUrl: (url: string) => string;
  Campaigns: CampaignsCollection;
  Flights: FlightsCollection;
}

export interface ISegmentUsage {
  id: string;
  kind: string;
  usage: string[];
  inclusion: boolean;
}

interface IRow extends RowRenderProps {
  original: ISegmentUsage;
}

function SegmentInformaticsColumns({
  localUrl,
  Campaigns,
  Flights,
}: IProps): Array<IColumn<ISegmentUsage>> {
  function getModel({ original }: IRow): Flight | Campaign {
    if (original.kind === 'flight') {
      return Flights.get(original.id);
    } else {
      return Campaigns.get(original.id);
    }
  }

  function getCampaignClass<T extends Flight | Campaign>(
    model: T,
    campaignType: string,
  ): CampaignClass | undefined {
    if (campaignType === 'Flight') {
      return undefined;
    } else {
      return (model as Campaign).get('_cls');
    }
  }

  function getCampaignType(model): string {
    const modelClass = model.get('_cls');
    if (!modelClass?.includes('Campaign')) {
      return 'Flight';
    } else {
      return CampaignLabelMap?.get(modelClass)?.toString() || 'Campaign';
    }
  }

  return [
    {
      id: 'name',
      Header: TableColumnHeader({
        title: 'Name',
      }),
      sortable: false,
      className: tableStyles.cell,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const model = getModel({ original });
              return !model.isPending ? (
                <Link className={styles.link} to={localUrl(model.getItemUrl())}>
                  <span>{model.name}</span>
                </Link>
              ) : (
                <div className={styles.link}>
                  <span>Loading...</span>
                </div>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'usage',
      Header: TableColumnHeader({
        title: 'Usage',
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <div className={styles.chipWrapper}>
            {original.usage.map((usage) => (
              <Chip key={usage}>{capitalize(usage)}</Chip>
            ))}
          </div>
        );
      },
    },
    {
      id: 'inclusion',
      Header: TableColumnHeader({
        title: 'Target Inclusion',
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              if (original.usage.includes('target')) {
                return (
                  <Chip
                    prefix={
                      <FontAwesomeIcon icon={original.inclusion ? faCirclePlus : faCircleMinus} />
                    }
                  >
                    {original.inclusion ? 'Included' : 'Excluded'}
                  </Chip>
                );
              } else {
                return <>-</>;
              }
            }}
          </Observer>
        );
      },
    },
    {
      id: 'type',
      Header: TableColumnHeader({
        title: 'Type',
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => {
              const model = getModel({ original });
              const campaignType = getCampaignType(model);
              const campaignClass = getCampaignClass(model, campaignType);
              if (model.isErrored) {
                return <>-</>;
              }
              return (
                <Chip
                  isLoading={model.isPending}
                  prefix={
                    <FontAwesomeIcon
                      icon={
                        !campaignClass
                          ? faFeatherAlt
                          : campaignIconMap.get(campaignClass) || faBullhorn
                      }
                    />
                  }
                  theme={campaignClass && campaignColorMap.get(campaignClass)}
                >
                  {campaignType}
                </Chip>
              );
            }}
          </Observer>
        );
      },
    },
    {
      id: 'state',
      Header: TableColumnHeader({
        title: 'Status',
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        if (original.kind === 'campaign') {
          return (
            <Observer>
              {(): JSX.Element => {
                // This component is only rendered as a campaign
                const model = getModel({ original }) as Campaign;
                if (model.isErrored) {
                  return <>-</>;
                }
                return (
                  <Chip
                    isLoading={model.isPending}
                    theme={campaignStateColorMap.get(model.get('state'))}
                  >
                    {capitalize(model.get('state'))}
                  </Chip>
                );
              }}
            </Observer>
          );
        }
        return <>-</>;
      },
    },
  ];
}

export default SegmentInformaticsColumns;
