import { faLayerPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type {
  BlackbaudRaisersEdgeIntegration,
  IBlackbaudContactMapping,
  TBlackbaudContactMappingKey,
} from '@feathr/blackbox';
import { EBlackbaudRaisersEdgeContactMappingKeys } from '@feathr/blackbox';
import type { ISelectOption, TButtonTooltip } from '@feathr/components';
import { Button, Modal, Select, toast } from '@feathr/components';
import { BlackbaudRaisersEdgeContactFieldsTranslationMap } from '@feathr/extender/styles/blackbaud_raisers_edge';
import { enumKeys, useToggle } from '@feathr/hooks';

interface IProps {
  integration: BlackbaudRaisersEdgeIntegration;
}

interface IContactMappingOption {
  id: TBlackbaudContactMappingKey;
  name: string;
}

function BlackbaudAddMapping({ integration }: IProps): JSX.Element {
  const { t } = useTranslation();
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [selectedFields, setSelectedFields] = useState<TBlackbaudContactMappingKey[]>([]);
  const [contactMappings, setContactMappings] = useState<IBlackbaudContactMapping[] | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getMappings(): Promise<void> {
      try {
        // TODO: Refactor to use ContactMappings.list() as part of #2651.
        setIsLoading(true);
        const mappings = await integration.getContactMappings();
        setIsLoading(false);
        setContactMappings(mappings);
      } catch (error) {
        setIsLoading(false);
        toast(t('Failed to load mappings.\n{{- error}}', { error }), { type: ToastType.ERROR });
      }
    }

    if (!contactMappings) {
      getMappings();
    }
  }, []);

  const contactMappingOptions: IContactMappingOption[] = enumKeys(
    EBlackbaudRaisersEdgeContactMappingKeys,
  ).map((key) => ({
    id: key,
    name: BlackbaudRaisersEdgeContactFieldsTranslationMap(t, key),
  }));

  // Filter out the contact mapping options that are already mapped
  const filteredContactMappingOptions = contactMappingOptions.filter((option) => {
    return !contactMappings?.some(
      (mapping: IBlackbaudContactMapping): boolean => mapping.key === option.id,
    );
  });

  const isDisabled = filteredContactMappingOptions.length === 0;
  const disabledTooltip: TButtonTooltip = {
    title: t("All Blackbaud Raiser's Edge contact fields have been added."),
    placement: 'left',
  };

  function handleOnClick(): void {
    toggleIsModalOpen();
  }

  async function handleConfirm(): Promise<void> {
    toggleIsModalOpen();
    try {
      await integration.addContactMappings(selectedFields);
      toast(t('Successfully added mapping.'), {
        type: ToastType.SUCCESS,
      });

      // TODO: Hard reloading will not be necesary with the implemention #2651.
      window.location.reload();
    } catch (error) {
      toast(t('Failed to add mapping. Try again.'), {
        type: ToastType.ERROR,
      });
    }
  }

  function handleSelectFields(options: ISelectOption[]): void {
    // Convert options to an array of option.id
    const selectedOptions: TBlackbaudContactMappingKey[] = options.map(
      (option) => option.id as TBlackbaudContactMappingKey,
    );
    setSelectedFields(selectedOptions);
  }

  return (
    <>
      <Button
        disabled={isDisabled || isLoading}
        onClick={handleOnClick}
        prefix={<FontAwesomeIcon icon={faLayerPlus} />}
        tooltip={isDisabled ? disabledTooltip : undefined}
        tooltipPlacement={'topLeft'}
        type={'primary'}
      >
        {t('Add mapping')}
      </Button>
      {isModalOpen && (
        <Modal
          confirmButtonText={'Add'}
          confirmButtonType={'primary'}
          confirmDisabled={selectedFields.length === 0}
          controlled={true}
          onClose={toggleIsModalOpen}
          onConfirm={handleConfirm}
          t={t}
          title={t('Add Mapping')}
        >
          <p>
            {t(
              "Select additional Blackbaud Raiser's Edge contact fields to create mappings to Feathr fields.",
            )}
          </p>
          <Select
            isMulti={true}
            label={t("Blackbaud Raiser's Edge contact fields")}
            onSelectMulti={handleSelectFields}
            options={filteredContactMappingOptions}
          />
        </Modal>
      )}
    </>
  );
}

export default observer(BlackbaudAddMapping);
