import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import debounce from 'lodash.debounce';
import { autorun, set, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useEffect, useState } from 'react';

import type { IDynamicContent, IFormField } from '@feathr/blackbox';
import { Session } from '@feathr/blackbox';
import {
  Button,
  Card,
  Checkbox,
  ColorPicker,
  Fieldset,
  Form,
  Input,
  Postscribe,
  Select,
  Toolbar,
} from '@feathr/components';
import { DEFAULT_DEBOUNCE_WAIT } from '@feathr/hooks';

import FontSelect from './FontSelect';

import * as styles from './TemplateEditor.css';

interface IProps {
  dynamicContent: IDynamicContent;
}

const getDebouncedSetter = (key: keyof IDynamicContent, dynamicContent: IDynamicContent) => {
  const debounced = debounce((value: string) => {
    set(dynamicContent, { [key]: value });
  }, DEFAULT_DEBOUNCE_WAIT);
  return (newValue?: string) => {
    debounced(newValue ?? '');
  };
};

// TODO: Extract this component into it's own file.
function FormFields({ dynamicContent }: IProps): JSX.Element {
  const fields: IFormField[] = dynamicContent.fields || [];
  return (
    <>
      {fields.map((field) => (
        <FormField dynamicContent={dynamicContent} field={field} key={field.name} />
      ))}
    </>
  );
}

interface IFormFieldProps {
  field: IFormField;
  dynamicContent: IDynamicContent;
}

const allFields = [
  { id: 'name', name: 'Name', placeholder: 'John Smith' },
  { id: 'first_name', name: 'First Name', placeholder: 'John' },
  { id: 'last_name', name: 'Last Name', placeholder: 'Smith' },
  { id: 'email', name: 'Email', placeholder: 'email@website.com' },
  { id: 'occupation', name: 'Occupation', placeholder: 'Director of Awesome' },
  { id: 'msg', name: 'Message', placeholder: 'Say Hello!' },
];

const getHeaders = () => Session.getHeaders();

// TODO: Extract this component into it's own file.
function FormField({ field, dynamicContent }: IFormFieldProps): JSX.Element {
  const availableFields = allFields.filter(
    (option) => !dynamicContent.fields!.some((dcf) => dcf.name === option.id),
  );
  const debouncedSetter = debounce((value: string) => {
    set(field, { placeholder: value });
  }, DEFAULT_DEBOUNCE_WAIT);

  function handlePlaceholderChange(newValue?: string): void {
    debouncedSetter(newValue ?? '');
  }

  return (
    <Card
      actions={[
        <Button
          key={'remove'}
          onClick={() => {
            const fields = dynamicContent.fields!;
            const filtered = fields.filter((f) => f.name !== field.name);
            set(dynamicContent, { fields: filtered });
          }}
          type={'naked'}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>,
      ]}
    >
      <Fieldset>
        <Select
          label={'Field'}
          onSelectSingle={(option) => {
            const toSet: Partial<IFormField> = {
              name: option.id,
              label: option.name,
              placeholder: option.placeholder,
              tag: option.id === 'msg' ? 'textarea' : 'input',
            };
            set(field, toSet);
          }}
          options={availableFields}
          value={allFields.find((option) => option.id === field.name)}
        />
        <Input
          label={'Placeholder'}
          onChange={handlePlaceholderChange}
          type={'text'}
          value={field.placeholder}
        />
        <Checkbox
          label={'required'}
          onChange={() => {
            set(field, { required: !field.required });
          }}
          value={field.required}
        />
      </Fieldset>
    </Card>
  );
}

function FormForm({ dynamicContent }: IProps): JSX.Element {
  const availableFields = allFields.filter(
    (option) => !dynamicContent.fields!.some((dcf) => dcf.name === option.id),
  );
  const [previewHTML, setPreviewHTML] = useState('');

  useEffect(() => {
    const getFormPreviewURL = (): string => {
      return `${BLACKBOX_URL}templates/form_preview?form=${encodeURIComponent(
        JSON.stringify(toJS(dynamicContent)),
      )}`;
    };
    return autorun(async () => {
      const url = getFormPreviewURL();
      // Endpoint returns HTML, so don't use wretch.
      const response = await fetch(url, {
        headers: getHeaders(),
        method: 'GET',
      });
      const html = await response.text();
      setPreviewHTML(html);
    });
  }, [dynamicContent]);

  function handleColorChange(color?: string): void {
    set(dynamicContent, {
      color: color?.split('#')[1],
    });
  }

  function handleBackgroundColorChange(color?: string): void {
    set(dynamicContent, {
      background_color: color?.split('#')[1],
    });
  }

  return (
    <div style={{ display: 'flex' }}>
      <Form
        actions={
          <Toolbar>
            <Button
              disabled={availableFields.length === 0}
              onClick={() => {
                const fields = dynamicContent.fields!;
                const nextField = availableFields[0];
                fields.push({
                  name: nextField.id,
                  label: nextField.name,
                  placeholder: nextField.placeholder,
                  tag: nextField.id === 'msg' ? 'textarea' : 'input',
                  type_: 'text',
                  required: true,
                });
              }}
            >
              Add field
            </Button>
          </Toolbar>
        }
        className={styles.formConfigForm}
        label={'Form Configuration'}
      >
        <Fieldset>
          <Input
            label={'Title'}
            onChange={getDebouncedSetter('title', dynamicContent)}
            type={'text'}
            value={dynamicContent.title}
          />
          <Input
            label={'Subtitle'}
            onChange={getDebouncedSetter('subtitle', dynamicContent)}
            type={'text'}
            value={dynamicContent.subtitle}
          />
          <Input
            label={'Button'}
            onChange={getDebouncedSetter('submit', dynamicContent)}
            type={'text'}
            value={dynamicContent.submit}
          />
          <Input
            label={'Confirmation Text'}
            onChange={getDebouncedSetter('complete', dynamicContent)}
            type={'text'}
            value={dynamicContent.complete}
          />
          <ColorPicker
            label={'Color'}
            onChange={handleColorChange}
            value={`#${dynamicContent.color}`}
          />
          <ColorPicker
            label={'Background Color'}
            onChange={handleBackgroundColorChange}
            value={`#${dynamicContent.background_color}`}
          />
          <FontSelect dynamicContent={dynamicContent} />
        </Fieldset>
        <legend>Fields</legend>
        <FormFields dynamicContent={dynamicContent} />
      </Form>
      <Postscribe className={styles.formPreview} html={previewHTML} style={{ transform: 'none' }} />
    </div>
  );
}

export default observer(FormForm);
