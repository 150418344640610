import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import type { RowRenderProps } from 'react-table';

import type { Breadcrumb } from '@feathr/blackbox';
import { AvatarV2 as Avatar } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';

import PersonName from '../PersonColumns/PersonName';

import * as styles from './BreadcrumbColumns.css';

interface IRow extends RowRenderProps {
  original: Breadcrumb;
}

function PersonCell({ original }: IRow): JSX.Element {
  const { Persons } = useContext(StoresContext);
  if (original.get('per_id')) {
    const person = Persons.get(original.get('per_id'));
    if (person && !person.isPending) {
      return (
        <>
          <Avatar name={person.get('name')} placeholder={person.get('placeholder')} />
          <div className={styles.stackedCellContent}>
            <PersonName person={person.toJS()} />
            {person.get('external_id') && (
              <span className={styles.secondaryText}>{person.get('external_id')}</span>
            )}
          </div>
        </>
      );
    }
  }
  return <>Anonymous</>;
}

export default observer(PersonCell);
