import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { TFunction } from 'i18next';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { OptionProps } from 'react-select';
import { components } from 'react-select';
import type { Option } from 'react-select/src/filters';

import type { IPredicate, TComparison, TPredicateKind } from '@feathr/blackbox';
import { CampaignClass, FieldDataType } from '@feathr/blackbox';
import {
  DatePicker,
  Input,
  NakedIndicatorsContainer,
  NumberInput,
  Popover,
  Select,
  Spinner,
} from '@feathr/components';
import { fieldDataTypeIcon } from '@feathr/extender/styles/fieldDataType';
import { cssVar } from '@feathr/hooks';

import type { ICollectionOption } from '../PredicateIdValueSelect';
import PredicateIdValueSelect from '../PredicateIdValueSelect';
import PredicateListValueSelect from '../PredicateListValueSelect';
import PredicateTextValueInput from '../PredicateTextValueInput';

import styles from './PredicateInputs.css';

// delete this and its css in #3595
interface IProps {
  attrOptionsIsLoading: boolean;
  breadcrumbAttrOptions: IAttrOption[];
  disabled?: boolean;
  group?: true;
  kind?: TPredicateKind;
  personAttrOptions?: IAttrOption[];
  predicate: IPredicate;
  filterContext:
    | 'campaign'
    | 'flight'
    | 'segment'
    | 'campaignGoal'
    | 'flightGoal'
    | 'facebookCampaign'
    | 'pinpoint';
  excludeIds?: string[];
  triggers?: IPredicate[];
  mode?: 'match_all' | 'match_any';
}

export interface IAttrOption {
  helpText: string;
  id: string;
  kind: 'Person' | 'Breadcrumb';
  name: string;
  type: FieldDataType;
}

interface IComparisonOption {
  id: TComparison;
  name: string;
}

function toPredicateAttrType(
  fieldType: FieldDataType,
): 'string' | 'boolean' | 'list' | 'date' | 'integer' | 'float' {
  switch (fieldType) {
    case FieldDataType.str:
      return 'string';

    case FieldDataType.int:
      return 'integer';

    case FieldDataType.bool:
      return 'boolean';

    default:
      return fieldType;
  }
}

function toPredicateKind(
  fieldKind: 'Person' | 'Breadcrumb',
  filterContext: string,
): 'attribute' | 'activity' | 'update' {
  if (fieldKind === 'Person') {
    return filterContext === 'pinpoint' ? 'update' : 'attribute';
  }
  return 'activity';
}

function FieldOption(props: OptionProps<IAttrOption>): JSX.Element {
  const { name, type } = props.data;
  const icon = fieldDataTypeIcon(type);

  return (
    <components.Option {...props}>
      <div className={styles.container}>
        <div className={styles.iconContainer}>{icon && <FontAwesomeIcon icon={icon} />}</div>
        <div className={styles.nameContainer}>
          <span>{name}</span>
        </div>
      </div>
    </components.Option>
  );
}

function ComparisonOption(props: any): JSX.Element {
  return (
    <components.Option {...props}>
      <span className={styles.comparison}>{props.data.name}</span>
    </components.Option>
  );
}

function getComparisons(attr: IAttrOption, t: TFunction): IComparisonOption[] {
  const baseOptions: IComparisonOption[] = [
    { id: 'exists', name: t('exists') },
    { id: 'nexists', name: t('does not exist') },
  ];
  const numberOptions: IComparisonOption[] = [
    ...baseOptions,
    { id: 'eq', name: t('equals') },
    { id: 'ne', name: t('does not equal') },
    { id: 'gt', name: t('is greater than') },
    { id: 'lt', name: t('is less than') },
  ];
  switch (attr.type) {
    case FieldDataType.str:
      if (attr.id.includes('_id')) {
        return [...baseOptions, { id: 'eq', name: t('is') }, { id: 'ne', name: t('is not') }];
      }
      return [
        ...baseOptions,
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
        { id: 'starts_with', name: t('starts with') },
        { id: 'wildcard', name: t('matches pattern') },
        { id: 'regexp', name: t('matches the expression') },
        { id: 'contains_substring', name: t('contains') },
      ];

    case FieldDataType.int:
      return numberOptions;

    case FieldDataType.float:
      return numberOptions;

    case FieldDataType.bool:
      return [
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
      ];

    case FieldDataType.date:
      return [
        ...baseOptions,
        { id: 'eq', name: t('is') },
        { id: 'ne', name: t('is not') },
        { id: 'gt', name: t('is after') },
        { id: 'lt', name: t('is before') },
      ];

    case FieldDataType.list:
      return [
        ...baseOptions,
        { id: 'in', name: t('contains') },
        { id: 'nin', name: t('does not contain') },
      ];

    default:
      return baseOptions;
  }
}

function shouldShowValueInput(predicate: IPredicate): boolean {
  if (!predicate.comparison || ['exists', 'nexists'].includes(predicate.comparison)) {
    return false;
  }
  return true;
}

function getValueInput(
  predicate: IPredicate,
  excludeIds: string[],
  disabled: boolean,
  t: TFunction,
  filterContext: string,
): JSX.Element | null {
  function getPath(): string {
    const collectionMap = {
      activity: 'breadcrumbs',
      attribute: 'persons',
      update: 'persons',
    };
    return `${collectionMap[predicate.kind || 'activity']}/${predicate.attr_against}/values`;
  }

  function onSelect(selected: ICollectionOption | ICollectionOption[] | null): void {
    if (Array.isArray(selected)) {
      predicate.value = selected.map((option) => option.id!);
    } else if (selected) {
      predicate.value = selected.id;
    } else {
      predicate.value = undefined;
    }
  }

  function onChange(value: string | string[]): void {
    runInAction(() => {
      predicate.value = value;
    });
  }

  function onDateStrChange(value: string | undefined): void {
    runInAction(() => {
      predicate.value = value;
    });
  }

  function onChangeBoolean(value): void {
    runInAction(() => {
      predicate.value = value.id;
    });
  }

  function handleChangeString(newValue?: string): void {
    onChange(newValue ?? '');
  }

  function handleChangeNumber(newValue?: number): void {
    onChange(newValue?.toString() ?? '');
  }

  const collectionSelectOptions = {
    key: predicate.comparison,
    onSelect,
    naked: true,
    placeholder: t('missing value'),
  };

  // Construct allowed classes for 'campaign is' dropdown
  function getAllowedClasses(filterContext: string): CampaignClass[] {
    const allowedClasses: CampaignClass[] = [
      CampaignClass.Conversation,
      CampaignClass.LandingPage,
      CampaignClass.EmailList,
      CampaignClass.Lookalike,
      CampaignClass.SeedSegment,
      CampaignClass.Affinity,
      CampaignClass.Segment,
      CampaignClass.Search,
      CampaignClass.MobileGeoFencing,
      CampaignClass.MobileGeoFenceRetargeting,
      CampaignClass.Facebook,
      CampaignClass.TrackedLink,
      CampaignClass.EmailListFacebook,
      CampaignClass.Referral,
      CampaignClass.PinpointEmail,
      CampaignClass.SmartPinpointEmail,
    ];

    /**
     * Only allow autosend in dropdown for group builder and the targets
     * step of the campaign wizard. We want to exclude autosend campaigns
     * from being used in the trigger condition of other autosends.
     */
    if (['segment', 'campaign'].includes(filterContext)) {
      allowedClasses.push(CampaignClass.AutoPinpointEmail);
    }

    return allowedClasses;
  }

  // Construct optional filters for 'campaign is' dropdown
  function getOptionalFilters(excludeIds: string[]): Record<string, string[]> {
    return excludeIds.length ? { id__nin: excludeIds } : {};
  }

  switch (predicate.attr_against) {
    case 'e_id':
      return (
        <PredicateIdValueSelect
          collection={'Events'}
          disabled={disabled}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'cpn_id':
      return (
        <PredicateIdValueSelect
          collection={'Campaigns'}
          disabled={disabled}
          filters={{
            _cls__in: getAllowedClasses(filterContext),
            ...getOptionalFilters(excludeIds),
          }}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'pp_opt_outs.events':
      return (
        <PredicateIdValueSelect
          collection={'Events'}
          disabled={disabled}
          multi={true}
          name={'predicate_value'}
          value={predicate.value as string[] & IObservableArray}
          {...collectionSelectOptions}
        />
      );

    case 'pp_opt_outs.campaigns':
      return (
        <PredicateIdValueSelect
          collection={'Campaigns'}
          disabled={disabled}
          filters={{
            _cls__in: [
              CampaignClass.PinpointEmail,
              CampaignClass.SmartPinpointEmail,
              CampaignClass.AutoPinpointEmail,
            ],
          }}
          multi={true}
          name={'predicate_value'}
          value={predicate.value as string[] & IObservableArray}
          {...collectionSelectOptions}
        />
      );

    case 'p_id':
      return (
        <PredicateIdValueSelect
          collection={'Partners'}
          disabled={disabled}
          name={'predicate_value'}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'seg_id':
      return (
        <PredicateIdValueSelect
          collection={'Segments'}
          disabled={disabled}
          filters={{
            context: 'breadcrumb',
            is_conversion_segment: true,
          }}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'tag_id':
      return (
        <PredicateIdValueSelect
          collection={'Tags'}
          disabled={disabled}
          filters={{
            context: 'breadcrumb',
          }}
          value={predicate.value as string | undefined}
          {...collectionSelectOptions}
        />
      );

    case 'tag_ids':
      return (
        <PredicateIdValueSelect
          collection={'Tags'}
          disabled={disabled}
          filters={{
            context: 'person',
          }}
          multi={true}
          value={predicate.value as IObservableArray<string> | undefined}
          {...collectionSelectOptions}
        />
      );

    default:
      switch (predicate.attr_type) {
        case 'string':
          return (
            <PredicateTextValueInput
              disabled={disabled}
              getPath={getPath}
              key={predicate.comparison}
              name={'predicate_value'}
              onChange={onChange}
              placeholder={t('missing value')}
              value={predicate.value as string}
            />
          );

        case 'list':
          return (
            <PredicateListValueSelect
              disabled={disabled}
              getPath={getPath}
              key={predicate.comparison}
              name={'predicate_value'}
              onChange={onChange}
              placeholder={t('missing value')}
              value={predicate.value as string[]}
            />
          );

        case 'date':
          if (predicate.comparison?.includes('_r')) {
            const count = predicate.value !== undefined ? +predicate.value : undefined;
            return (
              <div className={styles.container}>
                <Trans count={count} t={t}>
                  <NumberInput
                    className={styles.numberInput}
                    disabled={disabled}
                    key={predicate.comparison}
                    onChange={handleChangeNumber}
                    value={count}
                    wrapperClassName={styles.numberInput}
                  />
                  <span className={styles.time}>day ago</span>
                </Trans>
              </div>
            );
          }
          return (
            <DatePicker
              disabled={disabled}
              key={predicate.comparison}
              onDateStrChange={onDateStrChange}
              placeholder={t('missing value')}
              selected={predicate.value ? new Date(predicate.value as string) : null}
            />
          );

        case 'integer':

        case 'float':
          return (
            <NumberInput
              disabled={disabled}
              key={predicate.comparison}
              onChange={handleChangeNumber}
              value={predicate.value as number | undefined}
              wrapperClassName={styles.numberInput}
            />
          );

        case 'boolean':
          const booleanOptions = [
            { id: true, name: t('True') },
            { id: false, name: t('False') },
          ];
          return (
            <Select
              disabled={disabled}
              key={predicate.comparison}
              name={'predicate_value'}
              onChange={onChangeBoolean}
              options={booleanOptions}
              value={
                predicate.value !== undefined
                  ? booleanOptions.find((v) => predicate.value === v.id)
                  : booleanOptions.find((v) => false === v.id)
              }
            />
          );

        default:
          return (
            <Input
              disabled={disabled}
              key={predicate.comparison}
              onChange={handleChangeString}
              placeholder={t('missing value')}
              type={'text'}
            />
          );
      }
  }
}

function PredicateInputs({
  attrOptionsIsLoading,
  breadcrumbAttrOptions,
  disabled = false,
  filterContext,
  group,
  kind,
  personAttrOptions = [],
  predicate,
  excludeIds = [],
  triggers,
  mode,
}: IProps): JSX.Element {
  const { t } = useTranslation();

  function onAttrSelect(attrOption: IAttrOption): void {
    runInAction(() => {
      predicate.attr_against = attrOption.id;
      predicate.attr_type = toPredicateAttrType(attrOption.type);
      predicate.kind = toPredicateKind(attrOption.kind, filterContext);
      const comparisons = getComparisons(attrOption, t);
      predicate.comparison = comparisons[0].id;
      predicate.value = predicate.attr_type === 'boolean' ? false : undefined;
    });
  }

  function onComparisonSelect(comparisonOption: IComparisonOption): void {
    runInAction(() => {
      predicate.comparison = comparisonOption.id;
      predicate.value = predicate.attr_type === 'boolean' ? false : undefined;
    });
  }

  let optionGroups = [
    { label: t('Activity'), options: breadcrumbAttrOptions },
    { label: t('Person Attributes'), options: personAttrOptions },
  ];

  if (group && kind) {
    if (kind === 'activity') {
      optionGroups = [optionGroups[0]];
    } else if (kind === 'attribute') {
      optionGroups = [optionGroups[1]];
    }
  }

  // We only want to filter by predicate kind if there's at least 2 triggers.
  if (filterContext === 'pinpoint' && mode === 'match_all' && (triggers?.length ?? 0) >= 2) {
    const predicateKind = triggers![0].kind;
    if (predicateKind === 'activity') {
      optionGroups = [optionGroups[0]];
    } else if (predicateKind === 'update') {
      optionGroups = [optionGroups[1]];
    }
  }
  const attrValue = [...breadcrumbAttrOptions, ...personAttrOptions].find(
    (attrOption: IAttrOption) => {
      const attrMatch = predicate.attr_against === attrOption.id;
      const typeMatch = predicate.attr_type === toPredicateAttrType(attrOption.type);
      const kindMatch = predicate.kind === toPredicateKind(attrOption.kind, filterContext);
      return attrMatch && typeMatch && kindMatch;
    },
  );
  let comparisonOptions: IComparisonOption[] = [];
  let comparisonValue: any;
  if (attrValue) {
    comparisonOptions = getComparisons(attrValue, t);
    predicate.comparison === 'contains' && (predicate.comparison = 'in');
    comparisonValue = comparisonOptions.find(
      (compOption) => compOption.id === predicate.comparison,
    );
  }

  function filterOption(option: Option, inputValue: string): boolean {
    if (filterContext.includes('facebook')) {
      return option.data.id === 'loc_url';
    }
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  }

  const selectStyles = {
    /*
     * Margin-right here spaces the attr_against container from
     * the comparison operator container
     */
    container: (provided) => ({ ...provided, marginRight: cssVar('--spacing-1') }),
    menu: (provided) => ({ ...provided, width: 'auto' }),
    singleValue: (provided) => ({
      ...provided,
      transform: 'none',
      position: 'relative',
      top: 0,
      maxWidth: '100%',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      transform: 'none',
      position: 'relative',
      top: 0,
    }),
  };

  const input = attrValue ? (
    <Popover toggle={'onMouseOver'}>
      <span>
        <Select<IAttrOption>
          className={styles.select}
          components={{ Option: FieldOption, IndicatorsContainer: NakedIndicatorsContainer }}
          disabled={disabled}
          filterOption={filterOption}
          name={'predicate_attr'}
          onSelectSingle={onAttrSelect}
          options={optionGroups}
          styles={selectStyles}
          value={attrValue}
        />
      </span>
      <span>{attrValue?.helpText}</span>
    </Popover>
  ) : (
    <span>
      <Select<IAttrOption>
        className={styles.select}
        components={{ Option: FieldOption, IndicatorsContainer: NakedIndicatorsContainer }}
        disabled={disabled}
        filterOption={filterOption}
        name={'predicate_attr'}
        onSelectSingle={onAttrSelect}
        options={optionGroups}
        styles={selectStyles}
        value={attrValue}
      />
    </span>
  );

  return (
    <>
      {attrOptionsIsLoading ? (
        <div className={styles.spinner}>
          <Spinner size={14} />
        </div>
      ) : (
        input
      )}
      {predicate.attr_against && (
        <div>
          <Select
            className={styles.select}
            components={{ Option: ComparisonOption, IndicatorsContainer: NakedIndicatorsContainer }}
            disabled={disabled}
            name={'predicate_comparison'}
            onSelectSingle={onComparisonSelect}
            options={comparisonOptions}
            styles={selectStyles}
            value={comparisonValue}
          />
        </div>
      )}
      {shouldShowValueInput(predicate) &&
        getValueInput(predicate, excludeIds, disabled, t, filterContext)}
    </>
  );
}

export default observer(PredicateInputs);
