import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ToastType } from 'react-toastify';

import type { User } from '@feathr/blackbox';
import { ArchiveModal, ContextMenu, MenuItem, toast } from '@feathr/components';
import { useAccount, useUser } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

interface IOptionsColumnProps {
  readonly user: User;
}

function UserOptions({ user }: IOptionsColumnProps): JSX.Element {
  const account = useAccount();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const { t } = useTranslation();
  const currentUser = useUser();
  const email = user.get('email');

  // TODO: Move this to blackbox #3248
  async function removeUser(): Promise<void> {
    const currentACL = account.get('user_roles');
    try {
      account.set({ user_roles: currentACL.filter((item) => item.user !== user.id) });
      await account.patchDirty();
      user.collection?.remove(user.id);
      toast(t('Removed user.'), { type: ToastType.SUCCESS });
    } catch (error) {
      toast(t('Something went wrong while trying to remove this user: {{error}}', { error }), {
        type: ToastType.ERROR,
      });
    }
    toggleArchiveModalOpen();
  }

  async function handleSendResetLink(): Promise<void> {
    try {
      await user.sendPasswordResetLink();
      toast(t('Password reset link sent to {{email}}.', { email: email }), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(
        t(
          'Something went wrong while trying to send a password reset link to this user: {{error}}',
          {
            error,
          },
        ),
        {
          type: ToastType.ERROR,
        },
      );
    }
  }

  async function handleResendInvite(): Promise<void> {
    try {
      await user.resendInvite();
      toast(t('Invite link sent to {{email}}.', { email: email }), {
        type: ToastType.SUCCESS,
      });
    } catch (error) {
      toast(
        t('Something went wrong while trying to resend the invite link to this user: {{error}}', {
          error,
        }),
        {
          type: ToastType.ERROR,
        },
      );
    }
  }

  return (
    <>
      <ContextMenu>
        {user.get('confirmed') ? (
          <MenuItem disabled={user.id === currentUser.id} onClick={handleSendResetLink}>
            {t('Send password reset link')}
          </MenuItem>
        ) : (
          <MenuItem disabled={user.id === currentUser.id} onClick={handleResendInvite}>
            {t('Resend invite link')}
          </MenuItem>
        )}
        <MenuItem
          disabled={user.id === currentUser.id}
          id={`${user.id}-archive`}
          onClick={toggleArchiveModalOpen}
        >
          {t('Remove')}
        </MenuItem>
      </ContextMenu>
      {isArchiveModalOpen && (
        <ArchiveModal
          confirmButtonText={t('Remove user')}
          message={t('Are you sure you want to remove this user from this account?')}
          model={user}
          onClose={toggleArchiveModalOpen}
          onConfirm={removeUser}
          t={t}
          title={t('Remove User')}
        />
      )}
    </>
  );
}

export default observer(UserOptions);
