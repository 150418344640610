import { faEdit, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { when } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import type { RowRenderProps } from 'react-table';

import type { Segment } from '@feathr/blackbox';
import {
  ArchiveModal,
  ContextMenu,
  Fieldset,
  Input,
  MenuItem,
  Modal,
  toast,
} from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

interface IRow extends RowRenderProps {
  original: Segment;
}

function OptionsCell({ original }: IRow): JSX.Element {
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isRenameModalOpen, toggleRenameModalOpen] = useToggle(false);
  const { Goals, Segments } = useContext(StoresContext);
  const { t } = useTranslation();

  async function handleRename() {
    try {
      await original.save();
      toast(t('Renamed to "{{name}}"', { name: original.name }));
      toggleRenameModalOpen();

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(
        t('Something went wrong while saving your changes:\n{{error}}', {
          error: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  async function handleArchive() {
    const goals = Goals.list({ filters: { segment: original.id, is_archived__ne: true } });
    await when(() => !goals.isPending);
    if (goals.pagination.count > 0) {
      toast(
        t(
          "Can't archive this conversion pixel because it is used by one or more campaigns or flights.",
        ),
        { type: 'error' },
      );
      return;
    }
    try {
      await original.patch({ is_archived: true });
      Segments.remove(original.id);
      toast(t('Archived {{name}}', { name: original.name }));
      toggleArchiveModalOpen();

      // If err is instance of Error, it should be of type any.
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      toast(
        t('Something went wrong while saving your changes:\n{{error}}', {
          error: error.message,
        }),
        {
          type: 'error',
        },
      );
    }
  }

  function handleNameChange(newValue?: string) {
    original.set({ name: newValue });
  }

  return (
    <>
      <ContextMenu>
        <MenuItem onClick={toggleRenameModalOpen} prefix={<FontAwesomeIcon icon={faEdit} />}>
          {t('Rename')}
        </MenuItem>
        <MenuItem onClick={toggleArchiveModalOpen} prefix={<FontAwesomeIcon icon={faTrashAlt} />}>
          {t('Archive')}
        </MenuItem>
      </ContextMenu>
      {isRenameModalOpen && (
        <Modal
          confirmButtonText={t('Ok')}
          controlled={true}
          onClose={toggleRenameModalOpen}
          onConfirm={handleRename}
          t={t}
          title={t('Rename Conversion Pixel')}
        >
          <Fieldset>
            <Input
              label={t('Name')}
              onChange={handleNameChange}
              type={'text'}
              value={original.name}
            />
          </Fieldset>
        </Modal>
      )}
      {isArchiveModalOpen && (
        <ArchiveModal
          model={original}
          onClose={toggleArchiveModalOpen}
          onConfirm={handleArchive}
          successMessage={t('Successfully archived {{name}}.', { name: original.name })}
          t={t}
        />
      )}
    </>
  );
}

export default observer(OptionsCell);
