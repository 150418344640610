import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext } from 'react';
import type { RowRenderProps } from 'react-table';

import type { Breadcrumb } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { AvatarV2 as Avatar, Time } from '@feathr/components';
import { TimeFormat } from '@feathr/hooks';

import { StoresContext } from '../../state';
import PersonName from '../ConversionTableCard/PersonName';
import PartnerChip from '../PartnerChip';
import TemplateChip from '../TemplateChip';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './FormSubmissionsTableColumns.css';

interface IRow extends RowRenderProps {
  original: Breadcrumb;
}

interface ICell extends IRow {
  localUrl?: (url: string) => string;
}

function PersonCell({ localUrl, original }: ICell): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Persons } = useContext(StoresContext);
        const perId = original.get('per_id');
        if (perId) {
          const person = Persons.get(perId);
          if (!person.isPending) {
            return (
              <div className={styles.cell}>
                <Avatar name={person.get('name')} placeholder={person.get('placeholder')} />
                <div className={styles.personCell}>
                  <PersonName localUrl={localUrl} person={person.toJS()} />
                </div>
              </div>
            );
          }
        }
        return <div className={styles.cell}>Anonymous</div>;
      }}
    </Observer>
  );
}

function CompaniesCell({ original }: IRow): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Persons } = useContext(StoresContext);
        if (original.get('per_id')) {
          const model = Persons.get(original.get('per_id'));
          if (model && !model.isPending) {
            const companies = model.get('companies');
            if (Array.isArray(companies)) {
              return (
                <div className={styles.companiesCell}>
                  {(companies || []).map((value: string, index: number) => {
                    return (
                      <span className={styles.tag} key={index}>
                        {value}
                      </span>
                    );
                  })}
                </div>
              );
            } else {
              return <>-</>;
            }
          }
        }
        return <div className={styles.cell} />;
      }}
    </Observer>
  );
}

function EmailCell({ original }: IRow): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Persons } = useContext(StoresContext);
        if (original.get('per_id')) {
          const model = Persons.get(original.get('per_id'));
          if (model && !model.isPending) {
            return <div className={styles.cell}>{model.get('email') || '-'}</div>;
          }
        }
        return <div className={styles.cell} />;
      }}
    </Observer>
  );
}

function PhoneCell({ original }: IRow): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Persons } = useContext(StoresContext);
        if (original.get('per_id')) {
          const model = Persons.get(original.get('per_id'));
          if (model && !model.isPending) {
            return <div className={styles.cell}>{model.get('phone') || '-'}</div>;
          }
        }
        return <div className={styles.cell} />;
      }}
    </Observer>
  );
}

function CountryCell({ original }: IRow): JSX.Element {
  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        const { Persons } = useContext(StoresContext);
        if (original.get('per_id')) {
          const model = Persons.get(original.get('per_id'));
          if (model && !model.isPending) {
            const geoip = model.get('geoip');
            return <div className={styles.cell}>{geoip?.country_code || '-'}</div>;
          }
        }
        return <div className={styles.cell} />;
      }}
    </Observer>
  );
}

export const FormSubmissionsTableColumns = (
  campaignClass: string,
  localUrl?: (url: string) => string,
): Array<IColumn<Breadcrumb>> => {
  const columns: Array<IColumn<Breadcrumb>> = [
    {
      Header: 'Date',
      id: 'd_c',
      width: 100,
      className: tableStyles.cellCenter,
      Cell({ original }: IRow): JSX.Element {
        return (
          <Observer>
            {function useAnonymousFunction(): JSX.Element {
              return <Time format={TimeFormat.shortDate} timestamp={original.get('d_c')} />;
            }}
          </Observer>
        );
      },
    },
    {
      Header: 'Name',
      id: 'person',
      width: 150,
      className: tableStyles.cellCenter,
      sortable: false,
      Cell(row): JSX.Element {
        return <PersonCell {...row} localUrl={localUrl} />;
      },
    },
    {
      Header: 'Companies',
      id: 'companies',
      width: 100,
      className: tableStyles.cellCenter,
      sortable: false,
      Cell(row): JSX.Element {
        return <CompaniesCell {...row} />;
      },
    },
    {
      Header: 'Email',
      id: 'email',
      width: 150,
      className: tableStyles.cellCenter,
      sortable: false,
      Cell(row): JSX.Element {
        return <EmailCell {...row} />;
      },
    },
    {
      Header: 'Phone',
      id: 'phone',
      width: 100,
      className: tableStyles.cellCenter,
      sortable: false,
      Cell(row): JSX.Element {
        return <PhoneCell {...row} />;
      },
    },
    {
      Header: 'Template',
      id: 'template',
      width: 120,
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <TemplateChip id={original.get('t_id')!} key={original.get('t_id')} localUrl={localUrl} />
        );
      },
    },
    {
      Header: 'Message',
      id: 'message',
      sortable: false,
      Cell({ original }): JSX.Element {
        return <>{original.get('msg')}</>;
      },
    },
    {
      Header: 'Country',
      id: 'country',
      width: 60,
      className: tableStyles.cellCenter,
      sortable: false,
      Cell(row): JSX.Element {
        return <CountryCell {...row} />;
      },
    },
  ];

  if (campaignClass.includes('ReferralCampaign')) {
    columns.splice(4, 0, {
      Header: 'Partner',
      id: 'partner',
      width: 140,
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <PartnerChip id={original.get('p_id')} key={original.get('p_id')} localUrl={localUrl} />
        );
      },
    });
  }

  return columns;
};
