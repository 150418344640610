import type { JSX, ReactNode } from 'react';
import React from 'react';

import * as styles from './PaymentMethod.css';

interface IProps {
  icon: ReactNode;
  label: ReactNode;
}

function PaymentMethod({ icon, label }: IProps): JSX.Element {
  return (
    <div className={styles.root}>
      <div>{icon}</div>
      <div>{label}</div>
    </div>
  );
}

export default PaymentMethod;
