import { faSync } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { toJS } from 'mobx';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import type { IParticipation } from '@feathr/blackbox';
import { Campaign, Partners as PartnersCollection } from '@feathr/blackbox';
import type { IListboxElementOption, IListboxOptionComponentProps } from '@feathr/components';
import { Button, DualListbox, FormElement, Tooltip } from '@feathr/components';
import { StoresContext } from '@feathr/extender/state';
import type { Model } from '@feathr/rachis';

import * as styles from './Recipients.css';

export interface IParticipationWithCampaign extends IParticipation {
  campaign?: string;
}

interface IProps<T extends Model<IParticipation | IParticipationWithCampaign>> {
  disabled?: boolean;
  model: T;
  partnersCollection?: PartnersCollection;
}

interface IPartnersFilters {
  _parent: string;
  participation?: string;
}

function OptionComponent({ className, option }: IListboxOptionComponentProps): JSX.Element {
  return (
    <option className={className} title={`${option.name} <${option.email}>`} value={option.id}>
      {`${option.name} <${option.email}>`}
    </option>
  );
}

function Recipients<T extends Model<IParticipation | IParticipationWithCampaign>>({
  disabled = false,
  model,
  partnersCollection,
}: IProps<T>): JSX.Element {
  const { eventId } = useParams<{ eventId: string }>();
  const { Tags } = useContext(StoresContext);
  const localPartners = useRef(new PartnersCollection([])).current;
  const [reset, setReset] = useState(false);

  const Partners = partnersCollection || localPartners;
  const filters: IPartnersFilters = { _parent: eventId };

  return (
    <Observer>
      {function useAnonymousFunction(): JSX.Element {
        if (model.get('invites_campaign')) {
          filters.participation = model.get('invites_campaign');
        }
        const partnersCount = Partners.count({ filters }, { reset });
        const tagsCount = Tags.count({ filters: { context: 'partner' } }, { reset });
        const partners =
          partnersCount > 0
            ? Partners.list(
                {
                  filters,
                  only: ['id', 'name', 'email', 'tag_ids', 'is_active', 'logo'],
                  pagination: { page_size: partnersCount },
                },
                { reset },
              )
            : Partners.newListResponse();

        const tags =
          tagsCount > 0
            ? Tags.list(
                {
                  filters: { context: 'partner' },
                  pagination: { page_size: tagsCount },
                },
                { reset },
              )
            : Tags.newListResponse();

        useEffect(() => {
          if (reset) {
            setReset(false);
          }
        }, [reset]);

        const participation: IParticipation['participation'] = model.get('participation', {
          mode: 'manual',
          partner_ids: [] as string[],
        });

        const options: IListboxElementOption[] = partners.models.map((partner) => ({
          name: partner.name,
          email: partner.get('email'),
          id: partner.id,
          tags: toJS(partner.get('tag_ids', [])),
          is_active: partner.get('is_active'),
        }));

        function handleRefresh(): void {
          setReset(true);
        }

        const value = participation.partner_ids;

        return (
          <FormElement
            helpText={
              <div className={styles.help}>
                <p>
                  Pick partners from the left to add to the list on the right. Partners in the list
                  on the right will
                  {model instanceof Campaign
                    ? ' participate in this campaign'
                    : ' receive this message'}
                  .
                </p>
                <Tooltip placement={'left'} title={'Refresh partners'}>
                  <Button
                    className={classNames({ [styles.disabled]: disabled })}
                    disabled={disabled}
                    name={'refresh_partners'}
                    onClick={handleRefresh}
                  >
                    <FontAwesomeIcon icon={faSync} spin={partners.isPending} />
                  </Button>
                </Tooltip>
              </div>
            }
          >
            <DualListbox
              attribute={['participation', 'partner_ids']}
              disabled={disabled}
              isLoading={partners.isPending}
              isLoadingTags={tags.isPending}
              leftLabel={`Available (${options
                .filter((o) => !value.includes(o.id))
                .length.toLocaleString()})`}
              model={model}
              OptionComponent={OptionComponent}
              options={options}
              rightLabel={`Selected (${value.length.toLocaleString()})`}
              tags={tags.models.map((tag) => ({ name: tag.get('name'), id: tag.id }))}
            />
          </FormElement>
        );
      }}
    </Observer>
  );
}

export default Recipients;
