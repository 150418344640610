import type { IObservableArray } from 'mobx';
import type { JSX } from 'react';
import React from 'react';

import type { Goal, PinpointEmailBaseCampaign } from '@feathr/blackbox';

import CampaignReportSection from '../CampaignReportSection';
import PinpointCampaignDetailSection from './PinpointCampaignDetailSection';
import PinpointEmailCampaignPage from './PinpointEmailCampaignPage';

interface IProps {
  campaign: PinpointEmailBaseCampaign;
  goals?: IObservableArray<Goal>;
}

function PinpointEmailCampaignReportPage({ campaign, goals }: IProps): JSX.Element {
  const emailCampaignDetailSection = <PinpointCampaignDetailSection campaign={campaign} />;
  return (
    <PinpointEmailCampaignPage details={emailCampaignDetailSection} title={'Report'}>
      <CampaignReportSection campaign={campaign} goals={goals} />
    </PinpointEmailCampaignPage>
  );
}

export default PinpointEmailCampaignReportPage;
