import type { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';

import type { Campaign, CampaignClass } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import { campaignColorMap, campaignIconMap } from '@feathr/extender/styles/campaign';
import { cssVar } from '@feathr/hooks';

import { skeleton } from '@feathr/components/dist/style/skeleton.css';
import styles from './CampaignIcon.css';

interface IBaseProps {
  className?: string;
  size: number;
  hover?: boolean;
}

interface ICampaignProps extends IBaseProps {
  cls?: never;
  campaign: Campaign;
}

interface IClassProps extends IBaseProps {
  cls: CampaignClass;
  campaign?: never;
}

function CampaignIcon({
  campaign,
  className,
  cls,
  size,
  hover = false,
}: ICampaignProps | IClassProps) {
  const isPending = (campaign && campaign.isPending) || false;
  const isDraft = (campaign && campaign.get('state') === CampaignState.Draft) || false;

  const icon = campaign ? campaignIconMap.get(campaign.get('_cls'))! : campaignIconMap.get(cls!)!;
  let iconSize: SizeProp = '1x';

  if (size >= 24 && size < 36) {
    iconSize = 'lg';
  } else if (size >= 36 && size < 54) {
    iconSize = '2x';
  } else if (size >= 54 && size < 72) {
    iconSize = '3x';
  } else if (size >= 72) {
    iconSize = '4x';
  }

  const backgroundColor = campaignColorMap.get(campaign ? campaign.get('_cls') : cls!);

  return (
    <div
      className={classNames(
        styles.root,
        { [styles.hoverable]: hover },
        { [styles.draft]: isDraft },
        { [skeleton]: isPending },
        className,
      )}
      style={{
        ['--l-icon-background-color' as string]: backgroundColor
          ? cssVar(`--color-${backgroundColor}-500`)
          : null,
        ['--l-icon-size' as string]: `${size}px`,
      }}
    >
      {!isPending && <FontAwesomeIcon icon={icon} size={iconSize} />}
    </div>
  );
}

export default CampaignIcon;
