import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faGoogle, faMeta } from '@fortawesome/free-brands-svg-icons';
import {
  faAt,
  faAtlas,
  faBrowser,
  faBullseyePointer,
  faCommentDots,
  faEnvelope,
  faEnvelopeOpenText,
  faMapMarkerAlt,
  faMindShare,
  faRedo,
  faReplyAll,
  faSearch,
  faUsers,
  faWandMagicSparkles,
} from '@fortawesome/pro-light-svg-icons';
import type { TFunction } from 'i18next';

import { CampaignClass, CampaignState } from '@feathr/blackbox';

export const campaignColorMap = new Map<CampaignClass, string>([
  [CampaignClass.Segment, 'sky'],
  [CampaignClass.Lookalike, 'yellow'],
  [CampaignClass.SeedSegment, 'yellow'],
  [CampaignClass.Affinity, 'orange'],
  [CampaignClass.Search, 'orange'],
  [CampaignClass.EmailList, 'red'],
  [CampaignClass.MobileGeoFencing, 'indigo'],
  [CampaignClass.MobileGeoFenceRetargeting, 'indigo'],
  [CampaignClass.Referral, 'teal'],
  [CampaignClass.Conversation, 'blue'],
  [CampaignClass.LandingPage, 'green'],
  [CampaignClass.Facebook, 'sky'],
  [CampaignClass.EmailListFacebook, 'red'],
  [CampaignClass.TrackedLink, 'red'],
  [CampaignClass.PinpointEmail, 'yellow'],
  [CampaignClass.SmartPinpointEmail, 'yellow'],
  [CampaignClass.AutoPinpointEmail, 'yellow'],
  [CampaignClass.GoogleAdsSmart, 'sky'],
]);

export const campaignStateColorMap = new Map<CampaignState, string>([
  [CampaignState.Draft, 'default'],
  [CampaignState.Stopped, 'red'],
  [CampaignState.Erroring, 'orange'],
  [CampaignState.Published, 'green'],
  [CampaignState.Publishing, 'yellow'],
  [CampaignState.Archived, 'violet'],
]);

export const campaignIconMap = new Map<CampaignClass, IconDefinition>([
  [CampaignClass.Segment, faRedo],
  [CampaignClass.Lookalike, faUsers],
  [CampaignClass.SeedSegment, faUsers],
  [CampaignClass.Affinity, faWandMagicSparkles],
  [CampaignClass.Search, faSearch],
  [CampaignClass.EmailList, faAt],
  [CampaignClass.MobileGeoFencing, faMapMarkerAlt],
  [CampaignClass.MobileGeoFenceRetargeting, faAtlas],
  [CampaignClass.Referral, faEnvelope],
  [CampaignClass.Conversation, faCommentDots],
  [CampaignClass.LandingPage, faBrowser],
  [CampaignClass.GoogleAdsSmart, faGoogle],
  [CampaignClass.Facebook, faMeta],
  [CampaignClass.EmailListFacebook, faMeta],
  [CampaignClass.TrackedLink, faBullseyePointer],
  [CampaignClass.PinpointEmail, faEnvelopeOpenText],
  [CampaignClass.SmartPinpointEmail, faMindShare],
  [CampaignClass.AutoPinpointEmail, faReplyAll],
]);

export const campaignStateLabelMap = (t: TFunction, state: CampaignState): string => {
  const map = {
    [CampaignState.Draft]: t('Draft'),
    [CampaignState.Publishing]: t('Publishing'),
    [CampaignState.Published]: t('Published'),
    [CampaignState.Stopped]: t('Stopped'),
    [CampaignState.Erroring]: t('Erroring'),
    [CampaignState.Archived]: t('Archived'),
  };

  return map[state];
};
