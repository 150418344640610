import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import type { PinpointPartnerMessage } from '@feathr/blackbox';
import { CampaignState } from '@feathr/blackbox';
import {
  ArchiveModal,
  ConfirmModal,
  ContextMenu,
  EAlertV2Type,
  MenuDivider,
  MenuItem,
  toast,
} from '@feathr/components';
import { useLocalUrl } from '@feathr/extender/state';
import { useToggle } from '@feathr/hooks';

import PartnerMessageSummary from '../EventPartnersMessagePage/PartnerMessageSummary';

interface IEventPartnersMessagesOptions {
  message: PinpointPartnerMessage;
}

function EventPartnersMessagesOptions({ message }: IEventPartnersMessagesOptions): JSX.Element {
  const history = useHistory();
  const localUrl = useLocalUrl();
  const [isArchiveModalOpen, toggleArchiveModalOpen] = useToggle(false);
  const [isCloneModalOpen, toggleCloneModalOpen] = useToggle(false);
  const [isDeleteModalOpen, toggleDeleteModalOpen] = useToggle(false);
  const { t } = useTranslation();

  const state = message.get('state');

  async function clone(): Promise<void> {
    const clonedMessage: PinpointPartnerMessage = await message.clone({});
    history.push(localUrl(clonedMessage.getItemUrl()));
  }

  async function archive(): Promise<void> {
    /*
     * Close immediately to prevent "Can't perform a React state update on an
     * unmounted component." warning.
     */
    toggleArchiveModalOpen();
    try {
      await message.archive();
      toast(t('Message {{name}} has been archived.', { name: message.name }));
    } catch (error) {
      toast(t('There was a problem archiving the message:\n{{error}}', { error }));
    }
  }

  async function destroy(): Promise<void> {
    /*
     * Close immediately to prevent "Can't perform a React state update on an
     * unmounted component." warning.
     */
    toggleDeleteModalOpen();
    message.set({
      is_archived: true,
      state: CampaignState.Archived,
    });
    try {
      await message.delete();
      toast(t('Message has been deleted.'));
    } catch (error) {
      toast(t('There was a problem deleting the message:\n{{error}}', { error }));
    }
  }

  return (
    <>
      <ContextMenu>
        <MenuItem onClick={toggleCloneModalOpen}>{t('Clone')}</MenuItem>
        <MenuDivider />
        <MenuItem onClick={toggleArchiveModalOpen}>{t('Archive')}</MenuItem>
        {state === CampaignState.Draft && (
          <MenuItem onClick={toggleDeleteModalOpen}>{t('Delete')}</MenuItem>
        )}
      </ContextMenu>
      {isCloneModalOpen && (
        <ConfirmModal
          cancelButtonText={t('Cancel')}
          confirmButtonText={t('Clone')}
          message={t('Are you sure you want to clone this message?')}
          onClose={toggleCloneModalOpen}
          onConfirm={clone}
          t={t}
          title={t('Clone Message')}
          type={EAlertV2Type.info}
        >
          <PartnerMessageSummary message={message} />
        </ConfirmModal>
      )}
      {isArchiveModalOpen && (
        <ArchiveModal
          model={message}
          onClose={toggleArchiveModalOpen}
          onConfirm={archive}
          t={t}
          title={t('Archive Message')}
        />
      )}
      {isDeleteModalOpen && (
        <ArchiveModal
          message={t('Are you sure you want to delete this message? This action cannot be undone.')}
          method={'delete'}
          onClose={toggleDeleteModalOpen}
          onConfirm={destroy}
          t={t}
          title={t('Delete Message')}
        />
      )}
    </>
  );
}

export default EventPartnersMessagesOptions;
