import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { Account, TAttributionModel } from '@feathr/blackbox';
import { Card, Form, SaveButton } from '@feathr/components';
import { AttributionModel } from '@feathr/report_components';

import * as styles from './AttributionOptions.css';

interface IProps {
  account: Account;
}

function AttributionOptions({ account }: IProps): JSX.Element {
  const { t } = useTranslation();
  const isLoading = account.isPending;
  const isDisabled = isLoading || !account.isAttributeDirty('attribution_model');

  function setAttributionModel(attributionModel: TAttributionModel): void {
    account.set({ attribution_model: attributionModel });
  }

  return (
    <Card className={styles.card} title={t('Default Attribution Model')}>
      <div className={styles.description}>
        <Trans t={t}>
          Here you can set your default attribution model. The model you choose here will be set as
          the default for future campaigns and flights. Changing this selection will not override
          the current attribution model set on campaigns and flights.
        </Trans>
      </div>
      <Form
        actions={<SaveButton disabled={isDisabled} method={'patch'} model={account} />}
        label={t('Default attribution model')}
      >
        <AttributionModel
          isLoading={isLoading}
          onChange={setAttributionModel}
          value={account.get('attribution_model')}
        />
      </Form>
    </Card>
  );
}

export default observer(AttributionOptions);
