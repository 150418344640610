import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';

import type { Account } from '@feathr/blackbox';
import { Button, Card, ConfirmModal, Form, SaveButtonValid } from '@feathr/components';
import InputList from '@feathr/extender/components/InputList';
import { useAccount } from '@feathr/extender/state';
import { flattenErrors, useReactionEffect, useToggle } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import * as styles from './AllowList.css';

function AllowList(): JSX.Element {
  const { t } = useTranslation();
  const [domains, setDomains] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isConfirmModalOpen, toggleIsConfirmModalOpen] = useToggle(false);
  const account = useAccount();

  useReactionEffect(
    () => !account.isPending,
    () => {
      if (!account.isPending) {
        const allowedDomains = account.get('domain_allow_list', []);
        setDomains(allowedDomains);
      }
    },
  );

  const isLoading = account.isPending;
  const isDisabled = isLoading || !account.isDirty;

  const validationErrors: TValidateGrouped = account.validate(
    ['domain_allow_list'],
    false,
    'grouped',
  ).errors;

  function handleDomainChange(index: number, value: string): void {
    const newDomains = [...domains];
    newDomains[index] = value;
    setDomains(newDomains);
    account.set({
      domain_allow_list: newDomains,
    });
  }

  function handleDomainDelete(index: number): void {
    setCurrentIndex(index);
    toggleIsConfirmModalOpen();
  }

  function handleConfirmDelete(): void {
    const newDomains = domains.filter((_, i) => i !== currentIndex);
    setDomains(newDomains);
    account.set({
      domain_allow_list: newDomains,
    });
    toggleIsConfirmModalOpen();
  }

  function handleAddDomain(): void {
    const newDomains = [...domains, ''];
    setDomains(newDomains);
    account.set({
      domain_allow_list: newDomains,
    });
  }

  return (
    <>
      <Prompt
        message={t('You have unsaved changes. Are you sure you want to leave this page?')}
        when={account.isDirty}
      />
      <Card className={styles.card} title={t('Allowed Domains')}>
        <p>
          {t(
            'The domains you add here will never be blocked by our security systems. This ensures that all clicks from Feathr campaigns to URLs from these domains go through as intended.',
          )}
        </p>
        <Form
          actions={
            <SaveButtonValid<Account>
              disabled={isDisabled}
              errors={flattenErrors(validationErrors)}
              method={'patch'}
              model={account}
            />
          }
          label={t('Domain Allow List')}
        >
          <InputList
            actions={<Button onClick={handleAddDomain}>{t('Add Domain')}</Button>}
            emptyMessage={t('You have no domains in your allow list.')}
            items={domains}
            onChange={handleDomainChange}
            onRemove={handleDomainDelete}
          />
        </Form>
      </Card>
      {isConfirmModalOpen && (
        <ConfirmModal
          confirmButtonText={t('Confirm')}
          description={t('Are you sure you want to delete this domain from your allow list?')}
          onClose={toggleIsConfirmModalOpen}
          onConfirm={handleConfirmDelete}
          t={t}
          title={t('Confirm Delete')}
        />
      )}
    </>
  );
}

export default observer(AllowList);
