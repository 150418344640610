import { get, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Template } from '@feathr/blackbox';
import { Button, Card, Fieldset, Form, PageHeader } from '@feathr/components';
import type { IPluginForm, TFormResolveHandler } from '@feathr/extender/components/BeeEditor';

import FormFieldConfig from './FormFieldConfig';

import * as styles from './TemplateEditor.css';

interface IProps {
  template: Template;
  resolve?: TFormResolveHandler;
  reject?: (error?: Error) => void;
  form: IPluginForm['structure'];
  onClose: () => void;
}

function ManageFormDialog({ resolve, reject, form, onClose }: IProps): JSX.Element {
  function handleClickCancel(): void {
    if (reject) {
      reject();
    }
    onClose();
  }

  async function handleConfirmClick(): Promise<void> {
    if (resolve) {
      const formStructure = toJS(form);
      resolve({ structure: formStructure });
    }
    onClose();
  }

  return (
    <Card className={styles.dialogForm}>
      <Form
        actions={[
          <Button key={'cancel'} onClick={handleClickCancel}>
            Cancel
          </Button>,
          <Button key={'confirm'} onClick={handleConfirmClick} type={'primary'}>
            Confirm
          </Button>,
        ]}
        label={'Advanced Form Settings'}
      >
        <PageHeader className={styles.dialogHeader}>
          <h3>Advanced Form Settings</h3>
        </PageHeader>
        <Fieldset>
          {(form.layout as string[][])
            .map((row) => [row[0], get(form.fields, row[0])])
            .filter(([, field]) => !field.removeFromLayout)
            .map(([key, field]) => (
              <FormFieldConfig field={field} key={key} />
            ))}
        </Fieldset>
      </Form>
    </Card>
  );
}

export default observer(ManageFormDialog);
