import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { useRouteMatch } from 'react-router-dom';

import type { IEventBilling } from '@feathr/blackbox';
import { Alert, AlertType } from '@feathr/components';
import { StoresContext, useAccount, useLocalUrl } from '@feathr/extender/state';

interface IProps {
  className?: string;
}

function RouteAlert({ className }: IProps) {
  const { Events } = useContext(StoresContext);
  const account = useAccount();
  const localUrl = useLocalUrl();
  const { eventId } = useParams<{ eventId: string }>();
  const match = useRouteMatch<{
    accountId: string;
    primary: string;
    secondary?: string;
  }>({
    path: '/:accountId/:primary/:secondary?',
  });

  if (match && match.params.primary === 'projects' && eventId) {
    const event = Events.get(eventId);
    const billing = event.get('billing', {} as IEventBilling);
    const showConfigureMediaBilling = !(
      !account ||
      location.pathname.includes('settings/billing/edit') ||
      event.isPending ||
      billing.billable_id
    );
    const showMediaBillingBadStanding = false;
    if (showConfigureMediaBilling) {
      return (
        <Alert className={className} type={AlertType.danger}>
          <span>Please </span>
          <a
            href={localUrl(event.getItemUrl('settings/billing/edit'))}
            rel={'noreferrer'}
            target={'_blank'}
          >
            configure this project's media billing configuration
          </a>
          <span>. This will allow you to publish campaigns that incur media spend.</span>
        </Alert>
      );
    } else if (showMediaBillingBadStanding) {
      return (
        <Alert className={className} type={AlertType.danger}>
          <span>This project has an unpaid media bill. </span>
          <span>Please </span>
          <a
            href={localUrl(event.getItemUrl('settings/billing'))}
            rel={'noreferrer'}
            target={'_blank'}
          >
            pay any outstanding media bills{' '}
          </a>
          <span>for this project. </span>
          <span>
            If this project remains delinquent, its in-progress campaigns that incur media spend
            will stop spending and draft campaigns will not be allowed to publish.
          </span>
        </Alert>
      );
    }
  }
  return null;
}

export default observer(RouteAlert);
