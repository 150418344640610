import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { Nav, NavItem, NavMenu } from '@feathr/components';
import { useFlags, useLocalUrl, useRole } from '@feathr/extender/state';

import HomeMarketingNav from './HomeMarketingNav';

function HomeNav(): JSX.Element | null {
  const { t } = useTranslation();
  const { showDashboard } = useFlags();
  const localUrl = useLocalUrl();
  const { isSudoer } = useRole();
  const match = useRouteMatch<{ accountId: string; primary: string }>({
    path: '/:accountId/:primary',
  });

  if (!match) {
    return null;
  }

  return (
    <Nav indented={true} label={'Home navigation'} type={'vertical'}>
      {showDashboard && <NavItem to={localUrl('/dashboard')}>{t('Dashboard')}</NavItem>}
      <NavMenu hasDefaultChild={true} menu={<HomeMarketingNav />} to={localUrl('/marketing')}>
        {t('Marketing')}
      </NavMenu>
      {isSudoer && <NavItem to={localUrl('/accounts')}>{t('Accounts')}</NavItem>}
    </Nav>
  );
}

export default HomeNav;
