import type { ReactNode } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import type { IPerson } from '@feathr/blackbox';
import { useLocalUrl } from '@feathr/extender/state';
import { cssVar } from '@feathr/hooks';

interface IProps {
  person: IPerson;
  shared?: boolean;
}

interface IBoundaryProps {
  children?: ReactNode;
  name: string;
}

class ErrorBoundary extends React.Component<IBoundaryProps> {
  public static getDerivedStateFromError() {
    return { hasError: true };
  }

  public state = { hasError: false };

  public render() {
    const { children, name } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return name;
    }
    return children;
  }
}

function PersonName({ person, shared }: IProps) {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  const isNotAnonymous = person.name && person.name.trim().length !== 0;
  const placeholder = person.placeholder || {
    name: t('Anonymous'),
    color_hex: cssVar('--color-text-body'),
    color_name: t('Gray'),
    bird: t('Pigeon'),
  };
  const name = isNotAnonymous ? person.name! : placeholder.name || t('Anonymous')!;
  return (
    <div
      style={{
        fontWeight: isNotAnonymous ? 600 : 400,
        color: cssVar('--color-primary-3'),
      }}
    >
      <ErrorBoundary name={name}>
        {shared ? (
          <span>{name}</span>
        ) : (
          // TODO: Change person from IPerson to Person so we can use class methods.
          <Link to={localUrl(`/data/people/${person.id}/activity`)}>{name}</Link>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default PersonName;
