import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { IObservableArray } from 'mobx';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import type { IStats } from '@feathr/blackbox';
import type {
  Campaign,
  Goal as GoalModel,
  Segment,
  Segments as SegmentsCollection,
} from '@feathr/blackbox';
import { CampaignState, MAX_GOALS } from '@feathr/blackbox';
import {
  AlertV2,
  Button,
  ButtonValid,
  CardActions,
  CardContent,
  CardHeader,
  CardV2,
  EAlertV2Type,
  Fieldset,
  Form,
  Radios,
} from '@feathr/components';
import Goal from '@feathr/extender/components/Goal';
import { StoresContext, useLocalUrl } from '@feathr/extender/state';
import { flattenErrors, hasDuplicates } from '@feathr/hooks';
import type { TValidateGrouped } from '@feathr/rachis';

import AdvancedConversionTrackingSettings from './AdvancedConversionTrackingSettings';

import * as styles from './StepGoals.css';

interface IButtonProps {
  goals: IObservableArray<GoalModel>;
  onNext: () => void;
}

interface IProps extends IButtonProps {
  onPrev: () => void;
  campaign: Campaign;
  disabled?: boolean;
}

export interface IStepGoalsErrors extends TValidateGrouped {
  goals?: string[];
}

const maxGoalError = `You may not assign more than five goals to a single campaign.
 If you need to capture many different activities as conversions, consider
 using the Advanced conversion tracking mode`;

export function validateStepGoals(
  goals: IObservableArray<GoalModel>,
  goalSegments?: Segment[],
): IStepGoalsErrors {
  if (goals.filter((g) => !g.get('is_archived')).length === 0) {
    return { goals: [] };
  }

  if (goals.filter((g) => !g.get('is_archived')).length >= 6) {
    return { goals: [maxGoalError] };
  }

  const errorMessages: IStepGoalsErrors = { goals: [] };
  errorMessages.goals = goals
    .filter((g) => !g.get('is_archived'))
    .map((g) => {
      const errors = g.validate(['segment', 'conv_value'], false).errors;
      if (errors.length > 0) {
        return errors[0];
      }
      return undefined;
    })
    .filter((msg) => !!msg) as string[];
  if (goalSegments) {
    const segmentIds: string[] = [];
    errorMessages.goals = errorMessages.goals.concat(
      goalSegments
        .map((g) => {
          segmentIds.push(g.id);
          const errors = g.validate([], false).errors;
          if (!(g.get('predicates', []) || []).every((p) => p.kind !== 'attribute')) {
            errors.push('Groups with filters on person attributes cannot be used as goal groups.');
          }
          if (errors.length > 0) {
            return errors[0];
          }

          return undefined;
        })
        .filter((msg) => !!msg) as string[],
    );
    if (hasDuplicates(segmentIds)) {
      errorMessages.goals = errorMessages.goals.concat(
        'Must not use the same group for additional goals.',
      );
    }
  }

  return errorMessages;
}

const NextStepButton = observer(({ goals, onNext }: IButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const { Segments } = React.useContext(StoresContext);
  const segments = getGoalSegments(goals, Segments);
  const validationErrors = validateStepGoals(goals, segments);
  return (
    <ButtonValid errors={flattenErrors(validationErrors)} name={'next_step'} onClick={onNext}>
      {t('Next')}
    </ButtonValid>
  );
});

export function getGoalSegments(
  goals: IObservableArray<GoalModel>,
  Segments: SegmentsCollection,
): Segment[] {
  return goals
    .filter((g) => !!g.get('segment') && !g.get('is_archived'))
    .map((g) => Segments.get(g.get('segment')!));
}

function StepGoals({ campaign, disabled = false, goals, onNext, onPrev }: IProps): JSX.Element {
  const { t } = useTranslation();
  const { Goals } = React.useContext(StoresContext);
  const localUrl = useLocalUrl();

  function addGoal(): void {
    const model = Goals.create({
      parent: campaign.get('id'),
      conv_value: undefined,
      segment: undefined,
      kind: 'campaign',
    });
    runInAction(() => {
      goals.push(model);
    });
  }

  function removeGoal(goal: GoalModel): void {
    if (!goal.isEphemeral) {
      goal.set({ is_archived: true });
    } else {
      goals.remove(goal);
      Goals.remove(goal.id);
    }
  }

  function changeTrackingMode(mode: 'advanced' | 'auto'): void {
    goals.forEach(removeGoal);
    if (mode === 'advanced') {
      const goal = Goals.create({
        parent: campaign.get('id'),
        conv_value: 0,
        segment: undefined,
        conv_type: 'custom',
      });
      runInAction(() => {
        goals.push(goal);
      });
    }
    campaign.set({ conversion_tracking_mode: mode });
  }

  const helpTextAction = campaign.isEmail ? 'interacts with this email' : 'views an ad';

  const description = t(
    'This step is optional, but conversion tracking is often an important measure of success for a campaign. Conversions are typically the objective of the campaign, such as an event registration or a membership signup.',
  );

  const conversionTrackingMode = campaign.get('conversion_tracking_mode');

  const stats: IStats = campaign.get('total_stats');
  const advancedGoal =
    conversionTrackingMode === 'advanced'
      ? goals.find((goal) => !goal.get('is_archived'))
      : undefined;

  const actions = [
    <Button key={'prev'} name={'previous_step'} onClick={onPrev}>
      {t('Previous')}
    </Button>,
    <NextStepButton goals={goals} key={'next'} onNext={onNext} />,
  ];

  const hasMaxGoals = goals.length >= MAX_GOALS;

  function onModeChange(newValue?: string): void {
    if (newValue === 'advanced' || newValue === 'auto') {
      changeTrackingMode(newValue);
    }
  }

  return (
    <Form
      actions={actions}
      className={styles.form}
      description={description}
      label={t('Edit Campaign: Conversion Tracking')}
      width={'wide'}
    >
      <CardV2 contentClassName={styles.tempFix} name={'goals'} width={'full'}>
        <CardHeader title={t('Set Goals')} />
        <CardContent>
          <Trans t={t}>
            <p>
              Choose a goal to track conversions using a group, like people who reached a new member
              signup confirmation page. When a member of this campaign's target audience{' '}
              {{ helpTextAction }}, then joins the goal group, the campaign will record a conversion
              and add the amount defined as the value to the campaign's conversion value.
            </p>
            <p>
              There may be some cases where goals are not flexible enough to capture the behavior
              you want to track as a conversion. For instance, when your audience may purchase one
              of many different items or ticket types, each with a different monetary value. In
              these cases you may want to consider selecting the advanced conversion tracking mode
              below.{' '}
              <a
                href={
                  'https://help.feathr.co/hc/en-us/articles/360063115793-How-to-use-Advanced-Conversions'
                }
                target={'_blank'}
              >
                Learn more about advanced conversion tracking in this article.
              </a>
            </p>
          </Trans>
          {/* Display a warning that conversions may need to be rerun if a goal is added to published campaign */}
          {campaign.get('state') === CampaignState.Published && (
            <AlertV2
              className={styles.alertContainer}
              description={t(
                'To recalculate conversions according to new goal settings, select "Recalculate conversions" from the conversions table on this campaign\'s report.',
              )}
              title={t('Editing the goals of this campaign will make its conversions inaccurate.')}
              type={EAlertV2Type.warning}
            >
              <a href={localUrl(campaign.getItemUrl())} target={'_blank'}>
                {t('View campaign report')}
              </a>
            </AlertV2>
          )}
          <Fieldset>
            <Radios
              dataName={'conversion_tracking_mode'}
              disabled={disabled}
              label={t('Conversion tracking mode')}
              onChange={onModeChange}
              options={[
                { id: 'auto', name: t('Goal') },
                { id: 'advanced', name: t('Advanced') },
              ]}
              value={conversionTrackingMode}
            />
          </Fieldset>
          {conversionTrackingMode === 'advanced' && advancedGoal && (
            <AdvancedConversionTrackingSettings disabled={disabled} goal={advancedGoal} />
          )}
          {conversionTrackingMode === 'auto' && (
            <>
              {goals.filter((g) => !g.get('is_archived')).length > 0 ? (
                <>
                  {goals
                    .filter((g) => !g.get('is_archived'))
                    .map((goal, index) => {
                      return (
                        <Goal
                          disabled={disabled}
                          goal={goal}
                          goals={goals}
                          key={goal.id || index}
                          onRemove={removeGoal}
                          showWarning={!!stats.conversions && !!stats.conversions.full?.num}
                        />
                      );
                    })}
                </>
              ) : (
                <div className={styles.empty}>
                  {t('No Goals. This campaign will not track conversions.')}
                </div>
              )}
            </>
          )}
        </CardContent>
        {conversionTrackingMode === 'auto' && (
          <CardActions>
            <Button
              disabled={hasMaxGoals || disabled}
              id={'addGoal'}
              key={'add'}
              name={'add_goal'}
              onClick={addGoal}
              prefix={<FontAwesomeIcon icon={faPlus} />}
              tooltip={hasMaxGoals && t('Campaigns are limited to 5 goals.')}
            >
              {goals.length >= 1 ? t('Additional goal') : t('Goal')}
            </Button>
          </CardActions>
        )}
      </CardV2>
    </Form>
  );
}

export default observer(StepGoals);
