import type { JSX } from 'react';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@feathr/components';
import ErrorPage from '@feathr/extender/App/ErrorPage';
import { useLocalUrl } from '@feathr/extender/state';

function PermissionRequired(): JSX.Element {
  const localUrl = useLocalUrl();
  const { t } = useTranslation();

  // TODO: Add link to contact support - will be addressed in #3255
  const description = (
    <Trans t={t}>
      <Button type={'link'}>Contact the administrator</Button> or try logging in with a different
      account.
    </Trans>
  );

  return (
    <ErrorPage description={description} title={t('Permission required')}>
      <Button href={localUrl('/marketing/all')} type={'primary'}>
        {t('Take me home')}
      </Button>
    </ErrorPage>
  );
}

export default PermissionRequired;
