import classNames from 'classnames';
import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import type { JSX, ReactNode } from 'react';
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { ToastType } from 'react-toastify';

import type { Campaign, ITemplate, Template } from '@feathr/blackbox';
import { CampaignClass, RecipientType, TemplateClass } from '@feathr/blackbox';
import { Button, Time, toast, Value } from '@feathr/components';
import type {
  Bee,
  BeePluginError,
  IBeeEditorAction,
  IBeeEditorConfig,
  IEntityContentJson,
  IPluginForm,
  TDynamicContentResolveHandler,
  TFormResolveHandler,
  TResolveHandler,
} from '@feathr/extender/components/BeeEditor';
import BeeEditor, {
  EBeeEditorAction,
  formCustomFieldsFilters,
  getMergefieldCustomFieldsFilters,
} from '@feathr/extender/components/BeeEditor';
import TemplateSelectModal from '@feathr/extender/components/TemplateSelectModal';
import { StoresContext, useAccount } from '@feathr/extender/state';
import { TimeFormat, useReactionEffect, useRedirect } from '@feathr/hooks';

import AddDynamicContentDialog from './AddDynamicContentDialog';
import ManageFormDialog from './ManageFormDialog';
import SendPreview from './SendPreview';
import TemplatePreview from './TemplatePreview';

import * as styles from './TemplateEditor.css';

interface ITemplateEditorProps {
  campaign?: Campaign;
  actions?: ReactNode;
  className?: string;
  disableSave?: boolean;
  disableSaveMessage?: ReactNode;
  onTemplateSelect?: (template: Template) => void;
  isChangeable?: boolean;
  template: Template;
  recipientType?: RecipientType;
  isPartnerMessageCampaign?: boolean;
  isReadOnly?: boolean;
}

enum TemplateEditorState {
  ready = 'ready',
  saving = 'saving',
  sending = 'sending',
  previewing = 'previewing',
  addingDynamicContent = 'addingDynamicContent',
  managingForm = 'managingForm',
  resolvingSave = 'resolvingSave',
  resolvingSend = 'resolvingSend',
  resolvingPreview = 'resolvingPreview',
  showingPreview = 'showingPreview',
  showingSend = 'showingSend',
  error = 'error',
}

interface ITemplateEditorState {
  state: TemplateEditorState;
  json?: string;
  form?: IPluginForm['structure'];
  error?: BeePluginError;
  resolve?: TResolveHandler;
  reject?: (error?: Error) => void;
}

const initialState: ITemplateEditorState = {
  state: TemplateEditorState.ready,
  json: '',
  error: undefined,
};

function reducer(state: ITemplateEditorState, action: IBeeEditorAction): ITemplateEditorState {
  const resolveMap: Partial<Record<TemplateEditorState, TemplateEditorState>> = {
    [TemplateEditorState.error]: TemplateEditorState.ready,
    [TemplateEditorState.saving]: TemplateEditorState.resolvingSave,
    [TemplateEditorState.sending]: TemplateEditorState.resolvingSend,
    [TemplateEditorState.previewing]: TemplateEditorState.resolvingPreview,
    [TemplateEditorState.resolvingSave]: TemplateEditorState.ready,
    [TemplateEditorState.resolvingSend]: TemplateEditorState.showingSend,
    [TemplateEditorState.resolvingPreview]: TemplateEditorState.showingPreview,
    [TemplateEditorState.showingSend]: TemplateEditorState.ready,
    [TemplateEditorState.showingPreview]: TemplateEditorState.ready,
    [TemplateEditorState.addingDynamicContent]: TemplateEditorState.ready,
    [TemplateEditorState.managingForm]: TemplateEditorState.ready,
  };

  switch (action.type) {
    case EBeeEditorAction.save:
      return { ...state, state: TemplateEditorState.saving };

    case EBeeEditorAction.send:
      return { ...state, state: TemplateEditorState.sending };

    case EBeeEditorAction.preview:
      return { ...state, state: TemplateEditorState.previewing };

    case EBeeEditorAction.error:
      return { ...state, error: action.error, state: TemplateEditorState.error };

    case EBeeEditorAction.addDynamicContent:
      return {
        ...state,
        state: TemplateEditorState.addingDynamicContent,
        resolve: action.resolve,
        reject: action.reject,
      };

    case EBeeEditorAction.manageForm:
      return {
        ...state,
        form: observable(action.form!),
        state: TemplateEditorState.managingForm,
        resolve: action.resolve,
        reject: action.reject,
      };

    case EBeeEditorAction.resolve:
      return {
        ...state,
        state: resolveMap[state.state] as TemplateEditorState,
      };

    default:
      return state;
  }
}

function TemplateEditor({
  actions,
  className,
  disableSave,
  disableSaveMessage,
  onTemplateSelect,
  template,
  isChangeable = false,
  recipientType,
  isPartnerMessageCampaign = false,
  isReadOnly = false,
}: Readonly<ITemplateEditorProps>): JSX.Element {
  const { t } = useTranslation();
  const { Campaigns, CustomFields, Fonts } = useContext(StoresContext);
  const { campaignId } = useParams<{ campaignId: string }>();
  const account = useAccount();
  const location = useLocation();
  const [redirect] = useRedirect();
  const params = new URLSearchParams(location.search);
  const redirectParam = params.get('redirect');
  const [editorState, dispatchEditorAction] = useReducer(reducer, initialState);
  const rootRef = useRef<HTMLDivElement>(null);
  const [beePlugin, setBeePlugin] = useState<Bee | null>(null);
  const templateContent = template.get('content');

  const unsavedContent = localStorage.getItem(template.id);

  useReactionEffect(
    () => unsavedContent !== null,
    () => {
      async function patchUnsavedChanges(): Promise<void> {
        const parsedContent = JSON.parse(unsavedContent!);
        template.set({ content: parsedContent });
        const response = await template.patchDirty();
        if (response.isErrored) {
          toast(t('There was an error updating your unsaved changes.'), {
            type: ToastType.ERROR,
          });
          return;
        }
        toast(t('Your unsaved changes were updated.'), {
          type: ToastType.INFO,
        });
        localStorage.removeItem(template.id);
      }

      patchUnsavedChanges();
    },
    { once: true },
  );

  if (!recipientType) {
    const { PinpointEmail, SmartPinpointEmail, AutoPinpointEmail, PinpointPartnerMessage } =
      CampaignClass;
    const campaignClass = campaignId ? Campaigns.get(campaignId).get('_cls') : undefined;
    if (
      campaignClass &&
      [PinpointEmail, SmartPinpointEmail, AutoPinpointEmail].includes(campaignClass)
    ) {
      recipientType = RecipientType.Person;
    } else if (campaignClass === PinpointPartnerMessage) {
      isPartnerMessageCampaign = true;
      recipientType = RecipientType.Partner;
    }
  }

  const fonts = Fonts.list({ only: ['id', 'name'], ordering: ['id'] });

  const cls = template.get('_cls');
  const mergefieldCustomFields = CustomFields.list({
    filters: getMergefieldCustomFieldsFilters(template.get('_cls')),
    pagination: { page: 0, page_size: 1000 },
  });

  const { Page, LandingPage, ReferralPage } = TemplateClass;

  const formCustomFields = [Page, LandingPage, ReferralPage].includes(cls)
    ? CustomFields.list({
        filters: formCustomFieldsFilters,
        pagination: { page: 0, page_size: 1000 },
      })
    : CustomFields.newListResponse();

  const toastError = useCallback(
    (err?: string) => {
      localStorage.setItem(template.id, JSON.stringify(templateContent));
      toast(t('Something went wrong, please try again. {{- error}}', { error: err ?? '' }), {
        type: 'error',
      });
    },
    [t, template, templateContent],
  );

  async function handleManageForm(
    resolve: TFormResolveHandler,
    reject: (e?: Error) => void,
    form: IPluginForm['structure'],
  ): Promise<void> {
    await dispatchEditorAction({
      type: EBeeEditorAction.manageForm,
      resolve,
      reject,
      form,
    });
  }

  function onError(error: BeePluginError): void {
    dispatchEditorAction({ type: EBeeEditorAction.error, error });
  }

  function onSave(json: string): void {
    dispatchEditorAction({ type: EBeeEditorAction.resolve, json });
  }

  function onSend(): void {
    dispatchEditorAction({ type: EBeeEditorAction.resolve, json: undefined });
  }

  const handleChangeTemplate = useCallback(
    (newTemplate: ITemplate) => {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { content, dynamic_content, name, metatags, subject, thumbnail_url } = newTemplate;
      template.set({
        content,
        dynamic_content,
        name,
        metatags,
        subject,
        thumbnail_url,
        _cls: TemplateClass.PinpointEmail,
      });

      if (beePlugin) {
        const templateJSON: IEntityContentJson = {
          page: JSON.parse(template.get('content').json).page as IEntityContentJson['page'],
        };
        beePlugin.load(templateJSON);
      }

      if (onTemplateSelect) {
        onTemplateSelect(template);
      }
    },
    [onTemplateSelect, template, beePlugin],
  );

  useEffect(() => {
    if (
      [
        TemplateEditorState.resolvingPreview,
        TemplateEditorState.resolvingSend,
        TemplateEditorState.resolvingSave,
      ].includes(editorState.state)
    ) {
      const content: ITemplate['content'] = { ...templateContent };

      // Only update content.json if it's different from the current content.json
      if (editorState.json && editorState.json !== content.json) {
        content.json = editorState.json;
        template.set({ content });
      }

      // TODO: Can we skip to EBeeEditorAction.ready if content.isDirty is false?
      if (editorState.state === TemplateEditorState.resolvingSave) {
        onSave(editorState.json!);
      } else {
        dispatchEditorAction({ type: EBeeEditorAction.resolve });
      }
    } else if (editorState.state === TemplateEditorState.error) {
      toast(editorState.error?.message ?? 'Unknown error', {
        type: ToastType.ERROR,
      });
    }
  }, [template, templateContent, editorState]);

  const handleToggleFullscreen = useCallback(() => {
    if (rootRef.current) {
      const el = rootRef.current;
      if (el.requestFullscreen && !document.fullscreenElement) {
        el.requestFullscreen();
      } else if (document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        toastError(
          t("Your browser doesn't support this feature. Try it out in Chrome or Firefox!"),
        );
      }
    }
  }, [t, toastError]);

  function handleToggleSendMode(): void {
    dispatchEditorAction({ type: EBeeEditorAction.send });
    /*
     * TODO: refactor toolbar so we don't need to define this
     * function here, and remove the local reference to the
     * BeePlugin
     */
    beePlugin!.send();
  }

  function handleTogglePreviewMode(): void {
    if (editorState.state === TemplateEditorState.ready) {
      dispatchEditorAction({ type: EBeeEditorAction.preview });
      beePlugin!.save();
    } else if (editorState.state === TemplateEditorState.showingPreview) {
      dispatchEditorAction({ type: EBeeEditorAction.resolve });
    }
  }
  //

  function isBannerTemplate(template: Template): boolean {
    return (
      [TemplateClass.Banner, TemplateClass.ReferralBanner].includes(template.get('_cls')) &&
      template.get('bannersnack_enabled')
    );
  }

  async function handleSave(): Promise<void> {
    const response = await template.patchDirty();

    if (response.isErrored) {
      toast(t('Something went wrong. Please try again.'), { type: ToastType.ERROR });
      return;
    }

    if (!isBannerTemplate(template)) {
      toast(
        t('Changes saved. Domains linked in this email were added to your domain allow list.'),
        {
          type: ToastType.SUCCESS,
        },
      );
    } else {
      toast(t('Changes saved.'), { type: ToastType.SUCCESS });
    }
  }

  function onAutoSave(json: string): void {
    // Only auto-save if the template is not already being saved
    if (editorState.state === TemplateEditorState.saving) {
      return;
    }

    // Only auto-save if the template is dirty
    if (!template.isDirty) {
      return;
    }

    async function saveAndDispatch(): Promise<void> {
      dispatchEditorAction({ type: EBeeEditorAction.save, json });
      await handleSave();
      dispatchEditorAction({ type: EBeeEditorAction.resolve });
    }

    saveAndDispatch();
  }

  // need to test this
  async function handleRedirect(): Promise<void> {
    await handleSave();
    redirect();
  }

  function handleCloseDialog(): void {
    dispatchEditorAction({ type: EBeeEditorAction.resolve });
  }

  /*
   * TODO: refactor this toolbar so we don't need to have
   * a stateful reference to the BeePlugin at this level
   */
  const toolbar: ReactNode = (
    <>
      {actions}
      {isChangeable && (
        <TemplateSelectModal
          label={t('Change template')}
          onSelect={handleChangeTemplate}
          templateClass={TemplateClass.PinpointEmail}
        />
      )}
      <Button id={'toggleFullscreen'} onClick={handleToggleFullscreen}>
        {t('Full screen')}
      </Button>
      {[TemplateClass.ReferralEmail, TemplateClass.PinpointEmail].includes(
        template.get('_cls'),
      ) && (
        <Button
          disabled={!beePlugin || editorState.state !== TemplateEditorState.ready}
          isLoading={editorState.state === TemplateEditorState.sending}
          name={'send_preview'}
          onClick={handleToggleSendMode}
        >
          {t('Send preview')}
        </Button>
      )}
      <Button
        disabled={
          !beePlugin ||
          ![TemplateEditorState.ready, TemplateEditorState.showingPreview].includes(
            editorState.state,
          )
        }
        isLoading={editorState.state === TemplateEditorState.previewing}
        name={'show_preview'}
        onClick={handleTogglePreviewMode}
      >
        {editorState.state === TemplateEditorState.showingPreview
          ? t('Back to editor')
          : t('Show preview')}
      </Button>
      <Button
        disabled={
          !beePlugin ||
          disableSave ||
          !template.isDirty ||
          ![TemplateEditorState.ready, TemplateEditorState.showingPreview].includes(
            editorState.state,
          )
        }
        isLoading={editorState.state === TemplateEditorState.saving}
        name={'save_design'}
        onClick={handleSave}
        tooltip={disableSave ? disableSaveMessage : undefined}
        type={'primary'}
      >
        {t('Save design')}
      </Button>
      <Value
        className={styles.lastSaved}
        label={t('Last saved:')}
        value={
          <Time format={TimeFormat.timeFromNow} timestamp={template.get('date_last_modified')} />
        }
      />
      {redirectParam && (
        <Fragment key={'redirect'}>
          <div className={styles.divider} />
          <Button
            disabled={editorState.state !== TemplateEditorState.ready}
            isLoading={editorState.state === TemplateEditorState.saving}
            name={'return'}
            onClick={handleRedirect}
            type={'primary'}
          >
            {recipientType === RecipientType.Partner
              ? t('Return to message')
              : t('Return to campaign')}
          </Button>
        </Fragment>
      )}
    </>
  );

  function onChange(json: string): void {
    template.set({ content: { ...templateContent, json } });
  }

  const config: Omit<IBeeEditorConfig, 'mergefields'> = {
    actions: {
      handleManageForm,
      onChange,
      onError,
      onAutoSave,
      onSave,
      onSend,
      dispatchEditorAction,
      setBeePlugin,
    },
    models: { account, fonts, formCustomFields, mergefieldCustomFields },
    shouldParseMergeTags: isPartnerMessageCampaign,
    t,
  };

  return (
    <BeeEditor
      className={classNames({
        [styles.hidden]: [
          TemplateEditorState.showingPreview,
          TemplateEditorState.showingSend,
          TemplateEditorState.addingDynamicContent,
          TemplateEditorState.managingForm,
        ].includes(editorState.state),
      })}
      config={config}
      isLoading={mergefieldCustomFields.isPending || formCustomFields.isPending}
      isReadOnly={isReadOnly}
      setBeePlugin={setBeePlugin}
      template={template}
      toolbar={toolbar}
      wrapperClassName={className}
      wrapperId={'editorRoot'}
      wrapperRef={rootRef}
    >
      {editorState.state === TemplateEditorState.showingPreview && (
        <TemplatePreview
          recipientType={recipientType}
          template={template}
          visible={editorState.state === TemplateEditorState.showingPreview}
        />
      )}
      {editorState.state === TemplateEditorState.showingSend && (
        <SendPreview
          onClose={handleCloseDialog}
          recipientType={recipientType}
          template={template}
        />
      )}
      {editorState.state === TemplateEditorState.addingDynamicContent && (
        <AddDynamicContentDialog
          onClose={handleCloseDialog}
          reject={editorState.reject}
          resolve={editorState.resolve as TDynamicContentResolveHandler}
          template={template}
        />
      )}
      {editorState.state === TemplateEditorState.managingForm && editorState.form && (
        <ManageFormDialog
          form={editorState.form}
          onClose={handleCloseDialog}
          reject={editorState.reject}
          resolve={editorState.resolve as TFormResolveHandler}
          template={template}
        />
      )}
    </BeeEditor>
  );
}

export default observer(TemplateEditor);
