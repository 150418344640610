import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash.capitalize';
import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { Campaign } from '@feathr/blackbox';
import { CampaignLabelMap } from '@feathr/blackbox';
import { Chip, Time, TimeRange } from '@feathr/components';
import type { IPageProps } from '@feathr/extender/App/Page';
import Page from '@feathr/extender/App/Page';
import { useUser } from '@feathr/extender/state';
import {
  campaignColorMap,
  campaignIconMap,
  campaignStateColorMap,
} from '@feathr/extender/styles/campaign';
import { TimeFormat } from '@feathr/hooks';

import styles from './CampaignPage.css';

export interface IProps extends IPageProps {
  campaign: Campaign;
  children: React.ReactNode;
}

function CampaignPage({ campaign, children, ...props }: IProps): JSX.Element {
  const { t } = useTranslation();
  const user = useUser();

  const cls = campaign.get('_cls');
  const state = campaign.get('state');
  const timezone = user.get('timezone');

  function getCampaignDate(campaign: Campaign, timezone: string): JSX.Element {
    /*
     * Single Send campaigns are also email campaigns.
     * We check for them first because they have a start date. The other types have a date range.
     */
    if (campaign.isSingleSend) {
      return (
        <Time
          className={styles.daterange}
          format={TimeFormat.shortDateTime}
          formatLocal={true}
          timestamp={campaign.get('date_send_start')}
          timezone={timezone}
        />
      );
    }
    if (campaign.isEmail) {
      return (
        <TimeRange
          className={styles.daterange}
          end={campaign.get('date_send_end')}
          format={TimeFormat.shortDateTime}
          formatLocal={true}
          start={campaign.get('date_send_start')}
          timezone={timezone}
        />
      );
    }
    if (campaign.isAdCampaign) {
      return (
        <TimeRange
          className={styles.daterange}
          end={campaign.localEndTime}
          format={TimeFormat.pickerDateTime}
          start={campaign.localStartTime}
        />
      );
    }
    // Handle non-email campaign types
    return (
      <TimeRange
        className={styles.daterange}
        end={campaign.get('date_end')}
        format={TimeFormat.shortDate}
        start={campaign.get('date_start')}
      />
    );
  }

  return (
    <Page
      {...props}
      description={
        <div className={styles.metadata}>
          {getCampaignDate(campaign, timezone)}
          <Chip
            prefix={<FontAwesomeIcon icon={campaignIconMap.get(cls)!} />}
            theme={campaignColorMap.get(cls)}
          >
            {CampaignLabelMap.get(cls) || t('Campaign')}
          </Chip>
          <Chip theme={campaignStateColorMap.get(state)}>{capitalize(state)}</Chip>
        </div>
      }
      width={'wide'}
    >
      {children}
    </Page>
  );
}

export default observer(CampaignPage);
