import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Page from '@feathr/extender/App/Page';
import { useAccount, useUser } from '@feathr/extender/state';

import AttributionOptions from './AttributionOptions';
import ConversionsOptions from './ConversionsOptions';

function ConversionsPage(): JSX.Element {
  const user = useUser();
  const account = useAccount();
  const { t } = useTranslation();

  const isAdmin = user.isSudoer || user.isAdmin;

  return (
    <Page title={t('Conversions')}>
      {isAdmin && <ConversionsOptions account={account} />}
      <AttributionOptions account={account} />
    </Page>
  );
}

export default observer(ConversionsPage);
