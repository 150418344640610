import debounce from 'lodash.debounce';
import { set } from 'mobx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import type { IDynamicContent } from '@feathr/blackbox';
import { ColorPicker, DatePicker, Fieldset, Form, NumberInput } from '@feathr/components';
import { DEFAULT_DEBOUNCE_WAIT, moment, TimeFormat } from '@feathr/hooks';

import FontSelect from './FontSelect';

import * as styles from './TemplateEditor.css';

interface IProps {
  dynamicContent: IDynamicContent;
}

function CountdownTimerForm({ dynamicContent }: IProps) {
  const getCountdownTimerURL = () => {
    const searchParams = new URLSearchParams({
      font: dynamicContent.font,
      bg: dynamicContent.background_color,
      color: dynamicContent.color,
      width: `${dynamicContent.width}`,
      frames: `${dynamicContent.frames}`,
      time: dynamicContent.time,
    } as Record<string, string>);
    return `${FLAPBOOK_URL}?${searchParams.toString()}`;
  };
  const getDebouncedSetter = (key: keyof IDynamicContent) => {
    const debounced = debounce((value: number) => {
      set(dynamicContent, { [key]: value });
    }, DEFAULT_DEBOUNCE_WAIT);
    return (newValue?: number) => {
      debounced(newValue || 0);
    };
  };

  function handleColorChange(color?: string) {
    set(dynamicContent, {
      color: color?.split('#')[1],
    });
  }

  function handleBackgroundColorChange(color?: string) {
    set(dynamicContent, {
      background_color: color?.split('#')[1],
    });
  }

  function handleEndDateChange(date: Date | null) {
    set(dynamicContent, {
      time: moment.utc(date || undefined).format(TimeFormat.isoDate),
    });
  }

  return (
    <>
      <img alt={''} className={styles.countdownPreview} src={getCountdownTimerURL()} />
      <Form label={'Countdown Timer Configuration'}>
        <Fieldset>
          <ColorPicker
            helpText={'The hexidecimal value for the color of the text in the countdown.'}
            label={'Color'}
            onChange={handleColorChange}
            value={`#${dynamicContent.color}`}
          />
          <ColorPicker
            helpText={'The hexidecimal value for the color of the background of the countdown.'}
            label={'Background Color'}
            onChange={handleBackgroundColorChange}
            value={`#${dynamicContent.background_color}`}
          />
          <NumberInput
            helpText={'The width of the countdown animation in pixels.'}
            label={'Width'}
            max={900}
            min={400}
            onChange={getDebouncedSetter('width')}
            value={dynamicContent.width}
          />
          <NumberInput
            helpText={'The number of frames in the countdown animation.'}
            label={'Duration in Frames'}
            max={90}
            min={10}
            onChange={getDebouncedSetter('frames')}
            value={dynamicContent.frames}
          />
          <DatePicker
            label={'End Date'}
            onChange={handleEndDateChange}
            selected={moment.utc(dynamicContent.time).toDate()}
          />
          <FontSelect dynamicContent={dynamicContent} />
        </Fieldset>
      </Form>
    </>
  );
}

export default observer(CountdownTimerForm);
