import classNames from 'classnames';
import type { TFunction } from 'i18next';
import type { IObservableArray } from 'mobx';
import { Observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';
import type { RowRenderProps } from 'react-table';

import type { Breadcrumb, CustomField } from '@feathr/blackbox';
import type { IColumn } from '@feathr/components';
import { TableColumnHeader } from '@feathr/components';
import CampaignChip from '@feathr/extender/components/CampaignChip';
import DeviceChip from '@feathr/extender/components/DeviceChip';
import FlavorChip from '@feathr/extender/components/FlavorChip';
import type { TFlavor } from '@feathr/extender/components/FlavorChip/FlavorChip';
import MarkerMap from '@feathr/extender/components/MarkerMap';
import PartnerChip from '@feathr/extender/components/PartnerChip';
import { TimeFormat } from '@feathr/hooks';

import { DateCell, fieldTypeToCellMap, TextCell } from '../DataTableCells';
import EventChip from './EventChip';
import PersonCell from './PersonCell';
import TagChip from './TagChip';

import * as tableStyles from '@feathr/components/dist/Table/Table.css';
import * as styles from './BreadcrumbColumns.css';

interface IRow extends RowRenderProps {
  original: Breadcrumb;
}

function URLCell(fieldPath: string, fieldKey?: string) {
  return ({ original }: IRow): JSX.Element => {
    return (
      <Observer>
        {(): JSX.Element => (
          <div className={styles.cellWrapper}>
            <div className={styles.preWrapper}>
              <pre>{fieldKey ? original.get(fieldPath)[fieldKey] : original.get(fieldPath)}</pre>
            </div>
          </div>
        )}
      </Observer>
    );
  };
}

export function defaultBreadcrumbColumns(t: TFunction): Array<IColumn<Breadcrumb>> {
  return [
    {
      id: 'd_c',
      accessor: (original: Breadcrumb): string => original.get('d_c'),
      Header: TableColumnHeader({
        sortType: 'numeric',
        title: t('Date/Time'),
      }),
      headerClassName: tableStyles.sort,
      Cell: DateCell('d_c', undefined, TimeFormat.timeFromNow),
    },
    {
      id: 'per_id',
      Header: TableColumnHeader({
        title: t('Person'),
      }),
      className: classNames(tableStyles.cell, styles.person),
      Cell(row): JSX.Element {
        return <PersonCell {...row} />;
      },
    },
    {
      id: 'flvr',
      Header: TableColumnHeader({
        title: t('Flavor'),
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        return (
          <Observer>
            {(): JSX.Element => <FlavorChip flavor={original.get('flvr') as TFlavor} />}
          </Observer>
        );
      },
    },
    {
      id: 'loc_url',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('URL'),
      }),
      headerClassName: tableStyles.sort,
      Cell: URLCell('loc_url'),
    },
    {
      id: 'trgt',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Target URL'),
      }),
      headerClassName: tableStyles.sort,
      Cell: URLCell('trgt'),
    },
    {
      id: 'rfr',
      Header: TableColumnHeader({
        sortType: 'alpha',
        title: t('Referrer URL'),
      }),
      headerClassName: tableStyles.sort,
      Cell: URLCell('rfr'),
    },
    {
      id: 'location',
      Header: TableColumnHeader({
        title: t('Location'),
      }),
      sortable: false,
      width: 122,
      Cell({ original }): JSX.Element {
        const loc = original.get('loc') || {};
        return (
          <div className={styles.cellWrapper}>
            <MarkerMap
              className={styles.map}
              emptyMessage={'-'}
              lat={loc.latitude!}
              lng={loc.longitude!}
              zoom={4}
            />
          </div>
        );
      },
    },
    {
      id: 'device',
      Header: TableColumnHeader({
        title: t('Device'),
      }),
      sortable: false,
      width: 100,
      Cell({ original }): JSX.Element {
        return <DeviceChip breadcrumb={original} />;
      },
    },
    {
      id: 'e_id',
      Header: TableColumnHeader({
        title: t('Event'),
      }),
      sortable: false,
      Cell(row): JSX.Element {
        return <EventChip {...row} />;
      },
    },
    {
      id: 'cpn_id',
      Header: TableColumnHeader({
        title: t('Campaign'),
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        const campaignId = original.get('cpn_id');
        return <>{campaignId ? <CampaignChip id={campaignId} /> : '-'}</>;
      },
    },
    {
      id: 'p_id',
      Header: TableColumnHeader({
        title: t('Partner'),
      }),
      sortable: false,
      Cell({ original }): JSX.Element {
        const partnerId = original.get('p_id');
        return <>{partnerId ? <PartnerChip id={partnerId} /> : '-'}</>;
      },
    },
    {
      id: 'tag_id',
      Header: TableColumnHeader({
        title: t('Tag'),
      }),
      sortable: false,
      Cell(row): JSX.Element {
        return <TagChip {...row} />;
      },
    },
    {
      id: 'utm_params.utm_campaign',
      Header: TableColumnHeader({
        title: t('UTM Campaign'),
      }),
      Cell: TextCell('utm_campaign', 'utm_params'),
    },
    {
      id: 'utm_params.utm_source',
      Header: TableColumnHeader({
        title: t('UTM Source'),
      }),
      Cell: TextCell('utm_source', 'utm_params'),
    },
    {
      id: 'utm_params.utm_medium',
      Header: TableColumnHeader({
        title: t('UTM Medium'),
      }),
      Cell: TextCell('utm_medium', 'utm_params'),
    },
    {
      id: 'utm_params.utm_content',
      Header: TableColumnHeader({
        title: t('UTM Content'),
      }),
      Cell: TextCell('utm_content', 'utm_params'),
    },
    {
      id: 'utm_params.utm_term',
      Header: TableColumnHeader({
        title: t('UTM Term'),
      }),
      Cell: TextCell('utm_term', 'utm_params'),
    },
  ];
}

function BreadcrumbColumns(
  customFields: IObservableArray<CustomField>,
  t: TFunction,
): Array<IColumn<Breadcrumb>> {
  return [
    ...defaultBreadcrumbColumns(t),
    ...customFields.map((field) => ({
      id: `cust_params.${field.get('f_key')}`,
      Header: TableColumnHeader({
        title: field.get('u_key'),
      }),
      sortable: true,
      Cell: fieldTypeToCellMap.get(field.get('data_type'))!(field.get('u_key'), 'custom_data'),
    })),
  ];
}

export function filterBreadcrumbColumns(
  columnIds: string[],
  customFields: IObservableArray<CustomField>,
  t: TFunction,
): Array<IColumn<Breadcrumb>> {
  const columns = BreadcrumbColumns(customFields, t);
  return columns.filter((column: IColumn<Breadcrumb>) => columnIds.includes(column.id!));
}

export default BreadcrumbColumns;
