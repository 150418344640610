import { observer } from 'mobx-react-lite';
import type { JSX } from 'react';
import React from 'react';

import type { Billable } from '@feathr/blackbox';
import { CardV2 as Card, Input } from '@feathr/components';

interface IProps {
  billable: Billable;
  isDefault?: boolean;
}

function BillingInfoCard({ billable, isDefault = false }: Readonly<IProps>): JSX.Element {
  // TODO: When hooking up default billable in #3363, set this to an attribute from billable
  const isDefaultFormBillable = false;
  const calculatedIsDefault = isDefaultFormBillable ?? isDefault;

  return (
    <Card>
      <Card.Header title={'Billing info'} />
      <Card.Content addVerticalGap={true}>
        <Input attribute={'name'} label={'Full name'} model={billable} type={'text'} />
        <Input attribute={'email'} label={'Email address'} model={billable} type={'email'} />
        {!calculatedIsDefault && (
          <Input
            attribute={'description'}
            helpText={'Used as the label for this billing configuration thoughout the site.'}
            label={'Description'}
            model={billable}
            type={'text'}
          />
        )}
      </Card.Content>
    </Card>
  );
}

export default observer(BillingInfoCard);
